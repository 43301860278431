import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";

class NotepadManager {
  form(id = null, trainingCourseId = null) {
    let prefix = "/app/notepad/form";
    if (trainingCourseId) {
      prefix = "/app/studea-manager/" + trainingCourseId + "/notepad/form";
    }
    return apiConnection
      .get(prefix + (id ? "/" + id : ""))
      .then((response) => response);
  }

  post(params = {}, id = null, trainingCourseId = null) {
    let prefix = "/app/notepad/";
    if (trainingCourseId) {
      prefix = "/app/studea-manager/" + trainingCourseId + "/notepad/";
    }
    const url = prefix + (id ? id : "add");
    return id
      ? apiConnection.put(url, params)
      : apiConnection.post(url, params);
  }

  remove(id, trainingCourseId) {
    return new Promise((resolve) => {
      notificationManager
        .showAlert(
          "error",
          i18n.global.tc("global.delete.title", 2, {
            name: i18n.global.tc("studea_manager.note.title", 1).toLowerCase(),
          }),
          i18n.global.tc("global.delete.sub_title", 2, {
            name: i18n.global.tc("studea_manager.note.title", 1).toLowerCase(),
          }),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            let prefix = "/app/notepad/remove/";
            if (trainingCourseId) {
              prefix =
                "/app/studea-manager/" + trainingCourseId + "/notepad/remove/";
            }
            apiConnection.delete(prefix + id).then((data) => {
              resolve(data);
              if (data.success) {
                notificationManager.showNotification(
                  data.hasError ? "error" : "success",
                  data.hasError
                    ? data.detail[0].message
                    : i18n.global.tc("global.delete.success", 2, {
                        name: i18n.global.tc("studea_manager.note.title", 1),
                      }),
                );
              } else if (!data.success && data.message) {
                notificationManager.showNotification("error", data.message);
              }
            });
          }
        });
    });
  }

  pin(note) {
    return apiConnection.put("/app/notepad/pin/" + note.id).then((data) => {
      if (data.success) {
        notificationManager.showNotification(
          "success",
          i18n.global.t(
            "notepad." + (note.pinned ? "un" : "") + "pinned_success",
          ),
        );
      } else if (!data.success && data.message) {
        notificationManager.showNotification("error", data.message);
      }
    });
  }
}

export const notepadManager = new NotepadManager();
