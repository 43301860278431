import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { defineStore } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { useMainStore } from "@/store/main/main";
import { userManager } from "@/services/security/user-manager";

export const useQuestionnaireStore = defineStore({
  id: "Questionnaire",
  state: () => {
    return {
      questionnaires: [],
      questionnaire: {},
      model: {},
      toSign: [],
      toComplete: [],
      questionnaireLoading: false,
      userProgression: 0,
      triadProgression: 0,
      total: 0,
      totalTriad: 0,
    };
  },

  actions: {
    fetchQuestionnaires(id) {
      return questionnaireManager
        .loadQuestionnaires(id)
        .then((questionnaires) => {
          this.questionnaires = questionnaires;
          this.setQuestionnaireToDoAndProgress(useTriadStore().triad);
        });
    },

    fetchQuestionnaire(payload) {
      if (!payload.refresh) {
        this.setQuestionnaireRequest();
      }
      if (useMainStore().isInternet) {
        this.model = [];
        return questionnaireManager
          .loadQuestionnaire(payload.questionnaire, payload.triad)
          .then((questionnaire) => {
            if (questionnaire) {
              this.initQuestionnaireDatas(questionnaire);
            }
          });
      }
    },

    initQuestionnaireDatas(questionnaire) {
      this.questionnaire = questionnaire;
      this.model = questionnaireManager.buildModel();
      this.setQuestionnaireSuccess();
    },

    setQuestionnaireToDoAndProgress(triad) {
      this.questionnaires = triad.questionnaires;
      this.toComplete = [];
      this.toSign = [];
      this.totalTriad = 0;
      this.total = 0;
      this.userProgression = 0;
      this.triadProgression = 0;
      const profile = triad.userRoles
        ? userManager.getProfileKey(triad.userRoles[0])
        : null;
      if (triad.questionnaires && Object.keys(triad.questionnaires).length) {
        Object.values(triad.questionnaires).forEach((year) => {
          Object.values(year.categories).forEach((category) => {
            Object.values(category.originalQuestionnaires).forEach(
              (questionnaire) => {
                if (
                  !questionnaire.isCopy &&
                  (!questionnaire.canBeCopiedBy ||
                    (questionnaire.canBeCopiedBy &&
                      !questionnaire.canBeCopiedBy.length))
                ) {
                  if (questionnaire.userIsSignatory) {
                    this.total++;
                  }
                  if (questionnaire.userHasToComplete) {
                    this.toComplete.push({
                      title: questionnaire.title,
                      date: questionnaire.completenessDate
                        ? questionnaire.completenessDate
                        : questionnaire.profileStatus[profile].endDate,
                      id: questionnaire.id,
                    });
                  } else if (questionnaire.userHasToSign) {
                    this.toSign.push({
                      title: questionnaire.title,
                      date: questionnaire.completenessDate
                        ? questionnaire.completenessDate
                        : questionnaire.profileStatus[profile].endDate,
                      id: questionnaire.id,
                    });
                  }

                  if (questionnaire.userHasSigned) {
                    this.userProgression++;
                  }

                  this.totalTriad += questionnaire.signedBy.length;

                  Object.values(questionnaire.lastReply).forEach((reply) => {
                    if (reply && reply.signedAt) {
                      this.triadProgression++;
                    }
                  });
                }
              },
            );
          });
        });
      }
    },

    setAnswer(payload) {
      if (this.model[payload.id]) {
        if (payload.value) {
          this.model[payload.id].vars.value = payload.value;
        }
        if (payload.comments) {
          this.model[payload.id].vars.comments = payload.comments;
        }
      }
    },

    init() {
      this.questionnaires = [];
      this.questionnaire = {};
      this.model = {};
      this.toSign = [];
      this.toComplete = [];
      this.questionnaireLoading = false;
      this.userProgression = 0;
      this.triadProgression = 0;
      this.total = 0;
      this.totalTriad = 0;
    },

    setQuestionnaireRequest() {
      this.questionnaireLoading = true;
    },

    setQuestionnaireSuccess() {
      this.questionnaireLoading = false;
    },

    setToSign(data) {
      this.toSign = data;
    },

    setToComplete(data) {
      this.toComplete = data;
    },

    isInterviewDateVisibleBy(questionnaire, profile) {
      return questionnaire.interviewDateVisibleBy.includes(profile);
    },
  },
});
