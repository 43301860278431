<template>
  <div v-if="type === 2 && itemType === 'field'">
    {{ answers ? answers.value : "" }}
  </div>
  <div v-else-if="[2, 3, 4].includes(type) && itemType === 'cluster'">
    <FontAwesomeIcon
      icon="fa-light fa-circle-check"
      class="check-icon"
      v-if="answers"
    />
  </div>
  <div v-else-if="type === 11">
    {{ answers ? formatDate(answers) : "" }}
  </div>
  <div v-else-if="type === 12">
    {{ answers ? formatDate(answers, "short_time") : "" }}
  </div>
  <div v-else-if="type === 10" class="answer -file">
    <a :class="isPicture() ? 'answer__image' : ''" :href="fileDownloadLink()">
      <FontAwesomeIcon
        v-if="!isPicture()"
        class="me-1 textStyle"
        icon="fa-light fa-file-download"
      />
      <img v-if="isPicture()" :src="fileSrc()" />
      <span v-else>{{ answers.originalName }}</span>
    </a>
  </div>
  <div v-else-if="type === 4 && itemType === 'field'">
    <template v-for="answer in answers" :key="answer">
      <template v-if="typeof answer === 'object' && answer.checked">
        <span class="answer__coma">{{ answer.value }}</span>
      </template>
      <template v-else-if="typeof answer !== 'object'">
        <span class="answer__coma">{{ answer }}</span>
      </template>
    </template>
  </div>
  <div v-else v-html="answers"></div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";
import { loginManager } from "@/services/security/login-manager";
import { mapState } from "pinia";
import { usePlatformStore } from "@/store/platform/platform";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "QuestionnaireAnswerValueComponent",
  components: { FontAwesomeIcon },
  props: {
    answers: {
      type: [Object, String],
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    itemType: {
      type: String,
      required: false,
      default: "field",
    },
  },
  data() {
    return {
      commenting: false,
      commentModel: null,
      answerItem: this.answer,
      valueKey: 0,
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
      tutorProfileLabel: (store) => store.tutorProfileLabel,
      apprenticeMasterProfileLabel: (store) =>
        store.apprenticeMasterProfileLabel,
      studeaManagerProfileLabel: (store) => store.studeaManagerProfileLabel,
    }),
    ...mapState(useQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
    }),
  },
  methods: {
    formatDate(date, type = "short") {
      return dateFormatter.format(date, type);
    },

    isPicture() {
      if (this.answers) {
        return new RegExp("([./])(gif|jpe?g|png)$").test(this.answers.pathName);
      }
      return false;
    },

    fileDownloadLink() {
      const platform = loginManager?.getApiPath().slice(0, -3);
      if (this.answers) {
        return (
          platform +
          "download-file/path-name-" +
          this.answers.pathName +
          "/original-name-" +
          this.answers.originalName
        );
      }
      return "";
    },

    fileSrc() {
      const platform = loginManager?.getApiPath().slice(0, -3);
      if (this.answers) {
        return (
          platform +
          "inline-file/path-name-" +
          this.answers.pathName +
          "/original-name-" +
          this.answers.originalName
        );
      }
      return "";
    },

    isLoading(loading) {
      this.$emit("isLoading", loading);
    },

    draft(model) {
      this.$emit("draft", model);
    },
  },
};
</script>

<style lang="scss" scoped>
.answer {
  &__image {
    max-height: 150px;
    text-align: center;
    display: block;

    img {
      max-height: 150px;
      width: auto;
      object-fit: contain;
      display: inline-block;
    }
  }

  a {
    font-weight: bold;
    color: var(--primary-color);
    text-decoration: underline;
  }

  &.-file:nth-child(1) {
    border-top: none;
  }

  &__coma {
    &:after {
      content: ", ";
    }

    &:last-child::after {
      content: "";
    }
  }
}

.check-icon {
  font-size: 22px;
  color: $green;
}
</style>
