import { apiConnection } from "@/services/api-connection";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

const URL_EVENT_LIST = "/app/event/list/";

class AbsenceManager {
  loadEventList = (id, params) => {
    return apiConnection
      .get(URL_EVENT_LIST + id, params)
      .then((response) => response);
  };

  loadAbsenceList = (params, trainingCourseId, triadId) => {
    return apiConnection
      .get("/app/" + trainingCourseId + "/absence/list/" + triadId, params)
      .then((response) => response);
  };

  justifyForm = (id, type, trainingCourseId) => {
    return apiConnection
      .get(
        "/app/" + trainingCourseId + "/absence/justify/form/" + type + "/" + id,
      )
      .then((response) => response);
  };

  putJustifyForm = (id, params, trainingCourseId) => {
    return apiConnection
      .put("/app/" + trainingCourseId + "/absence/justify/put/" + id, params)
      .then((response) => response);
  };

  export = (listParams, url, trainingCourseId) => {
    return apiConnection
      .get(
        "/app/studea-manager/" + trainingCourseId + "/absence" + url,
        listParams,
      )
      .then((res) => {
        messengerMessageManager.processMessage(res);
      });
  };

  exportAttendanceCertificate(triad, period) {
    return apiConnection
      .get("/app/attendance-certificate/download/" + triad + "/" + period)
      .then((response) => {
        messengerMessageManager.processMessage(response);
      });
  }
}

export const absenceManager = new AbsenceManager();
