export default {
  tutorPart: ({ plural, named }) =>
    plural([
      "Es tutor de <strong>una </strong>formación",
      `Es tutor de <strong>${named("number")}</strong> formaciones`,
    ]),
  studeaManagerPart: ({ plural, named }) =>
    plural([
      "Actualmente es administrador en <strong>una</strong> formación",
      `Actualmente es administrador en <strong>${named("number")}</strong> formaciones`,
    ]),
  apprenticeMasterPart: ({ plural, named }) =>
    plural([
      "Actualmente es mentor en <strong>una</strong> formación",
      `Actualmente es mentor en <strong>${named("number")}</strong> formaciones`,
    ]),
  promotion: "Promoción",
  "Training courses list": "Lista de formaciones",
  loadingTrainingCourses: "Carga de formaciones en curso...",
  trainingCourseInfos: "Información sobre la formación",
  "Useful links": "Enlaces útiles",
  trainingCoursesSelected: "Formaciones seleccionadas",
  year: "Año",
  training_course: {
    errors: {
      start_year_upper_than_end_year:
        "El año de inicio debe ser inferior al año de finalización",
      month_duration_too_long:
        "La duración en meses debe ser inferior o igual a la diferencia entre el año de finalización y el año de inicio",
    },
  },
};
