export default {
  tutorPart: ({ plural, named }) =>
    plural([
      "You are a tutor for <strong>one</strong> training course",
      `You are a tutor for <strong>${named("number")}</strong> training courses`,
    ]),
  studeaManagerPart: ({ plural, named }) =>
    plural([
      "You are currently an Admin for <strong>one</strong> training course",
      `You are currently an Admin for <strong>${named("number")}</strong> training courses`,
    ]),
  apprenticeMasterPart: ({ plural, named }) =>
    plural([
      "You are currently a mentor for <strong>one</strong> training course",
      `You are currently a mentor for <strong>${named("number")}</strong> training courses`,
    ]),
  promotion: "Promotion",
  "Training courses list": "List of training courses",
  loadingTrainingCourses: "Loading training courses...",
  trainingCourseInfos: "Training course information",
  "Useful links": "Useful links",
  trainingCoursesSelected: "Selected training courses",
  year: "Year",
  training_course: {
    errors: {
      start_year_upper_than_end_year:
        "The start year must come before the end year",
      month_duration_too_long:
        "The duration in months must be less than or equal to the difference between the end year and the start year.",
    },
  },
};
