<template>
  <div class="container-fluid">
    <div class="row">
      <div v-if="!isAdminRoute()" class="col-lg-3 order-1 order-lg-0">
        <TriadMemberBlock />
        <ContractBlock />
        <DocumentsBlock />
      </div>
      <div :class="!isAdminRoute() ? 'col-lg-9 order-0 order-lg-1' : 'col-12'">
        <div class="widget">
          <div class="widget-heading">
            <div class="d-flex align-items-center">
              <button
                class="btn-icon me-2 d-flex align-items-center"
                v-tooltip="{ title: $t('back') }"
                @click="$router.back()"
              >
                <FontAwesomeIcon icon="fa-light fa-arrow-left" />
              </button>
              <h2 class="textStyle">
                {{ $t("admin.news.list.title") }}
                {{ isAdminRoute() ? "STUDEA" : "" }}
              </h2>
            </div>
            <div
              v-if="isAdminRoute()"
              class="d-flex align-items-center"
              v-tooltip="{ title: 'Activer les notifications' }"
            >
              <FontAwesomeIcon class="iconStyle fs-5" icon="fa-light fa-bell" />
              <SwitchTypeComponent :model="notifModel" />
            </div>
          </div>
          <div class="widget-content d-flex pt-4">
            <div class="container-fluid px-0">
              <div class="row">
                <div
                  class="col-xl-4 col-lg-6 col-12"
                  v-for="item in limitedNewList"
                  :key="item.id"
                >
                  <NewsCardComponent :news="item"></NewsCardComponent>
                </div>
                <BlockLoader :loading="loading"></BlockLoader>
              </div>
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <PaginationBlock
                    v-if="!loading"
                    :data="news"
                    :offset="offset"
                    :sliceLimit="sliceLimit"
                    :limit="limit"
                    @emit-data-list="handleNewsList"
                  ></PaginationBlock>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCardComponent from "@/components/blocks/NewsCardComponent.vue";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import { mapActions, mapState } from "pinia";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import PaginationBlock from "@/components/table/PaginationComponent.vue";
import { routeManager } from "@/services/utilities/route-manager";
import ContractBlock from "@/components/blocks/ContractBlock.vue";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import DocumentsBlock from "@/components/blocks/DocumentsBlock.vue";
import { useNewsStore } from "@/store/news/news";
import { useTriadStore } from "@/store/triad/triad";

export default {
  name: "NewsListView",
  components: {
    DocumentsBlock,
    TriadMemberBlock,
    ContractBlock,
    BlockLoader,
    SwitchTypeComponent,
    NewsCardComponent,
    PaginationBlock,
  },
  data() {
    return {
      notifModel: {
        vars: {
          name: "notif",
          id: "notif",
          block_prefixes: ["", "switch"],
        },
      },
      loading: true,
      limitedNewList: [],
      offset: 0,
      limit: 12,
      sliceLimit: 12,
    };
  },

  computed: {
    ...mapState(useNewsStore, {
      news: (store) => store.news,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
  },

  watch: {
    triad() {
      if (this.triad && this.triad.news) {
        this.createLimitedNewsList(this.limit, this.triad.news);
      }
    },
  },
  mounted() {
    if (this.isAdminRoute()) {
      this.fetchNews().then(() => {
        this.loading = false;
        this.createLimitedNewsList(this.limit, this.news);
      });
    } else {
      this.loading = false;
      if (this.triad && this.triad.news) {
        this.createLimitedNewsList(this.limit, this.triad.news);
      }
    }
  },
  methods: {
    createLimitedNewsList(limit, news) {
      return (this.limitedNewList = news.slice(this.offset, limit));
    },

    handleNewsList(value) {
      this.limitedNewList = value;
    },

    isAdminRoute() {
      return routeManager.isNewsAdminRoute(this.$route.name);
    },

    ...mapActions(useNewsStore, { fetchNews: "fetchNews" }),
  },
};
</script>
<style lang="scss" scoped>
.btn-icon {
  font-size: 19px;
}
</style>
