<template>
  <div v-if="active" class="chatbot" :class="{ open: open }">
    <button
      :class="'chatbotToggle' + (open ? ' open' : '')"
      @click="openChatBot"
      ref="toggle"
    >
      <div v-tooltip="{ title: 'Léa, votre assistante IA', position: 'left' }">
        <img
          class="chatbot__logo"
          :class="{ show: !buttonHover && (!animatedLogo || open) }"
          src="/assets/images/symbole_small.png"
        />
        <div
          class="loader-app chatbotLoader"
          :class="{ show: buttonHover || (animatedLogo && !open) }"
        >
          <div class="purple1 -fade--1"></div>
          <div class="purple2 -fade--2"></div>
          <div class="green -fade--3"></div>
          <div class="red -fade--4"></div>
        </div>
      </div>
      <div class="chatbot__bulle" v-if="bulle">
        <img src="/assets/images/illustration.png" />
        Léa a une information pour vous !
      </div>
    </button>
    <div class="blockStyle2 h-100">
      <div class="chatbot__heading">
        <img class="chatbot__logo show" src="/assets/images/illustration.png" />
        <h2 class="textStyle mt-3">LEA</h2>
        <p class="textStyle">Votre assistante IA</p>
        <button class="chatbot__close" @click="closeChatBot">
          <FontAwesomeIcon icon="fa-light fa-xmark" />
        </button>
      </div>
      <div ref="chatMessages" class="chatbot__messages">
        <div
          v-for="message in messages"
          :key="message.id"
          :class="'chatbot__row ' + (message.writer === 'me' ? 'me' : 'bot')"
        >
          <div class="chatbot__message">
            <div v-if="Object.keys(user).length" class="chatbot__writer">
              {{ message.writer === "me" ? user.firstName : "Léa" }}
            </div>
            <div
              class="chatbot__text"
              :class="{ 'text-typing': message.writer !== 'me' }"
              v-html="message.content"
            ></div>
          </div>
        </div>
        <div v-if="loading" class="chatbot__row bot">
          <div class="chatbot__message loading">
            <div class="chatbot__typing">
              <div class="dot-typing"></div>
            </div>
          </div>
        </div>
      </div>
      <form class="chatbot__form" @submit="submit($event)">
        <ModelComponent :model="model" />
        <button class="chatbot__submit" type="submit">
          <FontAwesomeIcon icon="fa-light fa-arrow-up" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { mapActions } from "pinia";
import { useUserStore } from "@/store/user/user";
import { useChatbotStore } from "@/store/chatbot/chatbot";
import ModelComponent from "@/components/form/ModelComponent.vue";

export default {
  name: "ChatBotComponent",
  computed: {
    ...mapState(useChatbotStore, {
      messages: (store) => store.messages,
      loading: (store) => store.loading,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  data() {
    return {
      model: {
        vars: {
          block_prefixes: ["", "text", "textarea"],
          name: "user_chatbot",
          label: false,
          id: "user_chatbot",
          value: "",
          required: false,
          attr: {
            placeholder: "Posez votre question...",
            autocomplete: "off",
          },
          row_attr: {
            class: "m-0",
          },
        },
      },
      open: false,
      active: true,
      interval: null,
      intervalLength: 10,
      animatedLogo: false,
      intervalLogo: null,
      intervalLogoLength: 5000,
      buttonHover: false,
      step: 0,
      bulle: false,
      scenarioType: null,
    };
  },
  mounted() {
    this.setLogoInterval();
    this.initLinkEvents();
    const backdrop = document.getElementById("backdrop");
    backdrop.classList.remove("show");
    backdrop.addEventListener("click", () => {
      this.closeChatBot();
    });
    this.$refs.toggle.addEventListener("mouseover", () => {
      this.buttonHover = true;
    });
    this.$refs.toggle.addEventListener("mouseout", () => {
      this.buttonHover = false;
    });
    const input = document.getElementById("user_chatbot");
    input.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        this.submit(e);
      }
    });
    if (this.$route.query.scenario) {
      this.scenarioType = parseInt(this.$route.query.scenario);
      if (this.scenarioType === 2) {
        this.bulle = true;
        this.clear();
        this.storeMessage({
          content:
            "Bonjour Said, je souhaitais vous informer que l’apprenti <strong>Liam Marty</strong> a eu une mauvaise évaluation de son MAP. Vous pouvez accéder à l'évaluation en cliquant sur ce lien :" +
            "<div><a class='d-inline-block' href='/dashboard/questionnaire/157/435'>Voir l'évaluation</a></div>" +
            "Souhaitez-vous prendre contact avec le tuteur entreprise ou l’apprenti ?" +
            "<div><button class='d-inline-block' data-step='1'>Oui</button><button class='d-inline-block' data-step='0'>Non</button></div>",
          writer: "chatbot",
        });
      }
    }
  },
  methods: {
    submit(e) {
      e.preventDefault();
      if (this.$route.query.scenario) {
        this.scenario(e);
      } else {
        this.post(e);
      }
    },

    scenario(e) {
      e.preventDefault();

      let responses;

      switch (this.scenarioType) {
        case 1:
          responses = [
            {
              content:
                "Bien sûr, j’ai importé pour vous le référentiel proposé par France Compétences.<div><a class='d-inline-block' href='/studea-manager/126/skill'>Configuration des compétences</a></div>",
            },
            {
              content:
                "Oui, je viens de mettre à jour la configuration du module compétences comme souhaité. " +
                "Je vois qu’aucune échelle d’évaluation n’est encore configurée. Je vous propose celle " +
                "la plus communément configurée sur ce type de diplôme : Non acquis, En cours d’Acquisition, " +
                "Acquis, Non Evaluable. Est-ce que cette proposition vous convient ?<div><button data-step='3' class='d-inline-block'>Oui</button><button class='d-inline-block' data-step='2'>Non</button></div>",
              suggestions: true,
            },
            {
              content:
                "Souhaitez-vous une autre suggestion d’échelle ? Vous pouvez cliquer sur l’une des propositions ci-dessous pour finaliser la configuration du module :" +
                "<div>" +
                "<button data-step='3'>Non Acquis, Acquis</button>" +
                "<button data-step='3'>Non acquis, En cours d'acquisition, Acquis, Expert</button>" +
                "<button data-step='3'>Maîtrise insuffisante, Maîtrise partielle, Maîtrise moyenne, Maîtrise bonne, Maîtrise excellente</button>" +
                "</div>",
              suggestions: true,
            },
            {
              content:
                "Très bien, la configuration du module compétences pour votre BUT Informatique promotion 2024 est désormais finalisée. Retrouvez également ici le lien pour vous dirigez directement dans la configuration du module.<div><a class='d-inline-block' href='/studea-manager/126/skill'>Configuration des compétences</a></div>Y'a t'il autre chose que je puisse faire pour vous ?",
            },
          ];
          break;

        case 2:
          responses = [
            {
              content:
                "C’est noté. Je vous informerai si une nouvelle alerte est à faire sur le livret de l’apprenti Lima Marty.",
            },
            {
              content:
                "Rendez-vous dans la messagerie Studea pour les contacter" +
                "<div><a class='d-inline-block' href='/dashboard/message'>Accéder à la messagerie</a></div>",
            },
          ];
          break;
      }

      const container = this.$refs.chatMessages;
      if (this.model.vars.value) {
        const message = {
          content: this.model.vars.value,
          writer: "me",
        };
        this.storeMessage(message);
        this.chatbotLoading();
        this.model.vars.disabled = true;
        setTimeout(() => {
          const content = {
            content: "",
            response: responses[this.step].content,
            writer: "Chatbot",
            id: Date.now().toString(36) + Math.random().toString(36).slice(2),
          };
          this.storeMessage(content);
          this.chatbotSuccess();
          this.write(content, responses[this.step].suggestions);
          this.step++;
        }, 1000);
        this.scrollToBottom(container);

        this.model.vars.value = "";
      }
    },

    initLinkEvents() {
      this.$refs.chatMessages.addEventListener("click", (e) => {
        e.preventDefault();
        let target = e.target;
        if (target.tagName !== "A" && target.tagName !== "BUTTON") {
          return;
        }
        if (target.href === location.href) {
          this.closeChatBot();
          return;
        }

        if (target.tagName === "A") {
          let url = null;
          try {
            url = new URL(target.href);
          } catch (err) {
            return;
          }

          const to = { path: url.pathname, query: this.$route.query };
          if (url.hash && window.location.pathname === to) {
            return;
          }

          this.$router.push(to);
          this.closeChatBot();
        } else if (target.tagName === "BUTTON") {
          this.step = parseInt(target.dataset.step);
          this.model.vars.value = target.textContent;
          this.scenario(e, 1, true);
        }
      });
    },

    post(e) {
      e.preventDefault();
      const $this = this;
      const container = $this.$refs.chatMessages;
      if (this.model.vars.value) {
        this.model.vars.disabled = true;
        const message = {
          content: this.model.vars.value,
          writer: "me",
        };
        $this.storeMessage(message);
        $this.chatbotRequest(message).then((res) => {
          $this.scrollToBottom(container);
          $this.write(res);
        });
        this.scrollToBottom(container);

        this.model.vars.value = "";
      }
    },

    setLogoInterval() {
      if (this.intervalLogo) {
        clearInterval(this.intervalLogo);
      }
      this.intervalLogo = setInterval(() => {
        this.animatedLogo = !this.animatedLogo;
      }, this.intervalLogoLength);
    },

    write(message, suggestions = false) {
      let start = 0;

      this.interval = setInterval(() => {
        const char = message.response[start];

        if (char === "<") {
          start = message.response.indexOf(">", start);
        }

        const content = message.response.slice(0, start);
        this.writeContent(message.id, content);
        this.scrollToBottom(this.$refs.chatMessages);
        if (++start > message.response.length) {
          this.cancelWriting();
          if (!suggestions) {
            this.model.vars.disabled = false;
            this.focusInput();
          } else {
            this.model.vars.disabled = true;
          }
        }
      }, this.intervalLength);
    },

    focusInput() {
      const input = document.getElementById("user_chatbot");
      setTimeout(() => {
        input.focus();
      }, 50);
    },

    cancelWriting() {
      clearInterval(this.interval);
      this.interval = null;
    },

    openChatBot() {
      this.open = true;
      const container = this.$refs.chatMessages;
      container.scrollTop = container.scrollHeight;
      const backdrop = document.getElementById("backdrop");
      backdrop.classList.add("show");
      this.focusInput();
      this.bulle = false;
    },

    closeChatBot() {
      this.open = false;
      const backdrop = document.getElementById("backdrop");
      backdrop.classList.remove("show");
    },

    scrollToBottom(element) {
      if (element) {
        setTimeout(function () {
          element.scroll({ top: element.scrollHeight, behavior: "smooth" });
        }, 5);
      }
    },

    ...mapActions(useChatbotStore, {
      storeMessage: "storeMessage",
      chatbotSuccess: "chatbotSuccess",
      chatbotRequest: "chatbotRequest",
      writeContent: "writeContent",
      chatbotLoading: "chatbotLoading",
      clear: "clear",
    }),
  },
  components: {
    ModelComponent,
  },
};
</script>

<style lang="scss">
.chatbotToggle {
  width: 70px;
  height: 64px;
  position: fixed;
  bottom: 85px;
  right: 400px;
  font-weight: 700;
  background: $white;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px;
  border-radius: $radius 0 0 $radius;
  border: 2px solid $lighter-grey;
  color: $blue;
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: space-between;
  z-index: -1;
  transition: all 300ms ease-in-out;

  img {
    width: 50px;
  }
}

.chatbot {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  width: 400px;
  transition: all 300ms ease-in-out;
  z-index: 9999;
  transform: translateX(100%);
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px;

  &.open {
    transform: translateX(0);
  }

  textarea {
    border-radius: 6px;
    border: 1px solid var(--primary-color);
    max-height: 50px;
    padding-right: 50px;
    resize: none;
    height: auto;
    min-height: 100px;

    &:focus,
    &:active {
      border: 1px solid var(--primary-color);
    }
  }

  &__heading {
    background: $white;
    height: 192px;
    width: 100%;
    padding: 15px;
    position: relative;
    text-align: center;
    border-bottom: 1px dashed var(--primary-color);

    h2 {
      height: auto;
      margin-top: 0;
    }
  }

  &__logo {
    width: 90px;
    position: relative;
    top: 2px;
    opacity: 0;
    visibility: visible;
    transition: all 300ms ease-in-out;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    transform: translateY(-50%);
    right: 10px;
    background: none;
    border: none;
    color: $black;
    font-size: 22px;
  }

  &__writer {
    font-weight: 700;
  }

  &__form {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    padding: 15px;
    border-top: 1px dashed var(--primary-color);
  }

  &__messages {
    padding: 25px 15px;
    height: calc(100% - 323px);
    overflow: scroll;
    background: $white;
    position: relative;
  }

  &__row {
    display: flex;
    justify-content: flex-end;

    &.bot {
      justify-content: flex-start;

      .chatbot__message {
        background: $blue;
      }
    }
  }

  &__message {
    background: var(--primary-color);
    color: $white;
    border-radius: 10px;
    padding: 10px;
    width: 80%;

    &.loading {
      max-width: 45px;
      height: 25px;
    }

    a,
    button {
      background: $white;
      color: $blue;
      margin-top: 10px;
      border-radius: $radius;
      padding: 5px 8px;
      border: none;
      display: block;
      text-align: left;
    }

    .d-inline-block + .d-inline-block {
      margin-left: 8px;
    }

    div:not([class]) {
      margin-bottom: 10px;
    }
  }

  &__typing {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__row + &__row {
    margin-top: 10px;
  }

  &__submit {
    position: absolute;
    right: 25px;
    bottom: 25px;
    border: none;
    width: 100%;
    height: 100%;
    max-width: 25px;
    max-height: 25px;
    border-radius: 50%;
    background: var(--primary-color);
    color: $white;
  }

  &__bulle {
    position: absolute;
    padding: 15px;
    border-radius: 15%;
    top: 50%;
    transform: translateY(-50%);
    right: 90px;
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.1) 0 5px 15px;
    text-align: center;
    width: 180px;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: rotate(45deg) translateY(-50%);
      background: $white;
      width: 20px;
      height: 20px;
    }

    img {
      display: block;
      margin: auto;
      margin-bottom: 10px;
    }
  }
}

/**
 * ==============================================
 * Dot Typing
 * ==============================================
 */
.dot-typing {
  position: relative;
  left: -9999px;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: $white;
  color: $white;
  box-shadow:
    9990px 0 0 0 $white,
    9999px 0 0 0 $white,
    10008px 0 0 0 $white;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
  16.667% {
    box-shadow:
      9990px -5px 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
  33.333% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
  50% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px -5px 0 0 $white,
      10008px 0 0 0 $white;
  }
  66.667% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
  83.333% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px -5px 0 0 $white;
  }
  100% {
    box-shadow:
      9990px 0 0 0 $white,
      9999px 0 0 0 $white,
      10008px 0 0 0 $white;
  }
}
</style>
