<template>
  <FormBlockComponent
    :title="$t('admin.user.modify_profile')"
    :models="models"
    @formCancel="formCancel"
    @submit="submit"
  />
  <BlockLoader :loading="loading" />
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import { notificationManager } from "@/services/utilities/notification-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { mapActions } from "pinia";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "UsersProfileEdit",
  data() {
    return {
      models: [],
      alias: "user-profile-title",
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.initData();
  },
  methods: {
    initData() {
      adminManager
        .form(this.alias, this.$route.params.id, {}, true)
        .then((data) => {
          this.models = data;
          this.modalTitle = this.$t("admin.user.modify_profile");
          this.loading = false;
        });
    },

    submit() {
      const result = formManager.processForm(this.models, false);
      let urlCall =
        "/app/admin/generic/" +
        encodeURI(this.alias) +
        "/post/" +
        this.$route.params.id;

      apiConnection.put(urlCall, result).then(() => {
        notificationManager
          .showNotification(
            "success",
            this.$t("admin.user.modification_succes"),
          )
          .then(() => {
            this.$router.push({ name: "UsersProfileTitleList" });
            this.setNeedUpdate(true);
          });
      });
    },

    formCancel() {
      this.$router.go(-1);
    },

    ...mapActions(usePlatformStore, { setNeedUpdate: "setNeedUpdate" }),
  },
  components: { BlockLoader, FormBlockComponent },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  justify-content: start;
}
</style>
