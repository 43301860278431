class StringManager {
  toCamelCase(str) {
    return str
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace("-", "").replace("_", ""),
      );
  }

  capitalize(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  gender(alias) {
    let ret = 1;
    const female = [
      "subject",
      "component",
      "help-document",
      "unit",
      "skill-category",
      "category",
      "skill",
      "news",
      "training-course",
      "training",
      "absence",
      "skill-session",
      "document-category",
    ];

    const unique = ["data-agreement"];

    if (female.includes(alias)) {
      ret = 2;
    } else if (unique.includes(alias)) {
      ret = 0;
    }

    return ret;
  }
}

export const stringManager = new StringManager();
