<template>
  <div class="widget form-widget pb-2">
    <div class="widget-content">
      <div class="panel-body rounded-pills-icon tabs">
        <ul
          id="languageTab"
          class="nav nav-pills mb-4 mt-3 justify-content-center align-items-center flex-md-row flex-column"
          role="tablist"
        >
          <li
            v-for="tab in tabs"
            :key="tab.code"
            class="nav-item ms-md-2 me-md-2 me-0 ms-0 h-100"
            role="presentation"
          >
            <a
              :id="'tab-' + tab.code + '-tab'"
              :ref="'ref-' + tab.code"
              :aria-controls="'tab-' + tab.code"
              :aria-selected="currentTabCode && currentTabCode === tab.code"
              :class="
                'nav-link mb-2 text-center bgStyle h-100' +
                (currentTabCode && currentTabCode === tab.code
                  ? ' active'
                  : '') +
                (tab.hidden ? ' d-none' : '') +
                (defaultColors ? ' defaultColors' : '')
              "
              :href="'#tab-' + tab.code"
              data-bs-toggle="pill"
              role="tab"
              @click="toggleTab(tab.code)"
            >
              <FontAwesomeIcon :icon="'fa-light fa-' + tab.icon" />
              {{ tab.title }}
            </a>
          </li>
        </ul>
        <div id="languageTabContent" class="tab-content">
          <div
            v-for="tab in tabs"
            :id="'tab-' + tab.code"
            :key="tab"
            :aria-labelledby="'tab-' + tab.code + '-tab'"
            :class="
              'tab-pane fade' +
              (currentTabCode && currentTabCode === tab.code
                ? ' show active'
                : '') +
              (tab.hidden ? ' d-none' : '')
            "
            role="tabpanel"
          >
            <FormComponent
              v-if="tab.models"
              :defaultColors="defaultColors"
              :models="tab.models"
              :keepHideValue="keepHideValue"
              :disableFocusAnimation="disableFocusAnimation"
              @update="update"
              :hideSubmit="true"
              @toggle="toggle"
              @uploaded="uploaded"
            />
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
  <div v-if="!removeSubmit" class="widget -sticky">
    <SubmitTypeComponent
      :defaultColors="defaultColors"
      :hideCancel="hideCancel"
      :loading="loading"
      @cancelForm="cancel"
      @submitForm="submit"
    />
  </div>
</template>

<script>
import FormComponent from "@/components/FormComponent.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";
import { formValidator } from "@/services/form/form-validator";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { tabsManager } from "@/services/utilities/tabs-manager";
import { formManager } from "@/services/form/form-manager";

export default {
  name: "FormTabsBlockComponent",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    invalidTab: {
      type: Array,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableFocusAnimation: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultColors: {
      type: Boolean,
      required: false,
      default: false,
    },
    keepHideValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    preserveTab: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      currentTabCode: null,
    };
  },
  emits: ["update", "uploaded", "formSubmit", "formCancel", "toggle"],
  watch: {
    invalidTab() {
      tabsManager.displayTab(this.invalidTab);
    },

    tabs() {
      if (!window.location.hash && this.tabs && this.tabs[0]) {
        this.setCurrentTabCode(this.tabs[0].code);
      } else {
        this.setCurrentTabCode(window.location.hash.substring(1));
      }
    },
  },
  mounted() {
    this.checkTabConditionalDisplay();
    if (!window.location.hash && this.tabs && this.tabs[0]) {
      this.setCurrentTabCode(this.tabs[0].code);
    } else {
      this.setCurrentTabCode(window.location.hash.substring(1));
    }
  },
  methods: {
    submit() {
      let errors = [];
      let invalidTab = {};
      Object.values(this.tabs).forEach(function (tab) {
        if (!errors.length && !tab.hidden) {
          errors = errors.concat(formValidator.validForm(tab.models));
          if (errors.length) {
            invalidTab = tab;
          }
        }
      });

      if (Object.keys(invalidTab).length) {
        tabsManager.displayTab(invalidTab);
      } else {
        this.$emit("formSubmit");
      }
    },

    toggleTab(code) {
      this.setCurrentTabCode(code);
    },

    setCurrentTabCode(code) {
      if (this.preserveTab) {
        this.currentTabCode = code;
        window.location.hash = this.currentTabCode;
      }
    },

    update() {
      this.$emit("update");
    },

    uploaded(data) {
      this.$emit("uploaded", data);
    },

    toggle(model) {
      this.$emit("toggle", model);
      if (Object.keys(this.tabs).length) {
        Object.values(this.tabs).forEach((tab) => {
          if (tab.toggleClass && tab.toggleTab) {
            this.checkTabConditionalDisplay();
          }
        });
      }
    },

    checkTabConditionalDisplay() {
      Object.values(this.tabs).forEach((tab) => {
        if (tab.toggleClass) {
          const splitConditionalTab = tab.toggleClass.split("-");
          const targetTab = tab.toggleTab;
          const targetName = splitConditionalTab[0];
          const targetValue = splitConditionalTab[1];
          const input = Object.keys(this.tabs[targetTab].models).length
            ? Object.values(this.tabs[targetTab].models).find(
                (item) => item.vars && item.vars.toggler_target === targetName,
              )
            : null;
          if (input) {
            let content = input.vars.value.toString();
            let hidden;
            if (
              formManager.isChoiceType(input) &&
              formManager.isMultiple(input)
            ) {
              hidden = !Object.values(content).find(
                (item) =>
                  (!input.vars.expanded ||
                    (input.vars.expanded && item.checked)) &&
                  item.value === targetValue,
              );
            } else if (
              formManager.isChoiceType(input) &&
              !formManager.isMultiple(input) &&
              !input.vars.expanded
            ) {
              hidden = content.value !== targetValue;
            } else {
              hidden = content !== targetValue;
            }
            tab.hidden = hidden;
          }
        }
      });
    },
    cancel() {
      this.$emit("formCancel");
    },
  },
  components: {
    BlockLoader,
    SubmitTypeComponent,
    FontAwesomeIcon,
    FormComponent,
  },
};
</script>

<style lang="scss" scoped>
.form-widget {
  min-height: 300px;
}

.widget .nav-link {
  min-height: 100px;
  width: 130px !important;
  @include up-to-md {
    min-height: fit-content;
    width: 100% !important;
  }
}

.widget {
  &.-sticky {
    position: sticky;
    bottom: 40px;
    z-index: 1000;

    @include up-to-md {
      bottom: 15px;
    }
  }
}
.nav {
  &-item {
    @include up-to-md {
      width: 100% !important;
    }
  }
}
</style>
