<template>
  <label
    v-if="itemModel.vars.label"
    :class="
      'form-label' +
      (itemModel.vars.label_attr && itemModel.vars.label_attr.class
        ? ' ' + itemModel.vars.label_attr.class
        : '') +
      (itemModel.vars.required ? ' required' : '')
    "
  >
    {{ index ? index + ". " : "" }}{{ itemModel.vars.label
    }}{{ itemModel.vars.required ? "*" : "" }}
    <span
      v-if="itemModel.vars.help && !isMobile()"
      class="form-info"
      v-tooltip="{ title: itemModel.vars.help }"
    >
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
    </span>
  </label>
  <div v-if="isMobile() && itemModel.vars.help" class="form-info">
    <FontAwesomeIcon icon="fa-light fa-circle-question" />
    {{ itemModel.vars.help }}
  </div>
  <textarea
    :id="itemModel.vars.id"
    v-model="itemModel.vars.value"
    :class="
      itemClass +
      ' ' +
      (itemModel.vars.attr && itemModel.vars.attr.class
        ? itemModel.vars.attr.class
        : '')
    "
    :disabled="itemModel.vars.disabled === true"
    :name="
      language ? itemModel.vars.name + '-' + language : itemModel.vars.name
    "
    :placeholder="
      itemModel.vars.attr && itemModel.vars.attr.placeholder
        ? itemModel.vars.attr.placeholder
        : ''
    "
    style=""
    :required="itemModel.vars.required === true"
    @blur="blur"
    @change="change"
    @focus="focus"
    @input="input"
  ></textarea>
  <div v-if="itemModel.vars.error" class="invalid-feedback d-block">
    {{ itemModel.vars.error }}
  </div>
</template>

<script>
import { formValidator } from "@/services/form/form-validator";

export default {
  name: "TextareaTypeComponent",
  data() {
    return {
      itemModel: this.model,
      itemClass: "form-control",
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    language: {
      type: String,
      required: false,
      default: "fr",
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ["draft", "focus", "update"],
  methods: {
    blur() {
      this.valid(true);
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    input() {
      this.valid();
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    change() {
      this.$emit("draft", this.itemModel);
      this.$emit("update", this.itemModel.vars.value, this.index);
    },
    focus() {
      this.$emit("focus");
    },
    valid(blur = false) {
      if (blur) {
        this.$emit("save");
      }
      this.getClass();
      if (this.itemModel.vars.required) {
        formValidator.validModel(this.itemModel, this.language);
      }
    },
    isMobile() {
      return window.innerWidth < 992;
    },
    getClass() {
      const errors = formValidator.validModel(this.itemModel);
      if (this.itemModel.vars.error || errors.length) {
        this.itemClass = "form-control is-invalid";
      } else if (
        !errors.length &&
        !this.itemModel.vars.error &&
        this.itemModel.vars.required
      ) {
        this.itemClass = "form-control is-valid";
      } else {
        this.itemClass = "form-control";
      }
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
textarea {
  min-height: 100px;
}
</style>
