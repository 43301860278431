export default {
  page: {
    home: "Home",
    search: "Search",
    skills: "Skills",
    skills_stats: "Statistics",
    dashboard: "Dashboard",
    questionnaires: "Questionnaires",
    weblinks: "Your useful links",
    gradebook: "Your gradebooks",
    attestations: "Your attendance records",
    message: "Inbox",
    document: "Your shared documents",
    document_add: "Add a document",
    document_add_note: "Add a note",
    lessons: "Your lessons",
    timetable: "Your timetable",
    absence: "Attendance management",
    profile: "Profile",
    questionnaire: "Questionnaire",
    training_course_list: "List of year groups",
    training_list: "List of training courses",
    training_dashboard: "Training dashboard",
    pre_registration: "Pre-registration form",
    contract: "Contract",
    login: "Log in",
    notepad: "Notepad",
    new_message: "New message",
    new_password: "New password",
    reset_password: "Reset password",
    error: "Error",
    switch: "Log in",
    maintenance: "Maintenance",
  },
};
