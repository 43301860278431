export default {
  page: {
    home: "Inicio",
    search: "Buscar",
    skills: "Competencias",
    skills_stats: "Estadísticas",
    dashboard: "Tablero de control",
    questionnaires: "Cuestionarios",
    weblinks: "Enlaces útiles",
    gradebook: "Sus boletines de notas",
    attestations: "Sus certificados de asistencia",
    message: "Mensajes",
    document: "Sus documentos compartidos",
    document_add: "Añadir un documento",
    document_add_note: "Añadir una nota",
    lessons: "Sus cursos",
    timetable: "Su empleo del tiempo",
    absence: "Gestión de asistencia",
    profile: "Perfil",
    questionnaire: "Cuestionario",
    training_course_list: "Lista de promociones",
    training_list: "Lista de formaciones",
    training_dashboard: "Tablero de control de la formación",
    pre_registration: "Ficha de preinscripción",
    contract: "Contrato",
    login: "Conexión",
    notepad: "Bloc de notas",
    new_message: "Nuevo mensaje",
    new_password: "Nueva contraseña",
    reset_password: "Restablecer su contraseña",
    error: "Error",
    switch: "Conexión",
    maintenance: "Mantenimiento",
  },
};
