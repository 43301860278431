import { i18n } from "@/i18n";

class SortManager {
  sort(object, key, type) {
    let sortable = [];
    for (let item in object) {
      sortable.push(object[item]);
    }

    let keys = null;
    if (key.includes(".")) {
      keys = key.split(".");
    }

    sortable.sort(function (a, b) {
      switch (type) {
        case "ASC":
          return (
            (keys && a[keys[0]] ? a[keys[0]][keys[1]] : a[key]) &&
            (keys && a[keys[0]] ? a[keys[0]][keys[1]] : a[key])
              .toString()
              .localeCompare(
                (keys && b[keys[0]] ? b[keys[0]][keys[1]] : b[key])
                  ? (keys && b[keys[0]]
                      ? b[keys[0]][keys[1]]
                      : b[key]
                    ).toString()
                  : "",
                i18n.global.locale,
              )
          );
        case "DESC":
          return (
            (keys && b[keys[0]] ? b[keys[0]][keys[1]] : b[key]) &&
            (keys && b[keys[0]] ? b[keys[0]][keys[1]] : b[key])
              .toString()
              .localeCompare(
                (keys && a[keys[0]] ? a[keys[0]][keys[1]] : a[key])
                  ? (keys && a[keys[0]]
                      ? a[keys[0]][keys[1]]
                      : a[key]
                    ).toString()
                  : "",
                i18n.global.locale,
              )
          );
      }
    });
    return sortable;
  }

  setMinLineLimit(activePage, limit) {
    if (activePage === 1) {
      return 1;
    } else {
      return Number(activePage * limit) - Number(limit);
    }
  }

  setMaxLineLimit(activePage, maxResult, limit) {
    if (activePage === 1 && limit < maxResult) {
      return limit;
    } else if (
      Number(activePage * limit) + Number(limit) > maxResult ||
      limit > maxResult
    ) {
      return maxResult;
    } else {
      return activePage * Number(limit);
    }
  }
}

export const sortManager = new SortManager();
