import { createRouter, createWebHistory } from "vue-router";
import dashboard from "@/router/dashboard/dashboard";
import trainingCourseAdmin from "@/router/studea-manager/studea-manager";
import globalAdmin from "@/router/admin/admin";
import login from "@/router/login/login";
import error from "@/router/error/error";
import profile from "@/router/profile/profile";
import { accessManager } from "@/services/security/access-manager";
import sharedDocument from "@/router/shared-document/shared-document";
import trainingCourseDuplicateView from "@/router/duplicate/duplicate";
import indentSheet from "@/router/indent-sheet/indend-sheet";
import driverLicenceList from "@/router/driver-licence/driver-licence";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error",
  },
  dashboard,
  trainingCourseAdmin,
  globalAdmin,
  login,
  error,
  profile,
  sharedDocument,
  trainingCourseDuplicateView,
  indentSheet,
  driverLicenceList,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  accessManager.checkRoleAccess(to, from).then((res) => {
    if (res) {
      next();
    }
  });
});

export default router;
