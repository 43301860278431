export default {
  login: "Log in",
  loginTo: "Log in to",
  resetPassword: "Reset password",
  magic_link_expired: "Link expired",
  magic_link_expired_subtitle:
    "Would you like to receive a new link by e-mail or log in with your password?",
  reconnect: "Log in",
  send_magic_link: "Send a link",
  magic_link_sent: "A new link has been sent to your e-mail address",
  magic_link_error: "Unable to generate new link.",
  reconnect_error: "Secure area",
  reconnect_error_message:
    "Please enter your password to access this part of the website",
  loginMobile: "Please enter your username or e-mail address to log in",
  loading: "Loading...",
  password: "Password",
  reinitialize: "Reset",
  username: "Username",
  organization: "Organisation/institution",
  showPassword: "Show password",
  keepMeLoggedIn: "Stay logged in",
  forgotPasswordLink: "Forgotten password?",
  loginValidation: "Please enter your login details",
  loginLoginValidation: "Please enter your username or e-mail address",
  loginPasswordValidation: "Please enter your password",
  alertIdentify: "You are logged in",
  alertIdentifyError: "Identification error",
  logout: "Logout",
  badCredentials: "Incorrect login details",
  password_reset_info:
    "Please enter your username or email address to reset your password",
  reset_password: "Forgotten password",
  reset_password_confirm:
    "A link to generate a new password has been sent to your inbox",
  new_password_confirm: "Your password has been updated successfully!",
  new_password: "New password",
  not_available: "Not available",
  accessAccountDenied: "You do not have access to the app on this account",
  legal_notice: "Legal information",
  personal_data: "Personal data",
  learner_privacy: "Personal data : Work-study apprentice",
  professional_tutor_privacy: "Personal data : Professional tutor",
  pedagogic_tutor_privacy: "Personal information - Training bodies",
  contact_us: "Contact us",
  contact_help: "Help",
  terms_and_conditions: "Terms of use",
  password_confirm: "Confirm your password",
  password_error: "Both passwords must be identical",
  login_presentation: "What is the digital booklet",
  login_in_progress: "Logging in...",
  password_not_valid:
    "Please enter a password made up of 12 characters, including at least one lowercase letter, one uppercase letter, one number and one special character.",
  "Personal data - Confidentiality": "Personal data - Confidentiality",
  "Since May 25, 2018, the entry into force of the General Data Protection Regulation has been amended; regulations on personal data.":
    "Since 25 May 2018, the application of the General Data Protection Regulation has changed; the regulation of personal data.",
  "We invite you to read the information notices below. The origin, purpose and processing carried out on the data collected by Studea depend, ... according to the profile of each user.":
    "Please read the information provided below. The source, purpose and processing of data collected by Studea will depend on the profile of each user.",
  "We offer 3 notices here, depending on your user profile, we invite you to read the one that corresponds to your case":
    "There are three notices here. Please read the notice that corresponds to your user profile.",
  "You are a work-study student and you have been registered on the platform by your training organization":
    "You are a work-study student and you have been registered on the platform by your training organisation",
  "Personal data - Confidentiality (Alternate access)":
    "Personal data - Confidentiality (Work/study student access)",
  "You are an employee of a company and you supervise a work-study program as a Tutor":
    "You are an employee of a company and you supervise a work-study programme as a Tutor",
  "Personal data - Confidentiality (apprenticeship masters and company tutor)":
    "Personal data - Confidentiality (mentors and company tutor)",
  "You are an employee of a training organization, you administratively manage training courses or you supervise young people as an educational tutor":
    "You are an employee of a training organisation. You manage the administration of training courses or you supervise young people as an educational tutor",
  "Personal data - Confidentiality (Employees of training organizations, educational tutor)":
    "Personal data - Confidentiality (Employees of training organisations, educational tutors)",
};
