<template>
  <div class="mx-0 mx-md-2">
    <div class="session">
      <div class="session__list" v-if="itemList && itemList.length">
        <div
          :class="
            'session__item ' +
            (currentItem && currentItem.id === item.id ? '-active' : '')
          "
          v-for="item in itemList"
          :key="item.id"
        >
          <div
            class="me-1"
            v-if="
              (itemType === 'referential' || itemType === 'session') &&
              this.$route.name !== 'MODULE_STUDEA_SKILL' &&
              this.$route.name !== 'skillsStats'
            "
          >
            <span
              v-tooltip="{
                title: $tc(
                  itemType === 'referential'
                    ? 'referential_visible'
                    : 'session_visible',
                  item && item.visible && item.visible === true ? 1 : 0,
                ),
              }"
              :class="
                'pellet ' +
                (item && item.visible && item.visible === true
                  ? 'success'
                  : 'danger')
              "
            >
            </span>
          </div>
          <button class="session__button" @click="changeItem(item.id)">
            <span
              v-if="itemType === 'session' && item.startDate && item.endDate"
              v-tooltip="{
                title:
                  $t('studea_manager.session.from') +
                  ' ' +
                  this.formatDate(item.startDate) +
                  ' ' +
                  $t('studea_manager.session.to') +
                  ' ' +
                  this.formatDate(item.endDate),
              }"
              class="me-2 session__info"
              ><FontAwesomeIcon icon="fa-light fa-circle-info" />
            </span>
            {{ item.name }}
            <FontAwesomeIcon
              v-if="!isStudeaManagerRoute && itemType === 'session'"
              class="ms-2"
              :icon="'fa-light fa-' + (item.open ? 'hourglass' : 'lock')"
            />
            <FontAwesomeIcon
              v-tooltip="{ title: 'Référentiel Admin' }"
              v-if="itemType === 'referential' && item.admin"
              icon="fa-light fa-crown"
              class="ms-2"
            />
          </button>
          <div class="session__action">
            <div v-if="canEdit(item)">
              <router-link
                :to="{
                  name: getActionRoute('Edit'),
                  params: { alias: 'skill-' + itemType, id: item.id },
                }"
                v-access:[permissions]="accessManager().EDIT"
                class="ms-3 ms-md-2 btn-icon"
                v-tooltip="{
                  title: $tc(
                    'global.edit.title',
                    itemType === 'session' ? 2 : 1,
                    {
                      name: this.$tc(
                        itemType === 'session'
                          ? 'studea_manager.skillSession.title'
                          : 'studea_manager.skillReferential.title',
                        1,
                      ).toLowerCase(),
                    },
                  ),
                }"
              >
                <FontAwesomeIcon icon="fa-light fa-pen" />
              </router-link>
            </div>
            <div v-if="canEdit(item)">
              <button
                class="btn-icon ms-md-0 ms-2"
                v-tooltip="{
                  title: $tc(
                    'global.delete.title',
                    itemType === 'session' ? 2 : 1,
                    {
                      name: this.$tc(
                        itemType === 'session'
                          ? 'studea_manager.skillSession.title'
                          : 'studea_manager.skillReferential.title',
                        1,
                      ).toLowerCase(),
                    },
                  ),
                }"
                v-access:[permissions]="accessManager().DELETE"
                @click="deleteItem(item.id)"
              >
                <FontAwesomeIcon icon="fa-light fa-trash" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center w-100">
        {{ $t(trad) }}
      </div>
      <div v-if="isStudeaManagerRoute && itemType === 'session'">
        <router-link
          class="add__icon"
          :to="{
            name: getActionRoute('Add'),
            params: {
              alias: 'skill-' + itemType,
              params: { trainingCourseId: $route.params.trainingCourseId },
            },
          }"
          v-tooltip="{
            title: $tc('global.add.title', 2, {
              name: $tc('studea_manager.skillSession.title', 1).toLowerCase(),
            }),
          }"
          v-access:[permissions]="accessManager().EDIT"
        >
          <FontAwesomeIcon icon="fa-light fa-plus" />
        </router-link>
      </div>
      <div v-if="itemType === 'referential'" class="add__icon__container">
        <router-link
          class="add__icon"
          :to="{
            name: isStudeaManagerRoute
              ? 'studeaManagerSkillReferentialAdd'
              : 'adminSkillReferentialAdd',
            params: {
              alias: 'skill-referential',
            },
          }"
          v-tooltip="{
            title: $tc('global.add.title', 1, {
              name: $tc(
                'studea_manager.skillReferential.title',
                1,
              ).toLowerCase(),
            }),
          }"
          v-access:[permissions]="accessManager().EDIT"
        >
          <FontAwesomeIcon icon="fa-light fa-plus" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { accessManager } from "@/services/security/access-manager";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";

export default {
  name: "ItemTabsBlock",
  components: { FontAwesomeIcon },
  data() {
    return {
      isStudeaManagerRoute: false,
      isAdminRoute: false,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  props: {
    itemList: {
      type: [Array, Object],
      required: true,
    },
    currentItem: {
      type: Object,
      required: true,
    },
    itemType: {
      type: String,
      required: false,
      default: "session",
    },
    trad: {
      type: String,
      required: false,
      default: "studea_manager.skillSession.list.empty",
    },
  },
  mounted() {
    this.isStudeaManagerRoute = this.$route.path.includes("studea-manager");
    this.isAdminRoute = this.$route.path.includes("admin");
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },

    changeItem(id) {
      this.$emit("changeItem", id);
    },

    deleteItem(id) {
      this.$emit("deleteItem", id);
    },

    canEdit(item) {
      return !(item.admin && this.isStudeaManagerRoute);
    },

    getActionRoute(action) {
      let route;
      switch (this.itemType) {
        case "session":
          if (this.isStudeaManagerRoute) {
            route = "studeaManagerSkillSession" + action;
          } else if (this.isAdminRoute) {
            route = "adminSkillSession" + action;
          }
          break;
        case "referential":
          if (this.isStudeaManagerRoute) {
            route = "studeaManagerSkillReferential" + action;
          } else if (this.isAdminRoute) {
            route = "adminSkillReferential" + action;
          }
          break;
      }
      return route;
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  padding: 20px;
}

.tab__btn {
  position: relative;
  border: none;
  border-radius: 5px;
  padding: 8px;
  background-color: #f1f2f3;
  color: var(--primary-color);
  font-size: 1rem;
  margin-right: 10px;
  cursor: default;

  @include up-to-md {
    margin-bottom: 10px;
  }

  .pointer,
  .btn-icon {
    color: var(--primary-color);
  }

  &.active {
    background-color: var(--primary-color);
    color: $white;

    .pointer,
    .btn-icon {
      color: $white;
    }
  }
}

.pointer {
  background: none;
  border: none;
  padding: 0;
}

.list__button.btn-icon {
  svg {
    width: 22px;
    height: 22px;
  }
}

.-info {
  @include up-to-md {
    display: none;
  }
}

.session {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 42px;

  @include up-to-md {
    margin-bottom: 10px;
    flex-direction: column;
  }

  &__list {
    display: flex;
    width: 100%;

    @include up-to-md {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    background-color: #f1f2f3;
    color: var(--primary-color);
    padding: 8px;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    margin-right: 10px;

    @include up-to-md {
      border-radius: 5px;
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }
    @include dark-theme {
      background-color: $dark-grey;
      color: $white;
    }

    &.-active {
      color: white;
      background-color: var(--primary-color) !important;

      .btn-icon {
        color: white !important;
      }
    }
  }

  &__info {
    @include up-to-md {
      display: none;
    }
  }

  &__button {
    width: 100%;
    text-align: left;
    border: none;
    background-color: inherit;
    color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__action {
    display: flex;
    color: var(--primary-color);

    @include up-to-md {
      position: absolute;
      right: 10px;
    }
  }
}

.add__icon {
  background-color: #f1f2f3;
  color: var(--primary-color);
  font-size: 20px;
  padding: 8px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  &__container {
    @include up-to-md {
      width: 100%;
    }
  }

  @include up-to-md {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @include dark-theme {
    background-color: $m-color_10;
  }
}
.pellet {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 15px;

  &.success {
    background-color: $success;
  }

  &.danger {
    background-color: $danger;
  }
}
</style>
