<template>
  <TabsTitleComponent :items="tabs" />
  <BlockLoader :loading="loading" />
  <FormBlockComponent
    v-if="this.models && Object.values(this.models).length"
    :models="models"
    @submit="submit"
  />
  <div v-else class="row">
    <div class="widget">
      <div class="widget-content">
        <div class="empty">
          <span class="mb-2">{{ $t("admin.skill_session.empty") }}</span>
          <router-link
            class="btn btnStyle"
            :to="{
              name: 'adminModuleSkillConfiguration',
              alias: 'skill-config',
            }"
            >Configuration du module</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import TabsTitleComponent from "@/components/blocks/TabsTitleComponent.vue";
import { apiConnection } from "@/services/api-connection";
import { mapActions, mapState } from "pinia";
import { useSkillStore } from "@/store/skill/skill";
import { formManager } from "@/services/form/form-manager";
import FormBlockComponent from "@/components/blocks/FormBlockComponent.vue";
import { skillManager } from "@/services/skill/skill-manager";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "SkillSessionListView",
  components: {
    FormBlockComponent,
    TabsTitleComponent,
    BlockLoader,
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          title: this.$t("studea_manager.skill_configuration.title"),
          icon: "gear",
          disabled: false,
          path: "adminModuleSkillConfiguration",
        },
        {
          id: 1,
          title: this.$t("studea_manager.skill_referential.title"),
          icon: "book",
          disabled: false,
          path: "adminModuleSkillReferential",
        },
        {
          id: 2,
          title: this.$t("admin.skill_session.generate"),
          icon: "medal",
          disabled: false,
          path: "adminModuleSkillGenerateSession",
        },
      ],
      loading: false,
      models: [],
    };
  },

  computed: {
    ...mapState(useSkillStore, {
      adminSkillConfig: (store) => store.skillConfig,
    }),
  },

  mounted() {
    this.loading = true;
    this.init();
  },

  methods: {
    init() {
      this.fetchAdminSkillConfig();
      this.getSkillSessionAddForm();
    },

    getSkillSessionAddForm() {
      this.models = [];
      skillManager.getSkillSessionAdminForm().then((response) => {
        this.models = response;
        this.loading = false;
      });
    },

    getSkillSessionsAdminIds(response) {
      const ids = [];
      Object.keys(response).forEach((item) => {
        if (item.includes("id")) {
          ids.push(item.substring(3));
        }
      });
      return ids;
    },

    generateSkillSessionToCreateList(ids, sessionsToCreate, response) {
      ids.forEach((id) => {
        const session = {
          id: id,
          name: response["name_" + id],
          startDate: response["startDate_" + id],
          endDate: response["endDate_" + id],
        };
        sessionsToCreate.push(session);
      });
    },

    submit() {
      const response = formManager.processForm(this.models);
      const sessionsToCreate = [];
      const ids = this.getSkillSessionsAdminIds(response);
      this.generateSkillSessionToCreateList(ids, sessionsToCreate, response);
      this.loading = true;
      apiConnection
        .put("/app/admin/skill/session", sessionsToCreate)
        .then((response) => {
          if (response.success) {
            notificationManager.showNotification(
              "success",
              this.$t("admin.skill_session.generation_success"),
            );
            this.loading = false;
          }
        });
    },

    ...mapActions(useSkillStore, ["fetchAdminSkillConfig"]),
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  &.-sticky {
    position: sticky;
    bottom: 40px;
    left: 0;
    margin: 0;
    z-index: 49;

    .buttons {
      display: inline-block;
      background: rgba(255, 255, 255, 0.8);
      padding: 0.8rem 1rem;
      border: 1px solid $m-color_3;
      border-radius: $radius;
    }
  }
}

.form__row + .form__row {
  border-top: 1px dashed $m_color_3;
  padding-top: 15px;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
