import { adminManager } from "@/services/admin/admin-manager";
import { defineStore } from "pinia";

export const useNewsStore = defineStore({
  id: "News",
  state: () => {
    return {
      news: [],
      newsItem: [],
      tagCorrespondance: [
        { id: 10, name: "Bug", color: "redBadge" },
        { id: 11, name: "Actualités", color: "blueBadge" },
        { id: 12, name: "Maintenance", color: "redBadge" },
        { id: 13, name: "Nouvelle fonctionnalité", color: "greenBadge" },
        { id: 14, name: "Tutoriel", color: "yellowBadge" },
      ],
    };
  },

  actions: {
    findNewsItem(list, id) {
      return list.find((item) => item.id === parseInt(id));
    },

    fetchNews() {
      return adminManager.list("news", false).then((news) => {
        this.news = news;
      });
    },

    toggleNews(id) {
      this.newsItem = this.findNewsItem(this.news, id);
      if (!this.newsItem["tagBadge"]) {
        this.newsItem["tagBadge"] = this.getTag();
      }
    },

    getTag() {
      return this.tagCorrespondance.find(
        (element) => element.id === this.newsItem.tag,
      );
    },

    init() {
      this.news = [];
      this.newsItem = [];
    },
  },
});
