<template>
  <div
    v-for="comment in comments"
    :key="comment"
    :class="
      'comment --' +
      comment.writerProfile +
      (isProfileOwner(comment.writerProfile) ? ' -owner' : '')
    "
  >
    <AvatarComponent
      :name="comment.user.fullName"
      :user="comment.user"
      :profile="comment.writerProfile"
    />
    <div :class="'comment__content --' + comment.writerProfile">
      <div class="comment__identity">
        {{ comment.user.fullName }}
        ({{ getProfile(comment.writerProfile) }})
      </div>
      {{ comment.value }}
      <div class="comment__date">
        {{ comment.date ? formatDate(comment.date) : "" }}
      </div>
    </div>
  </div>
</template>

<script>
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";
import { userManager } from "@/services/security/user-manager";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { mapState } from "pinia";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";

export default {
  name: "QuestionnaireCommentComponent",
  components: {
    AvatarComponent,
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(useQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
    }),
  },
  methods: {
    getProfile(profile) {
      return this.$t(userManager.getProfileKey(profile));
    },

    formatDate(date) {
      return dateFormatter.format(date, "short_time");
    },

    isProfileOwner(profile) {
      const userReply = this.questionnaire.userReply;
      return userReply && userReply.writerProfile === profile;
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
  display: flex;
  margin: 10px 0;

  &.-owner {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }

  &__content {
    border-radius: 9px;
    padding: 10px;

    &.--1 {
      background: $light-green;
    }

    &.--2 {
      background: $light-purple;
    }

    &.--3 {
      background: $light-red;
    }

    &.--4 {
      background: $light-orange;
    }
  }

  &__identity {
    font-weight: 700;
  }

  &__date {
    font-style: italic;
    font-size: 13px;
    text-align: right;
    margin-top: 5px;
  }
}
</style>
