import { apiConnection } from "@/services/api-connection";
import { useNotificationStore } from "@/store/notifications/notification";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";

class MessengerMessageManager {
  interval = null;
  intervalLength = 5000;
  messageId = null;
  loading = false;

  STATUS_WAITING = "waiting";
  STATUS_PROCESSING = "processing";
  STATUS_FAILED = "failed";
  STATUS_ERROR = "error";
  STATUS_SUCCESS = "success";
  STATUS_OFF = "off";

  startPing = (messageId, open = true) => {
    const store = useNotificationStore();
    store.setStatus(this.STATUS_WAITING);
    store.setOpen(open);
    this.messageId = messageId;
    store.addMessage({
      id: messageId,
      status: this.STATUS_WAITING,
      totalIterations: null,
      currentIterations: null,
      label: i18n.global.t("header.notification.waiting"),
      fileTypeIcon: "file",
      icon: "pause",
    });
    this.ping().then((res) => {
      if (res && !res.success) {
        this.setInterval();
      }
    });
  };

  processMessage = (res) => {
    if (res.messageId) {
      this.startPing(res.messageId);
    } else {
      notificationManager.showAlert("error", i18n.global.t("error_file"));
    }
  };

  setInterval = () => {
    this.interval = setInterval(this.ping, this.intervalLength);
  };

  ping = () => {
    return new Promise((resolve) => {
      if (!this.loading) {
        const store = useNotificationStore();
        this.loading = true;
        apiConnection
          .get("/app/messenger-monitoring/" + this.messageId)
          .then((res) => {
            store.setLast(res);

            const exist = store.messages.find(
              (message) => message.id === res.id,
            );
            if (exist) {
              exist.status = res.status;
              exist.action = res.action;
              exist.actionOptions = res.actionOptions;
              exist.totalIterations = res.totalIterations;
              exist.currentIterations = res.currentIterations;
              exist.label = res.label;
              exist.icon = res.icon;
              exist.fileTypeIcon = res.fileTypeIcon;
              exist.createdAt = res.createdAt;
              exist.completedAd = res.completedAt;
              exist.labelStatus = res.labelStatus;
            }
            store.setStatus(res.status);
            if (
              res.status === this.STATUS_SUCCESS ||
              res.status === this.STATUS_FAILED ||
              res.status === this.STATUS_ERROR
            ) {
              this.stopInterval();
              if (
                res.actionOptions &&
                typeof res.actionOptions === "object" &&
                res.actionOptions.refresh === true
              ) {
                store.incrementRefreshComponentKey();
              }
              resolve({ success: true });
            } else {
              resolve({ success: false });
            }
            this.loading = false;
          });
      }
    });
  };

  stopInterval = () => {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  };

  get() {
    return apiConnection.get("/app/messenger-monitoring/list").then((res) => {
      const store = useNotificationStore();
      store.setList(res);
      this.retrieveProcessingMessage();
    });
  }

  retrieveProcessingMessage() {
    const store = useNotificationStore();
    if (store.list && Object.keys(store.list).length) {
      Object.values(store.list).forEach((message) => {
        if (
          message.status === this.STATUS_WAITING ||
          message.status === this.STATUS_PROCESSING
        ) {
          store.addMessage(message);
          this.startPing(message.id, false);
          store.setStatus(this.STATUS_PROCESSING);
        }
      });
    }
  }
}

export const messengerMessageManager = new MessengerMessageManager();
