<template>
  <div
    :style="
      '--max-height:' +
      94 * (crew.triads.length ? crew.triads.length : 1) +
      'px'
    "
  >
    <div>
      <AccordionPersonReplyComponent
        v-if="triad.apprentice && triad.apprentice.id"
        :reply="apprenticeReply"
        :triad="triad"
        :questionnaire="questionnaire"
        :profile="apprenticeProfile"
        @manage="manage"
      />
    </div>
    <div>
      <AccordionPersonReplyComponent
        v-if="triad.tutor && triad.tutor.id"
        :reply="tutorReply"
        :triad="triad"
        :questionnaire="questionnaire"
        :profile="tutorProfile"
        @manage="manage"
      />
    </div>
    <div>
      <AccordionPersonReplyComponent
        v-if="triad.apprenticeMaster && triad.apprenticeMaster.id"
        :reply="apprenticeMasterReply"
        :triad="triad"
        :questionnaire="questionnaire"
        :profile="apprenticeMasterProfile"
        @manage="manage"
      />
    </div>
    <div>
      <AccordionPersonReplyComponent
        :reply="studeaManagerReply"
        :triad="triad"
        :questionnaire="questionnaire"
        :profile="studeaManagerPofile"
        @manage="manage"
      />
    </div>
  </div>
</template>

<script>
import AccordionPersonReplyComponent from "@/components/table/accordion/AccordionPersonReplyComponent.vue";
import { triadManager } from "@/services/triad/triad-manager";

export default {
  name: "AccordionTriadsRepliesComponent",
  components: { AccordionPersonReplyComponent },
  props: {
    triad: {
      type: Object,
      required: true,
    },
    crew: {
      type: Object,
      required: true,
    },
    questionnaire: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apprenticeReply: {},
      apprenticeMasterReply: {},
      tutorReply: {},
      studeaManagerReply: {},
      apprenticeProfile: triadManager.APPRENTICE_PROFILE,
      tutorProfile: triadManager.TUTOR_PROFILE,
      apprenticeMasterProfile: triadManager.APPRENTICE_MASTER_PROFILE,
      studeaManagerPofile: triadManager.STUDEA_MANAGER_PROFILE,
    };
  },
  mounted() {
    this.apprenticeReply = this.questionnaire.dashboardData[this.triad.id]
      ? this.questionnaire.dashboardData[this.triad.id].apprentice
      : {};
    this.tutorReply = this.questionnaire.dashboardData[this.triad.id]
      ? this.questionnaire.dashboardData[this.triad.id].tutor
      : {};
    this.apprenticeMasterReply = this.questionnaire.dashboardData[this.triad.id]
      ? this.questionnaire.dashboardData[this.triad.id].apprenticeMaster
      : {};
    this.studeaManagerReply = this.questionnaire.dashboardData[this.triad.id]
      ? this.questionnaire.dashboardData[this.triad.id].studeaManager
      : {};
  },
  methods: {
    manage(payload) {
      this.$emit("manage", payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
