import { apiConnection } from "@/services/api-connection";

const URL_INCIDENT_SHEET = "/app/studea-manager/incident-sheet";

class IncidentSheetManager {
  INCIDENT_INPROGRESS_STATUS = 1;
  INCIDENT_ENCLOSE_STATUS = 2;

  getIncidentSheetListForStudeaManager(params) {
    return apiConnection
      .get(URL_INCIDENT_SHEET + "/list", params)
      .then((response) => response);
  }

  delete(id) {
    return apiConnection
      .delete(URL_INCIDENT_SHEET + "/delete/" + id)
      .then((response) => response);
  }

  update(id) {
    return apiConnection
      .put(URL_INCIDENT_SHEET + "/update/" + id)
      .then((response) => response);
  }

  getIncidentSheetform(type, id) {
    return apiConnection.get(
      URL_INCIDENT_SHEET + "/" + type + "/form" + (id ? "/" + id : ""),
    );
  }

  postCommennt(params = [], id = null) {
    return apiConnection.put(URL_INCIDENT_SHEET + "/comment/" + id, params);
  }
}

export const incidentSheetManager = new IncidentSheetManager();
