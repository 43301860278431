export default {
  chat: {
    recipients: "Destinatarios",
    subject: "Objeto",
    message: "Mensaje",
    report: "Denunciar",
    report_confirm: "¿Confirma que quiere denunciar el mensaje?",
    report_success: "¡Gracias por su denuncia!",
    message_success: "Mensaje enviado correctamente",
    signaled: "Ha notificado un abuso",
    message_notifs: "nuevos mensajes",
    message_notif: "nuevo mensaje",
    message_error: "Vaya, se ha producido un error en el envío",
    create_message: "Nuevo mensaje",
    moderation: "Moderación",
    to: "Para",
    choose_recipients: "Selección de destinatarios",
    email_answer_notif:
      "Recibir una notificación de las respuestas por correo electrónico",
    email_recipient_notif:
      "Enviar una notificación a los destinatarios por correo electrónico",
    archived_messages: "Ver los mensajes archivados",
    archived_messages_list: "Mensajes archivados",
    receipt_messages: "Ver los mensajes recibidos",
    sending: "Envío en curso...",
    archive_confirm: "¿Confirma que quiere archivar este mensaje?",
    unarchive_confirm: "¿Confirma que quiere desarchivar este mensaje?",
    delete_title: "Eliminar una conversación",
    delete_confirm: "¿Confirma que quiere eliminar esta conversación?",
    delete_success: "Conversación eliminada correctamente",
    placeholder: "Escriba su mensaje...",
    placeholder_subject: "Tema...",
    select_message: "Haga clic en una conversación",
  },
};
