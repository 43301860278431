<template>
  <TabsTitleComponent :items="tabs" />
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <div class="widget p-4">
        <BlockLoader :loading="loading" />
        <ItemTabsBlock
          :currentItem="currentReferential"
          :itemList="referentials"
          itemType="referential"
          trad="studea_manager.skill_referential.empty_add"
          @changeItem="changeReferential"
          @deleteItem="deleteReferential"
        />
        <div class="loader-block" v-if="referentials && referentials.length">
          <VueDraggableNext
            :animation="200"
            :delay="200"
            :delay-on-touch-only="true"
            :group="{ name: 'categories' }"
            :list="currentReferential?.skillCategories"
            handle=".grip"
            :disabled="
              !accessManager().isAvailableForUser(
                this.$route,
                studeaManagerManager().STUDEA_MANAGER_SKILLS,
                accessManager().EDIT,
              )
            "
            @change="sortCategory"
          >
            <SkillCategoryComponent
              v-if="
                currentReferential &&
                currentReferential.skillCategories &&
                currentReferential.skillCategories.length
              "
              :skills="currentReferential.skillCategories"
              :currentSession="currentReferential.id"
              :currentSkillReferential="currentReferential"
              type="full-access"
              @init="init"
            />
            <div
              class="widget empty"
              v-else-if="currentReferential && currentReferential.id"
            >
              <div v-if="!isConfigured()" class="mb-2">
                <FontAwesomeIcon
                  icon="fa-light fa-triangle-exclamation"
                  class="text-warning fs-1"
                />
              </div>
              <h6 class="textStyle mb-3" v-if="isConfigured()">
                {{
                  $tc("global.empty.title", 2, {
                    name1: this.$tc(
                      "studea_manager.skill.title",
                      1,
                    ).toLowerCase(),
                    name2: this.$tc(
                      "studea_manager.skillCategory.title",
                      1,
                    ).toLowerCase(),
                  })
                }}
              </h6>
              <h6 class="textStyle mb-3" v-else>
                {{ $t("studea_manager.skill_configuration.warning") }}
              </h6>
              <router-link
                v-if="isConfigured()"
                class="btn btnStyle btn-primary"
                :to="{
                  name: 'studeaManagerSkillCategoryAdd',
                  params: { alias: 'skill-category' },
                }"
                v-access:[permissions]="accessManager().EDIT"
              >
                <FontAwesomeIcon icon="fa-light fa-plus" />
                <div class="ms-2">
                  {{ this.$tc("studea_manager.category.add") }}
                </div>
              </router-link>

              <router-link
                v-else
                class="btn btnStyle btn-primary"
                :to="{
                  name: 'studeaManagerSkillConfiguration',
                }"
                v-access:[permissions]="accessManager().EDIT"
              >
                <FontAwesomeIcon icon="fa-light fa-cog" />
                <div class="ms-2">
                  {{ $t("studea_manager.skill_configuration.title") }}
                </div>
              </router-link>
            </div>
          </VueDraggableNext>
        </div>
        <div class="widget empty" v-else>
          <div class="widget-content">
            <div class="text-center">
              <div class="noResult blockStyle">
                {{ $t("No data available") }}<br />
                {{ $t("Please click on the add button") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="importExportActions"
            :mobileCollapsable="true"
            :collapsable="false"
            :title="$t('studea_manager.skill.actions.import_export_actions')"
            collapseId="importExportActions"
            @export="exportSkills"
            @exportTaught="exportTaught"
            @import="importFranceCompetence"
          />
          <ActionsBlock
            :actions="skillActions"
            :mobileCollapsable="true"
            :collapsable="false"
            :title="$t('studea_manager.skill.actions.skill_actions')"
            collapseId="skillActions"
            @removeSkills="removeSkills"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notificationManager } from "@/services/utilities/notification-manager";
import { VueDraggableNext } from "vue-draggable-next";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { apiConnection } from "@/services/api-connection";
import { mapActions, mapState } from "pinia";
import { accessManager } from "@/services/security/access-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import SkillCategoryComponent from "@/components/skills/SkillCategoryComponent.vue";
import ItemTabsBlock from "@/components/blocks/ItemTabsBlock.vue";
import TabsTitleComponent from "@/components/blocks/TabsTitleComponent.vue";
import { useNotificationStore } from "@/store/notifications/notification";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";
import { useSkillStore } from "@/store/skill/skill";
import { i18n } from "@/i18n";

export default {
  name: "SkillReferentialListView",
  components: {
    TabsTitleComponent,
    ItemTabsBlock,
    SkillCategoryComponent,
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
    VueDraggableNext,
  },
  data() {
    return {
      skillActions: [],
      importExportActions: [],
      questionnaireModel: this.questionnaire,
      referentials: [],
      showModal: false,
      models: [],
      list: {},
      categories: [],
      years: {},
      skills: [],
      loading: false,
      tabs: [],
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      currentSession: (store) => store.currentSession,
    }),
    ...mapState(useTriadStore, {
      currentReferential: (store) => store.currentReferential,
    }),
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
      useSkillAdminConfig: (store) => store.useSkillAdminConfig,
    }),
    ...mapState(useNotificationStore, {
      refreshComponentKey: (store) => store.refreshComponentKey,
    }),
  },
  mounted() {
    this.fetchStudeaManagerSkillConfig(this.$route.params.trainingCourseId);
    this.init();
  },
  watch: {
    trainingCourse() {
      this.initActions();
      this.initTabs();
    },

    currentReferential() {
      this.initActions();
    },

    refreshComponentKey() {
      this.init();
    },
  },
  methods: {
    studeaManagerManager() {
      return studeaManagerManager;
    },
    accessManager() {
      return accessManager;
    },
    sortCategory(event) {
      this.loading = true;
      const id = event.moved.element.id;
      const position = event.moved.newIndex;
      studeaManagerManager
        .sort(
          "skill-category",
          this.$route.params.trainingCourseId,
          id,
          position,
        )
        .then(() => {
          this.init();
        });
    },

    isConfigured() {
      if (this.useSkillAdminConfig) {
        return true;
      }
      return !!(
        this.trainingCourse &&
        this.trainingCourse.maxSkillLevel &&
        this.trainingCourse.skillRoles &&
        this.trainingCourse.skillLevels &&
        Object.keys(this.trainingCourse.skillRoles).length &&
        Object.keys(this.trainingCourse.skillLevels).length &&
        (this.currentReferential ||
          (this.currentReferential && this.currentReferential.id))
      );
    },

    removeSkills() {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.skill_remove.title"),
          this.$t("studea_manager.skill_remove.sub_title"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection
              .get(
                "/app/studea-manager/" +
                  this.$route.params.trainingCourseId +
                  "/skill/remove-repository",
              )
              .then((response) => {
                if (response) {
                  notificationManager.showNotification(
                    "success",
                    i18n.global.t("studea_manager.skill_remove.success"),
                  );
                } else {
                  notificationManager.showNotification(
                    "error",
                    i18n.global.t("signed_error"),
                  );
                }
              });
          }
        });
    },

    importFranceCompetence() {
      notificationManager
        .showAlert(
          "info",
          this.$t("studea_manager.skill_import_france_competence.title"),
          this.$t("studea_manager.skill_import_france_competence.confirm"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            apiConnection
              .get(
                "/app/studea-manager/" +
                  this.$route.params.trainingCourseId +
                  "/skill/france-competence",
              )
              .then((response) => {
                messengerMessageManager.processMessage(response);
              });
          }
        });
    },

    exportSkills() {
      studeaManagerManager.export("skill", this.$route.params.trainingCourseId);
    },

    exportTaught() {
      studeaManagerManager.export(
        "subject",
        this.$route.params.trainingCourseId,
      );
    },

    init() {
      if (
        this.currentReferential &&
        !Object.keys(this.currentReferential).length
      ) {
        this.setCurrentReferential({});
      }
      this.loading = true;
      this.getReferentialList();
      this.initActions();
      this.initTabs();
    },

    getReferentialList() {
      studeaManagerManager
        .getReferentialList(this.$route.params.trainingCourseId)
        .then((response) => {
          if (response) {
            this.loading = false;
            this.referentials = response;
            if (this.currentReferential && !this.currentReferential.id) {
              this.setCurrentReferential(response[0]);
            } else if (this.currentReferential && this.currentReferential.id) {
              const newCurrentReferential = this.referentials.find(
                (referential) => referential.id === this.currentReferential.id,
              );
              if (newCurrentReferential) {
                this.setCurrentReferential(newCurrentReferential);
              } else {
                this.setCurrentReferential(response[0]);
              }
            } else {
              this.setCurrentReferential(response[0]);
            }
          }
        });
    },

    changeReferential(id) {
      this.setCurrentReferential(
        this.referentials.find((referential) => referential.id === id),
      );
    },

    deleteReferential(id) {
      notificationManager
        .showAlert(
          "error",
          this.$t("studea_manager.delete_referential_confirmation"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            const url =
              "/app/studea-manager/" +
              this.$route.params.trainingCourseId +
              "/generic/skill-referential/delete/" +
              id;
            apiConnection.delete(url).then(() => {
              this.loading = true;
              this.init();
            });
          }
        });
    },

    initActions() {
      this.skillActions = [
        {
          translation: "global.add.title",
          translationGender: 2,
          disabled: !this.isConfigured(),
          translationParams: {
            name: i18n.global
              .tc("studea_manager.skillCategory.title", 1)
              .toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillCategoryAdd",
          cfaStyle: false,
          params: { alias: "skill-category" },
          query: {
            currentReferential:
              this.currentReferential && this.currentReferential.id
                ? this.currentReferential.id
                : null,
          },
          access: accessManager.EDIT,
        },
        {
          translation: "global.add.title",
          translationGender: 2,
          disabled: !this.isConfigured(),
          translationParams: {
            name: i18n.global.tc("studea_manager.skill.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillAdd",
          cfaStyle: false,
          params: { alias: "skill" },
          query: {
            currentReferential:
              this.currentReferential && this.currentReferential.id
                ? this.currentReferential.id
                : null,
          },
          access: accessManager.EDIT,
        },
      ];

      this.importExportActions = [
        {
          translation: "global.import.title",
          translationGender: 2,
          disabled: !this.isConfigured(),
          translationParams: {
            name: i18n.global.tc("studea_manager.skill.title", 2).toLowerCase(),
          },
          icon: "download",
          type: "success",
          actionType: "router",
          action: "studeaManagerSkillImport",
          cfaStyle: false,
          params: { alias: "skill" },
          access: accessManager.EDIT,
        },
        {
          translation: "studea_manager.skill_import_france_competence.title",
          icon: "download",
          disabled: !this.trainingCourse?.training?.titleRncp,
          type: "success",
          action: "import",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
        {
          translation: "global.export.title",
          translationGender: 2,
          disabled: !this.isConfigured(),
          translationParams: {
            name: i18n.global.tc("studea_manager.skill.title", 2).toLowerCase(),
          },
          icon: "upload",
          type: "success",
          action: "export",
          cfaStyle: false,
        },
        {
          translation: "studea_manager.skill_export_taught.title",
          icon: "upload",
          disabled: !this.isConfigured(),
          type: "success",
          action: "exportTaught",
          cfaStyle: false,
        },
      ];
    },

    initTabs() {
      this.tabs = [
        {
          id: 0,
          title: i18n.global.t("studea_manager.skill_configuration.title"),
          icon: "gear",
          disabled: false,
          path: "studeaManagerSkillConfiguration",
          params: {
            alias: "skill-config",
            trainingCourseId: this.$route.params.trainingCourseId,
          },
        },
        {
          id: 1,
          title: i18n.global.t("studea_manager.skill_referential.title"),
          icon: "book",
          disabled: false,
          path: "studeaManagerSkillReferentialConfiguration",
          params: {
            alias: "skill-referential",
            trainingCourseId: this.$route.params.trainingCourseId,
          },
        },
        {
          id: 2,
          title: i18n.global.t("studea_manager.skillSession.gestion"),
          icon: "medal",
          disabled: false,
          path: "studeaManagerSkillList",
          params: {
            alias: "skill-session",
            trainingCourseId: this.$route.params.trainingCourseId,
          },
        },
      ];
    },

    ...mapActions(useSkillStore, ["fetchStudeaManagerSkillConfig"]),

    ...mapActions(useTriadStore, [
      "setCurrentSession",
      "setCurrentReferential",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.referential {
  &__list {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    background-color: #f1f2f3;
    color: var(--primary-color);
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include up-to-md {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }

    &.-active {
      color: white;
      background-color: var(--primary-color);

      .btn-icon {
        color: white;
      }
    }
  }

  &__button {
    text-align: left;
    border: none;
    background-color: inherit;
    color: inherit;

    @include mobile {
      width: 70%;
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  padding: 20px;
}

.empty-session {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list__button.btn-icon {
  svg {
    width: 22px;
    height: 22px;
  }
}

.noResult {
  padding: 20px;
}
</style>
