<template>
  <div
    class="dropdown nav-item notification-dropdown btn-group"
    ref="notificationDropdown"
  >
    <button
      type="button"
      id="ddlnotify"
      data-bs-toggle="dropdown"
      :aria-expanded="open"
      class="btn-notifications"
      :class="{
        waiting:
          status === messengerMessageManager().STATUS_FAILED ||
          status === messengerMessageManager().STATUS_WAITING ||
          status === messengerMessageManager().STATUS_PROCESSING,
        success: status === messengerMessageManager().STATUS_SUCCESS,
        off: status === messengerMessageManager().STATUS_OFF,
        error: status === messengerMessageManager().STATUS_ERROR,
        show: open,
      }"
    >
      <FontAwesomeIcon icon="fa-light fa-bell" />
    </button>
    <ul
      class="dropdown-menu dropdown-menu-right"
      :class="{ show: open }"
      aria-labelledby="ddlnotify"
      :data-bs-popper="open ? 'static' : ''"
    >
      <template v-for="(message, index) in messages" :key="message.id">
        <NotificationComponent :message="message" :separator="!!index" />
      </template>
      <li role="presentation" v-if="!messages || !Object.keys(messages).length">
        <div class="dropdown-item">
          <div class="media file-upload">
            <div class="media-body">
              <div class="data-info">
                <h6>{{ $t("header.notification.empty") }}</h6>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li role="presentation">
        <button class="dropdown-item list" @click="openList">
          <div class="media file-upload">
            <div class="media-body justify-content-start">
              <div class="icon-status d-flex align-items-center">
                <font-awesome-icon :icon="['fal', 'list']" />
              </div>
              <div class="data-info d-flex align-items-center ms-4">
                <h6>{{ $t("header.notification.list") }}</h6>
              </div>
            </div>
          </div>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import NotificationComponent from "@/components/notifications/NotificationComponent.vue";
import { mapActions, mapState } from "pinia";
import { useNotificationStore } from "@/store/notifications/notification";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

export default {
  name: "NotificationsComponent",
  components: { NotificationComponent },
  computed: {
    ...mapState(useNotificationStore, {
      status: (store) => store.status,
      open: (store) => store.open,
      messages: (store) => store.messages,
    }),
  },
  mounted() {
    this.$refs.notificationDropdown.addEventListener("hide.bs.dropdown", () => {
      this.setOpen(false);
    });
    this.$refs.notificationDropdown.addEventListener("show.bs.dropdown", () => {
      this.setOpen(true);
    });
  },
  methods: {
    messengerMessageManager() {
      return messengerMessageManager;
    },

    openList() {
      this.setListOpened(true);
    },

    ...mapActions(useNotificationStore, {
      setOpen: "setOpen",
      setListOpened: "setListOpened",
    }),
  },
};
</script>

<style lang="scss" scoped>
.btn-notifications {
  background: none;
  border: none;
  font-size: 22px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;

  svg {
    color: $white;
  }

  &.waiting {
    &:after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0;
      border-radius: 50%;
      border: 2px solid $white;
      animation: prixClipFix 2s ease infinite;
    }
  }

  &.error,
  &.success {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 2px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $red;
    }
  }

  &.success {
    &:after {
      background: $green;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    transform: rotate(0);
  }
  12.5% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  37.5% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    transform: rotate(1turn);
  }
  62.5% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  87.5% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    transform: rotate(0);
  }
}

h6 {
  color: var(--primary-color);

  &:hover {
    color: var(--primary-color) !important;
  }
}

.dropdown-item {
  &:hover {
    h6 {
      color: var(--primary-color) !important;
    }
  }
}
</style>
