<template>
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <div class="loader-block">
        <div class="panel-body pill-justify-centered tabs">
          <div class="widget p-3">
            <ul
              class="nav nav-pills"
              id="justify-center-pills-tab"
              role="tablist"
            >
              <li class="nav-item" v-for="(tab, index) in tabs" :key="tab">
                <a
                  :class="
                    'nav-link' +
                    ((activeTab ? activeTab === tab.code : index === 0)
                      ? ' active'
                      : '')
                  "
                  :id="tab.code + '-tab'"
                  data-bs-toggle="pill"
                  :href="'#' + tab.code"
                  role="tab"
                  :aria-controls="tab.code"
                  :aria-selected="index === '0'"
                  @click="changeTab(tab.code)"
                  >{{
                    tab.title
                      ? tab.title
                      : $t("studea_manager.document." + tab.code + "_documents")
                  }}</a
                >
              </li>
            </ul>
          </div>

          <div class="tab-content" id="justify-center-pills-tabContent">
            <div
              v-for="(tab, index) in tabs"
              :key="tab.code"
              :class="
                'tab-pane pt-0 fade' +
                ((activeTab ? activeTab === tab.code : index === 0)
                  ? ' show active'
                  : '')
              "
              :id="tab.code"
              role="tabpanel"
              :aria-labelledby="tab.code + '-tab'"
            >
              <div class="widget">
                <div class="widget-content">
                  <div
                    class="document"
                    v-if="tab.documents && Object.keys(tab.documents).length"
                  >
                    <div class="document__heading">
                      <div class="col-md-5">
                        {{ $t("global.label.name") }}
                      </div>
                      <div class="col-md-3">
                        {{ $t("global.label.triads") }}
                      </div>
                      <div class="col-md-3">
                        {{ $t("global.label.date") }}
                      </div>
                      <div class="col-md-1"></div>
                    </div>
                    <VueDraggableNext
                      :animation="200"
                      :delay="200"
                      :delay-on-touch-only="true"
                      :group="{ name: 'documents' }"
                      :list="tab.documents"
                      :disabled="
                        !accessManager().isAvailableForUser(
                          accessManager().EDIT,
                        )
                      "
                      @change="sort($event)"
                      class="document__list"
                    >
                      <div
                        class="document__row"
                        v-for="document in tab.documents"
                        :key="document.id"
                      >
                        <div class="col-md-5 col-12 document__item">
                          <div class="fw-bold document__cel">
                            <div class="col-4 d-md-none">
                              {{ $t("global.label.name") }}
                            </div>
                            <div class="col-md-12 col-8">
                              {{ document.title }} <br />
                              <span class="uploader"
                                >{{ $t("by") }}
                                {{
                                  document.uploader
                                    ? document.uploader.fullName
                                    : "--"
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-12 document__item">
                          <div class="document__cel">
                            <div class="col-4 d-md-none">
                              {{ $t("global.label.triads") }}
                            </div>
                            <div
                              class="col-md-12 col-8"
                              v-if="
                                document.triads &&
                                Object.keys(document.triads).length
                              "
                            >
                              <template
                                v-for="(triad, index) in document.triads"
                                :key="triad.id"
                              >
                                <span
                                  v-if="
                                    triad.apprentice &&
                                    triad.apprentice.fullName
                                  "
                                  >{{ index ? "," : "" }}
                                  {{ triad.apprentice.fullName }}</span
                                >
                              </template>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-12 document__item">
                          <div class="document__cel">
                            <div class="col-4 d-md-none">
                              {{ $t("global.label.date") }}
                            </div>
                            <div class="col-8 col-md-12">
                              {{ format(document.date) }}
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-1 document__actions">
                          <router-link
                            v-if="document.trainingCourseCount < 2"
                            :to="{
                              name: 'studeaManagerDocumentEdit',
                              params: {
                                id: document.id,
                                alias: 'document',
                                trainingCourseId:
                                  this.$route.params.trainingCourseId,
                              },
                            }"
                            v-access:[permissions]="accessManager().EDIT"
                            class="list__button"
                            v-tooltip="{
                              title: $tc('global.edit.title', 1, {
                                name: this.$tc(
                                  'studea_manager.document.title',
                                  1,
                                ).toLowerCase(),
                              }),
                            }"
                          >
                            <FontAwesomeIcon icon="fa-light fa-edit" />
                          </router-link>
                          <a
                            class="list__button"
                            v-tooltip="{
                              title: $t('global.download.title', 1, {
                                name: this.$tc(
                                  'studea_manager.document.title',
                                  1,
                                ).toLocaleLowerCase(),
                              }),
                            }"
                            v-access:[permissions]="accessManager().VIEW"
                            :href="downloadSrc(document)"
                          >
                            <FontAwesomeIcon icon="fa-light fa-download" />
                          </a>
                          <button
                            class="list__button"
                            v-if="document.trainingCourseCount < 2"
                            v-tooltip="{
                              title: $t('global.delete.title', 1, {
                                name: this.$tc(
                                  'studea_manager.document.title',
                                  1,
                                ),
                              }),
                            }"
                            v-access:[permissions]="accessManager().DELETE"
                            @click="remove(document.id, 'document')"
                          >
                            <FontAwesomeIcon icon="fa-light fa-trash" />
                          </button>
                          <button
                            v-else
                            class="list__button"
                            v-tooltip="{
                              title: $t(
                                'studea_manager.document.shared_document_info',
                              ),
                            }"
                            disabled=""
                          >
                            <FontAwesomeIcon icon="fa-solid fa-share-all" />
                          </button>
                        </div>
                      </div>
                    </VueDraggableNext>
                  </div>
                  <div class="mt-2" v-else>
                    <div class="textStyle fst-italic">
                      {{
                        $tc("global.empty.dashboard", 1, {
                          name: this.$tc(
                            "studea_manager.document.title",
                            1,
                          ).toLowerCase(),
                        })
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            :collapsable="false"
            collapseId="actions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueDraggableNext } from "vue-draggable-next";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { fileManager } from "@/services/file/file-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { accessManager } from "@/services/security/access-manager";
import { mapState } from "pinia";
import { useUserStore } from "@/store/user/user";

export default {
  name: "DocumentListView",
  components: {
    FontAwesomeIcon,
    BlockLoader,
    ActionsBlock,
    VueDraggableNext,
  },
  data() {
    return {
      tabs: [],
      loading: false,
      activeTab: "general",
      actions: [
        {
          translation: "global.add.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc("studea_manager.document.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerDocumentAdd",
          cfaStyle: false,
          params: {
            alias: "document",
            trainingCourseId: this.$route.params.trainingCourseId,
          },
          access: accessManager.EDIT,
        },
      ],
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    init(loading = true) {
      this.loading = loading;
      studeaManagerManager
        .list("document", this.$route.params.trainingCourseId)
        .then((res) => {
          this.tabs = res;
          this.loading = false;
          if (this.tabs.length === 1) {
            this.activeTab = this.tabs[0].code;
          }
        });
    },

    sort(event) {
      if (event.moved) {
        const id = event.moved.element.id;
        const position = event.moved.newIndex;
        const type = event.moved.element.pedagogic ? "pedagogic" : "general";
        studeaManagerManager
          .sort(
            "document",
            this.$route.params.trainingCourseId,
            id,
            position,
            false,
            type,
          )
          .then(() => {
            this.init(false);
          });
      }
    },

    changeTab(code) {
      this.activeTab = code;
    },

    format(date) {
      return dateFormatter.format(date, "long");
    },

    remove(id, alias) {
      studeaManagerManager
        .remove(alias, this.$route.params.trainingCourseId, id, "document")
        .then(() => {
          this.init();
        });
    },

    downloadSrc(document) {
      return fileManager.getDownloadSrc(document.documentFile);
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
}

.grip {
  cursor: grab;
}

.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);

    &[disabled] {
      cursor: initial;
    }

    svg {
      width: 16px;
    }
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__document {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }
    }

    &:hover {
      background: $lighter-grey;
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &.-action {
      width: 15%;
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;

    @include up-to-md {
      text-align: left;
    }
  }
}

.uploader {
  font-weight: 400;
  color: $m-color_8;
  font-size: 12px;

  @include dark-theme {
    color: $m-color_6;
  }
}

.nav {
  &-item {
    margin-left: 0 !important;
    margin-right: 10px !important;

    &:last-child {
      margin-right: 0 !important;
    }

    @include up-to-lg {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
}

.document {
  &__heading {
    display: flex;
    font-weight: bold;
    color: var(--primary-color);
    border-bottom: 1px dashed #adadad;
    padding-bottom: 5px;
    @include up-to-md {
      display: none;
    }
  }

  &__row {
    border-bottom: 1px dashed #adadad;
    width: 100%;
    display: flex;
    align-items: center;

    @include up-to-md {
      flex-direction: column;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: $lighter-grey;

      @include up-to-md {
        background-color: inherit;
      }

      @include dark-theme {
        color: $white;
        background-color: $m-color_19;
      }
    }
  }

  &__item {
    @include up-to-md {
      padding: 5px 10px;
    }

    &:nth-child(2n + 1) {
      @include up-to-md {
        background-color: $lighter-grey;
      }
    }
  }

  &__cel {
    display: flex;
  }

  &__actions {
    text-align: right;

    @include up-to-md {
      padding: 5px 10px;
    }
  }
}
</style>
