export default {
  chooseFile: "Choose a file",
  browse: "Browse",
  clearFile: "Delete file",
  requiredField: "This field is required",
  requiredNumberOrFloat: "Please enter a whole or decimal number",
  emailError: "Please enter a valid e-mail address",
  phoneError: "Please enter a valid phone number",
  lastName: "Surname",
  firstName: "First name",
  email: "E-mail",
  address: "Address",
  complement: "Extra line",
  postCode: "Postcode",
  city: "Town/city",
  phone: "Tel.",
  mobile: "Mobile",
  actions: "Actions",
  rights: "Rights",
  send: "Send",
  errors: "Errors requiring attention",
  form_validation: "The following fields are required:",
  entitled: "Title",
  title: "Title",
  selectValue: "Select a value",
  uploaded_on: "Uploaded on",
  error_file: "This file does not seem to be available",
  download_success: "File downloaded successfully!",
  file_downloading: "Downloading in progress...",
  download_file: "Download file",
  remove_file: "Remove file",
  signed_error: "Error when saving",
  saving: "Saving...",
  confirm: "Confirm",
  import: "Import",
  error_nofile: "Please add a file",
  error_file_format: "Please add a file in the correct format!",
  error_date: "The start date must fall before the end date",
  link: "Open link",
  dateChoice: "Click to choose a date",
  next: "Next",
  pleaseWait: "Please wait...",
  select: "Select",
  cancel: "Cancel",
  save: "Save",
  ok: "OK",
  edit: "Change",
  no: "No",
  yes: "Yes",
  warning: "Please note",
  iConfirm: "Confirm",
  Save: "Save",
  saveFinish: "Saved",
  fileFormatError: "Please select a file in the correct format",
  display: "Display",
  elements: "elements",
  lines: "Lines",
  pagination: {
    to: "to",
    on: "on",
  },
  submit: "Send",
  back: "Back",
  remove: "Remove",
  typeYourSearch: "Type in a search term",
  sortBy: "Sort by",
  order: "Order",
  desc: "Decreasing",
  asc: "Increasing",
  reset: "Reset",
  filter: "Filter",
  Search: "Search",
  "No data available in the table": "No data available in the table",
  "No data available": "No data available",
  "Please enter or modify your search": "Please enter or edit your search",
  "Please click on the add button": "Please click on the button to add more",
  search: "Search",
  All: "All",
  None: "N/A",
  "PDF export": "PDF export",
  "Excel export": "Excel export",
  "PNG export": "PNG export",
  "SVG export": "SVG export",
  "See statistics": "View statistics",
  multiselect: {
    press_enter: "",
    press_enter_group: "",
    selected: "",
    press_enter_remove: "",
    press_enter_remove_group: "",
    press_enter_create_tag: "Add a value",
    select_option: "",
    no_result: "No results match your search",
    no_options: "Type at least 2 characters to search results...",
  },
  init_search: "Reset search",
  search_motor: "Search engine",
  file_size_error: "Please choose a file of at least ",
  maxTagError: "Please make sure you do not enter more than the maximum value",
  minTagError: "Please make sure you do not enter less than the minimum value",
  see_more_fields: "View more fields",
  justificative_piece: "Supporting documents",
  skillRecap: "Skills assessment",
};
