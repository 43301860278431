export default {
  header: {
    dark: "Dark theme",
    light: "Light theme",
    system: "System theme",
    notification: {
      empty: "No processing in progress...",
      waiting: "Waiting for information on processing",
      list: "View full history",
      historic: "Processing history",
      download: "Download",
      file: "Your file",
    },
  },
  menu: "Menu",
  dashboard: {
    timeline: "Timeline",
    indicator: "Indicators",
    indicators: {
      see_details: "View completion rates in detail",
      see_details_complete_triad: "Complete three-party group rate",
      fill_rate: "Completion rates",
    },
    shared_docs: "Shared documents",
    registration: "Registrations",
    welcome_to_studea: "Welcome to your Studea portal!",
    your_actually_are: "You are currently",
    welcome_message: ({ named }) =>
      `Welcome to your Studea portal, <strong>${named("name")}</strong>!`,
    no_training_course_archived: "No year group archived",
    no_training_course_actived: "No active year group",
    no_triad: "No three-party group",
    triad_search_placeholder: "Search",
    triad_members: "Members of the trinomial",
    contract: {
      type: "Type of contract :",
      company: "Employer:",
      dates: "Contract dates:",
      rupture_date: "Date of termination",
      siret: "Siret:",
      address: "Address :",
      from: "From",
      to: "to",
      secondary: "secondary",
    },
    training: {
      triad_list: "View list of three-party groups",
    },
    archived_training_course: ({ plural, named }) =>
      plural([
        `View archived ${named("label")}s`,
        `View archived ${named("label")}s`,
      ]),
    unarchived_training_course: ({ plural, named }) =>
      plural([
        `View active ${named("label")}s`,
        `View active ${named("label")}s`,
      ]),
    Download_the_booklet: "Download digital booklet",
    Download_key_dates: "Download key dates",
    session_lock: "This session is not yet available for input.",
    skill_lock:
      "You cannot change \nthis skill, which is \ncalculated using your responses to the \nquestionnaires or which is not \nallocated to you.",
  },
};
