<template>
  <div
    ref="helpDocumentModal"
    aria-hidden="true"
    aria-labelledby="helpDocumentModal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("help") }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="helpDocument"
            v-for="helpDocument in helpDocuments"
            :key="helpDocument.id"
          >
            <h6>{{ helpDocument.title }}</h6>
            <p
              v-if="helpDocument.documentType === 2 && helpDocument.description"
              v-html="helpDocument.description"
            ></p>
            <a
              v-if="helpDocument.documentType === 1 && helpDocument.file"
              :href="getFileDownloadLink(helpDocument.file)"
              class="btn btn-success btnStyle"
            >
              <FontAwesomeIcon icon="fa-light fa-download" class="me-2" />
              {{ helpDocument.file.originalName }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { fileManager } from "@/services/file/file-manager";

export default {
  name: "HelpDocumentModalComponent",
  props: {
    helpDocuments: {
      type: Array,
      required: true,
    },
    opened: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    opened() {
      const container = this.$refs.helpDocumentModal;
      const modal = new Modal(container);
      if (this.opened) {
        modal.show();
      } else {
        modal.hide();
        this.$emit("hidden");
      }
    },
  },
  mounted() {
    this.$refs.helpDocumentModal.addEventListener(
      "hidden.bs.modal",
      this.hidden,
    );
  },
  methods: {
    hidden() {
      this.models = [];
      this.$emit("hidden");
      const container = this.$refs.helpDocumentModal;
      container.removeEventListener("click", this.stop);
    },

    stop(e) {
      e.stopPropagation();
    },

    getFileDownloadLink(file) {
      return fileManager.getDownloadSrc(file);
    },
  },
};
</script>

<style scoped lang="scss">
.helpDocument + .helpDocument {
  border-top: 1px dashed $m-color_6;
  padding-top: 15px;
  margin-top: 15px;
}
</style>
