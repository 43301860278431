export default {
  absence: {
    absence: "Absence",
    absences: "Absences",
    course: "Lessons",
    company: "Company",
    add_notification: "Send notification of a new certificate",
    add_event: "Add an event",
    edit_event: "Edit an event",
    delete_event: "Delete an event",
    delete_events: "Delete events",
    delete_event_sub_title: "Are you sure you want to delete this event?",
    delete_events_sub_title: "Are you sure you want to delete these events?",
    delete_event_success: "Event deleted successfully!",
    copy_event_success: "Event copied successfully!",
    cut_event_success: "Event cut successfully!",
    delete_events_success: "Events deleted successfully!",
    post_event: "Event added successfully!",
    list_planning: "List schedule",
    list_planning_search: "Search for events",
    import_planning: "Import schedule",
    import_planning_success: "Schedule imported successfully!",
    export_planning: "Export schedule",
    export_planning_success: "Schedule exported successfully!",
    add_absence: "Add absence(s)",
    edit_absence: "Edit an absence",
    delete_absence: "Delete an absence",
    delete_absences: "Delete absences",
    delete_absence_sub_title: "Are you sure you want to delete this absence?",
    delete_absences_sub_title:
      "Are you sure you want to delete these absences?",
    delete_absence_success: "Absence deleted successfully!",
    copy_absence_success: "Absence copied successfully!",
    cut_absence_success: "Absence cut successfully!",
    delete_absences_success: "Absences deleted successfully!",
    post_absence: "Absence added successfully!",
    list_absences: "List of absences",
    list_absences_search: "Search for absences",
    import_absences: "Import absences",
    import_absences_success: "Absences imported successfully!",
    notification: "Certification notification",
    absence_ask: "Application for exceptional leave of absence",
    display_apprentice_list: "Display list of",
    hide_apprentice_list: "Hide list of apprentices",
    training_detail: "View training details",
    apprentice_list: "List of",
    personnal_data: "Personal data",
    sheet_from: "Record of",
    event_moved_success: "Event moved successfully!",
    absence_moved_success: "Absence moved successfully!",
    justify: "Give reasons",
    planning: "Event",
    not_justify: "Not approved",
    context_menu: {
      copy: "Copy",
      cut: "Cut",
      paste: "Paste",
      delete: "Delete",
      modify: "Edit this event",
      add: "Add an event",
      add_absence: "Add an absence",
    },
    actions: {
      planning: "Schedule management",
      absences: "Absence management",
      options: "Options",
    },
  },
};
