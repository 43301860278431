import { i18n } from "@/i18n";
import DriverLicenceListView from "@/views/DriverLicence/DriverLicenceListView.vue";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";

export default {
  path: "/studea-manager/driver-licence",
  name: "driverLicenceView",
  component: GlobalLayoutView,
  meta: {
    authRequired: true,
    title: i18n.global.t("studea_manager.driver_licence.title"),
    breadcrumb: true,
  },
  children: [
    {
      path: "list",
      name: "driverLicenceList",
      component: DriverLicenceListView,
      meta: {
        authRequired: true,
        title: i18n.global.t("studea_manager.driver_licence.list"),

        breadcrumb: true,
      },
    },
  ],
};
