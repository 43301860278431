<template>
  <div class="row">
    <div class="col-lg-9 order-1 order-lg-0">
      <div class="widget">
        <BlockLoader :loading="loading" />
        <div class="widget-heading items-center">
          <h2 class="textStyle referential__title">
            <button
              class="btn-icon me-2"
              v-tooltip="{ title: $t('back') }"
              @click="$router.push({ name: 'adminModuleSkillReferential' })"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </button>
            {{ referential && referential.name ? referential.name : "" }}
            <span
              v-tooltip="{
                title:
                  referential && referential.visible
                    ? $t('global.visible')
                    : $t('global.hidden'),
              }"
              v-if="referential && referential.name"
              :class="'pellet ' + (referential.visible ? 'active' : 'inactive')"
            ></span>
          </h2>
          <router-link
            class="list__button"
            v-tooltip="{ title: $t('admin.skillReferential.edit') }"
            :to="{
              name: 'adminSkillReferentialEdit',
              params: {
                alias: 'skill-referential',
                skillReferentialId: this.$route.params.id,
              },
            }"
          >
            <FontAwesomeIcon icon="fa-light fa-pen-to-square" />
          </router-link>
        </div>
        <div class="widget-content">
          <div class="loader-block">
            <VueDraggableNext
              :animation="200"
              :delay="200"
              :delay-on-touch-only="true"
              :group="{ name: 'categories' }"
              :list="skills"
              handle=".grip"
            >
              <SkillCategoryComponent
                v-if="
                  referential &&
                  referential.skillCategories &&
                  referential.skillCategories.length
                "
                :skills="referential.skillCategories"
                :currentSession="referential.id"
                :currentSkillReferential="referential"
                type="full-access"
                @init="init"
              />
              <div
                class="widget empty"
                v-else-if="referential && referential.id"
              >
                <h6 class="textStyle mb-3">
                  {{
                    $tc("global.empty.title", 2, {
                      name1: this.$tc(
                        "studea_manager.skill.title",
                        1,
                      ).toLowerCase(),
                      name2: this.$tc(
                        "studea_manager.skillCategory.title",
                        1,
                      ).toLowerCase(),
                    })
                  }}
                </h6>
                <router-link
                  class="btn btnStyle btn-primary"
                  :to="{
                    name: 'adminSkillCategoryAdd',
                    params: {
                      alias: 'skill-category',
                      skillReferentialId: this.$route.params.id
                        ? this.$route.params.id
                        : '',
                    },
                  }"
                  v-access:[permissions]="accessManager().EDIT"
                >
                  <FontAwesomeIcon icon="fa-light fa-plus" />
                  <div class="ms-2">
                    {{ this.$tc("studea_manager.category.add") }}
                  </div>
                </router-link>
              </div>
            </VueDraggableNext>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 order-0 order-lg-1">
      <div class="sticky">
        <div id="action-blocks">
          <ActionsBlock
            :actions="importExportActions"
            :mobileCollapsable="true"
            :collapsable="false"
            :title="$t('studea_manager.skill.actions.import_export_actions')"
            @export="exportSkills"
            @exportTaught="exportTaught"
            @import="importFranceCompetence"
          />
          <ActionsBlock
            :actions="skillActions"
            :mobileCollapsable="true"
            :collapsable="false"
            :title="$t('studea_manager.skill.actions.skill_actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { VueDraggableNext } from "vue-draggable-next";
import SkillCategoryComponent from "@/components/skills/SkillCategoryComponent.vue";
import { accessManager } from "@/services/security/access-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { notificationManager } from "@/services/utilities/notification-manager";
import { mapActions, mapState } from "pinia";
import { useSkillStore } from "@/store/skill/skill";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";
import { useNotificationStore } from "@/store/notifications/notification";

export default {
  name: "SkillReferentialEditView",
  components: {
    FontAwesomeIcon,
    SkillCategoryComponent,
    BlockLoader,
    ActionsBlock,
    VueDraggableNext,
  },

  data() {
    return {
      skillActions: [
        {
          translation: "global.add.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc(
              "studea_manager.skillCategory.title",
              1,
            ).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "adminSkillCategoryAdd",
          cfaStyle: false,
          params: {
            alias: "skill-category",
            skillReferentialId: this.$route.params.id
              ? this.$route.params.id
              : "",
          },
          access: accessManager.EDIT,
        },
        {
          translation: "global.add.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.skill.title", 1).toLowerCase(),
          },
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "adminSkillAdd",
          cfaStyle: false,
          params: {
            alias: "skill",
            skillReferentialId: this.$route.params.id
              ? this.$route.params.id
              : "",
          },
          access: accessManager.EDIT,
        },
      ],
      importExportActions: [
        {
          translation: "global.import.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.skill.title", 2).toLowerCase(),
          },
          icon: "download",
          type: "success",
          actionType: "router",
          action: "adminSkillImport",
          cfaStyle: false,
          params: {
            alias: "skill",
            skillReferentialId: this.$route.params.id
              ? this.$route.params.id
              : "",
          },
          access: accessManager.EDIT,
        },
        {
          translation: "global.export.title",
          translationGender: 2,
          translationParams: {
            name: this.$tc("studea_manager.skill.title", 2).toLowerCase(),
          },
          icon: "upload",
          type: "success",
          action: "export",
          cfaStyle: false,
        },
      ],
      referential: [],
      showModal: false,
      models: [],
      list: {},
      categories: [],
      years: {},
      skills: [],
      loading: false,
    };
  },

  mounted() {
    this.init();
    this.fetchAdminSkillConfig();
  },

  computed: {
    ...mapState(useNotificationStore, {
      refreshComponentKey: (store) => store.refreshComponentKey,
    }),
  },

  watch: {
    referential() {
      this.initActions();
    },
    refreshComponentKey() {
      this.init();
    },
  },

  methods: {
    init() {
      this.getReferential();
      this.initActions();
    },

    initActions() {
      if (
        this.referential &&
        this.referential.titleRncp &&
        Object.keys(this.referential.titleRncp).length &&
        !this.importExportActions.find((item) => item.action === "import")
      ) {
        this.importExportActions.unshift({
          translation: "studea_manager.skill_import_france_competence.title",
          icon: "download",
          type: "success",
          action: "import",
          cfaStyle: false,
          access: accessManager.EDIT,
        });
      }
    },

    getReferential() {
      this.loading = true;
      adminManager
        .get("skill/referential/edit", this.$route.params.id)
        .then((response) => {
          this.loading = false;
          if (response && response.length) {
            this.referential = response[0];
          }
        });
    },

    importFranceCompetence() {
      notificationManager
        .showAlert(
          "info",
          this.$t("studea_manager.skill_import_france_competence.title"),
          this.$t("studea_manager.skill_import_france_competence.confirm"),
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            adminManager
              .get("skill/referential/france-competence", this.$route.params.id)
              .then((response) => {
                messengerMessageManager.processMessage(response);
              });
          }
        });
    },

    accessManager() {
      return accessManager;
    },

    exportSkills() {
      //TODO connect export skill route
    },

    exportTaught() {
      //TODO connect export taught route
    },

    ...mapActions(useSkillStore, ["fetchAdminSkillConfig"]),
  },
};
</script>

<style lang="scss" scoped>
.referential {
  &__list {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    background-color: #f1f2f3;
    color: var(--primary-color);
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @include up-to-md {
      margin-right: 0;
      margin-bottom: 10px;
      width: 100%;
    }

    &.-active {
      color: white;
      background-color: var(--primary-color);

      .btn-icon {
        color: white;
      }
    }
  }

  &__button {
    text-align: left;
    border: none;
    background-color: inherit;
    color: inherit;

    @include mobile {
      width: 70%;
    }
  }
}

.referential__title {
  display: flex;
  align-items: center;
}

.pellet {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  display: block;
  border-radius: 50%;

  &.active {
    background-color: $success;
  }

  &.inactive {
    background-color: $danger;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
  padding: 20px;
}

.empty-session {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.list__button.btn-icon {
  svg {
    width: 22px;
    height: 22px;
  }
}

.list__button {
  font-size: 18px;
  color: var(--primary-color);
}
</style>
