<template>
  <div class="dashboard__categories">
    <div
      v-for="(category, index) in categories"
      :key="category.id"
      :aria-expanded="!index"
      :class="'dashboard__category widget on category-' + category.id"
      :style="
        '--max-width:' +
        150 *
          (category.originalQuestionnaires &&
          category.originalQuestionnaires.length
            ? category.originalQuestionnaires.length
            : 1) +
        'px'
      "
    >
      <h3 :id="'heading' + category.id" class="dashboard__header">
        <button
          class="dashboard__category__title"
          @click="toggle(category.id, 'category-', true)"
        >
          <span class="dashboard__category__title__text">{{
            category.title
          }}</span>
          <FontAwesomeIcon icon="fa-light fa-chevron-right"></FontAwesomeIcon>
        </button>
      </h3>
      <div class="dashboard__questionnaires">
        <div
          v-for="questionnaire in category.originalQuestionnaires"
          :key="questionnaire.id"
          class="dashboard__questionnaires__item"
        >
          <div
            class="dashboard__questionnaires__title"
            v-tooltip="{ title: questionnaire.title }"
          >
            {{ questionnaire.title }}
          </div>
          <div
            v-if="type === 'questionnaire'"
            :class="
              'dashboard__questionnaires__actions actions-' + questionnaire.id
            "
          >
            <div class="dashboard__questionnaires__actions__container">
              <button
                class="btn-icon me-2"
                v-tooltip="{
                  title: $t(
                    'studea_manager.dashboard.questionnaire.unlock_all_questionnaires',
                  ),
                }"
                @click="unlockColumnQuestionnaires(questionnaire)"
              >
                <FontAwesomeIcon icon="fa-light fa-lock"></FontAwesomeIcon>
              </button>
              <button
                class="btn-icon"
                v-tooltip="{
                  title: $t(
                    'studea_manager.dashboard.export.all_promotion_replies',
                  ),
                }"
                @click="
                  exportAllQuestionnaireReplies(questionnaire.id, category.id)
                "
              >
                <FontAwesomeIcon icon="fa-light fa-download"></FontAwesomeIcon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export default {
  name: "AccordionHeadlineQuestionnaireComponent",
  props: {
    type: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
  methods: {
    unlockColumnQuestionnaires(questionnaire) {
      studeaManagerManager
        .unlockAllReplies(questionnaire.id, this.$route.params.trainingCourseId)
        .then(() => {
          Object.values(questionnaire.dashboardData).forEach((reply) => {
            if (reply.apprentice.icon === "lock") {
              reply.apprentice.icon = "lock-open";
              reply.apprentice.unlocked = true;
            }
            if (reply.tutor.icon === "lock") {
              reply.tutor.icon = "lock-open";
              reply.tutor.unlocked = true;
            }
            if (reply.apprenticeMaster.icon === "lock") {
              reply.apprenticeMaster.icon = "lock-open";
              reply.apprenticeMaster.unlocked = true;
            }
          });
        });
    },

    exportAllQuestionnaireReplies(item) {
      console.log(
        "Exporter l'ensemble des réponses de la promotion pour le questionnaire n°" +
          item,
      );
    },

    toggle(item, name, isMultipleElement) {
      studeaManagerManager.toggle(item, name, isMultipleElement);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__header {
    width: var(--max-width);
    margin-bottom: 0;
  }

  &__category {
    transition: all 300ms ease-in-out;
    width: 130px;
    margin-right: 10px;
    margin-bottom: 10px;
    height: fit-content;
    overflow: hidden;
    border: 1px solid #e0e6ed;

    &:last-child {
      margin-right: 0;
    }

    &__title {
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      background-color: var(--primary-color);
      color: $white;
      border-radius: 6px;
      border: none;
      height: 160px;
      width: 130px;
      font-size: 0.6rem;
      transition: all 300ms ease-in-out;

      svg {
        transition: all 300ms ease-in-out;
        transform: rotate(0);
        margin-left: 20px;
      }
    }

    &.on {
      width: var(--max-width);

      .dashboard__category__title {
        width: var(--max-width);
        height: 70px;
        font-size: 0.875rem;

        &__text {
          text-wrap: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        svg {
          transform: rotate(180deg);
        }
      }

      .dashboard__questionnaires {
        height: 90px;
        width: var(--max-width);
      }
    }
  }

  &__questionnaires {
    display: flex;
    background-color: $lighter-grey;
    position: relative;
    transition: all 300ms ease-in-out;
    height: 0;
    width: 130px;
    overflow: hidden;

    &__title {
      cursor: default;
      height: 50px;
      padding: 10px 20px 10px 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include dark-theme {
        color: $m-color_10;
      }
    }

    &__item {
      overflow: hidden;
      width: 150px;
      border-right: 1px solid #e0e6ed;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__actions {
      &__button {
        width: 100%;
        border: none;
        font-size: 0.7rem;
        padding: 2px 5px;
        border-radius: 6px 6px 0 0;

        .icon {
          transform: rotate(0);
          transition: all 300ms ease-in-out;
        }
      }

      &__container {
        display: flex;
        padding: 0 20px 10px 20px;
      }
    }
  }
}
</style>
