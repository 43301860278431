<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-3 col-lg-4 order-2 order-lg-0">
        <TriadMemberBlock />
      </div>
      <div class="col-xl-7 col-lg-8 order-1 position-relative widget">
        <div class="widget-heading">
          <h2 class="textStyle">
            <button
              class="btn-icon me-2"
              v-tooltip="{ title: $t('back') }"
              @click="$router.back()"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </button>
            {{ $t("skill_chart") }}
          </h2>
        </div>
        <div class="widget-content">
          <ItemTabsBlock
            :currentItem="currentSession"
            :itemList="sessions"
            trad="studea_manager.skill.list.empty_only"
            @changeItem="changeSession"
          />
          <div class="widget" v-if="charts && charts.length">
            <PdfExportComponent ref="pdf">
              <div class="pageBreak">
                <SkillChartComponent
                  v-for="chart in charts"
                  :key="chart.code"
                  :chart="chart"
                  :isMobile="isMobile"
                />
              </div>
            </PdfExportComponent>
          </div>
          <div class="widget" v-else>
            <div class="widget-content">
              <div class="text-center">
                <div class="noResult blockStyle">
                  {{ $t("No data available") }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <BlockLoader :loading="loading" />
      </div>
      <div class="col-xl-2 order-0 order-lg-2">
        <div class="sticky">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            @pdf="exportChartToPdf"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import ItemTabsBlock from "@/components/blocks/ItemTabsBlock.vue";
import { skillManager } from "@/services/skill/skill-manager";
import SkillChartComponent from "@/components/skills/SkillChartComponent.vue";
import PdfExportComponent from "@/components/export/PdfExportComponent.vue";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { useMainStore } from "@/store/main/main";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "SkillsStatsView",
  components: {
    BlockLoader,
    PdfExportComponent,
    SkillChartComponent,
    ItemTabsBlock,
    TriadMemberBlock,
    ActionsBlock,
  },
  data() {
    return {
      loading: false,
      isHeadingVisible: false,
      charts: {},
      isMobile: false,
      actions: [
        {
          translation: "PDF export",
          icon: "file-pdf",
          type: "primary",
          action: "pdf",
          cfaStyle: true,
        },
        {
          translation: this.$t("back"),
          icon: "arrow-left",
          type: "primary",
          actionType: "router",
          action: "MODULE_STUDEA_SKILL",
          cfaStyle: true,
        },
      ],
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
      skills: (store) => store.skills,
      sessions: (store) => store.sessions,
      currentSession: (store) => store.currentSession,
      currentSessionSkillReply: (store) => store.currentSessionSkillReply,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useMainStore, {
      windowWidth: (store) => store.windowWidth,
    }),
  },
  watch: {
    sessions() {
      this.initSession();
      this.initCharts();
    },
    windowWidth() {
      this.setIsMobile();
    },
    currentSession() {
      this.initCharts();
    },
  },
  mounted() {
    if (this.currentSession && this.currentSession.id) {
      this.initCharts();
    }
    this.setIsMobile();
  },
  methods: {
    initCharts() {
      this.loading = true;
      skillManager.sessionCharts().then((data) => {
        this.charts = data.charts;
        this.loading = false;
      });
    },

    setIsMobile() {
      this.isMobile = this.windowWidth < 768;
    },

    exportChartToPdf() {
      if (this.$refs.pdf) {
        const filename = this.$t("skill_chart_pdf_name") + "-" + Date.now();
        this.$refs.pdf.save(filename);
      } else {
        notificationManager.showNotification(
          "error",
          this.$t("No data available"),
        );
      }
    },

    changeSession(id) {
      this.setCurrentSession(
        this.sessions.find((session) => session.id === id),
      );
    },

    initSession() {
      this.currentSessionSkillReply = {};
      skillManager.initSession();
      if (!Object.keys(this.currentSessionSkillReply).length) {
        if (
          this.triad &&
          this.triad.lastSkillReply &&
          this.triad.lastSkillReply.length
        ) {
          this.setCurrentSessionSkillReply(
            this.triad.lastSkillReply.find(
              (session) => session.skillSessionId === this.currentSession.id,
            ),
          );
        }
      }
    },

    ...mapActions(useTriadStore, {
      setCurrentSession: "setCurrentSession",
      setCurrentSessionSkillReply: "setCurrentSessionSkillReply",
    }),
  },
};
</script>

<style lang="scss" scoped>
.skill-widget {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.noResult {
  padding: 20px;
}
</style>
