import { i18n } from "@/i18n";
import { defineStore } from "pinia";

export const useThemeStore = defineStore({
  id: "Theme",
  state: () => {
    return {
      dark_mode: "light",
      is_dark_mode: false,
      locale: localStorage.getItem("studea-i18n_locale")
        ? localStorage.getItem("studea-i18n_locale")
        : "fr",
      countryList: [
        { code: "en", name: "English" },
        { code: "fr", name: "French" },
        { code: "es", name: "Spanish" },
      ],
    };
  },

  actions: {
    toggleDarkMode(value) {
      //light|dark|system
      value = value || "light";
      localStorage.setItem("studea-dark_mode", value);
      this.dark_mode = value;
      if (value === "light") {
        this.is_dark_mode = false;
      } else if (value === "dark") {
        this.is_dark_mode = true;
      } else if (value === "system") {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          this.is_dark_mode = true;
        } else {
          this.is_dark_mode = false;
        }
      }

      if (this.is_dark_mode) {
        document.querySelector("body").classList.add("dark");
      } else {
        document.querySelector("body").classList.remove("dark");
      }
    },
    toggleLocal(value) {
      value = value || "en";
      i18n.global.locale = value;
      localStorage.setItem("studea-i18n_locale", value);
      this.locale = value;
    },
  },
});
