<template>
  <div class="user-profile layout-spacing">
    <div class="panel">
      <div class="panel-body">
        <div class="d-flex justify-content-between heading">
          <h2 class="textStyle">
            <button
              v-tooltip="{ title: $t('back') }"
              class="btn-icon me-1"
              @click="$router.back()"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </button>
            {{ $t("personalProfile") }}
          </h2>
          <button
            v-if="editable && !editing"
            class="icon-button btnStyle"
            @click="toggleEdit"
          >
            <FontAwesomeIcon class="icon-edit" icon="fa-light fa-pen" />
          </button>
        </div>
        <div class="text-center user-info">
          <div :class="editable ? 'picture-edit' : 'picture'" @click="browse">
            <AvatarComponent :user="profile" size="fit" type="square" />
            <div v-if="editable" class="icon-edit-picture">
              <FontAwesomeIcon icon="fa-light fa-pen" />
            </div>
            <div v-show="pictureModel.display">
              <FileTypeComponent
                v-if="Object.keys(pictureModel).length"
                :model="pictureModel"
                @update="submitMe"
              />
            </div>
          </div>
          <p class="identity textStyle m-0">
            {{ profile.firstName }} {{ profile.lastName }}
          </p>
          <div class="username">
            {{ $t("username") }} : {{ profile.username }}
          </div>
        </div>
        <form>
          <div class="user-info-list">
            <div class="">
              <ul class="contacts-block list-unstyled">
                <li
                  v-for="(item, key) in userModel"
                  :key="item"
                  class="contacts-block__item"
                >
                  <div
                    v-if="key !== 'id' && key !== 'photo'"
                    class="profile-item"
                  >
                    <FontAwesomeIcon
                      v-if="icon(item.vars.name)"
                      :icon="'fa-light fa-' + icon(item.vars.name)"
                      class="icon"
                    />
                    <span v-else class="space"></span>
                    <div v-if="editable && editing" class="w-100">
                      <ModelComponent :model="item" />
                    </div>
                    <span v-else-if="editable && item && item.vars.value">{{
                      item.vars.value
                    }}</span>
                    <span
                      v-else-if="
                        item &&
                        item.vars &&
                        !item.vars.value &&
                        item.vars.attr.placeholder
                      "
                      class="italic"
                      >{{ item.vars.attr.placeholder }}</span
                    >
                    <span v-else>{{ item.vars.value }}</span>
                  </div>
                </li>
              </ul>
              <SubmitTypeComponent
                v-if="editing"
                :standAlone="false"
                @cancelForm="reset"
                @submitForm="submitMe"
              />
            </div>
          </div>
        </form>
      </div>
      <BlockLoader :loading="loading" />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AvatarComponent from "@/components/utilities/AvatarComponent.vue";
import { mapActions, mapState } from "pinia";
import { notificationManager } from "@/services/utilities/notification-manager";
import FileTypeComponent from "@/components/form/FileTypeComponent.vue";
import SubmitTypeComponent from "@/components/form/SubmitTypeComponent.vue";
import { formValidator } from "@/services/form/form-validator";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { useMeta } from "@/composables/use-meta";
import { userManager } from "@/services/security/user-manager";
import { formManager } from "@/services/form/form-manager";
import { loginManager } from "@/services/security/login-manager";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import ModelComponent from "@/components/form/ModelComponent.vue";

export default {
  name: "ProfileBlockComponent",
  components: {
    ModelComponent,
    BlockLoader,
    SubmitTypeComponent,
    FileTypeComponent,
    AvatarComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      userModel: [],
      oldModel: [],
      loading: false,
      pictureModel: [],
      editable: false,
      editing: false,
      profile: [],
    };
  },
  computed: {
    picture() {
      if (this.editable && this.userPicture) {
        return this.userPicture;
      } else if (!this.editable) {
        const routeType = this.$router.currentRoute.value.params.type;
        if (routeType && Object.keys(this.triad[routeType]).length) {
          return this.triad[routeType].photo;
        }
      }

      return "";
    },
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
      userPicture: (store) => store.userPicture,
    }),
  },
  created() {
    if (this.profile && this.profile.firstName) {
      useMeta({
        title: this.profile.firstName + " " + this.profile.lastName,
      });
    }
  },
  watch: {
    "$route.params.id"() {
      if (this.$route.params.id) {
        this.init();
      }
    },
  },

  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      userManager.loadUser(this.$route.params.id).then((profile) => {
        this.editable = this.user.id === profile.id;
        this.profile = profile;
        this.setModel();
        this.loading = false;
        if (this.editable) {
          this.setUserPicture(profile.photo);
        }
      });
    },

    toggleEdit() {
      this.editing = true;
    },

    setModel() {
      this.userModel = [
        {
          vars: {
            block_prefixes: ["", "text"],
            label: false,
            name: "firstName",
            value: this.profile.firstName,
            required: true,
            attr: { placeholder: this.$t("firstName") + "..." },
            columns: 4,
            id: "input-firstName",
          },
        },
        {
          vars: {
            block_prefixes: ["", "text"],
            label: false,
            name: "lastName",
            value: this.profile.lastName,
            required: true,
            attr: { placeholder: this.$t("lastName") + "..." },
            columns: 4,
            id: "input-lastName",
          },
        },
        {
          vars: {
            block_prefixes: ["", "email"],
            label: false,
            name: "email",
            value: this.profile.email,
            required: true,
            attr: { placeholder: this.$t("email") + "..." },
            columns: 4,
            id: "input-email",
          },
        },
        {
          vars: {
            block_prefixes: ["", "text"],
            label: false,
            name: "address",
            value: this.profile.personalAddress
              ? this.profile.personalAddress.address
              : "",
            required: false,
            attr: { placeholder: this.$t("address") + "..." },
            columns: 4,
            id: "input-address",
          },
        },
        {
          vars: {
            block_prefixes: ["", "text"],
            label: false,
            name: "complement",
            value: this.profile.personalAddress
              ? this.profile.personalAddress.complement
              : "",
            required: false,
            attr: { placeholder: this.$t("complement") + "..." },
            columns: 4,
            id: "input-complement",
          },
        },
        {
          vars: {
            block_prefixes: ["", "text"],
            label: false,
            name: "postCode",
            value: this.profile.personalAddress
              ? this.profile.personalAddress.postCode
              : "",
            required: false,
            attr: { placeholder: this.$t("postCode") + "..." },
            columns: 4,
            id: "input-postCode",
          },
        },
        {
          vars: {
            block_prefixes: ["", "text"],
            label: false,
            name: "city",
            value: this.profile.personalAddress
              ? this.profile.personalAddress.city
              : "",
            required: false,
            attr: { placeholder: this.$t("city") + "..." },
            columns: 4,
            id: "input-city",
          },
        },
        {
          vars: {
            block_prefixes: ["", "phone_number"],
            label: false,
            name: "phone",
            value: this.profile.phone,
            required: false,
            attr: { placeholder: this.$t("phone") + "..." },
            columns: 4,
            id: "input-phone",
          },
        },
        {
          vars: {
            block_prefixes: ["", "phone_number"],
            label: false,
            name: "mobile",
            value: this.profile.mobile,
            required: false,
            attr: { placeholder: this.$t("mobile") + "..." },
            columns: 4,
            id: "input-mobile",
          },
        },
      ];

      this.oldModel = JSON.stringify(this.userModel);
      this.pictureModel = {
        vars: {
          block_prefixes: ["", "file"],
          label: "",
          name: "photo",
          id: "photo",
          conf_path: "picture",
          conf_name: "user",
          value: this.profile.photo ? this.profile.photo : {},
          required: false,
          display: false,
        },
      };
    },

    icon(key) {
      switch (key) {
        case "firstName":
        case "lastName":
          return "user";
        case "email":
          return "envelope";
        case "address":
          return "location-pin";
        case "complement":
        case "postCode":
        case "city":
          return "";
        case "phone":
          return "phone";
        case "mobile":
          return "mobile";
      }
    },

    submitMe(pictureLoaded = false) {
      const errors = formValidator.validForm(this.userModel);
      if (!errors.length) {
        this.loading = true;
        let res;
        if (!pictureLoaded) {
          res = formManager.processForm(this.userModel);
          const addressKeys = ["address", "complement", "postCode", "city"];
          res["personalAddress"] = {};
          Object.entries(res).forEach(([key, value]) => {
            if (addressKeys.includes(key)) {
              res.personalAddress[key] = value;
              delete res[key];
            }
          });
        } else {
          this.pictureModel.vars.value = pictureLoaded;
          res = formManager.processForm({ photo: this.pictureModel });
        }

        this.postUserUpdate({ id: this.$route.params.id, datas: res }).then(
          (res) => {
            loginManager.setToken(
              res,
              loginManager.getApiPath(),
              window.localStorage.getItem("studea-stayConnected"),
            );
            this.oldModel = JSON.stringify(this.userModel);
            notificationManager.showNotification("success", this.$t("success"));
            window.scroll({
              top: 0,
              behavior: "smooth",
            });
            this.editing = false;
            this.init();
          },
        );
      }
    },

    reset() {
      this.userModel = JSON.parse(this.oldModel);
      this.editing = false;
    },

    browse() {
      if (this.editable) {
        const input = document.getElementById("photo");
        input.click();
      }
    },

    ...mapActions(useUserStore, {
      setUserPicture: "setUserPicture",
      postUserUpdate: "postUserUpdate",
    }),
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 25px;
  height: 25px;
}

.identity {
  @include dark-theme {
    color: $white;
  }
}

.username {
  font-size: 1rem;
}

.panel {
  position: relative;
}

.heading {
  height: 25px;
}

.icon-edit {
  color: $white;
}

.user-info {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.user-profile .user-info-list ul.contacts-block {
  max-width: 350px;

  &.-edit {
    max-width: 100%;
  }
}

.user-profile .user-info-list ul.contacts-block li {
  font-size: 16px;
}

.space {
  margin-right: 35px;
}

.italic {
  font-style: italic;
  color: $light-grey;
}

.profile-item {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 35px;

  &:hover {
    .fa-pen {
      visibility: visible;
      opacity: 1;
    }
  }

  .form-group {
    height: 100%;
    margin-bottom: 0;
    width: 100%;
  }
}

.icon-edit-picture {
  background: $white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;

  svg {
    fill: $blue;
    font-size: 15px;
  }
}

.picture,
.picture-edit {
  width: 150px;
  height: 150px;
  margin: auto;
}

.picture-edit {
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: $black;
    z-index: 1;
    border-radius: 9px;
    transition: all 300ms ease-in-out;
  }

  &:hover {
    &:after {
      opacity: 0.2;
    }

    .icon-edit-picture {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
