export default {
  absence: {
    absence: "Ausente",
    absences: "Ausencias",
    course: "Curso",
    company: "Empresa",
    add_notification: "Enviar una notificación de nueva certificación",
    add_event: "Añadir un evento",
    edit_event: "Modificar un evento",
    delete_event: "Eliminar un evento",
    delete_events: "Eliminar eventos",
    delete_event_sub_title: "¿Confirma que quiere eliminar este evento?",
    delete_events_sub_title: "¿Confirma que quiere eliminar estos eventos?",
    delete_event_success: "Evento eliminado correctamente",
    copy_event_success: "Evento copiado correctamente",
    cut_event_success: "Evento cortado correctamente",
    delete_events_success: "Eventos eliminados correctamente",
    post_event: "Evento añadido correctamente",
    list_planning: "Hacer una lista del horario",
    list_planning_search: "Buscar eventos",
    import_planning: "Importar el horario",
    import_planning_success: "Planificación importada correctamente",
    export_planning: "Exportar el horario",
    export_planning_success: "Planificación exportada correctamente",
    add_absence: "Añadir una ausencia",
    edit_absence: "Modificar una ausencia",
    delete_absence: "Eliminar una ausencia",
    delete_absences: "Eliminar ausencias",
    delete_absence_sub_title: "¿Confirma que quiere eliminar esta ausencia?",
    delete_absences_sub_title: "¿Confirma que quiere eliminar estas ausencias?",
    delete_absence_success: "Ausencia eliminada correctamente",
    copy_absence_success: "Ausencia copiada correctamente",
    cut_absence_success: "Ausencia cortada correctamente",
    delete_absences_success: "Ausencias eliminadas correctamente",
    post_absence: "Ausencia añadida correctamente",
    list_absences: "Hacer una lista de ausencias",
    list_absences_search: "Buscar ausencias",
    import_absences: "Importar ausencias",
    import_absences_success: "Ausencias importadas correctamente",
    notification: "Notificación de certificación",
    absence_ask: "Solicitud de autorización de ausencia excepcional",
    display_apprentice_list: "Mostrar la lista de",
    hide_apprentice_list: "Ocultar la lista de alumnos",
    training_detail: "Ver los detalles de la formación",
    apprentice_list: "Lista de",
    personnal_data: "Datos personales",
    sheet_from: "Ficha de",
    event_moved_success: "Evento desplazado correctamente",
    absence_moved_success: "Ausencia desplazada correctamente",
    justify: "Justificar",
    planning: "Evento",
    not_justify: "No justificada",
    context_menu: {
      copy: "Copiar",
      cut: "Cortar",
      paste: "Pegar",
      delete: "Eliminar",
      modify: "Modificar este evento",
      add: "Añadir un evento",
      add_absence: "Añadir una ausencia",
    },
    actions: {
      planning: "Gestión del horario",
      absences: "Gestión de ausencias",
      options: "Opciones",
    },
  },
};
