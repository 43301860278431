export default {
  document: "Documento",
  notes: "Notas",
  online_since: "Online desde",
  file: "Archivo",
  note: "Nota",
  document_remove_confirm: "¿Confirma que quiere eliminar este documento?",
  note_remove_confirm: "¿Confirma que quiere eliminar esta nota?",
  document_remove_success: "Su documento se ha eliminado correctamente",
  note_remove_success: "Su nota se ha eliminado correctamente",
  document_remove_error: "Vaya, se ha producido un error en la eliminación",
  document_success: "Su documento se ha enviado correctamente",
  note_success: "Su nota se ha enviado correctamente",
  new_doc: "Añadir un documento",
  new_note: "Añadir una nota",
  new_doc_title: "Nuevo documento",
  new_note_title: "Nueva nota",
  file_integrate_learning_book:
    "Integrar el archivo (PDF) en el contenido del libro de aprendizaje",
  email_notif: "Notificación por e-mail",
  notes_summary: "Notas y resumen",
  by: "Por",
  on: "El",
  pedagogicDocuments: "Documentos pedagógicos",
  generalDocuments: "Documentos generales",
  globalDocuments: "Documentos para descargar",
  otherDocuments: "Otros documentos",
  "Documents to download": "Documentos para descargar",
  Watch: "Ver",
  Download: "Descargar",
  none_document: "No hay ningún documento disponible",
  none_pedagogic: "No hay ningún documento pedagógico disponible",
  none_note: "No hay ninguna nota disponible",
};
