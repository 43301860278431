<template>
  <div
    :id="itemModel.vars.id"
    :class="itemModel.vars.error ? ' is-invalid' : ''"
    v-if="!itemModel.vars.hide"
  >
    <div class="switch-row">
      <label
        v-if="itemModel.vars.label"
        :class="
          'form-label mb-1' +
          (itemModel.vars.label_attr && itemModel.vars.label_attr.class
            ? ' ' + itemModel.vars.label_attr.class
            : '') +
          (itemModel.vars.required ? ' required' : '')
        "
      >
        {{ index ? index + ". " : "" }}{{ itemModel.vars.label
        }}{{ itemModel.vars.required ? "*" : "" }}
        <span
          v-if="itemModel.vars.help && !isMobile()"
          v-tooltip="{ title: itemModel.vars.help }"
        >
          <FontAwesomeIcon icon="fa-light fa-circle-question" />
        </span>
      </label>
      <label class="ms-md-3 ms-0 switch s-icons s-outline s-outline-default">
        <input
          v-model="itemModel.vars.value"
          type="checkbox"
          @input="valid"
          :disabled="itemModel.vars.disabled === true"
        />
        <span
          :class="'slider round' + (itemModel.vars.disabled ? ' disabled' : '')"
        ></span>
      </label>
    </div>
    <div v-if="isMobile() && itemModel.vars.help" class="form-info">
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
      {{ itemModel.vars.help }}
    </div>
  </div>
  <div v-if="itemModel.vars.error" class="invalid-feedback d-block">
    {{ itemModel.vars.error }}
  </div>
</template>

<script>
export default {
  name: "SwitchTypeComponent",
  data() {
    return {
      itemModel: this.model,
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ["draft", "update", "toggle"],
  watch: {
    "model.vars.value"() {
      if (this.itemModel.vars.toggler_target) {
        this.$emit("toggle", this.itemModel);
      }
    },
  },
  mounted() {
    this.itemModel.vars.value =
      typeof this.itemModel.vars.value === "string"
        ? this.itemModel.vars.value === "1"
        : this.itemModel.vars.value;
  },
  methods: {
    valid(event) {
      this.itemModel.vars.value = event.currentTarget.checked;
      this.$emit("update", this.itemModel.vars.value);
      if (this.itemModel.vars.toggler_target) {
        this.$emit("toggle", this.itemModel);
      }
      this.$emit("draft", this.itemModel);
    },

    isMobile() {
      return window.innerWidth < 992;
    },

    focus() {
      this.$emit("focus");
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.switch.s-icons {
  height: 25px;
}

.switch .slider {
  @include up-to-md {
    left: initial;
    right: 0;
  }
}

.form-label {
  @include up-to-md {
    padding-right: 30px;
  }
}

.disabled {
  cursor: not-allowed !important;
}

.switch-row {
  display: flex;
  justify-content: start;
  align-items: center;

  @include up-to-md {
    justify-content: space-between;
  }
}

.switch-before {
  .switch-row {
    .form-label {
      order: 1;
      padding-right: 0;
    }

    .switch {
      min-width: 65px;
      margin-left: 0 !important;
      order: 0;
    }
  }
}
</style>
