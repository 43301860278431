import { apiConnection } from "@/services/api-connection";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

const URL_LIST = "/app/triad/list/";
const URL_ONE = "/app/triad/";
const URL_PDF = "/app/triad/pdf/";

class TriadManager {
  APPRENTICE_PROFILE = 1;
  TUTOR_PROFILE = 2;
  APPRENTICE_MASTER_PROFILE = 3;
  STUDEA_MANAGER_PROFILE = 4;
  loadTriadList = (id, archive, search = "") => {
    return apiConnection
      .get(URL_LIST + id, { archive: archive, search: search }, true)
      .then((response) => response);
  };
  loadOne = (id) => {
    return apiConnection
      .get(URL_ONE + id, {}, true)
      .then((response) => response);
  };

  pdf(id, keyDates = false) {
    return apiConnection
      .get(URL_PDF + id, { keyDates: keyDates ? 1 : 0 })
      .then((res) => {
        messengerMessageManager.processMessage(res);
      });
  }
}

export const triadManager = new TriadManager();
