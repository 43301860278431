<template>
  <InputTypeComponent
    v-if="isInputType()"
    :index="index"
    :model="model"
    @draft="draft"
    @focus="focus(model)"
    @update="update"
    @input="input"
  />
  <PhoneTypeComponent
    v-if="isPhoneType()"
    :index="index"
    :model="model"
    @draft="draft"
    @focus="focus(model)"
    @update="update"
  />
  <TextareaTypeComponent
    v-if="isTextareaType()"
    :index="index"
    :model="model"
    @draft="draft"
    @focus="focus(model)"
    @update="update"
  />
  <EditorTypeComponent
    v-if="isEditorType()"
    :index="index"
    :model="model"
    @draft="draft"
    @focus="focus(model)"
    @update="update"
  />
  <DateTypeComponent
    v-if="isDateType()"
    :index="index"
    :model="model"
    @draft="draft"
    @focus="focus(model)"
    @update="update"
    @dateChanged="dateChanged"
  />
  <RadioTypeComponent
    v-if="isRadioType()"
    :index="index"
    :model="model"
    @draft="draft"
    @update="update"
    @toggle="toggle"
  />
  <SwitchTypeComponent
    v-if="isSwitchType()"
    :index="index"
    :model="model"
    @draft="draft"
    @update="update"
    @toggle="toggle"
  />
  <CheckboxTypeComponent
    v-if="isCheckboxType()"
    :index="index"
    :model="model"
    @draft="draft"
    @update="update"
    @toggle="toggle"
  />
  <SelectTypeComponent
    v-if="isSelectType()"
    :index="index"
    :model="model"
    @ajaxTargetLoad="ajaxTargetLoad"
    @ajaxTargetTrig="ajaxTargetTrig"
    @resetInputs="resetInputs"
    @draft="draft"
    @update="update"
    @toggle="toggle"
  />
  <FileTypeComponent
    v-if="isFileType()"
    :index="index"
    :model="model"
    @draft="draft"
    @focus="focus(model)"
    @update="update"
    @uploading="uploading"
    @uploaded="uploaded"
  />
  <ListTypeComponent
    v-if="isListType()"
    :index="index"
    :model="model"
    @draft="draft"
    @focus="focus(model)"
    @update="update"
  />
  <RoleTypeComponent
    v-if="isRoleType()"
    :index="index"
    :model="model"
    @update="update"
  />
  <TemplateVariableTypeComponent
    :model="model"
    v-if="isTemplateVariableType()"
  />
  <template v-if="isFormType()">
    <button
      type="button"
      @click="expand"
      class="moreFields textStyle"
      v-if="model.vars.attr && model.vars.attr['data-form-more'] && !expanded"
    >
      <span>{{ $t("see_more_fields") }}</span>
    </button>
    <div
      :class="
        'row' +
        (model.vars.attr && model.vars.attr['data-form-more']
          ? ' more' + (expanded ? ' on' : '')
          : '')
      "
    >
      <template v-for="child in model.children" :key="child.vars.id">
        <div v-if="child.vars.line_break" class="col-12"></div>
        <div
          v-if="!child.vars.attr || !child.vars.attr['data-form-sticky']"
          :class="
            'form-group ' +
            (child.vars.row_attr && child.vars.row_attr.class
              ? ' ' + child.vars.row_attr.class
              : '') +
            (child.vars.toggle_class
              ? ' d-none ' + child.vars.toggle_class
              : '')
          "
        >
          <ModelComponent :model="child" />
        </div>
      </template>
    </div>
  </template>
</template>

<script>
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import TextareaTypeComponent from "@/components/form/TextareaTypeComponent.vue";
import DateTypeComponent from "@/components/form/DateTypeComponent.vue";
import RadioTypeComponent from "@/components/form/RadioTypeComponent.vue";
import CheckboxTypeComponent from "@/components/form/CheckboxTypeComponent.vue";
import FileTypeComponent from "@/components/form/FileTypeComponent.vue";
import SelectTypeComponent from "@/components/form/SelectTypeComponent.vue";
import EditorTypeComponent from "@/components/form/EditorTypeComponent.vue";
import ListTypeComponent from "@/components/form/ListTypeComponent.vue";
import SwitchTypeComponent from "@/components/form/SwitchTypeComponent.vue";
import PhoneTypeComponent from "@/components/form/PhoneTypeComponent.vue";
import { formManager } from "@/services/form/form-manager";
import RoleTypeComponent from "@/components/form/RoleTypeComponent.vue";
import TemplateVariableTypeComponent from "@/components/form/TemplateVariableTypeComponent.vue";

export default {
  name: "ModelComponent",
  components: {
    TemplateVariableTypeComponent,
    RoleTypeComponent,
    PhoneTypeComponent,
    SwitchTypeComponent,
    ListTypeComponent,
    EditorTypeComponent,
    SelectTypeComponent,
    FileTypeComponent,
    CheckboxTypeComponent,
    RadioTypeComponent,
    TextareaTypeComponent,
    InputTypeComponent,
    DateTypeComponent,
  },
  data() {
    return {
      errors: [],
      formModel: this.model,
      expanded: false,
    };
  },
  emits: [
    "input",
    "ajaxTargetLoad",
    "ajaxTargetTrig",
    "update",
    "toggle",
    "uploading",
    "uploaded",
    "dateChanged",
    "resetInputs",
    "draft",
    "focus",
    "focused",
  ],
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
    disableFocusAnimation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    if (this.model.vars.attr && this.model.vars.attr["data-ajax-load"]) {
      formManager.fieldLoad(
        this.model,
        this,
        this.model.vars.value ? this.model.vars.value.value : null,
      );
    }
  },
  methods: {
    isInputType() {
      return formManager.isInputType(this.model);
    },
    isPhoneType() {
      return formManager.isPhoneType(this.model);
    },
    isTextareaType() {
      return formManager.isTextareaType(this.model);
    },
    isEditorType() {
      return formManager.isEditorType(this.model);
    },
    isDateType() {
      return formManager.isDateType(this.model);
    },
    isRadioType() {
      return formManager.isRadioType(this.model);
    },
    isSwitchType() {
      return formManager.isSwitchType(this.model);
    },
    isCheckboxType() {
      return formManager.isCheckboxType(this.model);
    },
    isSelectType() {
      return formManager.isSelectType(this.model);
    },
    isTemplateVariableType() {
      return formManager.isTemplateVariableType(this.model);
    },
    isFileType() {
      return formManager.isFileType(this.model);
    },
    isListType() {
      return formManager.isListType(this.model);
    },
    isRoleType() {
      return formManager.isRoleType(this.model);
    },
    isFormType() {
      return formManager.isFormType(this.model);
    },
    focus(item) {
      if (window.innerWidth > 768 && !this.disableFocusAnimation) {
        let group;
        if (
          item.vars.block_prefixes[1] !== "checkbox" &&
          item.vars.block_prefixes[1] !== "radio"
        ) {
          const input = document.getElementById(item.vars.id);
          if (input) {
            group = input.parentNode;
          }
        } else {
          group = document.getElementById(item.id);
        }

        this.$emit("focused", item.vars.id);

        if (group) {
          setTimeout(function () {
            window.scrollTo({
              behavior: "smooth",
              top:
                group.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                250,
            });
          }, 1);
        }
      }
    },

    expand() {
      this.expanded = true;
    },

    draft(model) {
      this.$emit("draft", model);
    },
    update(value) {
      this.$emit("update", value);
    },
    dateChanged(model) {
      this.$emit("dateChanged", model);
    },
    input(value) {
      this.$emit("input", value);
    },
    toggle(model) {
      this.$emit("toggle", model);
    },
    ajaxTargetLoad(payload) {
      this.$emit("ajaxTargetLoad", payload);
    },
    ajaxTargetTrig(payload) {
      this.$emit("ajaxTargetTrig", payload);
    },
    uploading() {
      this.$emit("uploading");
    },
    uploaded(data) {
      this.$emit("uploaded", data);
    },

    resetInputs(payload) {
      this.$emit("resetInputs", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.non-empty {
  overflow: auto;
}

.more {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;

  &.on {
    max-height: 10000px;
  }
}

.moreFields {
  background: none;
  border: none;
  display: block;
  width: 100%;
  text-align: center;
  padding: 7px 0;
  position: relative;
  font-size: 15px;
  font-weight: 700;

  span {
    background: $white;
    position: relative;
    z-index: 2;
    padding: 0 25px;

    @include dark-theme {
      background: $m-color_10;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: $admin-color;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    @include dark-theme {
      background-color: $dark-grey;
    }
  }
}
</style>
