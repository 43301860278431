export default {
  chat: {
    recipients: "Recipients",
    subject: "Purpose",
    message: "Message",
    report: "Flag",
    report_confirm: "Are you sure you want to flag this message?",
    report_success: "Thank you for your report!",
    message_success: "Your message was sent successfully!",
    signaled: "You have reported a breach of the rules",
    message_notifs: "new messages",
    message_notif: "new message",
    message_error: "Error when sending",
    create_message: "New message",
    moderation: "Moderation",
    to: "To",
    choose_recipients: "Choice of recipients",
    email_answer_notif: "Receive notification of responses by e-mail",
    email_recipient_notif: "Send notification to recipients by e-mail",
    archived_messages: "View archived messages",
    archived_messages_list: "Archived messages",
    receipt_messages: "View received messages",
    sending: "Sending...",
    archive_confirm: "Are you sure you want to archive this message?",
    unarchive_confirm: "Are you sure you want to unarchive this message?",
    delete_title: "Delete a conversation",
    delete_confirm: "Are you sure you want to delete this conversation?",
    delete_success: "Conversation deleted successfully!",
    placeholder: "Enter your message...",
    placeholder_subject: "Subject...",
    select_message: "Click on a conversation",
  },
};
