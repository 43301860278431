export default {
  triadList: "List of three-party groups",
  triadTrainingList: "List of three-party groups by ",
  promotion: "year group | year groups",
  youHaveAccess: "You currently have access to {number} {label} {active}",
  female_active: "active | active",
  male_active: "active | active",
  female_archived: "archived | archived",
  male_archived: "archived | archived",
  loadingTriads: "Loading three-party groups...",
  homeTriad: "Three-party groups home",
  apprenticeShort: "A",
  tutorShort: "TP",
  apprenticeMasterShort: "MA",
  Apprentice: "Work-study apprentice",
  Tutor: "Tutor",
  "Apprentice master": "Mentor",
  "Apprentice master 2": "Mentor 2",
  "Company manager": "Company manager",
  Parent: "Parent",
  "Studea manager": "Digital Booklet supervisor",
  apprentices: "Work-study apprentices",
  tutors: "Tutors",
  apprenticeMasters: "Mentors",
  studeaManagers: "Digital booklet supervisors",
  map: "Company tutor",
  myContract: "My contract",
  myContracts: "My contracts",
  help: "Online help",
  company: "Company",
  pedagogic_tutor: "Teaching tutor",
  company_tutor: "Company mentor",
  apprentice: "Work-study apprentice",
  tutor: "Tutor",
  apprenticeMaster: "Mentor",
  studeaManager: "Digital Booklet supervisor",
  legal_responsible: "Legal guardian",
};
