<template>
  <div :id="itemModel.vars.id" class="w-100">
    <label
      v-if="itemModel.vars.label"
      :class="
        'form-label' +
        (itemModel.vars.label_attr && itemModel.vars.label_attr.class
          ? ' ' + itemModel.vars.label_attr.class
          : '') +
        (itemModel.vars.required ? ' required' : '')
      "
    >
      {{ index ? index + ". " : "" }}{{ itemModel.vars.label
      }}{{ itemModel.vars.required ? "*" : "" }}
      <span
        v-if="itemModel.vars.help && !isMobile()"
        v-tooltip="itemModel.vars.help"
        :aria-label="itemModel.vars.help"
      >
        <FontAwesomeIcon icon="fa-light fa-circle-question" />
      </span>
    </label>
    <div v-if="isMobile() && itemModel.vars.help" class="form-info">
      <FontAwesomeIcon icon="fa-light fa-circle-question" />
      {{ itemModel.vars.help }}
    </div>
    <Editor
      v-model="itemModel.vars.value"
      v-if="!itemModel.vars.hide"
      :class="
        'editor' +
        (inline ? ' inline' : '') +
        (itemModel.vars.attr && itemModel.vars.attr.class
          ? ' ' + itemModel.vars.attr.class
          : '')
      "
      :disabled="itemModel.vars.disabled === true"
      :init="init"
      ref="editor"
      api-key="no-api-key"
      @blur="blur"
      @change="change"
      @focus="focus"
    />
    <div
      v-if="
        itemModel.vars.error &&
        (!itemModel.vars.attr ||
          (itemModel.vars.attr && !itemModel.vars.attr['data-hide-error']))
      "
      class="invalid-feedback d-block"
    >
      {{ itemModel.vars.error }}
    </div>
  </div>
</template>

<script>
import "tinymce";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/themes/silver/theme";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/icons/default/icons.min";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
import "tinymce/plugins/link";
import "tinymce/plugins/autolink";
import "tinymce/plugins/charmap";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/help";
import "tinymce/plugins/importcss";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/codesample";
import "tinymce/plugins/hr";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/toc";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/autosave";
import "tinymce/plugins/spellchecker";
import "tinymce/plugins/textcolor";
import "tinymce/plugins/paste";
import "tinymce/plugins/contextmenu";
import "tinymce/plugins/save";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/emoticons/js/emojis.min";
import { formValidator } from "@/services/form/form-validator";
import { loginManager } from "@/services/security/login-manager";
import { fileManager } from "@/services/file/file-manager";
import tinymce from "tinymce";
import { mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";

export default {
  name: "EditorTypeComponent",
  data() {
    return {
      itemModel: this.model,
      apiPath: fileManager.getApiPath()
        ? fileManager.getApiPath().slice(0, -3)
        : "",
      userToken: loginManager.token,
      conf: "platform",
      path: null,
      windowManager: null,
      init: {
        width: "100%",
        height: this.height(),
        theme: "silver",
        inline: this.inline,
        language_url: "/js-vendor/tinymce/langs/" + this.getLanguage() + ".js",
        language: this.getLanguage(),
        menubar: true,
        body_class: "test",
        image_upload_url: "",
        filemanager_crossdomain: true,
        file_picker_callback: this.myFileBrowser,
        plugins: [
          "advlist autolink link image lists charmap print preview hr anchor spellchecker",
          "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
          "save table contextmenu directionality emoticons template paste textcolor",
        ],
        content_css: "/assets/editor/editor.css",
        content_style:
          "@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');",
        skin: false,
        toolbar:
          "undo redo | styleselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image insertfile media | forecolor backcolor | insert",
      },
    };
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
    language: {
      type: String,
      required: false,
      default: "fr",
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  watch: {
    "itemModel.vars.error"() {
      this.getClass();
    },
  },
  created() {
    if (this.model.vars.attr && this.model.vars.mcetype) {
      switch (this.model.vars.mcetype) {
        case "simple":
          this.init.menubar = false;
          this.init.toolbar =
            "undo redo | bold italic | alignleft aligncenter alignright alignjustify";
          this.init.plugins = [
            "autolink",
            "link",
            "lists",
            "nonbreaking",
            "textcolor",
          ];
          this.init.height = 150;
          break;
        case "medium":
          this.init.toolbar =
            "undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | forecolor backcolor";
          break;
      }
    }
    this.conf = this.model.vars.conf_name;
    this.path = this.model.vars.conf_path;
  },
  methods: {
    change() {
      this.valid();
    },

    messageCallback(event) {
      if (!event.data.filevalue) return;
      window.tinymceCallBackURL = event.data.filevalue;
      this.windowManager.close();
    },

    myFileBrowser(callback, value, meta) {
      window.addEventListener("message", this.messageCallback);
      let cmsURL =
        this.apiPath +
        "manager/?conf=" +
        this.conf +
        "&module=tinymce-api" +
        "&type=" +
        meta.filetype +
        "&bearer=" +
        this.userToken +
        "&extra[path]=" +
        this.path;

      if (this.conf === "triad" && this.triad && this.triad.cryptedId) {
        cmsURL = cmsURL + "&extra[subject]=" + this.triad.cryptedId;
      } else if (
        this.conf === "training_course" &&
        this.trainingCourse &&
        this.trainingCourse.cryptedId
      ) {
        cmsURL = cmsURL + "&extra[subject]=" + this.trainingCourse.cryptedId;
      }

      const windowManagerCSS =
        '<style type="text/css">' +
        ".tox-dialog {max-width: 100%!important; width:97.5%!important; overflow: hidden; height:95%!important; border-radius:0.25em;}" +
        ".tox-dialog__body { padding: 0!important; }" +
        ".tox .tox-form__group{height:100%;}" +
        ".tox-dialog__body-content > div { height: 100%; overflow:hidden}" +
        "</style > ";

      window.tinymceCallBackURL = "";
      this.windowManager = tinymce.activeEditor.windowManager;
      this.windowManager.open({
        title: "File Manager",
        body: {
          type: "panel",
          items: [
            {
              type: "htmlpanel",
              html:
                windowManagerCSS +
                '<iframe src="' +
                cmsURL +
                '"  frameborder="0" style="width:100%; height:100%"></iframe>',
            },
          ],
        },
        buttons: [],
        onClose: function () {
          if (window.tinymceCallBackURL !== "") {
            callback(window.tinymceCallBackURL, {}); //to set selected file path
            window.removeEventListener("message", this.messageCallback);
            this.windowManager = null;
          }
        },
      });
    },

    height() {
      let height = 300;
      if (this.model.vars.mcetype) {
        switch (this.model.vars.mcetype) {
          case "simple":
            height = 150;
        }
      }
      return height;
    },

    blur() {
      this.$emit("draft", this.itemModel);
    },

    getClass() {
      let content = this.itemModel.vars.value;
      const editor = document.getElementById(this.itemModel.vars.id);
      const tox = this.inline
        ? editor.querySelector(".inline")
        : editor.querySelector(".tox-tinymce");
      if (this.itemModel.vars.required && !content && tox) {
        tox.classList.add("is-invalid");
        tox.classList.remove("is-valid");
      } else if (this.itemModel.vars.required && content && tox) {
        tox.classList.add("is-valid");
        tox.classList.remove("is-invalid");
      }
    },

    isMobile() {
      return window.innerWidth < 992;
    },

    valid() {
      this.getClass();
      let content = this.itemModel.vars.value;
      if (this.itemModel.vars.required) {
        formValidator.validModel(this.itemModel, this.language);
      }
      this.$emit("update", content);
    },

    focus() {
      this.$emit("focus");
    },

    getLanguage() {
      switch (this.$i18n.locale) {
        case "fr":
          return "fr_FR";
        case "ca":
          return "ca";
      }
    },
  },
  components: {
    Editor,
  },
};
</script>

<style lang="scss" scoped>
.inline {
  border: 1px solid $m-color_4;
  border-radius: 6px;
  height: 100%;
  padding: 15px;
  line-height: 10px;
  max-height: 96px;

  &.is-valid {
    border-color: $green;
  }

  &.is-invalid {
    border-color: $red;
  }
}
</style>
