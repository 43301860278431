<template>
  <div
    class="modal fade"
    id="modal"
    ref="modalBlock"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title textStyle" id="exampleModalLabel">
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="Object.keys(modelsItem).length">
          <div class="row">
            <div v-for="item in modelsItem" :key="item.id">
              <router-link
                class="modal__link"
                :to="{
                  name: 'studeaManagerHome',
                  params: { trainingCourseId: item.id },
                }"
                v-if="item.type === 'router'"
              >
                <span data-bs-dismiss="modal" data-dismiss="modal">
                  {{ item.label }}
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "ModalBlockComponent",
  data() {
    return {
      modelsItem: [],
    };
  },
  props: {
    models: {
      type: Array,
      required: true,
    },
    modalTitle: {
      type: String,
      required: false,
      default: "",
    },
  },
  mounted() {
    this.$refs.modalBlock.addEventListener("hidden.bs.modal", this.hidden);
  },
  watch: {
    models() {
      this.modelsItem = this.models;
      let modal;
      if (this.$refs.modalBlock) {
        modal = new Modal(this.$refs.modalBlock);
      }
      if (modal) {
        modal.show();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__link {
  color: $primary !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
