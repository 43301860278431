<template>
  <div class="widget form-widget pb-2">
    <div class="widget-content">
      <div class="panel-body rounded-pills-icon tabs">
        <ul
          id="languageTab"
          class="nav nav-pills mb-4 mt-3 justify-content-center flex-md-row flex-column"
          role="tablist"
        >
          <li
            v-for="tab in tabs"
            :key="tab.code"
            class="nav-item ms-md-2 me-md-2 me-0 ms-0"
            role="presentation"
          >
            <a
              :id="'tab-' + tab.code + '-tab'"
              :ref="'ref-' + tab.code"
              :aria-controls="'tab-' + tab.code"
              :aria-selected="currentTabCode && currentTabCode === tab.code"
              :class="
                'nav-link mb-2 text-center bgStyle' +
                (currentTabCode && currentTabCode === tab.code
                  ? ' active'
                  : '') +
                (tab.hidden ? ' d-none' : '')
              "
              :href="'#tab-' + tab.code"
              data-bs-toggle="pill"
              role="tab"
              @click="toggleTab(tab.code)"
            >
              <FontAwesomeIcon :icon="'fa-light fa-' + tab.icon" />
              {{ tab.title }}
            </a>
          </li>
        </ul>
        <div id="languageTabContent" class="tab-content">
          <div
            v-for="(tab, index) in tabs"
            :id="'tab-' + tab.code"
            :key="tab.code"
            :aria-labelledby="'tab-' + tab.code + '-tab'"
            :class="
              'tab-pane fade' +
              (currentTabCode && currentTabCode === tab.code
                ? ' show active'
                : '') +
              (tab.hidden ? ' d-none' : '')
            "
            role="tabpanel"
          >
            <FormComponent
              v-if="!index && tab.models"
              :models="tab.models"
              @toggle="toggle"
              @formSubmit="submit"
              @formCancel="cancel"
              @update="update"
            />
            <div v-else>
              <button
                class="btn btn-success btnStyle mb-4"
                @click="getModifyModels"
              >
                <FontAwesomeIcon icon="fa-light fa-plus" class="me-2" />
                Ajouter un chargé de livret
              </button>
              <SortTableComponent
                :columns="columns"
                :datas="datas"
                :actions="actions"
                @modify="modify"
                @remove="remove"
                @embody="embody"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
  <ModalFormComponent
    :models="modifyModels"
    :title="modifyTitle"
    @submit="submitAddEdit"
    @hidden="hide"
    @cancel="hide"
  />
</template>

<script>
import { formManager } from "@/services/form/form-manager";
import { apiConnection } from "@/services/api-connection";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import FormComponent from "@/components/FormComponent.vue";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { adminManager } from "@/services/admin/admin-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "TrainingCourseEditView",
  data() {
    return {
      loading: false,
      tabs: [],
      invalidTab: null,
      columns: [],
      datas: [],
      actions: [],
      modifyModels: [],
      editingId: null,
      baseUrl: "",
      currentTabCode: null,
      models: [],
      modifyTitle: "",
    };
  },
  watch: {
    tabs() {
      this.checkHash();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.initActions();
      this.baseUrl = "/app/admin/training-course/" + this.$route.params.id;

      apiConnection.get(this.baseUrl).then((data) => {
        this.tabs = data.tabs;
        if (data && data.tabs && data.tabs[1] && data.tabs[1].models) {
          if (Object.keys(data.tabs[1].models).length) {
            this.columns = sortableTableManager.getColumns(
              data.tabs[1].models[0],
              "admin",
            );
            this.datas = data.tabs[1].models;
          }
        }
        this.checkHash();
        this.loading = false;
      });
    },

    checkHash() {
      if (!window.location.hash && this.tabs && this.tabs[0]) {
        this.setCurrentTabCode(this.tabs[0].code);
      } else {
        this.setCurrentTabCode(window.location.hash.substring(1));
      }
    },

    cancel() {
      this.$router.push({
        name: this.$route.meta.backPath,
        params: { entity: this.alias },
      });
    },

    initActions() {
      this.actions = [
        {
          translation: "admin.embody",
          icon: "user",
          type: "primary",
          action: "embody",
          cfaStyle: false,
        },
        {
          translation: "admin.modify",
          icon: "pen",
          type: "success",
          action: "modify",
          cfaStyle: false,
        },
        {
          translation: "admin.delete",
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
        },
      ];
    },

    toggleTab(code) {
      this.setCurrentTabCode(code);
    },

    setCurrentTabCode(code) {
      this.currentTabCode = code;
      window.location.hash = this.currentTabCode;
    },

    checkData() {
      if (
        this.tabs[0].models.startYear &&
        this.tabs[0].models.startYear.vars.value &&
        this.tabs[0].models.endYear &&
        this.tabs[0].models.endYear.vars.value &&
        this.tabs[0].models.monthDuration &&
        this.tabs[0].models.monthDuration.vars.value
      ) {
        if (
          this.tabs[0].models.startYear.vars.value >
          this.tabs[0].models.endYear.vars.value
        ) {
          this.tabs[0].models.startYear.vars.error = this.$t(
            "training_course.errors.start_year_upper_than_end_year",
          );
        } else {
          this.tabs[0].models.startYear.vars.error = null;
        }
        if (
          (this.tabs[0].models.endYear.vars.value -
            this.tabs[0].models.startYear.vars.value >
            0 &&
            (this.tabs[0].models.endYear.vars.value -
              this.tabs[0].models.startYear.vars.value) *
              12 <
              this.tabs[0].models.monthDuration.vars.value) ||
          (this.tabs[0].models.endYear.vars.value -
            this.tabs[0].models.startYear.vars.value ===
            0 &&
            this.tabs[0].models.monthDuration.vars.value > 12)
        ) {
          this.tabs[0].models.monthDuration.vars.error = this.$t(
            "training_course.errors.month_duration_too_long",
          );
        } else {
          this.tabs[0].models.monthDuration.vars.error = null;
          return false;
        }
      }
    },

    update() {
      this.checkData();
    },

    submit() {
      const response = formManager.processForm(this.tabs[0].models, false);
      const errors = this.checkData();
      if (errors === false) {
        this.loading = true;
        apiConnection.put(this.baseUrl, response).then((data) => {
          formManager.processResponse(
            data,
            { name: "adminTrainings" },
            this.$tc("global.edit.success", 1, {
              name: this.$tc("admin.trainingCourse.title", 1),
            }),
            this.tabs[0].models,
          );
          this.loading = false;
        });
      }
    },

    hide() {
      this.editingId = null;
      this.modifyTitle = "";
      this.modifyModels = [];
    },

    submitAddEdit(models) {
      const response = formManager.processForm(models, false, this.editingId);
      // response["trainingCourse"] = { id: this.$route.params.id };
      const url =
        "/app/admin/studea-manager/" +
        this.$route.params.id +
        "/post" +
        (this.editingId ? "/" + this.editingId : "");
      this.loading = true;
      (this.editingId
        ? apiConnection.put(url, response)
        : apiConnection.post(url, response)
      ).then((data) => {
        formManager.processResponse(
          data,
          this.$route,
          this.$tc(
            "global." + (this.editingId ? "edit" : "add") + ".title",
            1,
            {
              name: this.$tc("admin.studeaManager.title", 1).toLowerCase(),
            },
          ),
          this.modifyModels,
        );
        this.init();
      });
    },

    modify(row) {
      this.editingId = row.id;
      this.getModifyModels();
    },

    embody(row) {
      adminManager.switchUser(row.id, true);
    },

    getModifyModels() {
      this.modifyModels = [];
      this.modifyTitle = this.$tc(
        "global." + (this.editingId ? "edit" : "add") + ".title",
        1,
        {
          name: this.$tc("admin.studeaManager.title", 2).toLowerCase(),
        },
      );

      adminManager
        .form(
          "studea-manager/" + this.$route.params.id,
          this.editingId,
          {},
          false,
          false,
        )
        .then((data) => {
          this.modifyModels = data;
        });
    },

    remove(row) {
      adminManager.remove("studea-manager", row.id).then(() => {
        this.init();
      });
    },
  },
  components: {
    FontAwesomeIcon,
    ModalFormComponent,
    SortTableComponent,
    FormComponent,
    BlockLoader,
  },
};
</script>

<style lang="scss" scoped>
.widget-content {
  padding: 20px;
}
</style>
