import { i18n } from "@/i18n";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";
import IncidentSheetListView from "@/views/StudeaManager/IncidentSheet/IncidentSheetListView.vue";

export default {
  path: "/studea-manager/indent-sheet",
  name: "studeaManagerIndentSheet",
  component: GlobalLayoutView,
  meta: {
    authRequired: true,
    title: i18n.global.tc("studea_manager.incident_sheet.title", 1),
    breadcrumb: true,
  },
  children: [
    {
      path: "list",
      name: "studeaManagerIncidentSheetList",
      component: IncidentSheetListView,
      meta: {
        title: i18n.global.t("studea_manager.incident_sheet.list"),
        breadcrumb: true,
      },
    },
  ],
};
