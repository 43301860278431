export default {
  yourSkills: "Your skills",
  skills: "Skills",
  last_skill_update: "Last saved",
  duration: "Duration",
  Category: "Category",
  "Skills saved successfully": "Skills saved successfully",
  next: "Next",
  previous: "Previous",
  seeHistoric: "View history",
  resetSkill: "Reset skill",
  justificativePieceSend: "Documentary proof sent",
  sendJustificativePiece: "Send documentary proof",
  impossibleJustificativeSending:
    "Addition of documentary proof possible after grading",
  stats: {
    no_data: "No data has yet been entered",
  },
  no_skills: "No skill entered for this category",
  no_skill_available: "No skill currently entered",
  no_skill_complete: "No skill completed",
  skill_chart: "Skills graph",
  skill_chart_pdf_name: "graphique-des-compétences",
  referential_visible: ({ plural }) =>
    plural([
      "Active reference documentation",
      "Inactive reference documentation",
    ]),
  session_visible: ({ plural }) =>
    plural(["Active session", "Inactive session"]),
};
