export default {
  action_to_realize: "Actions to be carried out",
  sign_action: "Save and sign",
  complete: "Complete",
  toComplete: "To be completed",
  due_date: "Due date",
  toSign: "To be signed",
  description: "Description",
  yearProgression: "Yearly progress",
  progression: "Progress",
  your_progression: "Your completion rate",
  triad_progression: "Three-party group completion rate",
  question: "Question",
  incoming: "Waiting",
  signature: "Signature",
  questionnaire: "Questionnaire",
  questionnaires: "Questionnaires",
  yourAnswer: "Your response: ",
  answerHere: "Respond here",
  commentHere: "Leave a comment here",
  comment: "Comment response",
  answerComment: "Respond to comment",
  sendComment: "Post comment",
  sign: "Sign",
  draft: "Save draft",
  sharedDraft: "Save and share",
  downloadPdf: "Download questionnaire",
  downloadPdfModel: "Download template",
  interviewDate: "Company interview date",
  youAnswered: "You responded:",
  sendFile: "Send a file",
  sendDocument: "Send a document",
  takePicture: "Take a photo",
  fileSource: "File source",
  yesSign: "Yes, I want to sign",
  questionnaire_remove_title: "Delete a questionnaire",
  questionnaire_remove_sub_title:
    "Are you sure you want to delete this questionnaire?",
  copy_questionnaire: "Copy questionnaire",
  questionnaire_copy_success: "Questionnaire copied successfully!",
  questionnaire_removed_success: "Questionnaire deleted successfully!",
  questionnaires_copied: "Questionnaire versions above",
  signMessage:
    "Are you sure you want to sign the questionnaire? You will no longer be able to edit your responses after signing.",
  row_add_success: "Line added successfully!",
  comment_add_success: "Comment added successfully!",
  deleteMessage: "Are you sure you want to delete this line?",
  download_report: "Download report",
  signatory: "Signatory",
  signatories: "Signatories",
  expected_signature: "Signature expected prior to",
  signed_on: "Signed on",
  commented_by: "Comment by",
  success: "Saved successfully!",
  draftSuccess: "Draft saved successfully!",
  sharedSuccess: "Draft shared successfully!",
  signedSuccess: "Signed successfully!",
  deletedQuestionnaire: "This questionnaire has been deleted",
  addLine: "Add row",
  "Competence calculated by your answers to the questionnaires":
    "Skill calculated using your responses to questionnaires",
  open_from: "Open from",
  no_crew_dates: "No dates for the group",
  open_to: "to",
  marks: "Notes",
  block_sign:
    "Signature of the questionnaire possible only after validation by the other two members of the three-party group.",
  no_mark: "No note currently available",
  "Download dates": "Download",
  "Print all the questionnaires": "Print all questionnaires",
  answered: "responded on",
  remove_answer_title: "Delete a line",
  remove_answer: "Are you sure you want to delete this response line?",
  to_be_completed_from: " To be completed from ",
  to: " to ",
  open_at: "Open on ",
  export_questionnaire: "Export the questionnaire",
  import_questionnaire: "Import a questionnaire",
  no_questionnaire: ({ plural }) =>
    plural([
      "No questionnaire has been completed for this year",
      "No questionnaire has been completed for this category",
    ]),
};
