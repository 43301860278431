<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-9 order-1 order-lg-0">
        <div class="row app-notes" id="cancel-row">
          <div class="col-lg-12">
            <div class="app-container">
              <div class="app-note-container">
                <div class="row">
                  <NotepadFilterComponent
                    :activeFilter="activeFilter"
                    @filter="filterNotes"
                  />
                  <div
                    v-if="
                      this.filteredNotes &&
                      Object.keys(this.filteredNotes).length
                    "
                    class="col-xxl-10 col-lg-9 order-2 order-lg-1"
                  >
                    <div
                      id="ct"
                      class="note-container note-grid row"
                      v-if="filteredNotes.filter((note) => note.pinned).length"
                    >
                      <div
                        v-for="(note, index) in filteredNotes.filter(
                          (note) => note.pinned,
                        )"
                        class="note-item all-notes col-xl-6"
                        :key="index"
                      >
                        <NotepadNoteComponent
                          :note="note"
                          @show="show"
                          @openModal="openModal"
                          @refresh="refresh"
                          @loading="setLoading"
                        />
                      </div>
                      <div
                        v-if="
                          filteredNotes.filter((note) => !note.pinned).length
                        "
                        class="col-12"
                      >
                        <div class="separation"></div>
                      </div>
                    </div>
                    <div id="ct" class="note-container note-grid row">
                      <div
                        v-for="(note, index) in filteredNotes.filter(
                          (note) => !note.pinned,
                        )"
                        class="note-item all-notes col-xl-3 col-md-6"
                        :key="index"
                      >
                        <NotepadNoteComponent
                          :note="note"
                          @show="show"
                          @openModal="openModal"
                          @refresh="refresh"
                          @loading="setLoading"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlockLoader :loading="loading" />
      </div>
      <div class="col-lg-3 order-lg-2 order-0 order-lg-1">
        <div class="sticky">
          <ActionsBlock
            :actions="actions"
            :mobileCollapsable="true"
            :key="key"
            @add="openModal"
          />
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent :models="models" @submit="submit" />
  <ModalComponent
    :title="modalTitle"
    :content="modalContent"
    :opened="modalOpened"
    @hidden="cancelShow"
  />
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { notepadManager } from "@/services/notepad/notepad-manager";
import { formManager } from "@/services/form/form-manager";
import { mapActions, mapState } from "pinia";
import { notificationManager } from "@/services/utilities/notification-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import NotepadNoteComponent from "@/components/notepad/NotepadNoteComponent.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import NotepadFilterComponent from "@/components/notepad/NotepadFilterComponent.vue";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useUserStore } from "@/store/user/user";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export default {
  name: "NotepadView",
  components: {
    NotepadFilterComponent,
    NotepadNoteComponent,
    ModalComponent,
    BlockLoader,
    ModalFormComponent,
    ActionsBlock,
  },
  data() {
    return {
      models: [],
      filteredNotes: [],
      id: null,
      loading: false,
      modalTitle: "",
      modalContent: "",
      modalOpened: false,
      activeFilter: "all",
      key: 0,
      isStudeaManager: false,
      actions: [
        {
          translation: "new_note",
          icon: "clipboard-list-check",
          type: "primary",
          action: "add",
          actionType: "button",
          cfaStyle: true,
          disabled: false,
        },
      ],
      notes: [
        {
          title: "test",
          date: "21/02/2021",
          description: "Test description de la note",
        },
      ],
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
      notepads: (store) => store.notepads,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  watch: {
    activeFilter() {
      this.applyFilter();
    },
    notepads() {
      this.applyFilter();
    },
    triad() {
      trainingCourseManager.setPageTitle(
        trainingCourseManager.MODULE_STUDEA_NOTEPAD,
      );
    },
  },
  mounted() {
    this.applyFilter();
    if (this.$route.params.trainingCourseId) {
      this.isStudeaManager = true;
      this.getDatas();
    }
  },
  created() {
    trainingCourseManager.setPageTitle(
      trainingCourseManager.MODULE_STUDEA_NOTEPAD,
    );
  },
  methods: {
    openModal(id = null) {
      this.loading = true;
      this.id = id;
      this.models = [];
      notepadManager
        .form(id, this.isStudeaManager ? this.trainingCourse.id : null)
        .then((data) => {
          this.models = data;
          this.loading = false;
        });
    },

    getDatas() {
      this.loading = true;
      studeaManagerManager
        .list("notepad", this.$route.params.trainingCourseId)
        .then((res) => {
          this.setNotepads(res);
          this.loading = false;
        });
    },

    cancelShow() {
      this.modalOpened = false;
      this.modalContent = "";
      this.modalTitle = "";
    },

    show(note) {
      this.modalTitle = note.title;
      this.modalContent =
        "<div class='lead'>" +
        note.user.fullName +
        " - " +
        dateFormatter.format(note.date, "long") +
        "</div>";
      this.modalContent = this.modalContent.concat(note.text);
      this.modalOpened = true;
    },

    filterNotes(type) {
      this.activeFilter = type;
    },

    applyFilter() {
      if (this.activeFilter === "all") {
        this.filteredNotes = this.notepads;
      } else if (this.notepads && Object.keys(this.notepads).length) {
        this.filteredNotes = this.notepads.filter((note) => {
          return (
            (this.activeFilter === "pinned" && note.pinned) ||
            (this.activeFilter === "unpinned" && !note.pinned) ||
            (this.activeFilter === "apprentice" && note.profile === 1) ||
            (this.activeFilter === "tutor" && note.profile === 2) ||
            (this.activeFilter === "apprenticeMaster" && note.profile === 3) ||
            (this.activeFilter === "studeaManager" && note.profile === 4)
          );
        });
      }
    },

    refresh() {
      if (this.isStudeaManager) {
        this.getDatas();
      } else {
        this.changeTriad({
          triadId: this.triad.id,
          trainingCourseId: this.trainingCourse.id,
          disableLoading: true,
        }).then(() => {
          this.loading = false;
        });
      }
    },

    setLoading(value) {
      this.loading = value;
    },

    submit() {
      const result = formManager.processForm(this.models, false, this.id);
      if (!this.isStudeaManager) {
        result["triad"] = { id: this.triad.id };
      } else {
        result["triad"] = { id: parseInt(result["triad"]["id"]) };
      }
      if (this.id) {
        result["id"] = this.id;
      }

      this.loading = true;
      notepadManager
        .post(
          result,
          this.id,
          this.isStudeaManager ? this.trainingCourse.id : null,
        )
        .then((data) => {
          if (data && !data.hasError) {
            const message = this.$tc(
              "global." + (this.id ? "edit" : "add") + ".success",
              2,
              {
                name: this.$t("studea_manager.notepad.title", 1),
              },
            );

            this.refresh();
            notificationManager.showNotification("success", message);
            this.models = [];
          } else {
            notificationManager.showNotification(
              "error",
              data.message ? data.message : this.$t("errors"),
            );
            this.loading = false;
          }
        });
    },

    ...mapActions(useTriadStore, {
      changeTriad: "changeTriad",
      setNotepads: "setNotepads",
    }),
  },
};
</script>

<style scoped lang="scss">
.separation {
  margin: 15px 0 30px 0;
  border-top: 1px dashed $m-color_4;
}
</style>
