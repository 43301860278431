export default {
  triadList: "Lista de trinomios",
  triadTrainingList: "Lista de trinomios por ",
  promotion: "promoción | promociones",
  youHaveAccess: "Actualmente tiene acceso a {number} {label} {active}",
  female_active: "activa | activas",
  male_active: "activo | activos",
  female_archived: "archivada | archivadas",
  male_archived: "archivado | archivados",
  loadingTriads: "Carga de trinomios en curso...",
  homeTriad: "Inicio trinomio",
  apprenticeShort: "A",
  tutorShort: "TP",
  apprenticeMasterShort: "ME",
  Apprentice: "Alumno en prácticas",
  Tutor: "Tutor",
  "Apprentice master": "Mentor",
  "Apprentice master 2": "Mentor 2",
  "Company manager": "Gestor de empresa",
  Parent: "Padre",
  "Studea manager": "Responsable del cuaderno",
  apprentices: "Alumnos en prácticas",
  tutors: "Tutores",
  apprenticeMasters: "Mentores",
  studeaManagers: "Responsables de cuaderno",
  map: "Mentor",
  myContract: "Mi contrato",
  myContracts: "Mis contratos",
  help: "Ayuda online",
  company: "Empresa",
  pedagogic_tutor: "Tutor pedagógico",
  company_tutor: "Tutor de empresa",
  apprentice: "Alumno en prácticas",
  tutor: "Tutor",
  apprenticeMaster: "Mentor",
  studeaManager: "Responsable del cuaderno",
  legal_responsible: "Responsable legal",
};
