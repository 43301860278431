export default {
  timetable: "Empleo del tiempo",
  yourCourses: "Sus cursos",
  "There are no events on this day.": "No hay ningún evento en esta fecha",
  Today: "Hoy",
  Month: "Meses",
  Week: "Semana",
  Day: "Día",
  "Application for authorization of exceptional absence":
    "Solicitud de autorización de ausencia excepcional",
  "Add a new event": "Añadir un nuevo evento",
};
