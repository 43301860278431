<template>
  <div v-for="questionnaire in questionnaires" :key="questionnaire">
    <QuestionnaireListRowComponent
      :questionnaire="questionnaire"
      @copyQuestionnaire="copyQuestionnaire"
      @removeQuestionnaire="removeQuestionnaire"
    />
    <template v-if="Object.keys(questionnaire.questionnaireCopies).length">
      <QuestionnaireListRowComponent
        v-for="copy in questionnaire.questionnaireCopies"
        :key="copy.id"
        :questionnaire="copy"
        :copy="true"
        :hideIcons="hideIcons"
        @removeQuestionnaire="removeQuestionnaire"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { useUserStore } from "@/store/user/user";
import QuestionnaireListRowComponent from "@/components/questionnaire/QuestionnairesListRowComponent.vue";

export default {
  name: "QuestionnaireListComponent",
  components: { QuestionnaireListRowComponent },
  props: {
    questionnaires: {
      type: Array,
      required: true,
    },
    hideIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["copyQuestionnaire", "removeQuestionnaire"],
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  methods: {
    copyQuestionnaire(id) {
      this.$emit("copyQuestionnaire", id);
    },

    removeQuestionnaire(id) {
      this.$emit("removeQuestionnaire", id);
    },
  },
};
</script>

<style scoped lang="scss"></style>
