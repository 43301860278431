<template>
  <div class="loader__container" v-if="loading">
    <span class="spinner-border align-self-center loader"></span>
    <div class="loader__text">
      <span class="fw-bold">{{ title }}</span>
      <span v-if="subtitle">{{ subtitle }}</span>
    </div>
  </div>
</template>

<script>
import { i18n } from "@/i18n";

export default {
  name: "BlockLoaderWithText",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: i18n.global.t("loading"),
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loader__container {
  display: flex;
  flex-direction: column;
  z-index: 1000;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    opacity: 0.5;
    transition: all 300ms ease-in-out;
  }
}

.loader__text {
  z-index: 1001;
  margin-top: 15px;
  text-align: center;
  span {
    display: block;
  }
}
</style>
