export default {
  notepad: {
    pinned: "Fijados",
    unpinned: "No fijados",
    all: "Todas las notas",
    pinned_success: "Nota fijada correctamente",
    unpinned_success: "Nota deseleccionada correctamente",
    profiles: "Perfiles",
  },
};
