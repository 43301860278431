<template>
  <div class="widget tabs__container position-relative">
    <div class="widget-content">
      <div class="d-flex items-center justify-content-center">
        <div v-for="item in items" :key="item.id" class="ms-3">
          <router-link
            v-if="item.path === 'studeaManagerSkillList'"
            :disabled="item.disabled || isItemDisabled"
            :class="
              'tabs__item ' +
              (activeTab === item.id ? '-active ' : '') +
              (isItemDisabled ? '-disabled' : '')
            "
            :to="{
              name:
                item.disabled === false && !isItemDisabled && item.path
                  ? item.path
                  : '',
              params: item.params ? item.params : '',
            }"
          >
            <FontAwesomeIcon
              :icon="'fa-light fa-' + (item.icon ? item.icon : '')"
            />{{ item.title ? item.title : "" }}</router-link
          >
          <router-link
            v-else-if="item.disabled === false"
            :class="'tabs__item ' + (activeTab === item.id ? '-active' : '')"
            :to="{
              name: item.disabled === false && item.path ? item.path : '',
              params: item.params ? item.params : '',
            }"
          >
            <FontAwesomeIcon
              :icon="'fa-light fa-' + (item.icon ? item.icon : '')"
            />
            {{ item.title ? item.title : "" }}
          </router-link>
          <div v-if="item.disabled === true" class="tabs__item -disabled">
            <FontAwesomeIcon
              :icon="'fa-light fa-' + (item.icon ? item.icon : '')"
            />
            {{ item.title ? item.title : "" }}
          </div>
        </div>
      </div>
      <div class="text-warning text-center mt-4" v-if="warningTitle">
        <FontAwesomeIcon
          icon="fa-light fa-triangle-exclamation"
          class="me-2"
        />{{ warningTitle }}
      </div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { skillManager } from "@/services/skill/skill-manager";
import { mapActions, mapState } from "pinia";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { i18n } from "@/i18n";

export default {
  name: "TabsTitleComponent",
  components: { FontAwesomeIcon },
  data() {
    return {
      activeTab: null,
      isItemDisabled: false,
      warningTitle: "",
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  watch: {
    trainingCourse() {
      this.setIsItemDisabled();
      this.setWarning();
    },
  },
  mounted() {
    this.setTrainingCourse();
    this.getActiveTab();
    this.setIsItemDisabled();
    this.setWarning();
  },
  methods: {
    getActiveTab() {
      const path = this.$route.name;
      switch (path) {
        case "studeaManagerSkillConfiguration":
        case "adminModuleSkillConfiguration":
          this.activeTab = skillManager.SKILL_CONFIG_FROM;
          break;
        case "studeaManagerSkillReferentialConfiguration":
        case "adminModuleSkillReferential":
        case "adminModuleSkillReferentialEdition":
          this.activeTab = skillManager.SKILL_REFERENTIAL_FORM;
          break;
        case "studeaManagerSkillList":
        case "adminModuleSkillGenerateSession":
          this.activeTab = skillManager.SKILL_SESSION_FORM;
          break;
      }
    },

    setIsItemDisabled() {
      if (this.trainingCourse && this.trainingCourse.useSkillConfigAdmin) {
        this.isItemDisabled = false;
      } else
        this.isItemDisabled =
          this.trainingCourse &&
          this.trainingCourse.skillConfigCompleted === false;
    },

    setTrainingCourse() {
      if (this.$route.path.includes("studea-manager")) {
        if (
          !this.trainingCourse ||
          (this.trainingCourse && !Object.keys(this.trainingCourse).length)
        ) {
          this.fetchTrainingCourse(this.$route.params.trainingCourseId);
        }
      }
    },

    setWarning() {
      if (
        this.trainingCourse &&
        this.trainingCourse.skillConfigCompleted === false &&
        !this.trainingCourse.useSkillConfigAdmin
      ) {
        this.warningTitle = i18n.global.t("empty_config");
      } else {
        this.warningTitle = "";
      }
    },

    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourse: "fetchTrainingCourse",
    }),
  },
};
</script>
<style lang="scss" scoped>
.tabs__container {
  min-height: 175px;
}

.tabs__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 130px;
  padding: 8px;
  min-height: 130px;
  background-color: #f1f2f3;
  color: var(--secondary-color) !important;
  border-radius: 0.625rem;
  font-size: 0.875rem;
  &.router-link-active {
    color: var(--secondary-color) !important;
  }
  @include dark-theme {
    background-color: $dark-grey;
    color: $white !important;
  }

  svg {
    font-size: 25px;
    margin-bottom: 15px;
    @include dark-theme {
      color: $white !important;
    }
  }

  &.-active {
    background-color: var(--secondary-color);
    color: $white !important;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
    @include dark-theme {
      color: $white !important;
      background-color: var(--secondary-color);
      svg {
        color: $white !important;
      }
    }
  }

  &.-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
