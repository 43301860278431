export default {
  admin: {
    help_settings: "Paramètres de l'aide",
    help_settings_success: "Paramètres de l'aide modifiés avec succès !",
    lastEmail: "Lien du dernier mail envoyé",
    generatePassword: "Régénérer et envoyer le mot de passe",
    switchUser: "Incarner {name}",
    switchAdmin: "Vous incarnez",
    modify: "Modifier {title}",
    show: "Voir {title}",
    delete: "Supprimer {title}",
    add: "Ajouter",
    name: "Nom",
    title: "Titre",
    year: "Année",
    trainingOpeningStart: "Début des ouvertures de la formation",
    trainingOpeningEnd: "Fin des ouvertures de la formation",
    level: "Niveau",
    registrationDate: "Date d'enregistrement",
    active: "Actif",
    management_of: "Gestion des",
    contents: "contenus",
    users: "Utilisateurs",
    trainings: "Formations",
    modules: "Modules",
    module: "Module",
    code: "Code",
    error: "Une erreur s'est produite, veuillez réessayer plus tard !",
    lastName: "Nom",
    firstName: "Prénom",
    profile: "Profil",
    embody: "Incarner",
    visible: "Visible",
    popup: "Popup",
    visibleHomeLea: "Visible",
    endDate: "Date de fin",
    startDate: "Date de début",
    contractTypeColor: "Couleur du contrat",
    none_duplicated_user: "Aucun utilisateur à fusionner",
    skill: {
      title: ({ plural }) => plural(["Compétence", "Compétences"]),
    },
    skillCategory: {
      title: "Catégorie",
    },
    skill_session: {
      generate: "Générer des périodes",
      empty:
        "Vous devez renseigner un nombre de périodes à créer dans la configuration du module avant de pouvoir les configurer",
      generation_success: "Périodes générés avec succès",
      existing_session:
        "Vous êtes sur le point de supprimer des périodes existantes, êtes vous sûr de votre choix ?",
    },
    skillReferential: {
      title: "Référentiel de compétences",
      edit: "Modifier le référentiel",
    },
    skill_import_france_competence: {
      title: "Importer tous les référentiels France Compétences",
      confirm:
        "Êtes-vous sûr de vouloir charger tous les référentiels France Compétences ?",
      success: "Référentiels importés avec succès !",
    },
    navigation: {
      users: "Utilisateurs",
      trainings: "Formations",
      modules: "Modules",
      settings: "Paramétrage",
      general: "Général",
      steering: "Pilotage",
    },
    home: {
      title: "Configuration générale",
      title2: "Vue d'ensemble",
      title3: "Synchronisation automatique",
      title4: "Statistiques générales",
      active: "Activé",
      unique_connection: "connexion unique",
      apprentices_mentor: "Apprentices and mentors",
    },
    log: {
      title: ({ plural }) => plural(["Log", "Logs"]),
      view: "Voir les données modifiées",
      modify_field: "Champ modifié",
      old_data: "Ancienne donnée",
      data: "Nouvelle donnée",
    },
    mail: {
      title: ({ plural }) => plural(["Mail", "Mails"]),
      view: "Voir le contenu du mail",
      recipient: "Destinataire :",
      status: "Statut d'envoi :",
      sent_on: "Envoyé le :",
      open_on: "Ouvert le :",
    },
    role: {
      title: ({ plural }) => plural(["Rôle", "Rôles"]),
    },
    triad: {
      title: ({ plural }) => plural(["Trinôme", "Trinômes"]),
      delete_all_triad: {
        title: "Supprimer tous les trinômes",
        confirmation:
          "Êtes-vous sûr de vouloir supprimer tous les trinômes de cette promotion ?",
        success: "Trinômes supprimés avec succès !",
      },
    },
    studeaUser: {
      title: ({ plural }) => plural(["Utilisateur", "Utilisateurs"]),
    },
    user: {
      title: ({ plural }) => plural(["Utilisateur", "Utilisateurs"]),
      actions: {
        keep_user: "Garder",
        delete_user: "Supprimer",
        unselect_user: "Déselectionner cet utilisateurs",
        reset_password: {
          success:
            "Le lien de réinitialisation du mot de passe a été envoyé avec succès à l'utilisateur !",
        },
      },
      last_connection: "Dernière connexion le : \n",
      search_user_by_name_or_mail: "Rechercher un utilisateur par nom ou email",
      import_users: "Importer des utilisateurs",
      profile_config: "Configuration des profils",
      merge: "Fusion des utilisateurs",
      duplicated_user_count: "utilisateurs dupliqués à fusionner",
      merge_duplicated_user_confirmation:
        "Êtes vous sur de vouloir fusionner ces utilisateurs ?",
      merge_success: "Fusion effectuée avec succès",
      no_double_user: "Aucun doublon trouvé",
      mail_address: "Adresse mail",
      already_connected: "S'est déjà connecté au livret",
      yes: "Oui",
      no: "Non",
      row: "Ligne",
      reference_user: "Utilisateur référence",
      duplicated_user: "Doublons",
      reset_choices: "Réinitialiser les choix",
      options: "Options",
      account_creation: "Création du compte",
      username: "Identifiant",
      external_id: "ID externe",
      birth_date: "Date de naissance",
      send_credential_back_to_user:
        "Renvoyer les identifiants à l'utilisateur concerné",
      reference_user_is_not_duplicate:
        "L'utilisateur référence n'est pas un doublon",
      keep: "Garder",
      delete: "Supprimer",
      export: "Exporter",
      send: "Enregistrer",
      profile_title: "Libellés des profils utilisateur",
      users_profile_title_list: "Liste des libellés des profils utilisateur",
      modify_profile: "Modification du libellé",
      modification_succes: "Profile modifié avec succès",
      no_mail_title: "Aucun lien",
      no_mail:
        "Cet utilisateur n'a pas encore reçu de lien de réinitialisation de son mot de passe !",
      profile_user_info:
        "Créer les différents profils chargé de livret avec des droits spécifiques que l’on pourra rattacher par la suite aux utilisateurs. Exemple : Gestionnaire assiduité, responsable pédagogique, ….",
      role: {
        NONE: "Non",
        ALL: "Oui",
        VIEW: "Voir",
        EDIT: "Modifier",
        DELETE: "Supprimer",
      },
    },
    profile_label: {
      titleAbbreviation: "Abréviation",
      titlePlural: "Titre au pluriel",
      titleWithArticle: "Titre avec l'article",
    },
    training: {
      title: ({ plural }) => plural(["Formation", "Formations"]),
      ufa_add_test: "Créer une formation test",
      genere_triad_test: "Générer des trinômes test",
      generate_triad_test_confirmation:
        "Êtes-vous sûr de vouloir créer des trinômes test sur toutes les formations ? ",
      genere_triad_test_success:
        "Les trinômes test ont été crées avec succès !",
      genere_training_test_success:
        "La formation test a été crée avec succès !",
      import_triad: "Importer des trinômes",
    },
    registration: {
      title: ({ plural }) => plural(["Inscription", "Inscriptions"]),
    },
    trainingCourse: {
      title: ({ plural }) => plural(["Promotion", "Promotions"]),
      duplicate_on_next_year: "Dupliquer la promotion sur l'année suivante",
      duplicate: "Dupliquer la promotion",
      delete: "Supprimer la promotion",
    },
    studeaManager: {
      title: ({ plural }) => plural(["Chargé de livret", "Chargés de livret"]),
    },
    design: {
      title: "Habillage",
    },
    dataAgreement: {
      title: ({ plural }) =>
        plural(["Politique des données", "Politiques des données"]),
    },
    institution: {
      title: ({ plural }) => plural(["Établissement", "Établissements"]),
      list: {
        title: "Les établissements actifs",
      },
      export: {
        title: "Exporter les réponses aux questionnaires de l'année précédente",
      },
    },
    document: {
      title: ({ plural }) => plural(["Document public", "Documents publics"]),
    },
    documentCategory: {
      title: ({ plural }) =>
        plural(["Catégorie de document", "Catégories de document"]),
    },
    helpDocument: {
      title: ({ plural }) => plural(["Aide en ligne", "Aides en ligne"]),
    },
    component: {
      title: ({ plural }) => plural(["Composante", "Composantes"]),
      export: {
        title: "Exporter les réponses aux questionnaires de l'année précédente",
      },
    },
    degree: {
      title: ({ plural }) =>
        plural(["Niveau de formation", "Niveaux de formation"]),
    },
    contractType: {
      title: ({ plural }) => plural(["Type de contrat", "Types de contrat"]),
    },
    planContract: {
      title: ({ plural }) =>
        plural(["Année de formation", "Années de formation"]),
      actions: {
        activate: {
          title: "Activer",
          success: "Année de formation activée avec succès !",
        },
        activate_recruitment: {
          title: "Activer pour le recrutement",
          success: "Activée pour le recrutement avec succès !",
        },
      },
    },
    titleRncp: {
      title: ({ plural }) => plural(["Titre RNCP", "Titres RNCP"]),
    },
    modulet: {
      title: ({ plural }) => plural(["Module", "Modules"]),
      list: {
        title: "Gestion des modules",
      },
      trainingCourse: {
        edit: {
          title: "Modifier les formations rattachées au module",
          success: "Formations rattachées au module modifées avec succès !",
        },
        displayActive: "Afficher uniquement les formations rattachés au module",
      },
      config: {
        title: "Configuration du module",
      },
      skill_session: {
        title: "Générer des périodes",
      },
      skill_referential: {
        title: "Référentiel de compétences",
        list: "Les référentiels",
        edit: "Modification du référentiel",
        config: "Configuration du module",
      },
    },
    template: {
      title: ({ plural }) => plural(["Modèle", "Modèles"]),
    },
    absenceReason: {
      title: ({ plural }) => plural(["Motif d'absence", "Motifs d'absence"]),
    },
    questionnaireConfig: {
      title: "Configuration du module questionnaire",
    },
    ypareoConfig: {
      title: "Configuration du module Ypareo",
    },
    driverLicenceConfig: {
      title: "Configuration du module permis B",
    },
    studeaManagerProfile: {
      title: ({ plural }) =>
        plural(["Profil chargé de livret", "Profils chargé de livret"]),
      all_access: "Activer toutes les permissions",
    },
    historic: {
      title: "Historique",
    },
    statistic: {
      title: "Statistiques",
      general: "Générales",
      questionnaires: "Questionnaires",
      absence: "Absences",
      course: "Suivi de cours",
      global_export: "Exporter les statistiques",
      updated_on: "mis à jour le",
      indicators_generated:
        "Indicateurs complémentaires en cours de génération",
    },
    export: {
      title: "Les exports",
      year: "Les promotions",
      available_exports: "Exports disponibles",
    },
    duplicate: {
      title: "Duplications",
    },
    archive: {
      title: "Archivage",
    },
    news: {
      title: ({ plural }) => plural(["Actualité", "Actualités"]),
      news_list: "Liste des actualités",
      list: {
        title: "Actualités",
        uploaded_date: "Mise en ligne le",
        see_more: "Voir plus",
        see_all_news: "Retrouvez l'ensemble des actus",
        go_back_newslist: "Retour à la liste des actualités",
      },
    },
    import: {
      modele: "Modèle de fichier à télécharger",
      triad_new_file:
        "Attention, un nouveau modèle est disponible depuis le 18/09/2023.\n" +
        "          Merci de le télécharger et de l'utiliser pour charger vos trinômes.",
      triad_update:
        "Attention, seuls les membres manquants d'un trinôme seront mis à jour. Aucune donnée (nom, prénom ou email) d'un utilisateur existant ne sera mise à jour.",
      thanks:
        "Merci de ne pas modifier et / ou supprimer les colonnes du modèle",
      contract_type_id: "ID types de contrat (colonne AM)",
    },
    text: "Texte",
    dateToDisplay: "Date à afficher",
    emailContent: "Contenu e-mail",
    picture: "Image",
    subTitle: "Sous-titre",
    tag: "Tag",
    video: "Vidéo",
    activate_notification: "Activer les notifications",
  },
};
