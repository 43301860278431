import { i18n } from "@/i18n";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { usePlatformStore } from "@/store/platform/platform";
import { defineStore } from "pinia";

export const useStudeaManagerQuestionnaireStore = defineStore({
  id: "StudeaManagerQuestionnaire",
  state: () => {
    return {
      questionnaire: {},
      lastSavedVersion: {},
      lastIndex: 0,
      questionnaires: {},
      updated: 0,
    };
  },

  actions: {
    init() {
      this.questionnaire = {};
      this.lastSavedVersion = {};
      this.lastIndex = 0;
      this.questionnaires = {};
      this.updated = 0;
    },

    findQuestionnaireItem(list, index) {
      return Object.values(list).find((item) => item.index === index);
    },

    initLastIndex(index) {
      this.lastIndex = index;
    },

    retrieveNew() {
      if (window.localStorage.getItem("studea-questionnaireEditor")) {
        this.questionnaire = JSON.parse(
          window.localStorage.getItem("studea-questionnaireEditor"),
        );
      }
    },

    questionnaireNew() {
      this.questionnaire = {
        type: "",
        title: "",
        active: true,
        locked: false,
        useCrewDates: false,
        useInterviewDate: false,
        interviewDateLabel: "Date de visite en entreprise",
        useSameDates: true,
        defaultStartDate: "",
        defaultEndDate: "",
        defaultApprenticeAccessStartDate: "",
        defaultApprenticeAccessEndDate: "",
        defaultTutorAccessStartDate: "",
        defaultTutorAccessEndDate: "",
        defaultApprenticeMasterAccessStartDate: "",
        defaultApprenticeMasterAccessEndDate: "",
        defaultStudeaManagerAccessStartDate: "",
        defaultStudeaManagerAccessEndDate: "",
        completenessDate: "",
        firstReminder: "",
        secondReminder: "",
        code: "",
        secondaryQuestionnaires: [],
        description: "",
        descriptionPdf: null,
        visibleBy: [
          {
            label: usePlatformStore().apprenticeProfileLabel.title,
            value: "1",
            data: 1,
            checked: false,
          },
          {
            label: usePlatformStore().tutorProfileLabel.title,
            value: "2",
            data: 2,
            checked: false,
          },
          {
            label: usePlatformStore().apprenticeMasterProfileLabel.title,
            value: "3",
            data: 3,
            checked: false,
          },
        ],
        signedBy: [
          {
            label: usePlatformStore().apprenticeProfileLabel.title,
            value: 1,
            data: 1,
            checked: false,
            disabled: true,
          },
          {
            label: usePlatformStore().tutorProfileLabel.title,
            value: 2,
            data: 2,
            checked: false,
            disabled: true,
          },
          {
            label: usePlatformStore().apprenticeMasterProfileLabel.title,
            value: 3,
            data: 3,
            checked: false,
            disabled: true,
          },
          {
            label: usePlatformStore().studeaManagerProfileLabel.title,
            value: 4,
            data: 4,
            checked: false,
            disabled: true,
          },
        ],
        questions: [],
        category: null,
        trainingCourse: null,
        markAutomatic: false,
        canBeCopiedBy: [
          {
            label: usePlatformStore().apprenticeProfileLabel.title,
            value: 1,
            data: 1,
            checked: false,
          },
          {
            label: usePlatformStore().tutorProfileLabel.title,
            value: 2,
            data: 2,
            checked: false,
          },
          {
            label: usePlatformStore().apprenticeMasterProfileLabel.title,
            value: 3,
            data: 3,
            checked: false,
          },
          {
            label: usePlatformStore().studeaManagerProfileLabel.title,
            value: 4,
            data: 4,
            checked: false,
          },
        ],
        marksVisibleBy: [
          {
            label: usePlatformStore().apprenticeProfileLabel.title,
            value: "1",
            data: 1,
            checked: false,
          },
          {
            label: usePlatformStore().tutorProfileLabel.title,
            value: "2",
            data: 2,
            checked: false,
          },
          {
            label: usePlatformStore().apprenticeMasterProfileLabel.title,
            value: "3",
            data: 3,
            checked: false,
          },
        ],
        interviewDateVisibleBy: [
          {
            label: usePlatformStore().apprenticeProfileLabel.title,
            value: 1,
            data: 1,
            checked: false,
          },
          {
            label: usePlatformStore().tutorProfileLabel.title,
            value: 2,
            data: 2,
            checked: false,
          },
          {
            label: usePlatformStore().apprenticeMasterProfileLabel.title,
            value: 3,
            data: 3,
            checked: false,
          },
          {
            label: usePlatformStore().studeaManagerProfileLabel.title,
            value: 4,
            data: 4,
            checked: false,
          },
        ],
        markMaximum: "",
        summarySheet: false,
        allProfilSummarySheet: false,
        template: null,
      };
    },

    questionnaireAdd(type) {
      this.lastIndex++;

      const signedBy = this.questionnaire.signedBy
        ? this.questionnaire.signedBy.filter((item) => item.checked)
        : [
            {
              label: usePlatformStore().apprenticeProfileLabel.title,
              value: "1",
              data: 1,
              checked: false,
            },
            {
              label: usePlatformStore().tutorProfileLabel.title,
              value: "2",
              data: 2,
              checked: false,
            },
            {
              label: usePlatformStore().apprenticeMasterProfileLabel.title,
              value: "3",
              data: 3,
              checked: false,
            },
          ];
      let access = [];
      if (signedBy.length === 1) {
        access = [signedBy[0].data];
      }

      const item = {
        id: null,
        index: this.lastIndex,
        title: "",
        type: 0,
        itemType: "field",
        access: access,
        tip: "",
        tipActive: false,
        required: true,
        selected: false,
        displayMode: 1,
        comments: false,
        commentsLabel: "",
        commentsType: 1,
        displayMark: false,
        displayRadar: false,
        maximalMark: "",
        openedQuestionsAccess: [],
        openedQuestions: false,
        openedQuestionsType: 1,
        fieldSkills: [],
      };

      switch (type) {
        case 2:
          item["itemType"] = "cluster";
          item["displayMode"] = 1;
          break;
        case 3:
          item["itemType"] = "cluster";
          item["grid"] = true;
          item["valueList"] = [
            { value: "", mark: "" },
            { value: "", mark: "" },
          ];
          item["questions"] = [
            {
              id: null,
              index: this.lastIndex + 1,
              title: "",
              access: access,
              tip: "",
              tipActive: false,
              required: 0,
              selected: true,
              comments: false,
              commentsLabel: "",
              commentsType: 1,
              displayMark: false,
              displayRadar: false,
              maximalMark: "",
              openedQuestionsAccess: [],
              openedQuestions: false,
              openedQuestionsType: 0,
              fieldSkills: [],
            },
            {
              id: null,
              index: this.lastIndex + 2,
              title: "",
              access: access,
              tip: "",
              tipActive: false,
              required: 0,
              selected: true,
              comments: false,
              commentsLabel: "",
              commentsType: 1,
              displayMark: false,
              displayRadar: false,
              maximalMark: "",
              openedQuestionsAccess: [],
              openedQuestions: false,
              openedQuestionsType: 0,
              fieldSkills: [],
            },
          ];
          this.initLastIndex(this.lastIndex + 2);
          item["displayMode"] = 2;
          break;
      }

      this.questionnaire["questions"].push(item);
      window.localStorage.setItem(
        "studea-questionnaireEditor",
        JSON.stringify(this.questionnaire),
      );
    },

    questionnaireDuplicate(payload) {
      this.lastIndex++;
      const item = this.findQuestionnaireItem(
        this.questionnaire.questions,
        payload.id,
      );
      const clone = Object.assign({}, item);
      clone.index = this.lastIndex;
      clone.id = null;

      if (item.questions && Object.keys(item.questions).length) {
        clone.questions = [];
        Object.values(item.questions).forEach(function (child) {
          const cloneQuestion = Object.assign({}, child);
          cloneQuestion.id = null;
          clone.questions.push(cloneQuestion);
        });
      }

      if (item.valueList && Object.keys(item.valueList).length) {
        clone.valueList = [];
        Object.values(item.valueList).forEach(function (value) {
          clone.valueList.push({ mark: value.mark, value: value.value });

          if (clone.questions && Object.keys(clone.questions).length) {
            Object.values(clone.questions).forEach(function (child) {
              child.valueList = clone.valueList;
            });
          }
        });
      }
      this.questionnaire.questions.splice(payload.index + 1, 0, clone);
      window.localStorage.setItem(
        "studea-questionnaireEditor",
        JSON.stringify(this.questionnaire),
      );
    },

    questionnaireRemove(index) {
      const item = this.questionnaire.questions[index];
      if (item.id) {
        this.questionnaire["deletedQuestions"] = this.questionnaire[
          "deletedQuestions"
        ]
          ? this.questionnaire["deletedQuestions"]
          : [];
        this.questionnaire["deletedQuestions"].push({
          itemType: item.itemType,
          id: item.id,
        });
      }
      this.questionnaire.questions.splice(index, 1);
      window.localStorage.setItem(
        "studea-questionnaireEditor",
        JSON.stringify(this.questionnaire),
      );
    },

    questionnaireSelectBlock(index = null) {
      if (
        this.questionnaire &&
        this.questionnaire.questions &&
        Object.keys(this.questionnaire.questions).length
      ) {
        Object.values(this.questionnaire.questions).forEach(
          function (baseItem) {
            baseItem.selected = false;
            if (baseItem.questions && Object.keys(baseItem.questions).length) {
              Object.values(baseItem.questions).forEach(function (baseChild) {
                baseChild.selected = false;
              });
            }
          },
        );

        const item = this.findQuestionnaireItem(
          this.questionnaire.questions,
          index,
        );
        if (item) {
          item.selected = true;

          if (item.questions && Object.keys(item.questions).length) {
            Object.values(item.questions).forEach(function (child) {
              child.selected = true;
            });
          }
        }
      }
    },

    questionnaireUpdate(payload) {
      this.questionnaire = payload.questionnaire;
      if (payload.save) {
        window.localStorage.setItem(
          "studea-questionnaireEditor",
          JSON.stringify(this.questionnaire),
        );
      }
      if (payload.updateKey) {
        this.updated++;
      }
    },

    questionnaireSetLastSavedVersion(questionnaire) {
      this.lastSavedVersion = JSON.stringify(questionnaire);
    },

    initQuestionnaireList(list) {
      this.questionnaires = list;
    },

    initQuestionnaireChoices() {
      const signedBy = [
        {
          label: usePlatformStore().apprenticeProfileLabel.title,
          value: "1",
          data: 1,
          checked: false,
        },
        {
          label: usePlatformStore().tutorProfileLabel.title,
          value: "2",
          data: 2,
          checked: false,
        },
        {
          label: usePlatformStore().apprenticeMasterProfileLabel.title,
          value: "3",
          data: 3,
          checked: false,
        },
        {
          label: i18n.global.t("Studea manager"),
          value: "4",
          data: 4,
          checked: false,
        },
      ];

      let isNumberArray =
        this.questionnaire.signedBy &&
        this.questionnaire.signedBy[0] &&
        typeof this.questionnaire.signedBy[0] === "number";
      if (this.questionnaire.signedBy) {
        signedBy.forEach((choice) => {
          if (isNumberArray) {
            choice.checked = this.questionnaire.signedBy.includes(choice.data);
          } else {
            const item = this.questionnaire.signedBy.find(
              (item) => item.data === choice.data,
            );
            choice.checked = item && item.checked;
          }
        });
      }

      this.questionnaire.signedBy = signedBy;

      const marksVisibleBy = [
        {
          label: usePlatformStore().apprenticeProfileLabel.title,
          value: "1",
          data: 1,
          checked: false,
        },
        {
          label: usePlatformStore().tutorProfileLabel.title,
          value: "2",
          data: 2,
          checked: false,
        },
        {
          label: usePlatformStore().apprenticeMasterProfileLabel.title,
          value: "3",
          data: 3,
          checked: false,
        },
      ];
      if (this.questionnaire.marksVisibleBy) {
        marksVisibleBy.forEach((choice) => {
          choice.checked = Object.values(
            this.questionnaire.marksVisibleBy,
          ).includes(choice.data);
        });
        this.questionnaire.marksVisibleBy = marksVisibleBy;
      }

      const interviewDateVisibleBy = [
        {
          label: usePlatformStore().apprenticeProfileLabel.title,
          value: "1",
          data: 1,
          checked: false,
        },
        {
          label: usePlatformStore().tutorProfileLabel.title,
          value: "2",
          data: 2,
          checked: false,
        },
        {
          label: usePlatformStore().apprenticeMasterProfileLabel.title,
          value: "3",
          data: 3,
          checked: false,
        },
        {
          label: i18n.global.t("Studea manager"),
          value: "4",
          data: 4,
          checked: false,
        },
      ];
      if (this.questionnaire.interviewDateVisibleBy) {
        interviewDateVisibleBy.forEach((choice) => {
          choice.checked = Object.values(
            this.questionnaire.interviewDateVisibleBy,
          ).includes(choice.data);
        });
        this.questionnaire.interviewDateVisibleBy = interviewDateVisibleBy;
      }

      const canBeCopiedBy = [
        {
          label: usePlatformStore().apprenticeProfileLabel.title,
          value: "1",
          data: 1,
          checked: false,
        },
        {
          label: usePlatformStore().tutorProfileLabel.title,
          value: "2",
          data: 2,
          checked: false,
        },
        {
          label: usePlatformStore().apprenticeMasterProfileLabel.title,
          value: "3",
          data: 3,
          checked: false,
        },
        {
          label: usePlatformStore().studeaManagerProfileLabel.title,
          value: "4",
          data: 4,
          checked: false,
        },
      ];

      if (this.questionnaire.canBeCopiedBy) {
        canBeCopiedBy.forEach((choice) => {
          choice.checked =
            this.questionnaire.canBeCopiedBy &&
            this.questionnaire.canBeCopiedBy.includes(choice.data);
        });
        this.questionnaire.canBeCopiedBy = canBeCopiedBy;
      }

      const visibleBy = [
        {
          label: usePlatformStore().apprenticeProfileLabel.title,
          value: "1",
          data: 1,
          checked: false,
        },
        {
          label: usePlatformStore().tutorProfileLabel.title,
          value: "2",
          data: 2,
          checked: false,
        },
        {
          label: usePlatformStore().apprenticeMasterProfileLabel.title,
          value: "3",
          data: 3,
          checked: false,
        },
      ];
      if (this.questionnaire.visibleBy) {
        visibleBy.forEach((choice) => {
          choice.checked = this.questionnaire.visibleBy.includes(choice.data);
        });
        this.questionnaire.visibleBy = visibleBy;
      }
    },

    fetchEditorQuestionnaire(payload) {
      return questionnaireManager
        .getAdminQuestionnaire(payload.id, payload.trainingCourseId)
        .then((questionnaire) => {
          if (questionnaire) {
            this.questionnaireUpdate({
              questionnaire: questionnaire,
              save: false,
            });
            this.questionnaireSetLastSavedVersion(questionnaire);
          }
        });
    },

    fetchQuestionnaireList(trainingCourseId) {
      return questionnaireManager
        .getAdminQuestionnaireList(trainingCourseId)
        .then((list) => {
          this.initQuestionnaireList(list);
        });
    },

    saveQuestionnaire(payload) {
      const questionnaire = questionnaireManager.processQuestionnaire(
        this.questionnaire,
      );
      return new Promise((resolve, reject) => {
        questionnaireManager
          .postAdminQuestionnaire(
            questionnaire,
            payload.trainingCourseId,
            payload.id,
          )
          .then((res) => {
            if (!res.hasError) {
              resolve(res);
              notificationManager.showNotification(
                "success",
                i18n.global.t("studea_manager.questionnaire.save_success"),
              );
            } else {
              reject(res);
              notificationManager.showNotification(
                "success",
                i18n.global.t("studea_manager.questionnaire.save_error"),
              );
            }
          });
      });
    },
  },
});
