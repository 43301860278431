<template>
  <div class="row" ref="tableTop">
    <BlockLoader :loading="loading" />
    <div class="col-lg-10 order-1 order-lg-0">
      <div class="widget">
        <div class="widget-heading flex-column">
          <h1 class="textStyle">
            <router-link
              :to="{ name: 'adminUsersList' }"
              class="btn-icon"
              v-tooltip="{ title: $t('back') }"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </router-link>
            {{ $t("admin.user.merge") }}
          </h1>
          <p class="fst-italic mt-1">
            <FontAwesomeIcon icon="fa-light fa-circle-info" class="me-1" />
            <span v-if="maxResult">
              {{ maxResult }}
              {{ $t("admin.user.duplicated_user_count") }}
            </span>
            <span v-else>
              {{ $t("admin.none_duplicated_user") }}
            </span>
          </p>
        </div>
        <div class="widget-content position-relative">
          <div class="list">
            <div class="mb-4 d-flex justify-content-between">
              <div class="list__result">
                <div class="form-group mt-2 mt-md-none">
                  <span>{{ $t("display") }}</span>
                  <select
                    v-model="limit"
                    :disabled="Object.keys(this.duplicateUsers).length === 0"
                    class="form-control textStyle"
                    @change="limitList"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                  <span>{{ $t("elements") }}</span>
                </div>
              </div>
              <div>
                <InputTypeComponent :model="keywordSearch" @input="search" />
              </div>
            </div>
            <div class="list__heading">
              <div>{{ $t("admin.user.row") }}</div>
              <div>{{ $t("admin.user.reference_user") }}</div>
              <div>{{ $t("admin.user.duplicated_user") }}</div>
              <div>{{ $t("admin.user.options") }}</div>
            </div>
            <div
              class="list__content"
              v-if="duplicateUsers && duplicateUsers.length"
            >
              <div
                class="list__row"
                v-for="(duplicateUser, index) in duplicateUsers"
                :key="duplicateUser.id"
              >
                <TriadDuplicatedUserRowComponent
                  :index="index"
                  :duplicateUser="duplicateUser"
                  @updateModels="updateModels"
                  @userNotDuplicated="userNotDuplicated"
                  @sendBackCredential="sendBackCredential"
                />
              </div>
              <SortTablePaginationComponent
                :activePage="activePage"
                :nbPages="nbPage"
                @changePage="changePage"
              />
            </div>
            <div v-else>{{ $t("admin.user.no_double_user") }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 order-lg-1 order-0 position-relative">
      <div class="container__action">
        <ActionsBlock
          :actions="actions"
          @mergeUsers="mergeUsers"
          @exportUsers="exportUsers"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { accessManager } from "@/services/security/access-manager";
import InputTypeComponent from "@/components/form/InputTypeComponent.vue";
import { apiConnection } from "@/services/api-connection";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import TriadDuplicatedUserRowComponent from "@/components/triad/TriadDuplicatedUserRowComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import { adminManager } from "@/services/admin/admin-manager";
import SortTablePaginationComponent from "@/components/table/sortTable/SortTablePaginationComponent.vue";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

export default {
  name: "UserMergeView",
  components: {
    SortTablePaginationComponent,
    TriadDuplicatedUserRowComponent,
    BlockLoader,
    InputTypeComponent,
    ActionsBlock,
  },
  data() {
    return {
      loading: false,
      limit: 10,
      nbPage: 0,
      offset: 0,
      activePage: 1,
      listParams: [],
      isMergeButtonDisabled: true,
      keywordSearch: {
        vars: {
          name: "keyword",
          value: "",
          id: "keyword",
          required: false,
          block_prefixes: ["filter", "text"],
          disabled: false,
          attr: {
            placeholder: this.$t("filter"),
          },
          row_attr: {
            class: "h-100",
          },
        },
      },
      duplicateUsers: [],
      maxResult: 0,
      actions: [],
      sendBackLogin: {
        model: {
          vars: {
            label: this.$t("admin.user.send_login_back_to_user"),
            name: "sendBackLogin",
            attr: [],
            disabled: false,
            id: "user_sendBackLogin",
            required: true,
          },
        },
        choices: [
          {
            label: this.$t("admin.user.send_login_back_to_user"),
            value: "1",
            data: true,
            attr: [],
          },
        ],
      },
      notDuplicatedUser: {
        model: {
          vars: {
            label: this.$t("admin.user.send_login_back_to_user"),
            name: "notDuplicatedUser",
            attr: [],
            disabled: false,
            id: "user_notDuplicatedUser",
            required: true,
          },
        },
        choices: [
          {
            label: this.$t("admin.user.reference_user_is_not_duplicate"),
            value: "1",
            data: true,
            attr: [],
          },
        ],
      },
      models: [],
    };
  },
  watch: {
    menu() {
      if (Object.keys(this.menu).length) {
        this.getCurrentMenuEntry();
        this.init();
      }
    },

    isMergeButtonDisabled() {
      this.initAction();
    },
  },
  mounted() {
    this.setParams();
    this.init();
    this.initAction();
  },
  methods: {
    init() {
      this.loading = true;
      this.getData();
    },

    getData() {
      adminManager.getDuplicatedUserList(this.listParams).then((response) => {
        this.duplicateUsers = response.data;
        this.maxResult = response.count;
        this.loading = false;
      });
    },

    search(paylaod) {
      this.loading = true;
      this.listParams.keywords = paylaod;
      this.getData();
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          limit: 10,
          offset: 0,
        };
      }
    },

    updateModels(payload) {
      this.models[payload.index] = payload.item;
      if (
        !this.models[payload.index].userToKeep &&
        !this.models[payload.index].usersToRemove.length &&
        !this.models[payload.index].sendBackCredential.length &&
        !this.models[payload.index].referenceNotDuplicated
      ) {
        this.models.splice(payload.index, 1);
      }
      if (this.models && this.models.length) {
        this.isMergeButtonDisabled =
          this.models.length === 1 && this.models[0] === undefined;
      } else {
        this.isMergeButtonDisabled = true;
      }
    },

    userNotDuplicated(payload) {
      this.models[payload.index] = payload.item;
      if (this.models.length) {
        this.isMergeButtonDisabled = false;
      }
    },

    sendBackCredential(payload) {
      this.models[payload.index] = payload.item;
      if (this.models.length) {
        this.isMergeButtonDisabled = false;
      }
    },

    mergeUsers() {
      notificationManager
        .showAlert(
          "question",
          this.$t("admin.user.merge_duplicated_user_confirmation"),
          "",
          true,
          true,
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;
            apiConnection
              .post("/app/admin/user/merge", this.models)
              .then((response) => {
                if (response.success) {
                  notificationManager.showNotification(
                    "success",
                    this.$t("admin.user.merge_success"),
                  );
                  this.init();
                }
              });
          }
        });
    },

    exportUsers() {
      adminManager.postExport("user/merge").then((res) => {
        messengerMessageManager.processMessage(res);
      });
    },

    initAction() {
      this.actions = [
        {
          translation: "admin.user.merge",
          icon: "merge",
          type: "button",
          action: "mergeUsers",
          cfaStyle: true,
          access: accessManager.NONE,
          disabled: this.isMergeButtonDisabled,
        },
        {
          translation: "admin.user.export",
          icon: "download",
          type: "button",
          action: "exportUsers",
          cfaStyle: true,
          access: accessManager.NONE,
        },
      ];
    },

    scrollToTableTop(scroll = true) {
      if (scroll) {
        const $this = this;
        setTimeout(function () {
          if ($this.$refs.tableTop) {
            window.scrollTo({
              behavior: "smooth",
              top:
                $this.$refs.tableTop.getBoundingClientRect().top -
                document.body.getBoundingClientRect().top -
                130,
            });
          }
        }, 50);
      }
    },

    limitList() {
      this.listParams.limit = this.limit;
      this.initPagination();
      this.init();
    },

    initPagination() {
      this.nbPage = Math.ceil(this.maxResult / this.limit);
      this.scrollToTableTop(true);
    },

    changePage(payload) {
      this.activePage = payload;
      this.offset = (payload - 1) * this.limit;
      this.listParams.offset = this.offset;
      this.init();
      this.scrollToTableTop(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  width: 100%;

  &__heading {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    color: $primary;
    padding-bottom: 8px;
    border-bottom: 1px solid #adadad;

    @include dark-theme {
      color: $white;
    }

    @include up-to-md {
      display: none;
    }

    & div {
      width: 100%;

      &:first-child {
        flex-basis: 15%;
      }

      &:last-child {
        flex-basis: 50%;
      }
    }
  }

  &__row + &__row {
    border-top: 1px dotted #adadad;
  }

  &__row {
    display: flex;
    padding: 8px 0;

    @include up-to-lg {
      flex-direction: column;
    }

    & div {
      width: 100%;

      &:first-child {
        flex-basis: 15%;
      }

      &:last-child {
        flex-basis: 50%;
      }
    }
  }

  &__item {
    padding-right: 8px;

    @include up-to-lg {
      margin-bottom: 30px;
    }

    &:not(:last-child) {
      @include up-to-lg {
        margin-bottom: 20px;
      }
    }
  }

  &__result {
    .form-group {
      display: flex;
      align-items: center;
      font-weight: 700;
      margin-right: 15px;

      &.mobile {
        width: 200px;
        justify-content: space-between;

        .form-control {
          width: 120px;
        }
      }
    }
  }

  .form-control {
    margin: 0 5px;
    height: 35px;
    width: 50px;
    padding: 0 10px;
    font-weight: 700;

    @include dark-theme {
      color: $white;
    }
  }
}

.mobile__row__title {
  display: none;
  font-weight: 700;
  font-size: 14px;
  color: $primary;
  padding-bottom: 8px;
  @include up-to-lg {
    display: block;
  }
}

.container__action {
  position: sticky;
  top: 130px;
  @include up-to-lg {
    position: static;
    top: unset;
  }
}
</style>
