<template>
  <div class="sticky">
    <div class="toolBar">
      <div class="widget">
        <div class="blocks">
          <button
            class="btn btn-secondary title-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.add_title'),
              position: 'left',
            }"
            @click="add(2)"
          >
            <FontAwesomeIcon icon="fa-light fa-text" />
          </button>
          <button
            class="btn btn-secondary question-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.add_question'),
              position: 'left',
            }"
            @click="add(1)"
          >
            <FontAwesomeIcon icon="fa-light fa-plus" />
          </button>
          <button
            class="btn btn-secondary cluster-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.add_question_cluster'),
              position: 'left',
            }"
            @click="add(3)"
          >
            <FontAwesomeIcon icon="fa-light fa-diagram-cells" />
          </button>
        </div>
        <div class="params">
          <button
            class="btn-simple bgStyle btn info-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.informations'),
              position: 'left',
            }"
            @click="settings('questionnaireBasicData')"
          >
            <FontAwesomeIcon icon="fa-light fa-circle-info" />
          </button>
          <button
            class="btn-simple bgStyle btn rights-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.rights'),
              position: 'left',
            }"
            @click="settings('questionnaireRight')"
          >
            <FontAwesomeIcon icon="fa-light fa-lock" />
          </button>
          <button
            class="btn-simple bgStyle btn reminders-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.deadlines_reminders'),
              position: 'left',
            }"
            @click="settings('questionnaireDate')"
          >
            <FontAwesomeIcon icon="fa-light fa-timer" />
          </button>
          <button
            class="btn-simple bgStyle btn company-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.company_visit'),
              position: 'left',
            }"
            @click="settings('questionnaireVisit')"
          >
            <FontAwesomeIcon icon="fa-light fa-building" />
          </button>
          <button
            class="btn-simple bgStyle btn summary-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.notes_summary'),
              position: 'left',
            }"
            @click="settings('questionnaireNote')"
          >
            <FontAwesomeIcon icon="fa-light fa-arrow-up-9-1" />
          </button>
          <button
            class="btn-simple bgStyle btn parameter-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.advanced_parameter'),
              position: 'left',
            }"
            @click="settings('questionnaireAdvanced')"
          >
            <FontAwesomeIcon icon="fa-light fa-id-card" />
          </button>
        </div>
        <div class="params -settings">
          <button
            class="btn-simple bgStyle btn mobile-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.settings'),
              position: 'left',
            }"
            @click="settings(0)"
          >
            <FontAwesomeIcon icon="fa-light fa-gear" />
          </button>
        </div>
        <div class="params -save">
          <button
            class="btn btn-success save-btn"
            v-tooltip="{
              title: $t('studea_manager.questionnaire.save'),
              position: 'left',
            }"
            @click="save"
          >
            <FontAwesomeIcon icon="fa-light fa-save" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionnaireToolBarComponent",
  methods: {
    add(type) {
      this.$emit("add", type);
    },

    save() {
      this.$emit("save");
    },

    settings(code) {
      this.$emit("settings", code);
    },
  },
};
</script>

<style lang="scss" scoped>
.toolBar {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 62px;

  @include up-to-desktop-height {
    max-width: 120px;
  }

  .widget {
    padding: 10px;
    display: flex;
    flex-direction: column;

    @include up-to-md {
      flex-direction: row;
      justify-content: center;
    }
  }

  @include up-to-md {
    position: fixed;
    bottom: 40px;
    left: 0;
    top: initial;
    z-index: 1000;
    max-width: initial;
    width: 100%;
  }
}

.btn + .btn {
  margin-top: 10px;

  @include up-to-desktop-height {
    margin-top: 0;
  }

  @include up-to-md {
    margin-top: 0;
    margin-left: 10px;
  }
}

.blocks {
  @include up-to-desktop-height {
    order: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.params {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 2px solid $light-grey;

  @include up-to-desktop-height {
    order: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  @include up-to-md {
    display: none;
    border-top: none;
    margin-top: 0;
    padding-top: 0;
    margin-left: 10px;
  }

  &.-settings {
    display: none;
  }

  &.-save,
  &.-settings {
    @include up-to-md {
      display: block;
    }
  }
}
</style>
