import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { apiConnection } from "@/services/api-connection";
import { mainStore } from "@/main";

const URL_FILE = "/api/get_file";

class FileManager {
  b64toBlob(b64Data, contentType, sliceSize = 512) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  fileToB64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  saveFile(filename, base64, contentType) {
    const DataBlob = this.b64toBlob(base64, contentType);
    if (Capacitor.getPlatform() !== "web") {
      Filesystem.writeFile({
        path: filename,
        data: base64,
        directory: Directory.Documents,
      }).then((file) => {
        notificationManager.showNotification(
          "success",
          i18n.global.t("download_success"),
        );
        FileOpener.open({
          filePath: file.uri,
        });
      });
    } else {
      var url = URL.createObjectURL(DataBlob);
      var a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      notificationManager.showNotification(
        "success",
        i18n.global.t("download_success"),
      );
    }
  }

  getFile(fileName, folderName) {
    mainStore.fileDownloadRequest();
    return apiConnection
      .get(
        URL_FILE,
        {
          fileName: fileName,
          fileFolder: folderName,
        },
        true,
      )
      .then((success) => {
        if (success.data) {
          const myBase64 = success.data;
          const contentType = success.mime_type;
          this.saveFile(fileName, myBase64, contentType);
        }
      });
  }

  getFileData(fileName, folderName) {
    mainStore.fileDownloadRequest();
    return apiConnection
      .get(
        URL_FILE,
        {
          fileName: fileName,
          fileFolder: folderName,
        },
        true,
      )
      .then((success) => success);
  }

  initDocumentListener() {
    // const $this = this;
    const textContent = document.querySelector(".description");
    if (textContent) {
      // const docLink = textContent.querySelectorAll("a[href]");
      // const folderName =
      //   "web/resources/" + store.state.website.website.upload_dir;
      // docLink.forEach(function (link) {
      //   const url = link.getAttribute("href");
      //   const fileName = url.split("/").pop();
      //   link.href = "#";
      //
      //   if (url.search(/^(http|https):\/\//) === -1 && fileName !== "#") {
      //     link.addEventListener("click", function (e) {
      //       e.preventDefault();
      //       $this.getFile(fileName, folderName);
      //     });
      //   }
      // });
    }
  }

  getDownloadSrc(file) {
    if (file && file.pathName) {
      return (
        this.getApiPath().slice(0, -3) +
        "download-file/path-name-" +
        file.pathName +
        "/original-name-" +
        file.originalName
      );
    }
    return "";
  }

  getApiPath() {
    return window.localStorage.getItem("studea-apiPath")
      ? window.localStorage.getItem("studea-apiPath")
      : window.sessionStorage.getItem("studea-apiPath");
  }

  download(filename, text) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text),
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}

export const fileManager = new FileManager();
