<template>
  <div class="row flex-lg-nowrap">
    <div class="container-widget order-lg-0 order-1">
      <div class="widget">
        <div class="widget-heading -list position-relative">
          <h1 class="textStyle">
            {{
              $tc("global.list.title", 1, {
                name: this.$tc("admin.mail.title", 2).toLowerCase(),
              })
            }}
            {{
              isFiltered
                ? "(" +
                  (maxResult ? maxResult : Object.keys(datas).length) +
                  " résultat" +
                  ((maxResult ? maxResult : Object.keys(datas).length) > 1
                    ? "s"
                    : "") +
                  " filtré" +
                  ((maxResult ? maxResult : Object.keys(datas).length)
                    ? "s"
                    : "") +
                  ")"
                : ""
            }}
          </h1>
          <div>
            <MagnifyingGlassComponent
              @openSearch="openSearch"
              @resetSearch="resetSearch"
              :isFiltered="isFiltered"
            />
          </div>
        </div>
        <div class="widget-content">
          <SortTableComponent
            v-if="Object.values(datas)"
            :actions="actions"
            :apiFilters="true"
            :columns="columns"
            :datas="datas"
            :idKey="5"
            :loading="loading"
            :maxResult="maxResult"
            :keyword="keyword"
            :isSearchAvailable="true"
            :defaultLimit="10"
            :searchInput="true"
            type="mail"
            ref="sortTable"
            @refresh="refresh"
            @openSearch="openSearch"
          />
        </div>
      </div>
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :title="modalTitle"
    :storageKey="sessionStorageKey"
    @search="search"
  />
</template>

<script>
import { mapState } from "pinia";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { adminManager } from "@/services/admin/admin-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { formManager } from "@/services/form/form-manager";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import MagnifyingGlassComponent from "@/components/utilities/MagnifyingGlassComponent.vue";

export default {
  name: "MailsListView",
  components: {
    MagnifyingGlassComponent,
    ModalFormComponent,
    SortTableComponent,
  },
  data() {
    return {
      isFullScreen: true,
      loading: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      isFiltered: false,
      sessionStorageKey: "studea-admin-mails",
      maxResult: 0,
      keyword: "",
      modalTitle: "",
      modalType: "",
      actions: [
        {
          translation: "admin.mail.view",
          icon: "eye",
          type: "success",
          actionType: "router",
          action: "adminMailView",
          cfaStyle: false,
          alias: "mail",
          access: accessManager.EDIT,
        },
      ],
      columns: [],
      filterModels: [],
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  watch: {
    windowWidth() {
      this.updateWindowWidth();
    },
  },
  mounted() {
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  beforeRouteUpdate() {
    this.init();
    window.addEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    refresh(params) {
      this.listParams = params;
      window.sessionStorage.setItem(
        this.sessionStorageKey + "-keywords",
        this.listParams.keywords,
      );
      this.keyword = this.listParams.keywords;
      this.init();
    },

    search(params) {
      if (this.modalType === "search") {
        this.loading = true;
        this.listParams["offset"] = 0;
        this.$refs.sortTable.activePage = 1;
        this.listParams["search"] = params;
        this.getDatas();
        this.isFiltered = true;
      }
    },

    init() {
      this.loading = true;
      this.setParams();
      this.getDatas(true);
    },

    setKeyword() {
      if (this.$route.query.search) {
        this.keyword = this.$route.query.search;
      } else if (
        window.sessionStorage.getItem(this.sessionStorageKey + "-keywords")
      ) {
        this.keyword = window.sessionStorage.getItem(
          this.sessionStorageKey + "-keywords",
        );
      }
      this.listParams["keywords"] = this.keyword;
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          sortByField: 0,
          sortOrder: "ASC",
          limit: 10,
          offset: 0,
        };
      }
    },

    getDatas() {
      if (window.sessionStorage.getItem(this.sessionStorageKey)) {
        this.listParams["search"] = JSON.parse(
          window.sessionStorage.getItem(this.sessionStorageKey),
        );
        this.isFiltered = true;
      }
      if (this.isFiltered) {
        this.listParams["search"] = formManager.getQuery(
          this.listParams["search"],
        );
      }
      this.setKeyword();
      adminManager.list("mail", false, this.listParams).then((data) => {
        if (this.columns && this.columns.length === 0) {
          this.columns = sortableTableManager.getEntriesColumns(
            data.columns,
            this.columns,
          );
          this.columns.forEach((column) => {
            if (column.name === "Destinataires") {
              column.sortable = false;
            }
          });
        }
        let datas = [];
        Object.values(data.data).forEach((row) => {
          datas.push(Object.values(row));
        });
        this.datas = datas;
        this.maxResult = data.count;
        this.loading = false;
      });
    },

    getFilters() {
      this.filterModels = [];
      this.modalType = "search";
      this.modalTitle = this.$tc("global.search.title", 2, {
        name: this.$tc("admin.mail.title", 2).toLowerCase(),
      });
      adminManager.form("mail", null, {}, false, true).then((data) => {
        this.filterModels = data;
      });
    },

    openSearch() {
      this.getFilters();
      this.isSearchOpen = true;
    },

    resetSearch() {
      this.listParams = [];
      this.$refs.sortTable.activePage = 1;
      this.datas = [];
      this.columns = [];
      this.isFiltered = false;
      window.sessionStorage.removeItem(this.sessionStorageKey);
      window.sessionStorage.removeItem(this.sessionStorageKey + "-keywords");
      this.keyword = "";
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.-list {
    cursor: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}
</style>
