<template>
  <div
    ref="contentModal"
    aria-hidden="true"
    aria-labelledby="contentModal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content" v-if="indicator">
        <div class="modal-header">
          <h5 class="modal-title textStyle">
            {{ indicator.label }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <template
            v-if="
              indicator.indicators && Object.keys(indicator.indicators).length
            "
          >
            <div
              v-for="item in indicator.indicators"
              :key="item.label"
              class="elem"
            >
              <StatisticBlockComponent :data="item" />
            </div>
          </template>
          <template
            v-if="
              indicator.sub_indicators &&
              Object.keys(indicator.sub_indicators).length
            "
          >
            <div
              v-for="indicator in indicator.sub_indicators"
              :key="indicator.label"
              class="mb-4"
            >
              <template v-if="Object.keys(indicator.indicators).length">
                <div class="indicator__container fw-bold mb-1 textStyle">
                  {{ indicator.label }}
                </div>
                <div
                  v-for="item in indicator.indicators"
                  :key="item.label"
                  class="elem"
                >
                  <StatisticBlockComponent :data="item" />
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import StatisticBlockComponent from "@/components/blocks/statistic/StatisticBlockComponent.vue";

export default {
  name: "IndicatorsModalComponent",
  components: { StatisticBlockComponent },
  props: {
    indicator: {
      type: Object,
      required: false,
      default: () => null,
    },
    opened: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    opened() {
      const container = this.$refs.contentModal;
      const modal = new Modal(container);
      if (this.opened) {
        modal.show();
      } else {
        modal.hide();
        this.$emit("hidden");
      }
    },
  },
  mounted() {
    this.$refs.contentModal.addEventListener("hidden.bs.modal", this.hidden);
  },
  methods: {
    hidden() {
      this.$emit("hidden");
      const container = this.$refs.contentModal;
      container.removeEventListener("click", this.stop);
    },

    stop(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style scoped lang="scss">
.indicator__container {
  padding: 10px 10px 0 10px;
  font-size: 15px;
}
</style>
