import { SplashScreen } from "@capacitor/splash-screen";
import * as LiveUpdates from "@capacitor/live-updates";

class LiveUpdateManager {
  async initialize() {
    const result = await LiveUpdates.sync();
    if (result.activeApplicationPathChanged) {
      await LiveUpdates.reload();
    } else {
      await SplashScreen.hide();
    }
  }
}

export const liveUpdateManager = new LiveUpdateManager();
