<template>
  <div id="tabs" class="tabs bgStyle" :class="{ 'd-none': isKeyBoardOpen }">
    <div
      v-tooltip="{ title: $t(setRouterLinkLabel()) }"
      class="tabs__item home"
      :key="setRouterLinkLabel()"
    >
      <router-link :to="{ name: setRouterLinkPath() }" class="tabs__link">
        <FontAwesomeIcon :icon="'fa-light fa-' + setStudeaManagerHomeIcon()" />
        <div class="tabs__label">{{ $t(setRouterLinkLabel()) }}</div>
      </router-link>
    </div>
    <template v-if="triad && triad.trainingCourse && triad.trainingCourse.menu">
      <template v-for="item in triad.trainingCourse.menu" :key="item.id">
        <div
          v-if="moduleIsAvailable(item)"
          v-tooltip="{ title: item.title }"
          class="tabs__item"
        >
          <router-link :to="{ name: item.code }" class="tabs__link">
            <FontAwesomeIcon :icon="'fa-light fa-' + item.icon" />
          </router-link>
          <div class="tabs__label">{{ item.title }}</div>
        </div>
        <div
          v-if="
            item.code === 'MODULE_STUDEA_DRIVER_LICENCE' &&
            user.id === triad.apprentice.id
          "
          v-tooltip="{ title: item.title }"
          class="tabs__item position-relative"
        >
          <router-link :to="{ name: item.code }" class="tabs__link">
            <FontAwesomeIcon :icon="'fa-light fa-' + item.icon" />
          </router-link>
          <div class="tabs__label">{{ item.title }}</div>
        </div>
        <div
          v-if="item.code === 'MODULE_STUDEA_MESSAGE'"
          v-tooltip="{ title: item.title }"
          class="tabs__item position-relative"
        >
          <router-link :to="{ name: item.code }" class="tabs__link">
            <FontAwesomeIcon :icon="'fa-light fa-' + item.icon" />
          </router-link>
          <div class="tabs__label">{{ item.title }}</div>
          <div class="notification" v-if="user.userNewMessage > 0">
            {{ user.userNewMessage }}
          </div>
        </div>
      </template>
    </template>
    <div
      v-if="isStudeaManagerPage()"
      v-tooltip="{ title: $t('Training courses list') }"
      class="tabs__item burger"
    >
      <router-link :to="{ name: 'TrainingCourseList' }" class="tabs__link">
        <FontAwesomeIcon icon="fa-light fa-graduation-cap" />
        <div class="tabs__label">{{ $t("global.label.trainings") }}</div>
      </router-link>
    </div>
    <div
      v-tooltip="{ title: $t('menu') }"
      class="tabs__item burger"
      @click="openMenu"
    >
      <div class="tabs__link">
        <FontAwesomeIcon icon="fa-light fa-bars" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useUserStore } from "@/store/user/user";
import { useMessageStore } from "@/store/message/message";
import { useMainStore } from "@/store/main/main";

export default {
  name: "TabsComponent",
  data() {
    return {
      isKeyBoardOpen: false,
    };
  },
  computed: {
    getUserInfos() {
      return {
        id: this.user.id,
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        photo: this.user.photo,
      };
    },
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
      trainingCourseList: (store) => store.trainingCourseList,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useMessageStore, {
      messages: (store) => store.messages,
    }),
    ...mapState(useMainStore, {
      isMobile: (store) => store.isMobile,
    }),
  },
  mounted() {
    if (window.innerWidth < 768) {
      window.addEventListener("resize", this.checkKeyBoardOpen);
    }
  },
  unmounted() {
    if (window.innerWidth < 768) {
      window.removeEventListener("resize", this.checkKeyBoardOpen);
    }
  },
  methods: {
    openMenu() {
      if (this.isStudeaManagerPage()) {
        this.openSideBar();
      } else {
        const menu = document.getElementById("menu");
        menu.classList.add("show");
      }
    },

    moduleIsAvailable(item) {
      return ![
        "MODULE_STUDEA_REGISTRATION",
        "MODULE_STUDEA_GENERAL",
        "MODULE_STUDEA_QUESTIONNAIRE",
        "MODULE_STUDEA_MESSAGE",
        "MODULE_STUDEA_CONTROL",
        "MODULE_EMAILS",
        "MODULE_YPAREO",
        "MODULE_STUDEA_DRIVER_LICENCE",
      ].includes(item.code);
    },

    setRouterLinkPath() {
      if (this.$route.name === "Dashboard" && this.isTrainingRouterLinkPath()) {
        return "TrainingCourseList";
      } else {
        return "Dashboard";
      }
    },

    setRouterLinkLabel() {
      if (this.$route.name === "Dashboard" && this.isTrainingRouterLinkPath()) {
        return "global.label.trainings";
      } else {
        return "page.home";
      }
    },

    setStudeaManagerHomeIcon() {
      if (this.$route.name === "Dashboard" && this.isTrainingRouterLinkPath()) {
        return "graduation-cap";
      } else {
        return "house";
      }
    },

    isTrainingRouterLinkPath() {
      return this.user && this.user.target !== "triad";
    },

    isStudeaManagerPage() {
      return this.$router.currentRoute.value.matched.find(
        (item) => item.name === "studeaManagerLayout",
      );
    },

    checkKeyBoardOpen() {
      this.isKeyBoardOpen = window.innerHeight < window.screen.height * 0.7;
    },

    ...mapActions(useMainStore, { openSideBar: "openSideBar" }),
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  position: fixed;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
  max-width: 750px;
  width: auto;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  z-index: 1000;
  border-radius: $radius;
  padding: 10px;
  background: var(--primary-color);

  @include up-to-md {
    bottom: 0;
    left: 0;
    right: 0;
    transform: none;
    justify-content: space-between;
    background: var(--primary-color);
    border-radius: 0;
    padding: 10px 0;
  }

  @include dark-theme {
    background: $m-color-10;
    box-shadow: inset 1px 1px 1px 1px rgba(255, 255, 255, 0.5);
  }

  &__label {
    color: $white;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    display: none;

    @include up-to-md {
      display: block;
    }
  }

  &__link {
    @include up-to-md {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
    }
  }

  &__item {
    text-align: center;
    width: 50px;
    height: 50px;
    margin: 0 5px;

    &.burger,
    &.account {
      display: none;
      cursor: pointer;
    }

    @include up-to-md {
      display: none;
      width: 25%;
      height: 40px;

      &.burger,
      &.account,
      &.home {
        display: block;
      }
    }

    a,
    div.tabs__link {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      color: $white;

      @include up-to-md {
        font-size: 25px;
      }

      &.router-link-active {
        background: $white;
        border-radius: 50%;

        @include dark-theme {
          background: $white;
          color: $blue;
        }

        @include up-to-md {
          background: none;
        }
      }
    }
  }

  .home {
    svg {
      color: $white;
    }

    .router-link-active {
      svg {
        color: var(--primary-color);

        @include up-to-lg {
          color: $white;
        }
      }
    }
  }

  .notification {
    position: absolute;
    top: -5px;
    padding: 0 6px;
    border-radius: 5px;
    right: -2px;
    background-color: $green;
    z-index: 5;
    color: $white;
  }
}
</style>
