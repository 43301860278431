<template>
  <div class="list__first-col">
    {{
      fistLetterToUppercase(
        rows.isTranslatedName
          ? rows.name
          : $t((rows.tradPrefix ? rows.tradPrefix + "." : "") + rows.name),
      )
    }}
  </div>
</template>

<script>
export default {
  name: "SortTableMobileFirstColumnComponent",
  components: {},
  props: {
    rows: {
      type: Object,
      required: true,
    },
    nbRows: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    fistLetterToUppercase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__first-col {
    padding-right: 5px;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}
</style>
