<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 order-1 order-lg-0">
        <div class="sticky">
          <TriadMemberBlock />
        </div>
      </div>
      <div class="col-lg-9 col-12 order-0 order-lg-1">
        <div class="widget">
          <BlockLoader :loading="loading" />
          <div class="widget-heading">
            <h2 class="textStyle">{{ $t("page.driver_licence") }}</h2>
          </div>
          <div
            class="widget-content"
            v-if="models && Object.keys(models).length"
          >
            <div v-html="driverLicencePresentationText"></div>
            <a
              class="btn btnStyle btn-primary mb-4"
              :href="file"
              target="_blank"
              >{{ $t("studea_manager.import.modele") }}</a
            >
            <FormComponent
              v-if="Object.keys(models).length"
              :models="models"
              :hideSubmit="hideSubmit"
              @formSubmit="submit"
            />
          </div>
          <div class="widget-content" v-else>
            <DriverLicenceProgressComponent :data="driverLicenceProgressDate" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import { driverLicenceManager } from "@/services/driver-licence/driver-licence-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import FormComponent from "@/components/FormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import { mapState } from "pinia";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { loginManager } from "@/services/security/login-manager";
import { dateFormatter } from "@/services/utilities/date-formatter";
import DriverLicenceProgressComponent from "@/components/driver-licence/DriverLicenceProgressDateComponent.vue";

export default {
  name: "DriverLicenceView",
  components: {
    DriverLicenceProgressComponent,
    FormComponent,
    BlockLoader,
    TriadMemberBlock,
  },
  data() {
    return {
      driverLicencePresentationText: "",
      loading: false,
      models: [],
      hideSubmit: false,
      file: "",
      driverLicenceProgressDate: [],
      driverLicenceInProgress: null,
    };
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  watch: {
    triad() {
      this.getDriverLicenceForm();
      this.getDriverLicenceConfig();
    },
  },
  mounted() {
    this.loading = true;
    this.init();
  },
  methods: {
    init() {
      this.getDriverLicenceConfig();
      this.getDriverLicenceForm();
      this.getDriverLicenceModel();
    },

    getDriverLicenceForm() {
      if (
        this.trainingCourse &&
        this.trainingCourse.id &&
        this.triad &&
        this.triad.id
      ) {
        driverLicenceManager
          .get("form", this.trainingCourse.id, this.triad.id)
          .then((response) => {
            if (response) {
              if (Object.keys(response).includes("requestDate")) {
                this.driverLicenceProgressDate = response;
                this.driverLicenceInProgress = null;
              } else {
                this.models = response;
              }
              this.loading = false;
            }
          });
      }
    },

    getDriverLicenceConfig() {
      if (
        this.trainingCourse &&
        this.trainingCourse.id &&
        this.triad &&
        this.triad.id
      ) {
        driverLicenceManager
          .get("config", this.trainingCourse.id, this.triad.id)
          .then((response) => {
            if (response) {
              this.driverLicencePresentationText = response.presentationText;
            }
          });
      }
    },

    getDriverLicenceModel() {
      this.file =
        loginManager.getApiPath().slice(0, -3) +
        "models/permda-0958_saisissable.pdf";
    },

    submit() {
      if (
        this.trainingCourse &&
        this.trainingCourse.id &&
        this.triad &&
        this.triad.id
      ) {
        this.loading = true;
        const params = formManager.processForm(this.models);
        driverLicenceManager.post(
          null,
          params,
          this.trainingCourse.id,
          this.triad.id,
        );
        this.init();
      }
    },

    formatDate(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>

<style lang="scss" scoped></style>
