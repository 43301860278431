<template>
  <div v-if="Object.keys(infos).length" id="info" class="info">
    <div
      v-for="item in infos"
      v-show="item.label && item.content"
      :key="item.type"
      class="info__item bgStyle"
    >
      <div
        v-if="item.label && item.content"
        class="info__button"
        v-tooltip="{ title: item.label, position: 'right' }"
        @click="openContent($event)"
      >
        <FontAwesomeIcon
          :icon="'fa-light fa-' + getIcon(item.type)"
          class="info__icon textStyle"
        />
      </div>
      <div class="info__content">
        <div class="info__label textStyle">{{ item.label }}</div>
        <div class="info__text" v-html="item.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "pinia";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "InfosComponent",
  data() {
    return {
      content: "",
      label: "",
    };
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      infos: (store) => store.infos,
    }),
  },
  methods: {
    getIcon(type) {
      switch (type) {
        case "presentation":
          return "presentation-screen";
        case "contact":
          return "id-card-clip";
        case "partner":
          return "handshake";
        case "communication":
          return "megaphone";
      }
    },
    openContent(event) {
      const target = event.currentTarget.parentNode;
      if (!target.classList.contains("expanded")) {
        const backdrop = document.getElementById("backdrop");
        const info = document.getElementById("info");
        const $this = this;

        this.resetContent();
        backdrop.classList.add("show");
        info.classList.add("open");
        backdrop.addEventListener("click", function () {
          $this.resetContent();
          backdrop.classList.remove("show");
          setTimeout(function () {
            info.classList.remove("open");
          }, 600);
        });

        setTimeout(function () {
          target.classList.add("expanded");
        }, 300);
      }
    },
    resetContent() {
      const items = [].slice.call(document.querySelectorAll(".info__item"));
      items.map((item) => {
        item.classList.remove("expanded");
        item.classList.add("closing");
        setTimeout(function () {
          item.classList.remove("closing");
        }, 600);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  position: fixed;
  bottom: 0;
  left: 15px;
  z-index: 1051;
  display: inline-flex;
  align-items: flex-end;

  @include up-to-lg {
    display: none;
  }

  &__item {
    max-width: 60px;
    max-height: 60px;
    transform: translateY(100%);
    transition-property: max-width, transform;
    transition-duration: 300ms, 300ms;
    transition-timing-function: ease, ease-in-out;

    &.expanded {
      transform: translateY(0);
      max-height: 600px;
      max-width: 600px;
      transition-delay: 0ms, 300ms;

      .info__button {
        box-shadow: none;
      }
    }
  }

  &__item + &__item {
    margin-left: 15px;
  }

  &__button {
    position: absolute;
    top: -59px;
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radius $radius 0 0;
    border: 1px solid $m-color_3;
    border-bottom: none;
    z-index: 2;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    animation: all 300ms ease-in-out;

    &:hover {
      background: var(--secondary-color);
      border-color: var(--secondary-color);

      .info__icon {
        color: $white !important;
      }
    }

    @include dark-theme {
      background: $m-color_10;
      border: none;
    }

    .info__icon {
      color: var(--secondary-color) !important;
    }
  }

  &__content {
    background: $white;
    padding: 25px;
    border-radius: $radius $radius 0 0;
    border: 1px solid $m-color_3;
    position: relative;
    z-index: 1;
    max-height: 65vh;
    overflow: scroll;

    @include dark-theme {
      background: $m-color-10;
      color: $white;
      border: none;
    }
  }

  &__icon {
    font-size: 25px;
    color: $blue;

    @include dark-theme {
      color: $white;
    }
  }

  &__label {
    font-size: 20px;
    border-bottom: 1px dashed $m-color_3;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}
</style>
