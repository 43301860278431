<template>
  <div class="widget">
    <div class="widget-heading -list position-relative">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'absenceCalendar' }" class="btn-icon">
          <FontAwesomeIcon icon="fa-light fa-arrow-left" />
        </router-link>
        <h1 class="textStyle">
          {{ title }}
          {{
            isFiltered
              ? "(" +
                Object.keys(datas).length +
                " résultat" +
                (Object.keys(datas).length > 1 ? "s" : "") +
                " filtré" +
                (Object.keys(datas).length > 1 ? "s" : "") +
                ")"
              : ""
          }}
        </h1>
      </div>
      <div>
        <MagnifyingGlassComponent
          @openSearch="openSearch"
          @resetSearch="resetSearch"
          :isFiltered="isFiltered"
        />
      </div>
    </div>
    <div class="widget-content">
      <SortTableComponent
        :actions="actionsUser"
        :apiFilters="false"
        :checkable="user.type === 'lea_manager'"
        :columns="columns"
        :datas="datas"
        :idKey="5"
        :loading="loading"
        path="AbsenceLayoutView"
        @remove="remove"
        @updated="updated"
      />
      <button
        v-if="Object.keys(checked).length"
        class="btn btn-danger"
        @click="removeMultiple"
      >
        Supprimer
      </button>
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :storageKey="'studea-absence-' + type"
    :title="
      isEvent()
        ? $t('absence.list_planning_search')
        : $t('absence.list_absences_search')
    "
    @search="search"
  />
</template>

<script>
import { apiConnection } from "@/services/api-connection";
import { mapState } from "pinia";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { absenceManager } from "@/services/absence/absence-manager";
import { useUserStore } from "@/store/user/user";
import { useTriadStore } from "@/store/triad/triad";
import MagnifyingGlassComponent from "@/components/utilities/MagnifyingGlassComponent.vue";
import { useTrainingCourseStore } from "@/store/training-course/training-course";

export default {
  name: "AbsenceListView",
  data() {
    return {
      loading: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      isFiltered: false,
      title: "",
      checked: [],
      actionsUser: [],
      columns: [],
      filterModels: [],
    };
  },
  computed: {
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  watch: {
    triad() {
      this.init();
    },
    trainingCourse() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    search(params) {
      this.loading = true;
      this.listParams = {
        search: params,
      };
      this.getDatas();
      this.isFiltered = true;
    },

    init() {
      if (this.triad && this.triad.id) {
        this.loading = true;
        this.title = this.$t("absence.list_absences");
        window.localStorage.setItem(
          "studea-pageTitle",
          JSON.stringify({ title: this.title, name: this.$route.name }),
        );
        if (this.trainingCourse && this.trainingCourse.id) {
          this.getDatas();
        }
      }
    },

    isEvent() {
      return this.type === "event";
    },

    getDatas() {
      if (
        this.triad &&
        this.triad.id &&
        this.trainingCourse &&
        this.trainingCourse.id
      ) {
        absenceManager
          .loadAbsenceList(this.params, this.trainingCourse.id, this.triad.id)
          .then((data) => {
            this.columns = [];
            Object.entries(data.columns).forEach((column) => {
              if (column[1] !== "id") {
                this.columns.push({
                  name: column[1],
                  sortable: true,
                  sortWay: "",
                  isTranslatedName: true,
                  index: column[0],
                  filterActive: true,
                });
              }
            });
            this.datas = data.data;
            this.loading = false;
          });
      }
    },

    getFilters() {
      this.filterModels = [];
      apiConnection.get("/app/admin/training/search/form").then((data) => {
        this.filterModels = data;
      });
    },

    openSearch() {
      this.getFilters();
      this.isSearchOpen = true;
    },

    resetSearch() {
      this.listParams = [];
      this.isFiltered = false;
      this.getDatas();
      window.localStorage.removeItem("studea-absence-" + this.type);
    },

    remove(row) {
      console.log(row);
      notificationManager
        .showAlert(
          "error",
          this.isEvent()
            ? this.$t("absence.delete_event")
            : this.$t("absence.delete_absence"),
          this.isEvent()
            ? this.$t("absence.delete_event_sub_title")
            : this.$t("absence.delete_absence_sub_title"),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            // apiConnection.delete("/app/admin/generic/" + encodeURI(this.alias) + "/delete/" + row.row.id).then((data) =>
            // {
            //   notificationManager.showNotification(data.hasError ? "error" : "success", data.hasError ? data.detail[0].message : this.$t("admin." + this.current.params.alias + ".delete.success"));
            //   this.init();
            // });
            notificationManager.showNotification(
              "success",
              this.isEvent()
                ? this.$t("absence.delete_event_success")
                : this.$t("absence.delete_absence_success"),
            );
          }
        });
    },

    removeMultiple() {
      notificationManager
        .showAlert(
          "error",
          this.isEvent()
            ? this.$t("absence.delete_events")
            : this.$t("absence.delete_absences"),
          this.isEvent()
            ? this.$t("absence.delete_events_sub_title")
            : this.$t("absence.delete_absences_sub_title"),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            // apiConnection.delete("/app/admin/generic/" + encodeURI(this.alias) + "/delete/" + row.row.id).then((data) =>
            // {
            //   notificationManager.showNotification(data.hasError ? "error" : "success", data.hasError ? data.detail[0].message : this.$t("admin." + this.current.params.alias + ".delete.success"));
            //   this.init();
            // });
            notificationManager.showNotification(
              "success",
              this.isEvent()
                ? this.$t("absence.delete_events_success")
                : this.$t("absence.delete_absences_success"),
            );
          }
        });
    },

    updated(list) {
      this.checked = list;
    },
  },
  components: {
    MagnifyingGlassComponent,
    FontAwesomeIcon,
    ModalFormComponent,
    SortTableComponent,
  },
};
</script>

<style lang="scss" scoped></style>
