<template>
  <div class="progress br-30 mt-1">
    <span
      :class="'progress__text' + (parseInt(data.value) > 50 ? ' -fifty' : '')"
      >{{ data && data.value ? data.value : 0 }}
      {{ data && data.unit ? data.unit : "%" }}
    </span>
    <div
      :aria-valuenow="parseInt(data.value)"
      :style="'width:' + parseInt(data.value) + '%'"
      aria-valuemax="100"
      aria-valuemin="0"
      class="progress-bar bg-gradient-success progressBarStyle"
      role="progressbar"
    ></div>
  </div>
</template>

<script>
export default {
  name: "StatisticBarBlockComponent",
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.progress {
  position: relative;
  margin-bottom: 0;

  &__text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;

    &.-fifty {
      color: $white;
    }
  }
}
.subIndicator {
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 300ms ease-in-out;

  &.-active {
    padding-top: 10px;
    transform: scaleY(100%);
    height: 45px;

    &:last-child {
      height: 65px;
    }
  }
}
</style>
