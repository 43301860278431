export default {
  document: "Document",
  notes: "Notes",
  online_since: "Online since",
  file: "File",
  note: "Note",
  document_remove_confirm: "Are you sure you want to delete this document?",
  note_remove_confirm: "Are you sure you want to delete this note?",
  document_remove_success: "Your document has been deleted successfully!",
  note_remove_success: "Your note has been deleted successfully!",
  document_remove_error: "Error during deletion",
  document_success: "Your document was sent successfully!",
  note_success: "Your note has been sent successfully!",
  new_doc: "Add a document",
  new_note: "Add a note",
  new_doc_title: "New document",
  new_note_title: "New note",
  file_integrate_learning_book:
    "Enter file (PDF) into the apprenticeship Digital Booklet",
  email_notif: "Email notification",
  notes_summary: "Notes and summary",
  by: "By",
  on: "On",
  pedagogicDocuments: "Teaching documentation",
  generalDocuments: "General documents",
  globalDocuments: "Documents for download",
  otherDocuments: "Other documents",
  "Documents to download": "Documents for download",
  Watch: "View",
  Download: "Download",
  none_document: "No document available",
  none_pedagogic: "No teaching document available",
  none_note: "No note available",
};
