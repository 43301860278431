export default {
  global: {
    list: {
      title: "Lista de {name}",
    },
    view: {
      title: "Datos de {name}",
    },
    add: {
      title: ({ plural, named }) =>
        plural([`Añadir un ${named("name")}`, `Añadir una ${named("name")}`]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} añadido correctamente`,
          `${named("name")} añadida correctamente`,
        ]),
    },
    edit: {
      title: ({ plural, named }) =>
        plural([
          `Modificar ${named("name")}`,
          `Modificar ${named("name")}`,
          `Modificar ${named("name")}`,
        ]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} modificado correctamente`,
          `${named("name")} modificada correctamente`,
        ]),
    },
    import: {
      errors: "Su archivo contiene errores. Corríjalos y repita la importación",
      title: "Importar {name}",
      error: "Error en línea {line}, columna {column}:",
      success: ({ plural, named }) =>
        plural([
          `${named("name")} importados correctamente`,
          `${named("name")} importadas correctamente`,
        ]),
    },
    export: {
      title: "Exportar {name}",
      title_alone: "Exportar",
      success: ({ plural, named }) =>
        plural([
          `${named("name")} exportados correctamente`,
          `${named("name")} exportadas correctamente`,
        ]),
    },
    update: {
      title: "Completar {name}",
      success: "{name} completado correctamente",
    },
    search: {
      title: "Buscar {name}",
    },
    sort: {
      success: "Orden de {name} actualizado correctamente",
    },
    download: {
      success: "{name} descargado correctamente",
      title: "Descargar {name}",
    },
    move: {
      success: ({ plural, named }) =>
        plural([
          `${named("name")} desplazado correctamente`,
          `${named("name")} desplazada correctamente`,
        ]),
    },
    copy: {
      success: ({ plural, named }) =>
        plural([
          `${named("name")} duplicado correctamente`,
          `${named("name")} duplicada correctamente`,
        ]),
      title: ({ plural, named }) =>
        plural([
          `Duplicar este ${named("name")}`,
          `Duplicar esta ${named("name")}`,
        ]),
      sub_title: ({ plural, named }) =>
        plural([
          `¿Confirma que quiere duplicar este ${named("name")}?`,
          `¿Confirma que quiere duplicar esta ${named("name")}?`,
        ]),
    },
    empty: {
      title: ({ plural, named }) =>
        plural([
          `Todavía no hay ${named("name1")} en esta ${named("name2")}. Haga clic en + para añadir uno`,
          `Todavía no hay ${named("name1")} en esta ${named("name2")}. Haga clic en + para añadir una`,
        ]),
      dashboard: "Todavía no hay {name}",
      no_data_available: "Ningún dato disponible",
    },
    delete: {
      title: ({ plural, named }) =>
        plural([
          `Eliminar ${named("name")}`,
          `Eliminar un ${named("name")}`,
          `Eliminar una ${named("name")}`,
        ]),
      sub_title: ({ plural, named }) =>
        plural([
          `¿Confirma que quiere eliminar un ${named("name")}?`,
          `¿Confirma que quiere eliminar una ${named("name")}?`,
          `¿Confirma que quiere eliminar ${named("name")}?`,
        ]),
      success: ({ plural, named }) =>
        plural([
          `${named("name")} eliminado correctamente`,
          `${named("name")} eliminada correctamente`,
          `${named("name")} eliminados correctamente`,
          `${named("name")} eliminadas correctamente`,
        ]),
    },

    label: {
      name: "Apellido",
      date: "Fecha",
      triads: "Trinomios",
    },
    actions: {
      title: "Acciones",
      empty: "No hay ninguna acción disponible de momento",
    },
    see_more: "Ver más...",
    close: "Cerrar",
    show_hide_actions: "Mostrar/ocultar las acciones",
    visible: "Visible",
    hidden: "Invisible",
    send: "Enviar",
    stats: "Statistiques",
    setting: "Paramétrage",
    module: "Módulos",
    control: "Pilotage",
    light: "Claro",
    dark: "Oscuro",
  },
};
