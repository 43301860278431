<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-10 order-1 order-lg-0">
        <div class="widget">
          <div class="widget-heading position-relative">
            <h1 class="textStyle">
              <button
                class="btn-icon me-2"
                v-tooltip="{ title: $t('back') }"
                @click="$router.back()"
              >
                <FontAwesomeIcon icon="fa-light fa-arrow-left" />
              </button>
              <span>{{ $t("studea_manager.driver_licence.list") }}</span>
            </h1>
            <div>
              <MagnifyingGlassComponent
                @openSearch="openSearch"
                @resetSearch="resetSearch"
                :isFiltered="isFiltered"
              />
            </div>
          </div>
          <div class="widget-content">
            <SortTableComponent
              :columns="columns"
              :datas="data"
              :actions="tableActions"
              type="driver_licence"
              @refresh="refresh"
              ref="sortTable"
              idKey="9"
              @validDriverLicenceRequest="validDriverLicenceRequest"
              @editDriverLicenceRequest="editDriverLicenceRequest"
              @overviewDriverLicenceRequest="overviewDriverLicenceRequest"
              @downloadDriverLicenceFile="downloadDriverLicenceFile"
            />
          </div>
          <ModalFormComponent
            :models="models"
            :title="modelTitle"
            :hideSubmit="hideSubmit"
            @submit="submitDriverLicenceEdition"
            @search="search"
          />
          <BlockLoader :loading="loading" />
        </div>
      </div>
      <div class="col-lg-2 order-0 order-lg-1">
        <div class="sticky">
          <div id="action-blocks">
            <ActionsBlock
              :actions="actions"
              :mobileCollapsable="true"
              :collapsable="false"
              collapseId="actions"
              @exportDriverLicenceRequest="exportDriverLicenceRequest"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import { useMainStore } from "@/store/main/main";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { accessManager } from "@/services/security/access-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { i18n } from "@/i18n";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { driverLicenceManager } from "@/services/driver-licence/driver-licence-manager";
import { formManager } from "@/services/form/form-manager";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";
import MagnifyingGlassComponent from "@/components/utilities/MagnifyingGlassComponent.vue";

export default {
  name: "DriverLicenceListView",
  components: {
    MagnifyingGlassComponent,
    ActionsBlock,
    ModalFormComponent,
    SortTableComponent,
    BlockLoader,
  },
  data() {
    return {
      data: [],
      loading: false,
      listParams: [],
      columns: [],
      models: [],
      modelTitle: "",
      modalType: "",
      isFiltered: false,
      hideSubmit: false,
      currentDriverLicence: null,
      actions: [
        {
          translation: "global.export.title",
          translationGender: 1,
          translationParams: {
            name: this.$tc(
              "studea_manager.driver_licence.requests",
              1,
            ).toLowerCase(),
          },
          icon: "download",
          type: "success",
          actionType: "button",
          action: "exportDriverLicenceRequest",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
      ],
      tableActions: [
        {
          translation: "studea_manager.driver_licence.valid",
          icon: "check",
          type: "success",
          action: "validDriverLicenceRequest",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
        {
          translation: "edit",
          icon: "pen",
          type: "success",
          action: "editDriverLicenceRequest",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
        {
          translation: "download",
          icon: "download",
          type: "success",
          action: "downloadDriverLicenceFile",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
      ],
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  mounted() {
    this.setAppLoading(false);
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.setParams();
      this.getDriverLicenceRequestList();
    },

    getDriverLicenceRequestList() {
      driverLicenceManager
        .list(null, this.listParams, true)
        .then((response) => {
          if (response) {
            this.data = response.data;
            this.columns = sortableTableManager.getEntriesColumns(
              response.columns,
              this.columns,
            );
            this.columns.forEach((column) => {
              if (column.name === "Nb de documents chargés") {
                column.sortable = false;
              }
            });
            this.loading = false;
          }
        });
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          sortByField: 0,
          sortOrder: "ASC",
          limit: 10,
          offset: 0,
        };
      }
    },

    refresh(params) {
      this.listParams = params;
      this.init();
    },

    openSearch() {
      this.loading = true;
      this.models = [];
      this.modalType = "search";
      driverLicenceManager
        .get("search/form", null, null, null, true)
        .then((response) => {
          this.models = response;
          this.modelTitle = this.$tc("global.search.title", 2, {
            name: this.$t(
              "studea_manager.driver_licence.requests",
            ).toLowerCase(),
          });
          this.loading = false;
        });
    },

    search(params) {
      if (this.modalType === "search") {
        this.loading = true;
        this.listParams["offset"] = 0;
        this.$refs.sortTable.activePage = 1;
        this.listParams["search"] = params;
        this.init();
        this.isFiltered = true;
      }
    },

    resetSearch() {
      this.listParams = [];
      this.datas = [];
      this.$refs.sortTable.activePage = 1;
      this.columns = [];
      this.isFiltered = false;
      this.init();
    },

    validDriverLicenceRequest(payload) {
      if (
        payload.row[8] ===
        driverLicenceManager.DRIVER_LICENCE_REQUEST_STATUS_PAYED
      ) {
        notificationManager
          .showAlert(
            "warning",
            this.$t("studea_manager.driver_licence.valid_confirmation"),
            "",
            false,
            true,
          )
          .then((res) => {
            if (res.isConfirmed) {
              this.loading = true;
              driverLicenceManager
                .validDriverLicenceRequest(payload.id)
                .then(() => {
                  this.data = [];
                  this.columns = [];
                  this.init();
                });
            }
          });
      }
    },

    editDriverLicenceRequest(payload) {
      this.models = [];
      this.modalType = "edit";
      this.hideSubmit = false;
      this.currentDriverLicence = payload.id;
      this.loading = true;
      driverLicenceManager
        .get(null, null, null, this.currentDriverLicence, true)
        .then((response) => {
          this.loading = false;
          this.models = response;
          this.modelTitle =
            this.$t("studea_manager.driver_licence.edit") + payload.row[0];
        });
    },

    overviewDriverLicenceRequest(payload) {
      this.models = [];
      this.modalType = "overview";
      this.hideSubmit = true;
      this.currentDriverLicence = payload.id;
      this.loading = true;
      driverLicenceManager
        .get(null, null, null, this.currentDriverLicence, true)
        .then((response) => {
          this.loading = false;
          Object.values(response).forEach((element) => {
            element.vars.disabled = true;
          });

          this.models = response;
          this.modelTitle =
            this.$t("studea_manager.driver_licence.overview") + payload.row[0];
        });
    },

    downloadDriverLicenceFile(payload) {
      driverLicenceManager
        .downloadDriverLicenceZipFile(payload.id)
        .then((response) => {
          messengerMessageManager.processMessage(response);
        });
    },

    submitDriverLicenceEdition(payload) {
      if (this.modalType === "edit") {
        this.loading = true;
        let result = formManager.processForm(payload, false, null, true);
        result.paymentAmount = Number(result.paymentAmount);
        driverLicenceManager
          .put("post", result, null, this.currentDriverLicence, true)
          .then((response) => {
            if (!response.hasError) {
              notificationManager.showNotification(
                "success",
                i18n.global.t(
                  "studea_manager.driver_licence.request_edit.success",
                ),
              );
            } else {
              notificationManager.showNotification(
                "error",
                i18n.global.t("message_error"),
              );
            }
            this.init();
          });
      }
    },

    exportDriverLicenceRequest() {
      driverLicenceManager.get("export", null, null, null, true).then((res) => {
        messengerMessageManager.processMessage(res);
      });
    },

    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>

<style lang="scss" scoped></style>
