import { apiConnection } from "@/services/api-connection";

const URL_DRIVER_LICENCE = "/app/driver-licence";
const STUDEA_MANAGER_URL_DRIVER_LICENCE = "/app/studea-manager/driver-licence";
class DriverLicenceManager {
  DRIVER_LICENCE_REQUEST_STATUS_PAYED = 5;
  DRIVER_LICENCE_REQUEST_STATUS_COMPLETED = 3;
  DRIVER_LICENCE_ALL_DOCUMENT = 5;
  get(
    alias = null,
    trainingCourse = null,
    triad = null,
    id = null,
    isStudeaManagerRoute = false,
  ) {
    return apiConnection
      .get(
        (isStudeaManagerRoute
          ? STUDEA_MANAGER_URL_DRIVER_LICENCE
          : URL_DRIVER_LICENCE) +
          (trainingCourse ? "/" + trainingCourse : "") +
          (triad ? "/" + triad : "") +
          (id ? "/" + id : "") +
          (alias ? "/" + alias : ""),
      )
      .then((response) => response);
  }

  post(alias = null, params = null, trainingCourse = null, triad = null) {
    return apiConnection
      .post(
        URL_DRIVER_LICENCE +
          (trainingCourse ? "/" + trainingCourse : "") +
          (triad ? "/" + triad : "") +
          "/post" +
          (alias ? "/" + alias : ""),
        params,
      )
      .then((response) => response);
  }

  genericPost(
    alias = null,
    params = null,
    trainingCourse = null,
    id = null,
    isStudeaManagerRoute = false,
  ) {
    return apiConnection
      .post(
        (isStudeaManagerRoute
          ? STUDEA_MANAGER_URL_DRIVER_LICENCE
          : URL_DRIVER_LICENCE) +
          (trainingCourse ? "/" + trainingCourse : "") +
          (alias ? "/" + alias : "") +
          (id ? "/" + id : ""),
        params,
      )
      .then((response) => response);
  }

  put(
    alias = null,
    params = null,
    trainingCourse = null,
    id = null,
    isStudeaManagerRoute = null,
  ) {
    return apiConnection
      .put(
        (isStudeaManagerRoute
          ? STUDEA_MANAGER_URL_DRIVER_LICENCE
          : URL_DRIVER_LICENCE) +
          (trainingCourse ? "/" + trainingCourse : "") +
          (alias ? "/" + alias : "") +
          (id ? "/" + id : ""),
        params,
      )
      .then((response) => response);
  }

  sendDriverLicenceValidationMailToStudeaManager(
    trainingCourse = null,
    triad = null,
  ) {
    return apiConnection.post(
      URL_DRIVER_LICENCE +
        (trainingCourse ? "/" + trainingCourse : "") +
        (triad ? "/" + triad : "") +
        "/mail",
    );
  }

  list(trainingCourse = null, params = {}, isStudeaManagerRoute = false) {
    return apiConnection
      .get(
        (isStudeaManagerRoute
          ? STUDEA_MANAGER_URL_DRIVER_LICENCE
          : URL_DRIVER_LICENCE) +
          "/list" +
          (trainingCourse ? "/" + trainingCourse : ""),
        params,
      )
      .then((response) => response);
  }

  validDriverLicenceRequest(id) {
    return apiConnection
      .post("/app/studea-manager/driver-licence/valid/" + id)
      .then((response) => response);
  }

  downloadDriverLicenceZipFile(id) {
    return apiConnection.get(
      "/app/studea-manager/driver-licence/" + id + "/export-zip",
    );
  }
}

export const driverLicenceManager = new DriverLicenceManager();
