<template>
  <div class="row">
    <div :class="alias === 'absence' ? 'col-lg-9' : ''">
      <div class="widget">
        <div class="widget-heading -list position-relative">
          <h1 class="textStyle">
            <button
              class="btn-icon"
              v-tooltip="{ title: $t('back') }"
              @click="$router.back()"
            >
              <FontAwesomeIcon icon="fa-light fa-arrow-left" />
            </button>
            {{
              $tc("global.list.title", 1, {
                name: this.$tc(
                  "studea_manager." + alias + ".title",
                  2,
                ).toLowerCase(),
              })
            }}
            {{
              isFiltered
                ? "(" +
                  Object.keys(datas).length +
                  " résultat" +
                  (Object.keys(datas).length > 1 ? "s" : "") +
                  " filtré" +
                  (Object.keys(datas).length > 1 ? "s" : "") +
                  ")"
                : ""
            }}
          </h1>
          <div>
            <MagnifyingGlassComponent
              @openSearch="openSearch"
              @resetSearch="resetSearch"
              :isFiltered="isFiltered"
            />
          </div>
        </div>
        <div class="widget-content">
          <SortTableComponent
            v-if="this.alias"
            :actions="listActions"
            :apiFilters="false"
            :columns="columns"
            :datas="datas"
            :checkable="this.alias === 'planning'"
            :loading="loading"
            :idKey="this.alias === 'absence' ? 7 : 8"
            type="absence"
            dateFormat="short_time"
            path="adminTrainingCourseEdit"
            @remove="remove"
            @updated="updated"
          />
          <button
            v-if="Object.keys(selected).length"
            class="btn btn-danger"
            @click="removeSelected"
          >
            <FontAwesomeIcon class="me-2" icon="fa-light fa-trash" />
            {{ $t("studea_manager.planning.removeSelected.title") }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="alias === 'absence'" class="col-lg-3">
      <ActionsBlock
        v-if="actions"
        :actions="actions"
        :mobileCollapsable="true"
        @exportFullListXlsx="exportListToExcel"
        @exportApprenticeListXlsx="exportApprenticeToExcel"
        @exportFullListPdf="exportListToPdf"
        @exportApprenticeListPdf="exportApprenticeToPdf"
        @exportStateList="exportPlanningToPdf"
      />
    </div>
  </div>
  <ModalFormComponent
    :models="filterModels"
    :storageKey="sessionStorageKey"
    :title="
      $tc('global.search.title', 2, {
        name: this.$tc('admin.training.title', 2).toLowerCase(),
      })
    "
    @search="search"
  />
</template>

<script>
import { mapState } from "pinia";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { formManager } from "@/services/form/form-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { apiConnection } from "@/services/api-connection";
import { accessManager } from "@/services/security/access-manager";
import { useUserStore } from "@/store/user/user";
import { usePlatformStore } from "@/store/platform/platform";
import { absenceManager } from "@/services/absence/absence-manager";
import MagnifyingGlassComponent from "@/components/utilities/MagnifyingGlassComponent.vue";

export default {
  name: "AbsenceListView",
  components: {
    MagnifyingGlassComponent,
    ActionsBlock,
    ModalFormComponent,
    SortTableComponent,
    FontAwesomeIcon,
  },
  data() {
    return {
      loading: false,
      listParams: [],
      datas: [],
      isSearchOpen: false,
      isFiltered: false,
      sessionStorageKey: "studea-manager-" + this.alias,
      alias: "",
      listActions: [],
      selected: [],
      actions: [],
      columns: [],
      filterModels: [],
    };
  },
  computed: {
    ...mapState(useUserStore, {
      users: (store) => store.usersList,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
    }),
  },
  mounted() {
    this.init();
    if (this.apprenticeProfileLabel.title) {
      this.initActions();
    }
  },
  watch: {
    apprenticeProfileLabel() {
      this.initActions();
    },
  },
  methods: {
    initActions() {
      this.actions = [
        {
          translation: "studea_manager.absence.export.full_list",
          icon: "file-excel",
          type: "success",
          action: "exportFullListXlsx",
          cfaStyle: false,
        },
        {
          translation:
            this.$t("studea_manager.absence.export.apprentice_list") +
            this.apprenticeProfileLabel.title.toLowerCase(),
          icon: "file-excel",
          type: "success",
          action: "exportApprenticeListXlsx",
          cfaStyle: false,
        },
        {
          translation: "studea_manager.absence.export.full_list",
          icon: "file-pdf",
          type: "success",
          action: "exportFullListPdf",
          cfaStyle: false,
        },
        {
          translation:
            this.$t("studea_manager.absence.export.apprentice_list") +
            this.apprenticeProfileLabel.title.toLowerCase(),
          icon: "file-pdf",
          type: "success",
          action: "exportApprenticeListPdf",
          cfaStyle: false,
        },
        {
          translation: "studea_manager.absence.export.state",
          icon: "calendar",
          type: "success",
          action: "exportStateList",
          cfaStyle: false,
        },
      ];
    },

    remove(row) {
      studeaManagerManager
        .remove(
          this.alias,
          this.$route.params.trainingCourseId,
          row.id,
          2,
          true,
        )
        .then(() => {
          this.init();
        });
    },

    updated(selected) {
      this.selected = selected;
    },

    removeSelected() {
      notificationManager
        .showAlert(
          "error",
          i18n.global.t("studea_manager.planning.removeSelected.title"),
          i18n.global.t("studea_manager.planning.removeSelected.sub_title"),
          true,
          true,
        )
        .then((data) => {
          if (data.isConfirmed) {
            apiConnection
              .delete(
                "/app/studea-manager/" +
                  this.$route.params.trainingCourseId +
                  "/planning/delete",
                { plannings: this.selected },
              )
              .then((data) => {
                notificationManager.showNotification(
                  data.hasError ? "error" : "success",
                  data.hasError
                    ? data.detail[0].message
                    : i18n.global.t(
                        "studea_manager.planning.removeSelected.success",
                      ),
                );
                this.init();
              });
          }
        });
    },

    search(params) {
      this.loading = true;
      this.listParams = {
        search: params,
      };
      this.getDatas();
      this.isFiltered = true;
    },

    init() {
      this.selected = [];
      this.loading = true;
      this.alias = this.$route.params.alias;
      this.initListActions();
      this.setParams();
      this.getDatas(true);
    },

    initListActions() {
      this.listActions = [
        {
          translation: this.$tc("global.edit.title", 2, {
            name: this.$tc(
              "studea_manager." + this.alias + ".title",
              1,
            ).toLowerCase(),
          }),
          icon: "pen",
          type: "primary",
          action: "studeaManagerAbsenceEdit",
          cfaStyle: true,
          actionType: "router",
          alias: this.alias,
          query: {
            backPath:
              this.alias === "absence"
                ? "studeaManagerAbsenceAbsenceList"
                : "studeaManagerPlanningList",
          },
          access: accessManager.EDIT,
        },
        {
          translation: this.$tc("global.delete.title", 2, {
            name: this.$tc(
              "studea_manager." + this.alias + ".title",
              1,
            ).toLowerCase(),
          }),
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
          access: accessManager.DELETE,
        },
      ];
    },

    setParams() {
      if (window.sessionStorage.getItem(this.sessionStorageKey)) {
        this.listParams = JSON.parse(
          window.sessionStorage.getItem(this.sessionStorageKey),
        );
        this.isFiltered = true;
      }
    },

    getDatas(init = false) {
      if (this.isFiltered && init) {
        this.listParams = { search: formManager.getQuery(this.listParams) };
      }
      studeaManagerManager
        .list(
          this.alias,
          this.$route.params.trainingCourseId,
          false,
          this.listParams,
        )
        .then((data) => {
          this.columns = sortableTableManager.getEntriesColumns(data.columns);
          this.datas = data.data;
          this.loading = false;
        });
    },

    getFilters() {
      this.filterModels = [];
      studeaManagerManager
        .form(
          this.alias,
          this.$route.params.trainingCourseId,
          null,
          {},
          false,
          true,
        )
        .then((data) => {
          this.filterModels = data;
        });
    },

    openSearch() {
      this.getFilters();
      this.isSearchOpen = true;
    },

    resetSearch() {
      this.listParams = [];
      this.isFiltered = false;
      this.getDatas();
      window.sessionStorage.removeItem(this.sessionStorageKey);
    },

    exportListToExcel() {
      absenceManager.export(
        this.listParams,
        "/export-excel-list",
        this.$route.params.trainingCourseId,
      );
    },

    exportApprenticeToExcel() {
      absenceManager.export(
        this.listParams,
        "/export-excel-apprentice",
        this.$route.params.trainingCourseId,
      );
    },

    exportListToPdf() {
      absenceManager.export(
        this.listParams,
        "/export-pdf-list",
        this.$route.params.trainingCourseId,
      );
    },

    exportApprenticeToPdf() {
      absenceManager.export(
        this.listParams,
        "/export-pdf-apprentice",
        this.$route.params.trainingCourseId,
      );
    },

    exportPlanningToPdf() {
      absenceManager.export(
        this.listParams,
        "/export-pdf-planning",
        this.$route.params.trainingCourseId,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-heading {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.-list {
    cursor: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }

  &.collapsed {
    padding-bottom: 0;
  }
}

.widget {
  padding-bottom: 20px;
}

.widget-content {
  padding-bottom: 0;
}
</style>
