export default {
  yourSkills: "Sus competencias",
  skills: "Competencias",
  last_skill_update: "Último registro",
  duration: "Duración",
  Category: "Categoría",
  "Skills saved successfully": "Competencias registradas correctamente",
  next: "Siguiente",
  previous: "Anterior",
  seeHistoric: "Visualizar historial",
  resetSkill: "Reinicializar la competencia",
  justificativePieceSend: "Justificantes enviados",
  sendJustificativePiece: "Enviar justificantes",
  impossibleJustificativeSending:
    "Opción de añadir justificantes después de la nota",
  stats: {
    no_data: "Ningún dato introducido todavía",
  },
  no_skills: "No se ha introducido ninguna competencia para esta categoría",
  no_skill_available: "No hay ninguna competencia introducida de momento",
  no_skill_complete: "No se ha completado ninguna competencia",
  skill_chart: "Gráfico de competencias",
  skill_chart_pdf_name: "gráfico-de-competencias",
  referential_visible: ({ plural }) =>
    plural(["Referencia activa", "Referencia inactiva"]),
  session_visible: ({ plural }) => plural(["Sesión activa", "Sesión inactiva"]),
};
