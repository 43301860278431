<template>
  <ModalFormComponent
    :models="models"
    :title="$t(modalTitle)"
    :storageKey="modalStorageKey"
    :submitText="modalSubmitText"
    @search="submitModal"
  />
  <BlockLoader :loading="loading"></BlockLoader>
  <div class="container-fluid">
    <div class="row flex-lg-nowrap">
      <div
        :class="
          'dashboard__container ' +
          (isFullScreen || !actions || !actions.length
            ? 'full-screen'
            : 'col-lg-10')
        "
      >
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">
              {{ $t("studea_manager.dashboard.list.title") }}
            </h2>
            <div
              class="ms-2"
              v-if="
                trainingCourse &&
                trainingCourse.trainingCourseYears &&
                trainingCourse.trainingCourseYears.length > 1
              "
            >
              |
              <button
                :class="
                  'tab__year ' + (currentYear === year.id ? '-active' : '')
                "
                v-for="year in trainingCourse.trainingCourseYears"
                :key="year.id"
                @click="changeCurrentTrainingCourseYear(year.id)"
              >
                {{ $t("year") }} {{ year.year }}
              </button>
            </div>
          </div>
          <div class="position-relative" v-if="categories && categories.length">
            <FilterBlock
              @export="exportPdf"
              @exportExcel="exportExcel"
              @filter="filter"
              @reset="resetFilter"
              :isFiltered="isFiltered"
            />
            <AccordionTableComponent
              :categories="categories"
              :crews="crews"
              type="questionnaire"
              @manage="manage"
            />
          </div>
          <div v-else class="widget-content">
            {{ $tc("no_questionnaire", 1) }}
          </div>
        </div>
      </div>
      <div
        v-if="actions && actions.length"
        :class="
          'col-lg-2 order-lg-1 order-0 position-relative  ' +
          (!isFullScreen ? 'action__full-screen' : '')
        "
      >
        <button
          :class="'toggle_action ' + (isFullScreen ? '' : '-on')"
          @click="toggleAction"
          v-tooltip="{ title: $t('global.show_hide_actions') }"
        >
          <FontAwesomeIcon
            icon="fa-light fa-chevron-left"
            :class="'toggle_icon ' + (isFullScreen ? '' : 'rotate')"
          />
        </button>
        <ActionsBlock
          :actions="actions"
          :mobileCollapsable="true"
          :collapsable="false"
          :title="$t('global.actions.title')"
          collapseId="actions"
          @export-dashboard-to-excel="exportDashboardToExcel"
          @export-visit-date="exportVisitDate"
          @emailReminder="emailReminder"
          @export-questionnaires-to-pdf="exportQuestionnairesToPdf"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AccordionTableComponent from "@/components/table/accordion/AccordionTableComponent.vue";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import FilterBlock from "@/components/blocks/FilterBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { formManager } from "@/services/form/form-manager";
import { mapActions, mapState } from "pinia";
import { useMainStore } from "@/store/main/main";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "DashboardView",
  components: {
    BlockLoader,
    ModalFormComponent,
    AccordionTableComponent,
    ActionsBlock,
    FilterBlock,
  },

  data() {
    return {
      actions: [
        {
          translation: "studea_manager.dashboard.mail_relance",
          icon: "envelope",
          type: "success",
          actionType: "button",
          action: "emailReminder",
          cfaStyle: false,
        },
        // {
        //   translation: "studea_manager.dashboard.export.visit_date",
        //   icon: "calendar-days",
        //   type: "success",
        //   actionType: "button",
        //   action: "exportVisitDate",
        //   cfaStyle: false,
        // },
        // {
        //   translation: "studea_manager.dashboard.export.dashboard_excel",
        //   icon: "file-excel",
        //   type: "success",
        //   actionType: "button",
        //   action: "exportDashboardToExcel",
        //   cfaStyle: false,
        // },
        // {
        //   translation: "studea_manager.dashboard.export.questionnaires_pdf",
        //   icon: "file-pdf",
        //   type: "success",
        //   actionType: "button",
        //   action: "exportQuestionnairesToPdf",
        //   cfaStyle: false,
        // },
      ],
      models: [],
      modalTitle: "",
      isFiltered:
        sessionStorage.getItem("questionnaire-dashboard") &&
        sessionStorage.getItem("questionnaire-dashboard").length,
      loading: true,
      isFullScreen: false,
      isMobile: window.innerWidth < 992,
      categories: [],
      crews: [],
      params: {},
      modalType: "",
      modalStorageKey: "",
      currentYear: null,
      modalSubmitText: "",
    };
  },
  computed: {
    ...mapState(useMainStore, {
      sideBarOpened: (store) => store.sideBarOpened,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
    }),
  },
  mounted() {
    if (!this.isMobile) {
      this.toggleSideBar();
    }
    this.getDashboardData();
    this.currentYear =
      this.trainingCourse &&
      this.trainingCourse.trainingCourseYears &&
      this.trainingCourse.trainingCourseYears.length
        ? this.trainingCourse.trainingCourseYears[0].id
        : null;
  },
  beforeUnmount() {
    if (!this.isMobile && !this.sideBarOpened) {
      this.toggleSideBar();
    }
  },
  watch: {
    trainingCourse() {
      this.currentYear =
        this.trainingCourse &&
        this.trainingCourse.trainingCourseYears &&
        this.trainingCourse.trainingCourseYears.length
          ? this.trainingCourse.trainingCourseYears[0].id
          : null;
    },
  },
  methods: {
    filter() {
      this.loading = true;
      this.models = [];
      this.modalTitle = "";
      this.modalSubmitText = this.$t("studea_manager.dashboard.filter");
      this.modalStorageKey = "questionnaire-dashboard-filter";
      studeaManagerManager
        .form(
          "dashboard",
          this.$route.params.trainingCourseId,
          null,
          {},
          false,
          true,
        )
        .then((data) => {
          this.loading = false;
          this.models = data;
          this.modalType = "filter";
          this.modalTitle = "studea_manager.dashboard.filter";
        });
    },

    exportPdf() {
      this.loading = true;
      this.models = [];
      this.modalSubmitText = this.$t("global.export.title_alone");
      this.modalTitle = "";
      this.modalStorageKey = "questionnaire-dashboard-export";
      studeaManagerManager
        .form("dashboard/export", this.$route.params.trainingCourseId)
        .then((data) => {
          this.models = data;
          this.modalType = "export";
          this.modalTitle = "studea_manager.dashboard.export.title";
          this.loading = false;
        });
    },

    exportExcel() {
      this.models = [];
      this.modalSubmitText = this.$t("global.export.title_alone");
      this.modalTitle = "";
      this.modalStorageKey = "questionnaire-dashboard-export";
      studeaManagerManager
        .form("dashboard/export", this.$route.params.trainingCourseId)
        .then((data) => {
          this.models = data;
          this.modalType = "export-excel";
          this.modalTitle = "studea_manager.dashboard.export.title";
        });
    },

    submitModal(params) {
      this.params = {
        search: params,
      };
      if (this.modalType === "filter") {
        this.loading = true;
        this.getDashboardData();
        this.isFiltered = true;
      } else if (this.modalType === "export") {
        studeaManagerManager.export(
          "dashboard",
          this.$route.params.trainingCourseId,
          this.params,
        );
      } else if (this.modalType === "export-excel") {
        studeaManagerManager.export(
          "dashboard/excel",
          this.$route.params.trainingCourseId,
          this.params,
        );
      } else if (this.modalType === "emailReminder") {
        const payload = formManager.processForm(this.models);
        studeaManagerManager
          .post(
            "questionnaire/email-reminder",
            this.$route.params.trainingCourseId,
            false,
            payload,
          )
          .then((res) => {
            let message = this.$t("signed_error");
            let type = "error";
            if (res && res.success) {
              message = this.$t(
                "studea_manager.dashboard.questionnaire.send_email_reminder_success",
              );
              type = "success";
            }
            notificationManager.showNotification(type, message);
          });
      }
    },

    resetFilter() {
      this.models = [];
      this.modalTitle = "";
      this.params = [];
      this.isFiltered = false;
      this.getDashboardData(this.$route.params.trainingCourseId);
      sessionStorage.removeItem("questionnaire-dashboard");
    },

    exportDashboardToExcel() {
      studeaManagerManager.exportToExcel("questionnaires");
    },

    exportVisitDate() {
      console.log("Exporter les dates de visite");
    },

    emailReminder() {
      this.loading = true;

      this.models = [];
      this.modalTitle = "";
      this.modalSubmitText = this.$t("send");
      studeaManagerManager
        .form(
          "questionnaire/email-reminder",
          this.$route.params.trainingCourseId,
          "",
          "",
          false,
        )
        .then((data) => {
          this.models = data;
          this.modalType = "emailReminder";
          this.modalTitle = "studea_manager.dashboard.mail_relance";
          this.loading = false;
        });
    },

    exportQuestionnairesToPdf() {
      console.log("Exporter les questionnaires en pdf");
    },

    checkParams() {
      if (
        sessionStorage.getItem("questionnaire-dashboard") &&
        sessionStorage.getItem("questionnaire-dashboard").length
      ) {
        this.params.search = formManager.getQuery(
          JSON.parse(sessionStorage.getItem("questionnaire-dashboard")),
        );
      }
    },

    getDashboardData() {
      this.loading = true;
      this.checkParams();
      studeaManagerManager
        .getDashboardData(this.$route.params.trainingCourseId, this.params)
        .then((response) => {
          if (
            response &&
            response.categories &&
            response.trainingCourse &&
            response.trainingCourse.crews
          ) {
            this.categories = response.categories.filter(
              (category) => category.originalQuestionnaires.length,
            );
            this.crews = response.trainingCourse.crews;
            this.loading = false;
          }
        });
    },

    manage(payload) {
      const expired =
        new Date().getTime() > new Date(payload.reply.endDate).getTime();
      if (
        payload.reply.icon === "check" ||
        (payload.reply.icon === "lock" && expired)
      ) {
        studeaManagerManager
          .unlockReply(
            Number(payload.profile),
            payload.questionnaire,
            payload.triad.id,
          )
          .then(() => {
            if (payload.reply.icon === "lock") {
              payload.reply.icon = "lock-open";
            }
            if (payload.reply.icon === "check") {
              payload.reply.icon = "hourglass-start";
              payload.reply.signedAt = null;
            }
          });
      }
      if (payload.reply.icon === "hourglass-start") {
        let data = {};
        data["triad"] = [
          {
            id: payload.triad.id,
          },
        ];
        data["questionnaire"] = [{ id: payload.questionnaire }];
        data["userType"] = [{ id: Number(payload.profile) }];
        data["emailContent"] = null;
        studeaManagerManager.sendOneEmailReminder(
          data,
          this.$route.params.trainingCourseId,
        );
      }
    },

    toggleAction() {
      this.isFullScreen = !this.isFullScreen;
    },

    changeCurrentTrainingCourseYear(id) {
      this.params = {
        search: "year=" + id,
      };
      this.currentYear = id;
      this.getDashboardData();
    },

    ...mapActions(useMainStore, {
      toggleSideBar: "toggleSideBar",
    }),
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__container {
    transition: width ease-in-out 300ms;
    padding-left: 0;
    padding-right: 30px;
  }
}

.full-screen {
  width: 100%;
}

.action__full-screen {
  transition: all ease-in-out 300ms;
}

.toggle_icon {
  transform: rotate(0deg);
  transition: all ease-in-out 300ms;

  &.rotate {
    transform: rotate(180deg);
  }
}

.toggle_action {
  position: absolute;
  display: block;
  z-index: 0;
  top: 0;
  left: -20px;
  padding: 2px 20px 2px 10px;
  border: 1px solid #e0e6ed;
  background-color: $green;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  border-radius: 6px;
  color: $white;
  height: 67px;
  transition: background-color ease-in-out 300ms;

  &.-on {
    color: #212529;
    background-color: $white;
    @include dark-theme {
      background-color: $dark-grey;
      color: $m-color_10;
      border: 2px $m-color_10 solid;
    }
  }

  @include up-to-lg {
    display: none;
  }
}

.tab__year {
  width: fit-content;
  position: relative;
  border: none;
  border-radius: 5px;
  padding: 5px 8px;
  background-color: #f1f2f3;
  color: var(--primary-color);
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.22);

  &:first-child {
    margin-left: 5px;
  }

  &:hover,
  &.-active {
    background-color: $primary;
    color: $white;
  }
}

.widget-heading {
  justify-content: start !important;
  align-items: center !important;
}
</style>
