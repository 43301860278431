export default {
  choose_subject: "Seleccione una materia",
  show_lesson: "El curso",
  course: "Curso",
  lessonLink: "Enlace",
  activityDone: "Actividades realizadas",
  workToDo: "Trabajo por hacer",
  generalCourses: "Cursos generales",
  attachments: "Adjuntos",
  attachment: "Adjunto",
  download: "Descargar",
  "Taught subject": "Materia impartida",
};
