<template>
  <div class="container_fluid">
    <div class="row">
      <div class="col-lg-3 order-2 order-lg-0">
        <div class="sticky">
          <TriadMemberBlock />
        </div>
      </div>
      <div class="col-lg-9 order-1 order-lg-1">
        <div class="widget">
          <div class="widget-heading">
            <h2 class="textStyle">
              <button
                class="btn-icon"
                v-tooltip="{ title: $t('back') }"
                @click="$router.back()"
              >
                <FontAwesomeIcon icon="fa-light fa-arrow-left" />
              </button>
              {{ $t("absence.list_of_attendance_certificate") }}
            </h2>
          </div>
          <div class="widget-content position-relative">
            <div v-if="attendances && attendances.length" class="list">
              <div class="list__heading">
                <div class="col-3">
                  <span
                    v-if="
                      apprenticeProfileLabel && apprenticeProfileLabel.title
                    "
                    >{{ apprenticeProfileLabel.title }}</span
                  >
                </div>
                <div class="col-3">
                  <span>{{ $t("global.duration") }}</span>
                </div>
                <div class="col-3">
                  <span>{{ $t("global.period") }}</span>
                </div>
                <div class="col-3">
                  {{ $t("absence.download_certificate") }}
                </div>
              </div>
              <div class="list__content">
                <div
                  v-for="(attendance, index) in attendances"
                  :key="index"
                  class="list__item"
                >
                  <div class="list__column col-12 col-lg-3">
                    <div
                      class="col-4 d-lg-none"
                      v-if="
                        apprenticeProfileLabel && apprenticeProfileLabel.title
                      "
                    >
                      {{ apprenticeProfileLabel.title }}
                    </div>
                    <span
                      class="col-8 col-lg-12"
                      v-if="attendance.apprentice.fullName"
                      >{{ attendance.apprentice.fullName }}</span
                    >
                    <span v-else>--</span>
                  </div>
                  <div class="list__column col-12 col-lg-3">
                    <div class="col-4 d-lg-none">
                      {{ $t("global.duration") }}
                    </div>
                    <span class="col-8 col-lg-12" v-if="attendance.duration">{{
                      attendance.duration
                    }}</span>
                    <span v-else>--</span>
                  </div>
                  <div class="list__column col-12 col-lg-3">
                    <div class="col-4 d-lg-none">
                      {{ $t("global.period") }}
                    </div>
                    <span class="col-8 col-lg-12" v-if="attendance.period">{{
                      attendance.period
                    }}</span>
                    <span v-else>--</span>
                  </div>
                  <div class="list__column col-12 col-lg-3">
                    <div class="col-4 d-lg-none">
                      {{ $t("absence.download_certificate") }}
                    </div>
                    <span class="col-8 col-lg-12">
                      <button
                        v-if="attendance.period"
                        class="btn-icon"
                        v-tooltip="{
                          title: $t('absence.download_certificate'),
                        }"
                        @click="downLoadCertificate(attendance.period)"
                      >
                        <FontAwesomeIcon icon="fa-ligth fa-download" />
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              {{ $t("absence.no_attendance_certificate_available") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <BlockLoader :loading="loading" />
  </div>
</template>

<script>
import TriadMemberBlock from "@/components/blocks/TriadMemberBlock.vue";
import { apiConnection } from "@/services/api-connection";
import { mapState } from "pinia";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useTriadStore } from "@/store/triad/triad";
import { usePlatformStore } from "@/store/platform/platform";
import { absenceManager } from "@/services/absence/absence-manager";
import { notificationManager } from "@/services/utilities/notification-manager";

export default {
  name: "attendanceCertificateListView",
  components: { FontAwesomeIcon, BlockLoader, TriadMemberBlock },
  data() {
    return {
      attendances: [],
      loading: false,
    };
  },
  watch: {
    triad() {
      this.initData();
    },
  },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(usePlatformStore, {
      apprenticeProfileLabel: (store) => store.apprenticeProfileLabel,
    }),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.triad && this.triad.id) {
        this.loading = true;
        apiConnection
          .get("/app/attendance-certificate/list/" + this.triad.id)
          .then((data) => {
            this.loading = false;
            this.attendances = data;
          });
      }
    },

    downLoadCertificate(period) {
      if (this.triad && this.triad.id) {
        this.loading = true;
        absenceManager
          .exportAttendanceCertificate(this.triad.id, period)
          .then((response) => {
            if (response && response.success) {
              notificationManager.showNotification(
                "success",
                this.$t("absence.attendance_certificate_export_confirmed"),
              );
            }
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__heading {
    color: var(--primary-color);
    font-weight: bold;
    display: flex;
    padding: 8px;

    @include up-to-lg {
      display: none;
    }
  }

  &__item {
    display: flex;
    padding: 8px;
    border-top: 1px dashed $light-grey;

    @include up-to-lg {
      padding: 0;
      flex-direction: column;
    }
  }

  &__column {
    @include up-to-lg {
      display: flex;
      padding: 8px;
      &:nth-child(2n + 1) {
        background-color: $lighter-grey;
      }
    }
  }
}
</style>
