import HomeView from "@/views/StudeaManager/HomeView.vue";
import LayoutView from "@/views/StudeaManager/LayoutView.vue";
import { i18n } from "@/i18n";
import StudeaManagerHomeView from "@/views/StudeaManager/StudeaManagerHomeView.vue";
import SkillListView from "@/views/StudeaManager/Skill/SkillListView.vue";
import SkillAddEditView from "@/views/StudeaManager/Skill/SkillAddEditView.vue";
import QuestionnaireListView from "@/views/StudeaManager/Questionnaire/QuestionnaireListView.vue";
import QuestionnaireAddEditView from "@/views/StudeaManager/Questionnaire/QuestionnaireAddEditView.vue";
import AddEditView from "@/views/StudeaManager/AddEditView.vue";
import DashboardView from "@/views/StudeaManager/Dashboard/DashboardView.vue";
import TriadListView from "@/views/StudeaManager/Triad/TriadListView.vue";
import ImportView from "@/views/StudeaManager/ImportView.vue";
import AbsenceView from "@/views/Dashboard/Absence/AbsenceView.vue";
import LessonListView from "@/views/StudeaManager/Lesson/LessonListView.vue";
import SubjectListView from "@/views/StudeaManager/Lesson/SubjectListView.vue";
import AbsenceListView from "@/views/StudeaManager/Absence/AbsenceListView.vue";
import SkillGenerateQuestionnaireView from "@/views/StudeaManager/Skill/SkillGenerateQuestionnaireView.vue";
import WebLinkListView from "@/views/StudeaManager/WebLink/WebLinkListView.vue";
import GradeBookDashboardView from "@/views/StudeaManager/GradeBook/GradeBookDashboardView.vue";
import GlobalLayoutView from "@/views/Global/GlobalLayoutView.vue";
import SkillDashboardView from "@/views/StudeaManager/Skill/SkillDashboardView.vue";
import ContractEditView from "@/views/StudeaManager/Triad/ContractEditView.vue";
import ContractListView from "@/views/StudeaManager/Triad/ContractListView.vue";
import SendUserInfosView from "@/views/StudeaManager/Triad/SendUserInfosView.vue";
import SkillTypeListView from "@/views/StudeaManager/Skill/SkillTypeListView.vue";
import SkillReferencialListView from "@/views/StudeaManager/Skill/SkillReferencialListView.vue";
import ChatView from "@/views/Dashboard/Chat/ChatView.vue";
import DocumentListView from "@/views/StudeaManager/Document/DocumentListView.vue";
import { accessManager } from "@/services/security/access-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import ModerationListView from "@/views/StudeaManager/Message/ModerationListView.vue";
import SkillAddSessionReferentialView from "@/views/StudeaManager/Skill/SkillAddSessionReferentialView.vue";
import MailsListView from "@/views/StudeaManager/Mail/MailsListView.vue";
import MailViewView from "@/views/StudeaManager/Mail/MailViewView.vue";

export default {
  path: "/studea-manager/:trainingCourseId",
  name: "studeaManagerLayout",
  redirect: { name: "studeaManagerHome" },
  component: LayoutView,
  meta: {
    title: i18n.global.t("studea_manager.home.page_title"),
    breadcrumb: true,
    authRequired: true,
  },
  children: [
    {
      path: "config",
      name: "studeaManagerHome",
      component: StudeaManagerHomeView,
      meta: {
        title: i18n.global.t("studea_manager.home.page_title"),
        breadcrumb: false,
      },
    },
    {
      path: "skin",
      name: "studeaManagerSkin",
      component: HomeView,
      meta: {
        title: i18n.global.t("studea_manager.skin.list.title"),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_TRAINING_DESIGN,
        access: accessManager.EDIT,
      },
    },
    {
      path: "triad",
      name: "studeaManagerTriad",
      component: GlobalLayoutView,
      redirect: { name: "studeaManagerTriadList" },
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global.tc("studea_manager.triad.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
      },
      children: [
        {
          path: "",
          name: "studeaManagerTriadList",
          component: TriadListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("studea_manager.triad.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(triad)/add",
          name: "studeaManagerTriadAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.triad.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(triad)/edit/:id",
          name: "studeaManagerTriadEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.triad.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(crew)/add",
          name: "studeaManagerCrewAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.crew.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(crew)/edit/:id",
          name: "studeaManagerCrewEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.crew.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
            access: accessManager.EDIT,
          },
        },
        {
          path: "send-user-infos",
          name: "studeaManagerTriadSendUserInfos",
          component: SendUserInfosView,
          meta: {
            title: i18n.global.t("studea_manager.triad.send_user_infos"),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(contract)/:triadId",
          name: "studeaManagerContract",
          component: GlobalLayoutView,
          redirect: { name: "studeaManagerContractList" },
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("studea_manager.contract.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
          },
          children: [
            {
              path: "list",
              name: "studeaManagerContractList",
              component: ContractListView,
              meta: {
                title: i18n.global.tc("global.list.title", 1, {
                  name: i18n.global
                    .tc("studea_manager.contract.title", 2)
                    .toLowerCase(),
                }),
                breadcrumb: false,
                code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
                access: accessManager.VIEW,
              },
            },
            {
              path: "add",
              name: "studeaManagerContractAdd",
              component: ContractEditView,
              meta: {
                title: i18n.global.tc("global.add.title", 1, {
                  name: i18n.global
                    .tc("studea_manager.contract.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: true,
                code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
                access: accessManager.EDIT,
              },
            },
            {
              path: "edit/:id",
              name: "studeaManagerContractEdit",
              component: ContractEditView,
              meta: {
                title: i18n.global.tc("global.edit.title", 1, {
                  name: i18n.global
                    .tc("studea_manager.contract.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: true,
                code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
                access: accessManager.EDIT,
              },
            },
          ],
        },
        {
          path: ":alias(studea-user)/add",
          name: "studeaManagerUserAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.user.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(triad)/import",
          name: "studeaManagerTriadImport",
          component: ImportView,
          meta: {
            title: i18n.global.tc("global.import.title", 1, {
              name: i18n.global
                .tc("studea_manager.triad.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD_IMPORT,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(triad)/update",
          name: "studeaManagerTriadUpdate",
          component: ImportView,
          meta: {
            title: i18n.global.tc("global.update.title", 1, {
              name: i18n.global
                .tc("studea_manager.triad.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerTriadList",
            code: studeaManagerManager.STUDEA_MANAGER_TRIAD_IMPORT,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "questionnaire",
      name: "studeaManagerQuestionnaire",
      component: GlobalLayoutView,
      redirect: { name: "studeaManagerQuestionnaireList" },
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global
            .tc("studea_manager.questionnaire.title", 2)
            .toLowerCase(),
        }),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_QUESTIONNAIRES,
        generic: "studea_manager",
      },
      children: [
        {
          path: "",
          name: "studeaManagerQuestionnaireList",
          component: QuestionnaireListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("studea_manager.questionnaire.title", 2)
                .toLowerCase(),
            }),
            code: studeaManagerManager.STUDEA_MANAGER_QUESTIONNAIRES,
            access: accessManager.VIEW,
            breadcrumb: false,
          },
        },
        {
          path: "add",
          name: "studeaManagerQuestionnaireAdd",
          component: QuestionnaireAddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.questionnaire.title", 1)
                .toLowerCase(),
            }),
            code: studeaManagerManager.STUDEA_MANAGER_QUESTIONNAIRES,
            access: accessManager.EDIT,
            breadcrumb: true,
          },
        },
        {
          path: "edit/:id",
          name: "studeaManagerQuestionnaireEdit",
          component: QuestionnaireAddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.questionnaire.title", 1)
                .toLowerCase(),
            }),
            code: studeaManagerManager.STUDEA_MANAGER_QUESTIONNAIRES,
            access: accessManager.EDIT,
            breadcrumb: true,
          },
        },
      ],
    },
    {
      path: "skill",
      name: "studeaManagerSkill",
      component: GlobalLayoutView,
      redirect: { name: "studeaManagerSkillConfiguration" },
      meta: {
        title: i18n.global.tc("global.list.title", 2, {
          name: i18n.global.tc("studea_manager.skill.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
        code: "STUDEA_MANAGER_SKILLS",
        generic: "studea_manager",
      },
      children: [
        {
          path: "",
          name: "studeaManagerSkillList",
          component: SkillListView,
          meta: {
            title: i18n.global.tc("global.list.title", 2, {
              name: i18n.global
                .tc("studea_manager.skill.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(skill-session)/edit/:id",
          name: "studeaManagerSkillSessionEdit",

          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 2, {
              name: i18n.global
                .tc("studea_manager.skillSession.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSkillList",
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(skill-session)/add",
          name: "studeaManagerSkillSessionAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 2, {
              name: i18n.global
                .tc("studea_manager.skillSession.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSkillList",
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.EDIT,
          },
        },
        {
          path: "configuration",
          name: "studeaManagerSkillConfiguration",
          component: SkillAddEditView,
          meta: {
            title: i18n.global.t("studea_manager.skill_configuration.title"),
            breadcrumb: true,
            backPath: "studeaManagerSkillList",
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.EDIT,
          },
        },
        {
          path: "referential",
          name: "studeaManagerSkillReferentialConfiguration",
          redirect: { name: "studeaManagerSkillReferentialConfiguration" },
          component: GlobalLayoutView,
          meta: {
            title: i18n.global.t("studea_manager.skill_referential.title"),
            breadcrumb: true,
            backPath: "studeaManagerSkillList",
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.EDIT,
          },
          children: [
            {
              path: "",
              name: "studeaManagerSkillReferentialConfiguration",
              component: SkillReferencialListView,
              meta: {
                title: i18n.global.tc("global.add.title", 2, {
                  name: i18n.global
                    .tc("studea_manager.skillReferential.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: false,
                backPath: "studeaManagerSkillReferentialConfiguration",
                code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
                access: accessManager.EDIT,
              },
            },
            {
              path: ":alias(skill-referential)/add",
              name: "studeaManagerSkillReferentialAdd",
              component: AddEditView,
              meta: {
                title: i18n.global.tc("global.add.title", 2, {
                  name: i18n.global
                    .tc("studea_manager.skillReferential.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: true,
                backPath: "studeaManagerSkillReferentialConfiguration",
                code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
                access: accessManager.EDIT,
              },
            },
            {
              path: ":alias(skill-referential)/edit/:id",
              name: "studeaManagerSkillReferentialEdit",
              component: AddEditView,
              meta: {
                title: i18n.global.tc("global.edit.title", 2, {
                  name: i18n.global
                    .tc("studea_manager.skillReferential.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: true,
                backPath: "studeaManagerSkillReferentialConfiguration",
                code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
                access: accessManager.EDIT,
              },
            },
            {
              path: ":alias(skill)/edit/:id",
              name: "studeaManagerSkillEdit",
              component: AddEditView,
              meta: {
                title: i18n.global.tc("global.edit.title", 2, {
                  name: i18n.global
                    .tc("studea_manager.skill.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: true,
                backPath: "studeaManagerSkillReferentialConfiguration",
                code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
                access: accessManager.EDIT,
              },
            },
            {
              path: ":alias(skill)/add",
              name: "studeaManagerSkillAdd",
              component: AddEditView,
              meta: {
                title: i18n.global.tc("global.add.title", 2, {
                  name: i18n.global
                    .tc("studea_manager.skill.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: true,
                backPath: "studeaManagerSkillReferentialConfiguration",
                code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
                access: accessManager.EDIT,
              },
            },
            {
              path: ":alias(skill-category)/edit/:id",
              name: "studeaManagerSkillCategoryEdit",
              component: AddEditView,
              meta: {
                title: i18n.global.tc("global.edit.title", 2, {
                  name: i18n.global
                    .tc("studea_manager.skillCategory.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: true,
                backPath: "studeaManagerSkillReferentialConfiguration",
                code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
                access: accessManager.EDIT,
              },
            },
            {
              path: ":alias(skill-category)/add",
              name: "studeaManagerSkillCategoryAdd",
              component: AddEditView,
              meta: {
                title: i18n.global.tc("global.add.title", 2, {
                  name: i18n.global
                    .tc("studea_manager.skillCategory.title", 1)
                    .toLowerCase(),
                }),
                breadcrumb: true,
                backPath: "studeaManagerSkillReferentialConfiguration",
                code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
                access: accessManager.EDIT,
              },
            },
            {
              path: ":alias(skill)/import",
              name: "studeaManagerSkillImport",
              component: ImportView,
              meta: {
                title: i18n.global.t("studea_manager.skill_import.title"),
                breadcrumb: true,
                backPath: "studeaManagerSkillReferentialConfiguration",
                code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
                access: accessManager.EDIT,
              },
            },
          ],
        },
        {
          path: "referential/session",
          name: "studeaMananerSkillAddSessionReferential",
          component: SkillAddSessionReferentialView,
          meta: {
            title: i18n.global.t("studea_manager.skillReferential.use"),
            breadcrumb: true,
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.VIEW,
          },
        },
        {
          path: "generate-questionnaire/:currentSessionId",
          name: "studeaManagerSkillGenerateQuestionnaire",
          component: SkillGenerateQuestionnaireView,
          meta: {
            title: i18n.global.t("studea_manager.skill_generate.title"),
            breadcrumb: true,
            backPath: "studeaManagerSkillList",
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.EDIT,
          },
        },

        {
          path: ":alias(skill-type)/list",
          name: "studeaManagerSkillTypeList",
          component: SkillTypeListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("studea_manager.skillType.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSkillList",
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(skill-type)/edit/:id",
          name: "studeaManagerSkillTypeEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.skillType.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSkillTypeList",
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(skill-type)/add",
          name: "studeaManagerSkillTypeAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.skillType.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSkillTypeList",
            code: studeaManagerManager.STUDEA_MANAGER_SKILLS,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "template",
      name: "studeaManagerTemplate",
      component: HomeView,
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global
            .tc("studea_manager.template.title", 2)
            .toLowerCase(),
        }),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_MAIL_TEMPLATES,
        access: accessManager.VIEW,
      },
    },
    {
      path: "gradebook",
      name: "studeaManagerGradebook",
      component: HomeView,
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global
            .tc("studea_manager.gradebook.title", 2)
            .toLowerCase(),
        }),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_NOTE,
        access: accessManager.VIEW,
        generic: "studea_manager",
      },
    },
    {
      path: ":alias(lesson)",
      name: "studeaManagerLesson",
      component: GlobalLayoutView,
      redirect: { name: "studeaManagerLessonList" },
      meta: {
        title: i18n.global.tc("global.list.title", 2, {
          name: i18n.global.tc("studea_manager.lesson.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
        code: "STUDEA_MANAGER_COURSE",
        generic: "studea_manager",
      },
      children: [
        {
          path: "",
          name: "studeaManagerLessonList",
          component: LessonListView,
          meta: {
            title: i18n.global.tc("global.list.title", 2, {
              name: i18n.global
                .tc("studea_manager.lesson.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            code: studeaManagerManager.STUDEA_MANAGER_COURSE,
            access: accessManager.VIEW,
          },
        },
        {
          path: "add",
          name: "studeaManagerLessonAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.lesson.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerLessonList",
            code: studeaManagerManager.STUDEA_MANAGER_COURSE,
            access: accessManager.EDIT,
          },
        },
        {
          path: "edit/:id",
          name: "studeaManagerLessonEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.lesson.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerLessonList",
            code: studeaManagerManager.STUDEA_MANAGER_COURSE,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "subject",
      name: "studeaManagerSubject",
      component: GlobalLayoutView,
      redirect: { name: "studeaManagerSubjectList" },
      meta: {
        title: i18n.global.tc("global.list.title", 2, {
          name: i18n.global.tc("studea_manager.subject.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
      },
      children: [
        {
          path: ":alias(subject)/list",
          name: "studeaManagerSubjectList",
          component: SubjectListView,
          meta: {
            title: i18n.global.tc("global.list.title", 2, {
              name: i18n.global
                .tc("studea_manager.subject.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            backPath: "studeaManagerSubjectList",
            code: studeaManagerManager.STUDEA_MANAGER_COURSE,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(subject)/add",
          name: "studeaManagerSubjectAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 2, {
              name: i18n.global
                .tc("studea_manager.subject.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSubjectList",
            code: studeaManagerManager.STUDEA_MANAGER_COURSE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(subject)/edit/:id",
          name: "studeaManagerSubjectEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 2, {
              name: i18n.global
                .tc("studea_manager.subject.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSubjectList",
            code: studeaManagerManager.STUDEA_MANAGER_COURSE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(unit)/add",
          name: "studeaManagerUnitAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 2, {
              name: i18n.global
                .tc("studea_manager.unit.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSubjectList",
            code: studeaManagerManager.STUDEA_MANAGER_COURSE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(unit)/edit/:id",
          name: "studeaManagerUnitEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 2, {
              name: i18n.global
                .tc("studea_manager.unit.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerSubjectList",
            code: studeaManagerManager.STUDEA_MANAGER_COURSE,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "absence",
      name: "studeaManagerAbsence",
      component: GlobalLayoutView,
      redirect: { name: "studeaManagerAbsenceList" },
      meta: {
        title: i18n.global.t("studea_manager.absence.list.title"),
        breadcrumb: true,
        code: "STUDEA_MANAGER_ABSENCE",
        generic: "studea_manager",
      },
      children: [
        {
          path: "",
          name: "studeaManagerAbsenceList",
          component: AbsenceView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("studea_manager.absence.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(planning)/list",
          name: "studeaManagerPlanningList",
          component: AbsenceListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("studea_manager.planning.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.VIEW,
          },
        },
        {
          path: ":alias(planning)/add",
          name: "studeaManagerPlanningAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.planning.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerAbsenceList",
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(planning)/edit/:id",
          name: "studeaManagerPlanningEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.planning.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerAbsenceList",
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(planning)/import",
          name: "studeaManagerPlanningImport",
          component: ImportView,
          meta: {
            title: i18n.global.tc("global.import.title", 1, {
              name: i18n.global
                .tc("studea_manager.planning.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerAbsenceList",
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(absence)/list",
          name: "studeaManagerAbsenceAbsenceList",
          component: AbsenceListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("studea_manager.absence.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(absence)/add",
          name: "studeaManagerAbsenceAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 2, {
              name: i18n.global
                .tc("studea_manager.absence.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerAbsenceList",
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(absence)/edit/:id",
          name: "studeaManagerAbsenceEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.absence.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerAbsenceList",
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.EDIT,
          },
        },
        {
          path: ":alias(absence)/import",
          name: "studeaManagerAbsenceImport",
          component: ImportView,
          meta: {
            title: i18n.global.tc("global.import.title", 1, {
              name: i18n.global
                .tc("studea_manager.absence.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerAbsenceList",
            code: studeaManagerManager.STUDEA_MANAGER_ABSENCE,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "registration",
      name: "studeaManagerRegistration",
      component: HomeView,
      meta: {
        title: i18n.global.tc("global.list.title", 2, {
          name: i18n.global
            .tc("studea_manager.registration.title", 2)
            .toLowerCase(),
        }),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_REGISTRATION,
        generic: "studea_manager",
        access: accessManager.VIEW,
      },
    },
    {
      path: "notepad",
      name: "studeaManagerNotepad",
      component: HomeView,
      meta: {
        title: i18n.global.tc("global.list.title", 2, {
          name: i18n.global.tc("studea_manager.notepad.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_NOTE,
        access: accessManager.VIEW,
        generic: "studea_manager",
      },
    },
    {
      path: ":alias(document)",
      name: "studeaManagerDocument",
      component: GlobalLayoutView,
      redirect: { name: "studeaManagerDocumentList" },
      meta: {
        title: i18n.global.tc("global.list.title", 1, {
          name: i18n.global
            .tc("studea_manager.document.title", 2)
            .toLowerCase(),
        }),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_DOCUMENTS_UPLOAD,
        access: accessManager.VIEW,
        generic: "studea_manager",
      },
      children: [
        {
          path: "",
          name: "studeaManagerDocumentList",
          component: DocumentListView,
          meta: {
            title: i18n.global.tc("global.list.title", 1, {
              name: i18n.global
                .tc("studea_manager.document.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            code: studeaManagerManager.STUDEA_MANAGER_DOCUMENTS_UPLOAD,
            access: accessManager.VIEW,
          },
        },
        {
          path: "add",
          name: "studeaManagerDocumentAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.document.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            backPath: "studeaManagerDocumentList",
            postNotGeneric: true,
            code: studeaManagerManager.STUDEA_MANAGER_DOCUMENTS_UPLOAD,
            access: accessManager.EDIT,
          },
        },
        {
          path: "edit/:id",
          name: "studeaManagerDocumentEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.document.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            backPath: "studeaManagerDocumentList",
            putNotGeneric: true,
            code: studeaManagerManager.STUDEA_MANAGER_DOCUMENTS_UPLOAD,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: ":alias(web-link)",
      name: "studeaManagerWebLink",
      component: GlobalLayoutView,
      redirect: { name: "studeaManagerWebLinkList" },
      meta: {
        title: i18n.global.tc("global.list.title", 2, {
          name: i18n.global.tc("studea_manager.webLink.title", 2).toLowerCase(),
        }),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_WEBLINKS,
        generic: "studea_manager",
      },
      children: [
        {
          path: "",
          name: "studeaManagerWebLinkList",
          component: WebLinkListView,
          meta: {
            title: i18n.global.tc("global.list.title", 2, {
              name: i18n.global
                .tc("studea_manager.webLink.title", 2)
                .toLowerCase(),
            }),
            breadcrumb: false,
            code: studeaManagerManager.STUDEA_MANAGER_WEBLINKS,
            access: accessManager.VIEW,
          },
        },
        {
          path: "add",
          name: "studeaManagerWebLinkAdd",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.add.title", 1, {
              name: i18n.global
                .tc("studea_manager.webLink.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerWebLinkList",
            code: studeaManagerManager.STUDEA_MANAGER_WEBLINKS,
            access: accessManager.EDIT,
          },
        },
        {
          path: "edit/:id",
          name: "studeaManagerWebLinkEdit",
          component: AddEditView,
          meta: {
            title: i18n.global.tc("global.edit.title", 1, {
              name: i18n.global
                .tc("studea_manager.webLink.title", 1)
                .toLowerCase(),
            }),
            breadcrumb: true,
            backPath: "studeaManagerWebLinkList",
            code: studeaManagerManager.STUDEA_MANAGER_WEBLINKS,
            access: accessManager.EDIT,
          },
        },
      ],
    },
    {
      path: "messaging",
      name: "studeaManagerMessagingList",
      component: GlobalLayoutView,
      meta: {
        title: i18n.global.t("studea_manager.messaging.list.title"),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_MESSAGE_CONTACT,
        access: accessManager.VIEW,
        generic: "studea_manager",
      },
      children: [
        {
          path: "",
          name: "studeaManagerMessaging",
          component: ChatView,
          meta: {
            title: i18n.global.t("studea_manager.messaging.list.title"),
            breadcrumb: true,
            code: studeaManagerManager.STUDEA_MANAGER_MESSAGE_CONTACT,
            access: accessManager.VIEW,
            generic: "studea_manager",
          },
        },
        {
          path: ":id",
          name: "studeaManagerMessagingShow",
          component: ChatView,
          meta: {
            title: i18n.global.t("page.message"),
            breadcrumb: false,
            code: studeaManagerManager.STUDEA_MANAGER_MESSAGE_CONTACT,
            access: accessManager.VIEW,
          },
        },
        {
          path: "new",
          name: "studeaManagerMessagingNew",
          component: ChatView,
          meta: {
            title: i18n.global.t("page.new_message"),
            breadcrumb: true,
            code: studeaManagerManager.STUDEA_MANAGER_MESSAGE_CONTACT,
            access: accessManager.EDIT,
          },
        },
        {
          path: "moderation",
          name: "studeaManagerMessagingModeration",
          component: ModerationListView,
          meta: {
            title: i18n.global.t("chat.moderation"),
            breadcrumb: true,
            code: studeaManagerManager.STUDEA_MANAGER_MESSAGE_MODERATION,
            access: accessManager.EDIT,
          },
        },
      ],
    },

    {
      path: "dashboard",
      name: "studeaManagerDashboard",
      component: DashboardView,
      meta: {
        title: i18n.global.t("studea_manager.dashboard.list.title"),
        breadcrumb: true,
      },
    },
    {
      path: "gradeBook-dashboard",
      name: "studeaManagerGradeBookDashboard",
      component: GradeBookDashboardView,
      meta: {
        title: i18n.global.t("studea_manager.gradeBookDashboard.list.title"),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_DASHBOARD_NOTE,
        access: accessManager.VIEW,
      },
    },
    {
      path: "skill-dashboard",
      name: "studeaManagerSkillDashboard",
      component: SkillDashboardView,
      meta: {
        title: i18n.global.t("studea_manager.skillDashboard.list.title"),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_SKILLS_DASHBOARD,
        access: accessManager.VIEW,
      },
    },
    {
      path: "mail",
      name: "studeaManagerMail",
      redirect: { name: "studeaManagerMailsList" },
      meta: {
        title: i18n.global.t("studea_manager.mail.list.title"),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_MAIL,
        access: accessManager.VIEW,
      },
      children: [
        {
          path: "list",
          name: "studeaManagerMailsList",
          component: MailsListView,
          meta: {
            title: i18n.global.t("studea_manager.mail.list.title"),
            breadcrumb: false,
            code: studeaManagerManager.STUDEA_MANAGER_MAIL,
            access: accessManager.VIEW,
          },
        },
        {
          path: "view/:id",
          name: "studeaManagerMailView",
          component: MailViewView,
          meta: {
            title: i18n.global.t("studea_manager.mail.data"),
            breadcrumb: true,
            code: studeaManagerManager.STUDEA_MANAGER_MAIL,
            access: accessManager.VIEW,
          },
        },
      ],
    },
    {
      path: "history",
      name: "studeaManagerHistory",
      component: HomeView,
      meta: {
        title: i18n.global.t("studea_manager.history.list.title"),
        breadcrumb: true,
        code: studeaManagerManager.STUDEA_MANAGER_HISTORY,
        access: accessManager.VIEW,
      },
    },
  ],
};
