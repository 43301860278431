export default {
  notepad: {
    pinned: "Pinned",
    unpinned: "Unpinned",
    all: "All notes",
    pinned_success: "Note pinned successfully!",
    unpinned_success: "Note unpinned successfully!",
    profiles: "Profiles",
  },
};
