import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import { defineStore } from "pinia";

export const useStudeaManagerStore = defineStore({
  id: "StudeaManager",
  state: () => {
    return {
      menu: [],
      configMenu: [],
      moduleMenu: [],
      pilotageMenu: [],
    };
  },

  actions: {
    fetchStudeaManagerMenu(payload) {
      if (payload.refresh) {
        this.setMenu([]);
      }
      return studeaManagerManager
        .getMenu(payload.trainingCourseId)
        .then((menu) => {
          if (!menu || (menu && !menu.hasError)) {
            this.setMenu(menu.items);
          }
        });
    },

    setMenu(menu) {
      this.menu = menu;
      this.configMenu = [];
      this.moduleMenu = [];
      this.pilotageMenu = [];
      if (menu && typeof menu === "object") {
        Object.values(menu).forEach((subMenu) => {
          if (subMenu.id === "menusetting") {
            Object.values(subMenu.children.items).forEach((item) => {
              this.configMenu.push(item);
            });
          } else if (subMenu.id === "menumodules") {
            Object.values(subMenu.children.items).forEach((item) => {
              this.moduleMenu.push(item);
            });
          } else if (subMenu.id === "menucontrol") {
            Object.values(subMenu.children.items).forEach((item) => {
              //  TODO child.id !== 'tableau-des-notes' && child.id !== 'tableau-de-bord-des-competences' when the dev will be done
              if (
                item.id !== "menugrade_book_dashboard" &&
                item.id !== "menuskill_dashboard"
              )
                this.pilotageMenu.push(item);
            });
          }
        });
      }
    },

    setModuleVisibility(payload) {
      const module = this.moduleMenu.find((item) => item.id === payload.id);
      module.disabled = payload.visible;
    },

    init() {
      this.menu = [];
      this.configMenu = [];
      this.moduleMenu = [];
      this.pilotageMenu = [];
    },
  },
});
