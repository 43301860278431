export default {
  login: "Conexión",
  loginTo: "Conexión a",
  resetPassword: "Restablecer su contraseña",
  magic_link_expired: "El enlace ha caducado",
  magic_link_expired_subtitle:
    "¿Quiere recibir un nuevo enlace por correo electrónico o conectarse con su contraseña?",
  reconnect: "Conectarme",
  send_magic_link: "Volver a enviar enlace",
  magic_link_sent: "Se ha enviado un nuevo enlace por correo electrónico",
  magic_link_error: "No ha podido generarse el nuevo enlace.",
  reconnect_error: "Espacio seguro",
  reconnect_error_message:
    "Introduzca su contraseña para acceder a esta parte del sitio",
  loginMobile:
    "Introduzca su identificador o su correo electrónico para conectarse",
  loading: "Carga en curso...",
  password: "Contraseña",
  reinitialize: "Reiniciar",
  username: "Nombre de usuario",
  organization: "Organismo",
  showPassword: "Ver la contraseña",
  keepMeLoggedIn: "Mantener la conexión",
  forgotPasswordLink: "¿Ha olvidado la contraseña?",
  loginValidation: "Indique sus identificadores para conectarse",
  loginLoginValidation: "Introduzca su identificador o su correo electrónico",
  loginPasswordValidation: "Introduzca su contraseña",
  alertIdentify: "Identificación correcta",
  alertIdentifyError: "Error en la identificación",
  logout: "Desconexión",
  badCredentials: "Identificadores equivocados",
  password_reset_info:
    "Introduzca su identificador o su correo electrónico para restablecer su contraseña",
  reset_password: "¿Ha olvidado la contraseña?",
  reset_password_confirm:
    "Hemos enviado a su buzón de correo un enlace para generar una contraseña nueva",
  new_password_confirm: "Su contraseña se ha actualizado correctamente",
  new_password: "Nueva contraseña",
  not_available: "No disponible",
  accessAccountDenied: "No tiene acceso a la aplicación en esta cuenta",
  legal_notice: "Declaraciones legales",
  personal_data: "Datos personales",
  learner_privacy: "Datos personales : Alumno en prácticas",
  professional_tutor_privacy: "Datos personales : Tutor profesional",
  pedagogic_tutor_privacy: "Datos personales - Organismos de formación",
  contact_us: "Contacto",
  contact_help: "Asistencia",
  terms_and_conditions: "Condiciones de uso",
  password_confirm: "Confirmar su contraseña",
  password_error: "Las dos contraseñas deben ser idénticas",
  login_presentation: "Qué es el cuaderno digital",
  login_in_progress: "Conexión en curso",
  password_not_valid:
    "Introduzca una contraseña de 12 caracteres, que incluya una letra minúscula, una letra mayúscula, una cifra y un carácter especial.",
  "Personal data - Confidentiality": "Datos personales - Confidencialidad",
  "Since May 25, 2018, the entry into force of the General Data Protection Regulation has been amended; regulations on personal data.":
    "El 25 de mayo de 2018 entró en vigor la modificación del Reglamento General de Protección de Datos, el reglamento europeo sobre datos personales.",
  "We invite you to read the information notices below. The origin, purpose and processing carried out on the data collected by Studea depend, ... according to the profile of each user.":
    "Le invitamos a leer la información siguiente. El origen, la finalidad y el procesamiento de los datos recogidos por Studea dependen..., en función del perfil de cada usuario.",
  "We offer 3 notices here, depending on your user profile, we invite you to read the one that corresponds to your case":
    "Le invitamos a leer la política de privacidad correspondiente a su perfil de usuario.",
  "You are a work-study student and you have been registered on the platform by your training organization":
    "Alumno inscrito en la plataforma para su centro formativo",
  "Personal data - Confidentiality (Alternate access)":
    "Datos personales - Confidencialidad (Alumno)",
  "You are an employee of a company and you supervise a work-study program as a Tutor":
    "Empleado de una empresa que supervisa un programa de formación en centros de trabajo como tutor",
  "Personal data - Confidentiality (apprenticeship masters and company tutor)":
    "Datos personales - Confidencialidad (Tutor de empresa)",
  "You are an employee of a training organization, you administratively manage training courses or you supervise young people as an educational tutor":
    "Empleado de un centro formativo responsable de la gestión administrativa de las formaciones o tutor de la escuela.",
  "Personal data - Confidentiality (Employees of training organizations, educational tutor)":
    "Datos personales - Confidencialidad (Empleado de centro formativo o tutor de escuela)",
};
