<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-9">
        <div class="loader-block">
          <div class="panel-body pill-justify-centered tabs">
            <div class="widget">
              <div class="widget-heading">
                <h2 class="textStyle d-flex">
                  <button
                    class="btn-icon me-2"
                    v-tooltip="{ title: $t('back') }"
                    @click="$router.back()"
                  >
                    <FontAwesomeIcon icon="fa-light fa-arrow-left" />
                  </button>
                  {{ $t("studea_manager.document.shared_documents") }}
                </h2>
              </div>
              <div class="widget-content">
                <div
                  class="list"
                  v-if="documents && Object.keys(documents).length"
                >
                  <div class="list__document row -heading">
                    <div class="col-md-5">
                      {{ $t("global.label.name") }}
                    </div>
                    <div class="col-md-3 col-3">
                      {{ $t("global.label.date") }}
                    </div>
                    <div class="col-md-1 col-3"></div>
                  </div>
                  <div
                    v-for="document in documents"
                    :key="document"
                    class="list__document row grip"
                  >
                    <div class="col-md-5 col-12 mb-2 mb-md-0">
                      {{ document.title }}<br />
                      <span class="uploader"
                        >{{ $t("by") }}
                        {{
                          document.uploader ? document.uploader.fullName : "-"
                        }}</span
                      >
                    </div>
                    <div class="col-md-3 col-12 mb-2 mb-md-0">
                      {{ format(document.date) }}
                    </div>
                    <div class="col-md-1 col-4 pe-0">
                      <div class="list__action">
                        <router-link
                          :to="{
                            name: 'studeaManagerSharedDocumentEdit',
                            params: {
                              id: document.id,
                            },
                          }"
                          class="list__button"
                          v-tooltip="{
                            title: $tc('global.edit.title', 1, {
                              name: this.$tc(
                                'studea_manager.document.title',
                                1,
                              ).toLowerCase(),
                            }),
                          }"
                          v-access:[permissions]="accessManager().EDIT"
                        >
                          <FontAwesomeIcon icon="fa-light fa-edit" />
                        </router-link>
                        <a
                          v-access:[permissions]="accessManager().VIEW"
                          v-tooltip="{
                            title: $t('global.download.title', 1, {
                              name: this.$tc(
                                'studea_manager.document.title',
                                1,
                              ).toLocaleLowerCase(),
                            }),
                          }"
                          :href="downloadSrc(document)"
                          class="list__button"
                        >
                          <FontAwesomeIcon icon="fa-light fa-download" />
                        </a>
                        <button
                          class="list__button"
                          v-tooltip="{
                            title: $t('global.delete.title', 1, {
                              name: this.$tc(
                                'studea_manager.document.title',
                                1,
                              ),
                            }),
                          }"
                          v-access:[permissions]="accessManager().DELETE"
                          @click="remove(document.id, 'document')"
                        >
                          <FontAwesomeIcon icon="fa-light fa-trash" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2" v-else>
                  <div class="textStyle fst-italic">
                    {{
                      $tc("global.empty.dashboard", 1, {
                        name: this.$tc(
                          "studea_manager.document/shared.title",
                          1,
                        ).toLowerCase(),
                      }) +
                      " " +
                      $t("studea_manager.currently")
                    }}
                  </div>
                </div>
              </div>
            </div>
            <BlockLoader :loading="loading" />
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="sticky">
          <div id="action-blocks">
            <ActionsBlock
              :actions="actions"
              :collapsable="false"
              collapseId="actions"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionsBlock from "@/components/blocks/ActionsBlock.vue";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { dateFormatter } from "@/services/utilities/date-formatter";
import { accessManager } from "@/services/security/access-manager";
import { fileManager } from "@/services/file/file-manager";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";

export default {
  name: "SharedDocumentListView",
  components: {
    BlockLoader,
    ActionsBlock,
  },
  data() {
    return {
      documents: [],
      loading: false,
      actions: [
        {
          translation: "studea_manager.document.shared_document_add",
          icon: "plus",
          type: "success",
          actionType: "router",
          action: "studeaManagerSharedDocumentAdd",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
      ],
    };
  },
  computed: {
    ...mapState(useUserStore, {
      permissions: (store) => store.permissions,
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    accessManager() {
      return accessManager;
    },
    init(loading = true) {
      if (
        !this.trainingCourseList ||
        !Object.keys(this.trainingCourseList).length
      ) {
        this.fetchTrainingCourseList().then(() => {
          this.setAppLoading(false);
          if (this.trainingCourse) {
            this.setCfaDesign();
          }
        });
      }

      if (this.trainingCourse && Object.keys(this.trainingCourse).length) {
        this.setCfaDesign();
      }

      this.loading = loading;
      studeaManagerManager.list("document/shared").then((res) => {
        this.documents = res;
        this.loading = false;
      });
    },

    format(date) {
      return dateFormatter.format(date, "long");
    },

    remove(id) {
      studeaManagerManager.remove("document", null, id, 1, false).then(() => {
        this.init();
      });
    },
    downloadSrc(document) {
      return fileManager.getDownloadSrc(document.documentFile);
    },

    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseList: "fetchTrainingCourseList",
    }),
    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 300px;
}

.list {
  &__button {
    background: none;
    border: none;
    font-size: 16px;
    position: relative;
    color: var(--primary-color);
  }

  &__button + &__button {
    padding-left: 10px;
    margin-left: 5px;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background: $white;
    }
  }

  &__document {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);

      @include up-to-md {
        display: none;
      }

      @include dark-theme {
        color: $white;
      }
    }

    &:hover {
      background: $lighter-grey;
      @include dark-theme {
        color: $white;
        background-color: $m-color_19;
      }
    }

    &:last-child {
      border: none;
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;

    &.-sortable {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &.-action {
      width: 15%;
    }
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }
}

.uploader {
  color: $m-color_8;
  font-size: 12px;

  @include dark-theme {
    color: $m-color_6;
  }
}
</style>
