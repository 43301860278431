<template>
  <div
    id="questionnaire-modal"
    ref="modal"
    aria-hidden="true"
    aria-labelledby="questionnaire-modal"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="exampleModalLabel" class="modal-title">
            {{ $t("studea_manager.questionnaire.questionnaire_settings") }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          ></button>
        </div>
        <div class="modal-body">
          <FormTabsBlockComponent
            ref="formTabsBlock"
            :key="key"
            :defaultColors="true"
            :removeSubmit="true"
            :tabs="tabs"
            :keepHideValue="true"
            :preserveTab="false"
            :disableFocusAnimation="true"
            @update="update"
            @uploaded="uploaded"
          />
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" type="button" @click="submit">
            {{ $t("studea_manager.questionnaire.confirm") }}
          </button>
          <button
            class="btn btn-danger"
            data-bs-dismiss="modal"
            data-dismiss="modal"
            type="button"
          >
            <i class="flaticon-cancel-12"></i
            >{{ $t("studea_manager.questionnaire.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import FormTabsBlockComponent from "@/components/blocks/FormTabsBlockComponent.vue";
import { Modal, Tab } from "bootstrap";
import { questionnaireManager } from "@/services/questionnaire/questionnaire-manager";
import { useStudeaManagerQuestionnaireStore } from "@/store/studea_manager/studea-manager-questionnaire";
import { formValidator } from "@/services/form/form-validator";
import { tabsManager } from "@/services/utilities/tabs-manager";

export default {
  name: "QuestionnaireConfigComponent",
  components: {
    FormTabsBlockComponent,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
      default: false,
    },
    code: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    config: {
      type: Array,
      required: true,
    },
  },
  watch: {
    opened() {
      const container = this.$refs.modal;
      container.addEventListener("click", this.stop);
      const modal = new Modal(container);
      if (this.opened) {
        if (!Object.keys(this.tabs).length) {
          this.initTabs();
        }
        this.openingDatas = JSON.stringify(this.tabs);
        modal.show();
        setTimeout(() => {
          const tab = document.getElementById("tab-" + this.code + "-tab");
          if (tab) {
            new Tab(tab).show();
          }
          if (this.$refs.formTabsBlock) {
            this.$refs.formTabsBlock.currentTabCode = this.code;
          }
        }, 50);

        this.$emit("opening");
      } else {
        modal.hide();
        this.$emit("closing");
      }
    },
  },
  mounted() {
    this.$refs.modal.addEventListener("hidden.bs.modal", this.hidden);
  },
  data() {
    return {
      tabs: [],
      key: 0,
      invalidTab: {},
      openingDatas: null,
      submitted: false,
    };
  },
  computed: {
    ...mapState(useStudeaManagerQuestionnaireStore, {
      questionnaire: (store) => store.questionnaire,
    }),
  },
  methods: {
    hidden() {
      if (!this.submitted) {
        this.update(JSON.parse(this.openingDatas));
      }
      this.submitted = false;
      this.$emit("hidden");
      const container = this.$refs.modal;
      if (container) {
        container.removeEventListener("click", this.stop);
      }
    },

    submit() {
      let errors = [];
      let invalidTab = {};
      Object.values(this.tabs).forEach(function (tab) {
        if (!errors.length && !tab.hidden) {
          errors = errors.concat(formValidator.validForm(tab.models));
          if (errors.length) {
            invalidTab = tab;
          }
        }
      });

      if (Object.keys(invalidTab).length) {
        tabsManager.displayTab(invalidTab);
      } else {
        this.submitted = true;
        if (this.$refs.modal) {
          const modal = Modal.getOrCreateInstance(this.$refs.modal);
          modal.hide();
        }
        this.$emit("closing");
      }
    },

    stop(e) {
      e.stopPropagation();
    },

    initTabs() {
      this.tabs = this.config;
      Object.values(this.tabs).forEach((tab) => {
        Object.values(tab.models).forEach((model) => {
          if (model.vars.name && model.vars.block_prefixes[1] !== "choice") {
            model.vars.value = this.questionnaire[model.vars.name];
          } else {
            if (
              this.questionnaire[model.vars.name] &&
              model.vars.expanded &&
              model.vars.multiple
            ) {
              if (
                ["triads", "crews", "signedByStudeaManagerProfiles"].includes(
                  model.vars.name,
                )
              ) {
                Object.values(model.vars.choices).forEach((choice) => {
                  if (this.questionnaire[model.vars.name]) {
                    const data = this.questionnaire[model.vars.name].find(
                      (item) => item.id === choice.data.id,
                    );
                    choice.checked = !!data;
                  }
                });
              } else {
                model.vars.choices = this.questionnaire[model.vars.name];
              }
            } else if (
              this.questionnaire[model.vars.name] &&
              typeof this.questionnaire[model.vars.name] === "boolean"
            ) {
              model.vars.value = this.questionnaire[model.vars.name];
            }
          }
        });
      });
      this.key++;
    },

    update(tabs = null) {
      this.tabs = tabs ? tabs : this.tabs;
      Object.values(this.tabs).forEach((tab) => {
        Object.values(tab.models).forEach((model) => {
          if (model.vars.name) {
            this.questionnaire[model.vars.name] = model.vars.value;
          }
        });
        questionnaireManager.updateFields(tab.models);
      });
      this.$emit("update");
    },

    uploaded(data) {
      this.questionnaire["base64Pdf"] = data;
      this.$emit("update");
    },

    ...mapActions(useStudeaManagerQuestionnaireStore, {
      questionnaireUpdate: "questionnaireUpdate",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
