<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="widget">
          <div class="widget-heading position-relative">
            <h1 class="textStyle d-flex">
              <button
                class="btn-icon me-2"
                v-tooltip="{ title: $t('back') }"
                @click="$router.back()"
              >
                <FontAwesomeIcon icon="fa-light fa-arrow-left" />
              </button>
              {{ $t("studea_manager.incident_sheet.list") }}
              {{
                isFiltered
                  ? "(" +
                    (maxResult ? maxResult : Object.keys(data).length) +
                    " résultat" +
                    ((maxResult ? maxResult : Object.keys(data).length) > 1
                      ? "s"
                      : "") +
                    " filtré" +
                    ((maxResult ? maxResult : Object.keys(data).length)
                      ? "s"
                      : "") +
                    ")"
                  : ""
              }}
            </h1>
            <div>
              <MagnifyingGlassComponent
                @openSearch="openSearch"
                @resetSearch="resetSearch"
                :isFiltered="isFiltered"
              />
            </div>
          </div>
          <div class="widget-content">
            <SortTableComponent
              :columns="columns"
              :datas="data"
              :actions="tableActions"
              type="incident_sheet"
              idKey="replyId"
              ref="sortTable"
              @addComment="openCommentForm"
              @closeIncident="closeIncident"
              @deleteIncident="deleteIncident"
              @refresh="refresh"
            />
          </div>
        </div>
        <ModalFormComponent
          :models="models"
          :title="modalTitle"
          :storageKey="sessionStorageKey"
          @submit="addComment"
          @search="submitSearch"
        />
        <BlockLoader :loading="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "pinia";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { incidentSheetManager } from "@/services/incidentSheet/incident-sheet-manager";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import { accessManager } from "@/services/security/access-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";
import { notificationManager } from "@/services/utilities/notification-manager";
import ModalFormComponent from "@/components/form/ModalFormComponent.vue";
import { formManager } from "@/services/form/form-manager";
import MagnifyingGlassComponent from "@/components/utilities/MagnifyingGlassComponent.vue";

export default {
  name: "IncidentSheetList",
  components: {
    MagnifyingGlassComponent,
    ModalFormComponent,
    BlockLoader,
    SortTableComponent,
  },
  data() {
    return {
      data: [],
      columns: [],
      loading: false,
      listParams: [],
      isFiltered: false,
      sessionStorageKey: "studea-studeaManager-incidentSheet",
      maxResult: 0,
      modalType: null,
      tableActions: [
        {
          translation: "studea_manager.incident_sheet.add_comment",
          icon: "comment",
          type: "primary",
          action: "addComment",
          cfaStyle: true,
          access: accessManager.EDIT,
        },
        {
          translation: "studea_manager.incident_sheet.open",
          secondaryTranslation: "studea_manager.incident_sheet.close",
          icon: "lock",
          secondaryIcon: "check",
          type: "warning",
          secondaryType: "success",
          switchCondition: 1,
          referenceIndex: "incidentStatus",
          action: "closeIncident",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
        {
          translation: "studea_manager.incident_sheet.delete",
          icon: "trash",
          type: "danger",
          action: "deleteIncident",
          cfaStyle: false,
          access: accessManager.EDIT,
        },
      ],
      models: [],
      modalTitle: "",
      currentReply: null,
    };
  },
  computed: {},
  mounted() {
    this.setAppLoading(false);
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.setParams();
      this.getIncidentSheets();
    },

    setKeyword() {
      if (this.$route.query.search) {
        this.keyword = this.$route.query.search;
      } else if (
        window.sessionStorage.getItem(this.sessionStorageKey + "-keywords")
      ) {
        this.keyword = window.sessionStorage.getItem(
          this.sessionStorageKey + "-keywords",
        );
      }
      if (this.keyword) {
        this.listParams["keywords"] = this.keyword;
      }
    },

    getIncidentSheets() {
      if (window.sessionStorage.getItem(this.sessionStorageKey)) {
        this.listParams["search"] = JSON.parse(
          window.sessionStorage.getItem(this.sessionStorageKey),
        );
        this.isFiltered = true;
      }
      if (this.isFiltered) {
        this.listParams["search"] = formManager.getQuery(
          this.listParams["search"],
        );
      }
      this.setKeyword();
      incidentSheetManager
        .getIncidentSheetListForStudeaManager(this.listParams)
        .then((response) => {
          if (response) {
            this.data = response.data;
            this.columns = sortableTableManager.getEntriesColumns(
              response.columns,
              this.columns,
            );
            this.maxResult = response.count;
          }
          this.loading = false;
        });
    },

    setParams() {
      if (!this.listParams || !Object.keys(this.listParams).length) {
        this.listParams = {
          search: "",
          sortByField: 0,
          sortOrder: "ASC",
          limit: 10,
          offset: 0,
        };
      }
    },

    refresh(params) {
      this.listParams = params;
      window.sessionStorage.setItem(
        this.sessionStorageKey + "-keywords",
        this.listParams.keywords,
      );
      this.keyword = this.listParams.keywords;
      this.init();
    },

    resetSearch() {
      this.listParams = [];
      this.$refs.sortTable.activePage = 1;
      this.data = [];
      this.columns = [];
      this.isFiltered = false;
      window.sessionStorage.removeItem(this.sessionStorageKey);
      window.sessionStorage.removeItem(this.sessionStorageKey + "-keywords");
      this.keyword = "";
      this.init();
    },

    openCommentForm(payload) {
      this.loading = true;
      this.currentReply = null;
      this.modalType = "comment";
      this.modalTitle = this.$t("studea_manager.incident_sheet.add_comment");
      this.models = [];
      incidentSheetManager
        .getIncidentSheetform("comment", payload.id)
        .then((response) => {
          this.models = response;
          this.loading = false;
          this.currentReply = payload.id;
        });
    },

    submitSearch(params) {
      if (this.modalType === "search") {
        this.loading = true;
        this.$refs.sortTable.activePage = 1;
        this.listParams["offset"] = 0;
        this.listParams["search"] = params;
        this.getIncidentSheets();
        this.isFiltered = true;
        this.models = [];
      }
    },

    addComment(payload) {
      if (this.modalType === "comment") {
        incidentSheetManager
          .postCommennt(formManager.processForm(payload), this.currentReply)
          .then((response) => {
            if (response.success) {
              this.init();
              this.modalType = null;
            }
          });
      }
    },

    closeIncident(payload) {
      const notificationTitle =
        payload.row.incidentStatus ===
        incidentSheetManager.INCIDENT_ENCLOSE_STATUS
          ? "open_confirmation"
          : "close_confirmation";

      notificationManager
        .showAlert(
          "question",
          this.$t("studea_manager.incident_sheet." + notificationTitle),
          null,
          true,
          true,
        )
        .then((response) => {
          if (response.isConfirmed) {
            incidentSheetManager.update(payload.id).then(() => {
              this.init();
            });
          }
        });
    },

    deleteIncident(payload) {
      notificationManager
        .showAlert(
          "warning",
          this.$t("studea_manager.incident_sheet.delete_confirmation"),
          null,
          true,
          true,
        )
        .then((response) => {
          if (response.isConfirmed) {
            incidentSheetManager.delete(payload.id).then(() => {
              this.init();
            });
          }
        });
    },

    openSearch() {
      this.modalType = "search";
      this.modalTitle = this.$t("studea_manager.incident_sheet.search");
      this.loading = true;
      incidentSheetManager.getIncidentSheetform("search").then((response) => {
        if (response) {
          this.models = response;
          this.loading = false;
        }
      });
    },

    ...mapActions(useTrainingCourseStore, ["fetchTrainingCourseList"]),
    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>
<style lang="scss" scoped></style>
