<template>
  <div class="">
    <div class="error404 text-center">
      <div class="container-fluid error-content">
        <div class="logo">
          <img
            alt="logo"
            class="navbar-logo"
            :src="'/assets/images/logo_studea.png'"
          />
        </div>
        <FontAwesomeIcon icon="fa-light fa-triangle-person-digging" />
        <h2>
          Votre plateforme Studea<br />
          n'est pas encore configurée
        </h2>
        <RouterLink class="btn blockStyle btn-success mt-lg-5 mt-0" to="/login"
          >{{ $t("error.reload_page") }}
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useMainStore } from "@/store/main/main";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "PlatformNotFoundView",
  components: { FontAwesomeIcon },
  watch: {
    loading() {
      if (this.loading) {
        this.setAppLoading(false);
      }
    },
  },
  computed: {
    ...mapState(useMainStore, {
      loading: (store) => store.appLoading,
      maintenance: (store) => store.maintenance,
    }),
  },
  mounted() {
    if (this.loading) {
      this.setAppLoading(false);
    }
  },
  methods: {
    ...mapActions(useMainStore, ["setAppLoading"]),
  },
};
</script>
<style lang="scss" scoped>
.error404 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23e6e6e6'/%3E%3Cstop offset='1' stop-color='%23eaf1ff'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%234361ee' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%234361ee' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.5'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
  background-color: #fff;
  background-position: center;
  height: 100vh;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include mobile {
    padding: 0 15px;
  }

  .logo {
    margin-bottom: 40px;
    max-width: 100%;

    img {
      width: 100%;
    }
  }

  > .error-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: 100%;

    svg {
      width: 120px;
      height: auto;
      color: $red;

      @include up-to-lg {
        width: 80px;
      }
    }
  }

  h2 {
    height: auto;
    color: $blue;
    font-size: 30px;
    margin-top: 30px;

    @include up-to-lg {
      font-size: 20px;
      margin-bottom: 30px;
    }
  }

  Q .mini-text {
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 0;
    color: $primary;
    @include mobile {
      font-size: 20px;
    }
  }

  .error-number {
    font-size: 170px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 15px;
    text-shadow: 0 5px 4px rgba(31, 45, 61, 0.1019607843);

    @include mobile {
      font-size: 100px;
    }
  }

  .error-text {
    font-size: 18px;
    color: $dark;
    font-weight: 600;

    @include mobile {
      font-size: 12px;
    }
  }
}
</style>
