export default {
  studea_manager: {
    confirm_duplication: "Are you sure you want to duplicate this data?",
    data_duplicated_success: "Data duplicated successfully",
    delete_session_confirmation: "Are you sure you want to delete this period?",
    delete_referential_confirmation:
      "Are you sure you want to delete this reference documentation?",
    navigation: {
      general: "General",
      modules: "Modules",
      pilotage: "Steering",
    },
    triadUser: {
      title: "secondary user",
    },
    moderation: {
      title: ({ plural }) => plural(["Flag", "Flags"]),
      censor: "Block",
      restore: "Cancel block",
      show: "View",
      show_censor: "View blocked messages",
      show_message: "View messages for moderation",
      file: "File",
      modal_title: "Conversation",
      alert_title: "Block a message",
      alert_sub_title:
        "Would you like to block or cancel the flagging on this message?",
      censor_success: "Message blocked successfully!",
    },
    home: {
      title: ({ plural }) =>
        plural(["Configuration of: ", "Configuration of: "]),
      page_title: "Configuration",
    },
    questionnaire: {
      title: ({ plural }) => plural(["Questionnaire", "Questionnaires"]),
      list: {
        title: "Advanced configuration",
      },
      questionnaire_already_signed:
        "Please note, the questionnaire has already been signed!",
      start_date: ({ named }) => `Access date ${named("profile")} - for`,
      end_date: "to",

      finish: {
        title: "Finish configuration",
      },
      empty_title:
        "To get started, please add a title, a question or a response cluster!",
      add_question: "Add a question",
      add_title: "Add a title",
      add_question_cluster: "Add a cluster of questions",
      required: "Mandatory",
      question_settings: "Question settings",
      question_access: "Access settings",
      cluster_settings: "Cluster settings",
      remove_question: "Delete question",
      remove_cluster: "Delete cluster",
      remove_title: "Delete title",
      duplicate_cluster: "Duplicate cluster",
      duplicate_question: "Duplicate question",
      tip: "Tooltip",
      display_mode: "Response cluster",
      save: "Save",
      save_success: "Questionnaire saved successfully!",
      save_error: "Error when saving!",
      missing_error:
        "Please note that some access permissions or question titles are missing!",
      informations: "Information",
      deadlines_reminders: "Due dates & reminders",
      advanced_parameter: "Advanced settings",
      company_visit: "Company visit",
      settings: "Settings",
      summary: "Summary",
      add_column: "Add a column",
      add_row: "Add row",
      row_settings: "Who needs to complete this?",
      skill_settings: "Manage related skills",
      access_missing: "Access permissions missing for this question",
      title_missing: "Please enter a title for this question",
      duplicate_row: "Duplicate line",
      remove_row: "Delete line",
      remove_column: "Delete column",
      empty_line: "Untitled line",
      add_skill: "Add this skill",
      skill_category_name: "Category",
      skill_name: "Surname",
      coefficient: "Coefficient",
      empty_column: "Column title",
      remove_field_skill: "Delete linked skill",
      field_skill_used: "This skill is already linked to this question",
      remove_field_skill_sub:
        "Are you sure you want to remove the link between this skill and the question?",
      untitled_column: "Untitled column",
      remove_row_warning: "Are you sure you want to delete this line?",
      remove_column_warning: "Are you sure you want to delete this column?",
      untitled_questionnaire: "Untitled form",
      placeholder_description: "Add your description",
      access_profil: "Profiles matching the question",
      field_skill: "Skills relating to the question",
      search_skill: "Search for a skill",
      opened_question_access: "Access to open questions",
      opened_questions: "Open questions",
      opened_questions_type: "Type of open questions",
      grid_title: "Cluster title",
      comments_enabled: "Comments enabled",
      comments_label: "Comment title",
      comments_type: "Type of comment fields",
      one_line: "Short response",
      multi_line: "Text area",
      maximal_mark: "Maximum grade",
      display_mark: "Use notes",
      display_radar: "Display radar",
      question_title: "Question title",
      infobulle_content: "Popup content",
      question_comment: "Comments",
      field_type_choose: "Choose a type",
      short_answer: "Short response",
      long_answer: "Long response",
      textarea: "Text area",
      number: "Number",
      dropdown_list: "Dropdown list",
      unique_choice: "Single answer",
      multiple_choices: "Multiple choice",
      file: "File",
      date: "Date",
      question: ({ plural }) => plural(["question", "questions"]),
      date_time: "Date and time",
      remove_question_warning: "Are you sure you want to delete this question?",
      option: "Option",
      untitled_cluster: "Untitled cluster",
      untitled_title: "Title",
      title_label: "Title",
      remove_cluster_warning: "Are you sure you want to delete this cluster?",
      value: "Value",
      choose_option: "Choose an option",
      questionnaire_settings: "Questionnaire settings",
      confirm: "Confirm",
      cancel: "Cancel",
      rights: "Rights",
      notes_summary: "Notes and summary",
      option_placeholder: "Option",
      value_placeholder: "Note",
      comment_placeholder: "Add a comment",
      option_settings: "Options configuration",
      columns: "Columns",
      active: "Active",
    },
    category: {
      title: ({ plural }) => plural(["Category", "Categories"]),
      add: "Add a category",
    },
    webLink: {
      title: ({ plural }) => plural(["Useful link", "Useful links"]),
    },
    trainingCourse: {
      title: ({ plural }) => plural(["Year group", "Year groups"]),
    },
    skill: {
      title: ({ plural }) => plural(["Skill", "Skills"]),
      list: {
        title: "Configuration of skills",
        empty:
          "There are no skills associated with this training course yet. Please create a new category to begin",
        empty_only: "No skills entered",
      },
      actions: {
        add: "Add a skill",
        module_actions: "Configuration",
        skill_actions: "Manage skills",
        session_actions: "Manage periods",
        import_export_actions: "Import/Export",
        reminder_actions: "Reminders",
      },
      name: "Surname",
      level: "Level required",
      default: {
        not_acquired: "Not acquired",
        acquired: "Acquired",
        expert: "Expert",
        being_acquired: "In process of being acquired",
        insufficient_mastery: "Insufficient understanding",
        partial_mastery: "Partial understanding",
        average_mastery: "Average understanding",
        good_mastery: "Good understanding",
        excellent_mastery: "Excellent understanding",
      },
    },
    skill_configuration: {
      title: "Module configuration",
      success: "Configuration updated successfully!",
      use_admin: "Use the admin configuration?",
      warning:
        "Please note! Please complete the skills module configuration to enter reference documentation",
    },
    skill_referential: {
      title: "Skills reference documentation management",
      success: "Reference documentation updated successfully!",
      warning:
        "Please note! Please complete the skills module configuration to enter reference documentation",
      empty_add:
        "There is currently no reference documentation. Please click on ‘+’ to add some",
    },
    skill_generate: {
      title: "Generate a questionnaire based on skills",
      success: "Questionnaire generated successfully!",
    },
    skill_remove: {
      title: "Delete reference documentation (without questionnaires)",
      sub_title:
        "Are you sure you want to delete this reference documentation?",
      success: "Reference documentation deleted successfully!",
    },
    skill_import: {
      title: "Import your skills",
      success: "Skills imported successfully!",
    },
    skill_import_france_competence: {
      title: "Import France Skills reference documentation",
      confirm:
        "Are you sure you want to upload the France Skills reference documentation to this training course?",
      success: "Reference documentation imported successfully!",
    },
    skill_export: {
      title: "Export skills",
      success: "Skills exported successfully!",
    },
    skill_export_taught: {
      title: "Export subjects",
      success: "Subjects exported successfully!",
    },
    skillSession: {
      gestion: "Skills periods management",
      list: {
        title: "Configuration of periods",
        empty: "There are currently no periods. Please click on ‘+’ to add one",
      },
      title: ({ plural }) => plural(["Period", "Periods"]),
    },
    skillReferential: {
      title: ({ plural }) =>
        plural(["Reference material", "Reference documentation"]),
      use: "Use reference documentation",
    },
    skillCategory: {
      title: ({ plural }) => plural(["Category", "Categories"]),
    },
    skin: {
      title: ({ plural }) => plural(["Layout", "Layouts"]),
      list: {
        title: "Layout",
      },
    },
    gradebook: {
      title: ({ plural }) => plural(["Gradebook", "Gradebooks"]),
    },
    triad: {
      title: ({ plural }) =>
        plural(["Three-party group", "Three-party groups"]),
      list: {
        name: "Surname",
        apprentice_name: "Apprentice name",
        tutor_name: "Name of the teaching tutor",
        map_name: "Name of the company mentor",
        gestionnary_name: "Administrator name",
      },
      tutor: {
        title: ({ plural }) => plural(["Teaching tutor", "Teaching tutors"]),
      },
      map: {
        title: ({ plural }) => plural(["Company mentor", "Company mentors"]),
      },
      questionnaire_triad_already_signed:
        "Note that the signatures of \nquestionnaires are attached \nto this three-party group.",
      usurp: {
        title: "Log in as",
      },
      login: {
        title: "Manage username notifications",
      },
      delete_all: {
        tutor: "Delete all teaching tutors",
        map: "Delete all company mentors",
      },
      delete: {
        tutor: "Delete this teaching tutor",
        map: "Delete this company mentor",
        legal_responsible: "Delete this legal guardian",
      },
      delete_member: {
        title: "Member of three-party group",
      },
      delete_all_tutor: {
        title: "all teaching tutors",
      },
      delete_all_map: {
        title: "all company mentors",
      },
      edit: {
        legal_responsible: "Edit legal guardian",
        tutor: "Amend teaching tutor",
      },
      contract: {
        title: "Contract management",
      },
      complete: {
        title: "Add a member to the three-party group",
      },
      actions: {
        title: "Actions",
      },
      add_test: "test three-party group",
      add_test_success: "Test three-party group added successfully!",
      send_user_infos: "Manage username notifications",
      send_welcome_email: "Send welcome e-mail with log-in details",
      send_welcome_email_success: "Welcome e-mail(s) sent successfully!",
      reset_password: "Reset password and resend log-in details",
      reset_password_success: "Password reset e-mail(s) sent successfully!",
      send_user_infos_message:
        "Tick users and click on the type of e-mail you wish to send to them",
      add_secondary_user: "Add a secondary user to this three party group",
      add_secondary_user_success: "Secondary user added successfully",
      remove_secondary_user: "Secondary user deleted successfully",
    },
    studeaUser: {
      title: ({ plural }) => plural(["User", "Users"]),
    },
    user: {
      title: ({ plural }) => plural(["User", "Users"]),
    },
    crew: {
      title: ({ plural }) => plural(["Group", "Groups"]),
      cannot_remove: "You cannot delete a group containing three-party groups",
    },
    contract: {
      title: ({ plural }) => plural(["Contract", "Contracts"]),
      empty: "This three-party group does not have any contracts",
      dates: "Contract dates",
      company: "Company",
      duration: "Duration",
      address: "Address",
      map: "Company tutor",
      manager: "Manager",
      contrat_type: "Type of contract",
      rupture_date: "Date of termination",
      contract_rupture_date: "Contract terminated on: ",
      rupture_reason: "Reason for termination",
      months: "month",
      also_tutor: "You are a tutor for this three-party group",
      incident_sheet: "An incident sheet is pending for this three-party group",
    },
    template: {
      title: ({ plural }) => plural(["Template", "Templates"]),
    },
    lesson: {
      title: ({ plural }) => plural(["Lessons", "Lessons"]),
    },
    skillType: {
      title: ({ plural }) =>
        plural(["Type of skill", "Types of skills", "Type of skills"]),
    },
    subject: {
      title: ({ plural }) => plural(["Subject", "Subjects"]),
    },
    unit: {
      title: ({ plural }) => plural(["Unit", "Units"]),
    },
    planning: {
      title: ({ plural }) => plural(["Event", "Events"]),
      removeSelected: {
        title: "Delete selected events",
        sub_title: "Are you sure you want to delete these events?",
        sucess: "Events deleted successfully!",
      },
    },
    trainingCourseModuleTriad: {
      title: ({ plural }) => plural(["Module", "Modules"]),
    },
    absence: {
      title: ({ plural }) => plural(["Absence", "Absences"]),
      list: {
        title: "Attendance",
      },
      config: {
        title: "Configuration",
        success: "Configuration edited successfully!",
      },
      export: {
        full_list: "Full list of absences",
        apprentice_list: "List of absences by ",
        excel: "(excel)",
        pdf: "(pdf)",
        state: "Absences status (schedule)",
      },
    },
    module: {
      active: {
        success: "Module activated successfully!",
      },
      unactive: {
        success: "Module deactivated successfully!",
      },
    },
    registration: {
      title: ({ plural }) => plural(["Registration", "Registrations"]),
      list: {
        title: "Registrations",
      },
    },
    notepad: {
      title: ({ plural }) => plural(["Notepad", "Notepads"]),
    },
    note: {
      title: ({ plural }) => plural(["Note", "Notes"]),
    },
    document: {
      title: ({ plural }) => plural(["Document", "Documents"]),
      pedagogic_documents: "Teaching documentation",
      general_documents: "General documents",
      shared_documents: "Shared documents",
      shared_document_add: "Add a shared document",
      shared_document_edit: "Edit a shared document",
      shared_document_list: "Shared documents",
      shared_document_info:
        "This document has been shared. To edit it, please \n go to the list of shared documents",
    },
    "document/shared": {
      title: ({ plural }) => plural(["Shared document", "Shared documents"]),
    },
    incident_sheet: {
      title: ({ plural }) => plural("Incident sheet", "Incident sheets"),
      list: "Incident sheets",
      add_comment: "Add a comment",
      close: "Close",
      close_confirmation: "Are you sure you want to close this incident sheet?",
      open: "Open",
      open_confirmation: "Are you sure you want to reopen this incident sheet?",
      delete: "Delete",
      delete_confirmation:
        "Are you sure you want to delete this incident sheet?",
      in_progress: "In progress",
      closed: "Closed",
      search: "Search for an incident sheet",
    },
    currently: "current",
    documentExchange: {
      list: {
        title: "Document exchange",
      },
    },
    messaging: {
      list: {
        title: "Inbox",
      },
    },
    dashboard: {
      list: {
        title: "Dashboard",
      },
      signature_cancel_confirmation:
        "Are you sure you want to cancel the signature?",
      filter: "Filter",
      delete_filters: "Remove filters",
      questionnaire: {
        unlocked_confirmation: "Would you like to unlock this questionnaire?",
        locked_confirmation: "Would you like to lock this questionnaire?",
        unlocked_all_confirmation:
          "Would you like to unlock all the questionnaires in this column?",
        unlock_all_questionnaires: "Unlock all questionnaires in this column",
        unlock_questionnaire: "Unlock this questionnaire",
        opening_date_on: "Opening date on",
        lock_questionnaire: "Lock this questionnaire",
        cancel_signature: "Cancel this signature",
        send_email_reminder: "E-mail reminder",
        send_email_reminder_confirmation:
          "Are you sure you want to send an e-mail reminder?",
        send_email_reminder_success: "E-mail sent successfully",
        success_all_unlock: "Questionnaires unlocked successfully !",
        success_unlock: "Questionnaire unlocked successfully!",
        success_lock: "Questionnaire locked successfully!",
        success_cancel_signature: "Signature cancelled successfully!",
      },
      mail_relance: "E-mail reminders",
      email_reminder: "E-mail reminder",
      export: {
        title: "Export",
        all_promotion_replies: "Export all responses from the year group",
        visit_date: "Export company visit dates",
        dashboard_excel: "Export dashboard in Excel format",
        questionnaires_pdf: "Export questionnaires",
      },
      roles: {
        alternate: "Work-study apprentice",
        educational_tutor: "Teaching tutor",
        company_tutor: "Company mentor",
        lea_manager: "Digital booklet supervisor",
      },
    },
    gradeBookDashboard: {
      list: {
        title: "Notepad",
      },
      export_dashboard_excel: "Export notes in Excel format",
    },
    skillDashboard: {
      list: {
        title: "Skills dashboard",
      },
      export_dashboard_excel: "Export skills in Excel format",
      skill: {
        acquired: "Acquired",
        in_progress: "In progress",
        not_acquired: "Not acquired",
      },
    },
    export: {
      list: {
        title: "Exports",
      },
    },
    mail: {
      list: {
        title: "E-mail follow-up",
      },
    },
    history: {
      list: {
        title: "Log-in history",
      },
    },
    assiduite: "Attendance management",
    subscriptions_dashboard: "Registrations dashboard",
    import: {
      modele: "File template download",
      triad_new_file:
        "Please note - a new template came online on 18/09/2023.\n" +
        "          Please download this updated version for use to populate your three-party groups.",
      triad_update:
        "Please note. Only the missing members of a three-party group will be updated. No existing user data (last name, first name or e-mail address) will be updated.",
      thanks:
        "Please do not modify and/or delete any of the columns in the template",
    },
    download_file_here: "Download the file HERE",
    session: {
      from: "From",
      to: "to",
    },
    user_never_connected: "This user has never logged in",
    welcome_mail_send: ({ named }) =>
      `Welcome e-mail sent ${named("number")} times`,
    reset_password_mail_send: ({ named }) =>
      `Reset e-mail sent ${named("number")} times`,
    see_last_mail_content: "View the content of the last e-mail sent",
    duplication: "Duplicated data",
    statistic: {
      title: ({ plural }) => plural(["Statistics", "Statistics"]),
      shared_documents: "Incomplete three-party groups",
    },
  },
  add_session: "Add a period",
};
