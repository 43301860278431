<template>
  <div
    class="position-relative d-flex align-items-center justify-content-center"
  >
    <div
      class="spinner-border text-primary align-self-center loader me-3"
    ></div>
    <span class="textStyle fw-bold">{{ $t("login_in_progress") }}</span>
  </div>
</template>

<script>
import { loginManager } from "@/services/security/login-manager";
import { mapActions, mapState } from "pinia";
import { useUserStore } from "@/store/user/user";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { useMainStore } from "@/store/main/main";
import { platformManager } from "@/services/platform/platform-manager";
import { platformStore } from "@/main";
import { usePlatformStore } from "@/store/platform/platform";

export default {
  name: "SSOLoginView",
  components: {},
  data() {
    return {
      loading: true,
      token: null,
      refreshToken: null,
    };
  },
  computed: {
    ...mapState(useTrainingCourseStore, {
      trainingCourses: (store) => store.trainingCourseList,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
    }),
  },
  mounted() {
    window.localStorage.removeItem("studea-oldTriad");
    window.localStorage.removeItem("studea-oldTrainingCourse");
    this.setNeedUpdate(true);
    if (!loginManager.getApiPath() && !loginManager.apiPath) {
      platformManager.loadPlatform().then((platform) => {
        if (platform.hostname && platform.name) {
          loginManager.setApiPath(platform);
          platformStore.setPlatformName(platform.name);
          this.init();
        }
      });
    } else {
      this.init();
    }
  },
  methods: {
    init() {
      this.token = this.$route.query.token;
      this.refreshToken = this.$route.query.refreshToken;
      this.tokenExpirationDate = this.$route.query.expires;

      window.sessionStorage.removeItem("studea-token");
      window.sessionStorage.removeItem("studea-refreshToken");
      window.sessionStorage.removeItem("studea-tokenExpirationDate");
      window.localStorage.removeItem("studea-returnPathName");
      this.initStore();

      const data = {
        token: this.token,
        refresh_token: this.refreshToken,
        expires: this.tokenExpirationDate,
      };

      loginManager.setToken(data, loginManager.getApiPath());

      this.setAppLoading(true);
      this.fetchUserInfos().then(() => {
        window.localStorage.removeItem("studea-oldTriad");
        window.localStorage.removeItem("studea-oldTrainingCourse");
        loginManager.redirect();
      });
    },

    ...mapActions(useMainStore, {
      setAppLoading: "setAppLoading",
      initStore: "initStore",
    }),
    ...mapActions(useUserStore, { fetchUserInfos: "fetchUserInfos" }),
    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseList: "fetchTrainingCourseList",
    }),
    ...mapActions(usePlatformStore, {
      setNeedUpdate: "setNeedUpdate",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
