<template>
  <button
    class="btn btn-primary blockStyle"
    type="submit"
    @click="submit($event)"
    :disabled="loading"
  >
    <span
      class="spinner-border text-white align-self-center loader"
      v-if="loading"
    ></span>
    <span v-if="!loading">{{ text ?? $t("login") }}</span>
    <span v-else>{{ $t("loading") }}</span>
  </button>
</template>

<script>
export default {
  name: "SubmitWithLoaderComponent",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
  },
  emits: ["submit"],
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
  border-width: 0.15rem;
  margin-right: 15px;
}
</style>
