<template>
  <router-view></router-view>
  <MobileMenuComponent />
</template>

<script>
import MobileMenuComponent from "@/components/MobileMenuComponent.vue";
import { mapActions, mapState } from "pinia";
import { colorManager } from "@/services/platform/color-manager";
import { trainingCourseManager } from "@/services/training-course/training-course-manager";
import { userManager } from "@/services/security/user-manager";
import { useTriadStore } from "@/store/triad/triad";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { usePlatformStore } from "@/store/platform/platform";
import { useUserStore } from "@/store/user/user";
import { useMainStore } from "@/store/main/main";
import { apiConnection } from "@/services/api-connection";

export default {
  name: "DashboardLayoutView",
  components: { MobileMenuComponent },
  computed: {
    ...mapState(useTriadStore, {
      triad: (store) => store.triad,
    }),
    ...mapState(useTrainingCourseStore, {
      trainingCourse: (store) => store.trainingCourse,
      trainingCourseList: (store) => store.trainingCourseList,
    }),
    ...mapState(usePlatformStore, {
      questionnaireConfig: (store) => store.questionnaireConfig,
      design: (store) => store.design,
      needUpdate: (store) => store.needUpdate,
    }),
    ...mapState(useUserStore, {
      user: (store) => store.user,
      userProfile: (store) => store.userProfile,
    }),
  },
  watch: {
    userProfile() {
      this.setUserProfileName(userManager.getProfileKey(this.userProfile));
    },
    $route() {
      if (this.isModulePageNeedTriadReload()) {
        this.loadTriad();
      }
    },
  },
  mounted() {
    this.init();
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  updated() {
    this.setCfaDesign();
    if (this.needUpdate) {
      this.getConfig();
    }
  },
  methods: {
    init() {
      if (!this.triad || !Object.keys(this.triad).length) {
        this.loadTriad();
      } else if (this.isModulePageNeedTriadReload()) {
        this.loadTriad();
      }
      this.setCfaDesign();
    },

    loadTrainingCourse() {
      if (!this.trainingCourse || !Object.keys(this.trainingCourse).length) {
        this.fetchTrainingCourse(this.triad.trainingCourse.id, true).then(
          () => {
            this.setCfaDesign();
          },
        );
      }
    },

    loadTriad() {
      let triadId = localStorage.getItem("studea-oldTriad");
      if (triadId) {
        this.changeTriad({
          triadId: triadId,
          disableLoading: true,
        }).then(() => {
          this.checkUserProfile();
          if (!apiConnection.getMagicLinkToken()) {
            this.loadTrainingCourse();
          }
        });
      } else {
        this.$router.push({ name: "TrainingCourseList" });
      }
    },

    isModulePageNeedTriadReload() {
      const modulePagesNeedReloadTriad = [
        "MODULE_STUDEA_SKILL",
        "MODULE_STUDEA_NOTEPAD",
        "MODULE_STUDEA_COURSE",
        "MODULE_STUDEA_QUESTIONNAIRE",
        "MODULE_STUDEA_WEBLINKS",
        "MODULE_STUDEA_NOTE",
        "MODULE_STUDEA_DOCUMENT",
        "Dashboard",
      ];
      return this.$route.matched.find((item) =>
        modulePagesNeedReloadTriad.includes(item.name),
      );
    },

    isTrainingCourseList() {
      return this.$route.path.includes("training-course-list");
    },

    setColor() {
      if (this.$route.path === "/dashboard/training-course-list") {
        colorManager.setPlatformStyle();
      } else if (
        this.trainingCourse &&
        Object.keys(this.trainingCourse).length
      ) {
        colorManager.setPlatformStyle(this.trainingCourse);
      }
    },

    setLogoCfa() {
      if (this.$route.path === "/dashboard/training-course-list") {
        this.setLogoCfaPromotion(null);
      } else if (
        this.trainingCourse &&
        Object.keys(this.trainingCourse).length
      ) {
        trainingCourseManager.setNewCfaLogo(this.trainingCourse);
      }
    },

    setCfaDesign() {
      this.setColor();
      this.setLogoCfa();
    },

    getConfig() {
      this.fetchConfig();
      this.setNeedUpdate(false);
    },

    checkUserProfile() {
      this.setUserProfile(userManager.checkUserProfile(this.user, this.triad));
    },

    ...mapActions(useUserStore, {
      setUserProfileName: "setUserProfileName",
      setUserProfile: "setUserProfile",
    }),
    ...mapActions(useMainStore, { setAppLoading: "setAppLoading" }),
    ...mapActions(useTrainingCourseStore, {
      fetchTrainingCourseList: "fetchTrainingCourseList",
      fetchTrainingCourse: "fetchTrainingCourse",
    }),
    ...mapActions(useTriadStore, { changeTriad: "changeTriad" }),
    ...mapActions(usePlatformStore, {
      setLogoCfaPromotion: "setLogoCfaPromotion",
      fetchConfig: "fetchConfig",
      setNeedUpdate: "setNeedUpdate",
    }),
  },
};
</script>

<style scoped></style>
