<template>
  <div class="widget">
    <div class="widget-heading">
      <h2>
        {{
          $tc("global.view.title", 1, {
            name: $t("admin.log.title"),
          })
        }}
      </h2>
    </div>
    <div class="widget-content">
      <div class="row">
        <div class="col-lg-12 order-lg-0 order-1">
          <div class="loader-block">
            <div class="widget">
              <div class="widget-content">
                <div class="list">
                  <div class="list__data -heading">
                    <div class="list__column textStyle">
                      {{ $t("admin.log.modify_field") }}
                    </div>
                    <div class="list__column textStyle">
                      {{ $t("admin.log.old_data") }}
                    </div>
                    <div class="list__column textStyle">
                      {{ $t("admin.log.data") }}
                    </div>
                  </div>
                  <div
                    class="list__data"
                    v-for="(value, key) in log.data"
                    :key="key"
                  >
                    <div class="list__column">
                      <div class="d-flex align-items-start w-100">
                        {{ key }}
                      </div>
                    </div>
                    <div class="list__column">
                      <div
                        class="d-flex align-items-start w-100"
                        v-if="log.oldData"
                      >
                        <template v-if="key in log.oldData">
                          {{
                            log.oldData[key] === null ? "-" : log.oldData[key]
                          }}
                        </template>
                      </div>
                    </div>
                    <div class="list__column">
                      <div class="d-flex align-items-start w-100">
                        {{ value === null ? "-" : value }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";

export default {
  name: "LogViewView",
  data() {
    return {
      log: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.getDatas();
    },

    getDatas() {
      adminManager.get("log", this.$route.params.id).then((data) => {
        this.log = data;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  &__data {
    padding: 6px 0;
    border-bottom: 1px dashed $light-grey;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    animation: all 300ms ease-in-out;

    &.-heading {
      font-weight: bold;
      color: var(--primary-color);
    }

    &:hover {
      background: $m-color_9;

      @include dark-theme {
        background-color: $m-color_19;
      }
    }
  }

  &__column {
    width: 22%;
    padding-right: 10px;
    word-wrap: anywhere;
  }

  &__action {
    text-align: right;
    min-width: 50px;
  }
}
</style>
