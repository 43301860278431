<template>
  <div class="widget form-widget pb-2">
    <div class="widget-content">
      <div class="panel-body rounded-pills-icon tabs">
        <ul
          id="languageTab"
          class="nav nav-pills mb-4 mt-3 justify-content-center flex-md-row flex-column"
          role="tablist"
        >
          <li class="nav-item ms-md-2 me-md-2 me-0 ms-0" role="presentation">
            <a
              id="tab-absence-reason-tab"
              ref="ref-absence-reason"
              :aria-selected="true"
              aria-controls="tab-absence-reason"
              class="nav-link mb-2 text-center bgStyle active"
              data-bs-toggle="pill"
              href="#tab-absence-reason"
              role="tab"
            >
              <FontAwesomeIcon icon="fa-light fa-square-xmark" />
              {{
                $tc("global.list.title", 1, {
                  name: $tc("admin.absenceReason.title", 2).toLowerCase(),
                })
              }}
            </a>
          </li>
        </ul>
        <div id="absenceTabContent" class="tab-content">
          <div
            id="tab-absence-reason"
            aria-labelledby="tab-absence-reason-tab"
            class="tab-pane fade show active"
            role="tabpanel"
          >
            <router-link
              :to="{
                name: 'adminAbsenceReasonAdd',
                params: { alias: 'absence-reason' },
              }"
              class="btn btn-success btnStyle mb-3"
            >
              <FontAwesomeIcon class="me-2" icon="fa-light fa-plus" />
              {{
                $tc("global.add.title", 1, {
                  name: $tc("admin.absenceReason.title", 1).toLowerCase(),
                })
              }}
            </router-link>
            <SortTableComponent
              :actions="actions"
              :columns="reasonsColumns"
              :datas="reasons"
              :apiFilters="false"
              :defaultLimit="10"
              @remove="remove"
            />
            <BlockLoader :loading="loading" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminManager } from "@/services/admin/admin-manager";
import SortTableComponent from "@/components/table/sortTable/SortTableComponent.vue";
import { sortableTableManager } from "@/services/utilities/sortable-table-manager";
import BlockLoader from "@/components/blocks/BlockLoader.vue";

export default {
  name: "ModuleAbsenceConfigView",
  components: { BlockLoader, SortTableComponent },
  data() {
    return {
      reasons: [],
      reasonsColumns: [],
      actions: [],
      loading: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.initActions();
      this.loading = true;
      adminManager.list("absence-reason", true).then((data) => {
        this.reasons = data;
        if (data && data.length) {
          this.reasonsColumns = sortableTableManager.getColumns(
            this.reasons[0],
            "admin",
          );
        }
        this.loading = false;
      });
    },

    initActions() {
      this.actions = [
        {
          translation: "admin.modify",
          translationParams: { name: "name", id: "id" },
          icon: "pen",
          type: "success",
          actionType: "router",
          action: "adminAbsenceReasonEdit",
          cfaStyle: false,
          alias: "absence-reason",
        },
        {
          translation: "admin.delete",
          translationParams: { name: "name", id: "id" },
          icon: "trash",
          type: "danger",
          action: "remove",
          cfaStyle: false,
        },
      ];
    },

    remove(row) {
      adminManager.remove("absence-reason", row.id).then(() => {
        this.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
