import { defineStore } from "pinia";
import { apiConnection } from "@/services/api-connection";

export const useChatbotStore = defineStore({
  id: "Chatbot",
  state: () => {
    return {
      messages: [
        {
          content: "Bonjour, en quoi puis-je vous aider ?",
          writer: "chatbot",
        },
      ],
      loading: false,
    };
  },

  actions: {
    storeMessage(message) {
      this.messages.push(message);
    },
    clear() {
      this.messages = [];
    },
    chatbotRequest(message) {
      this.loading = true;
      const url = process.env.VUE_APP_CHATBOT_SERVEUR_URL + "/api/chatbot";
      return new Promise((resolve) => {
        apiConnection
          .fetchApi("POST", url, { message: message.content }, false)
          .then((res) => {
            if (res && res.response) {
              const content = {
                content: "",
                response: res.response,
                writer: "Chatbot",
                id:
                  Date.now().toString(36) + Math.random().toString(36).slice(2),
              };
              this.storeMessage(content);
              resolve(content);
            }
            this.loading = false;
          });
      });
    },
    chatbotLoading() {
      this.loading = true;
    },
    chatbotSuccess() {
      this.loading = false;
    },
    writeContent(id, value) {
      const message = this.messages.find((message) => message.id === id);
      if (message) {
        message.content = value;
      }
    },
    init() {
      this.messages = [
        {
          content: "Bonjour, en quoi puis-je vous aider ?",
          writer: "chatbot",
        },
      ];
      this.loading = false;
    },
  },
});
