export default {
  error: {
    error_occured: "An error has occurred",
    reload_page: "Refresh the page",
    back: "Back",
    message: {
      not_found: "The page you were looking for was not found!",
      unavailable: "Service not available!",
      not_authorized_title: "Access not authorised",
      not_authorized: "You are not authorised to access this page",
      unauthenticated: "You must be logged in to access this page",
    },
    go_back_to_home: "Home",
  },
};
