<template>
  <div class="d-flex">
    <button
      v-if="isFiltered"
      class="btn-reset me-2"
      v-tooltip="{ title: $t('init_search') }"
      type="button"
      @click="resetSearch"
    >
      <FontAwesomeIcon icon="fa-light fa-arrows-rotate" />
    </button>
    <button
      class="btn-search"
      v-tooltip="{ title: $t('search_motor') }"
      type="button"
      @click="openSearch"
    >
      <FontAwesomeIcon icon="fa-light fa-magnifying-glass" />
    </button>
  </div>
</template>

<script>
export default {
  name: "MagnifyingGlassComponent",
  emits: ["openSearch", "resetSearch"],
  props: {
    isFiltered: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    openSearch() {
      this.$emit("openSearch");
    },

    resetSearch() {
      this.$emit("resetSearch");
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search,
.btn-reset {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: $green;
  border: 0;
  color: $white;

  svg {
    width: 18px;
    height: 18px;
  }
}
</style>
