<template>
  <div v-for="category in stats" :key="category.label">
    <h5 class="mb-3 fw-bold">{{ category.label }}</h5>
    <div class="row">
      <div
        v-for="indicator in category.indicators"
        :key="indicator.label"
        :class="
          'mb-4 ' +
          (indicator.columns
            ? indicator.columns
            : 'col-xl-3 col-lg-4 col-md-3 col-sm-6')
        "
      >
        <div class="summary-list h-100 blockStyle">
          <div class="summery-info">
            <div class="w-summary-details">
              <div class="w-summary-info">
                <h6>
                  {{ indicator.label }}<br />
                  <span class="summary-count"
                    >{{ indicator.value
                    }}{{ indicator.unit ? indicator.unit : "" }}</span
                  >
                </h6>
                <button v-if="indicator.download" class="btn-icon -white">
                  <FontAwesomeIcon icon="fa-light fa-download" />
                </button>
                <button
                  v-if="indicator.sub_indicators"
                  class="btn-icon -white ms-2"
                >
                  <FontAwesomeIcon icon="fa-light fa-eye" />
                </button>
              </div>
              <div
                v-if="indicator.connected || indicator.unconnected"
                class="connected"
              >
                <div>Connecté : {{ indicator.connected }}%</div>
                <div>Non connecté : {{ indicator.unconnected }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberCardChart",
  components: {},
  props: {
    stats: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.summary-list {
  position: relative;
  padding: 15px;
  border-radius: 6px;

  h6 {
    font-size: 12px !important;
  }

  span {
    font-size: 20px !important;
  }

  h6,
  span {
    color: $white !important;
  }

  .connected {
    margin-top: 10px;
    color: $white;
    font-size: 12px;
    text-align: right;
  }
}
</style>
