<template>
  <h1 class="">
    <span class="fw-normal fs-6">{{ $t("resetPassword") }}</span
    ><br />
    <span class="brand-name textStyle">{{ name }}</span>
  </h1>
  <div v-if="message" class="badge badge-danger me-1 w-100">
    {{ message }}
  </div>
  <form class="text-start">
    <div id="username-field" class="field-wrapper input">
      <svg
        class="feather feather-user svgStyle"
        fill="none"
        height="24"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </svg>
      <input
        v-model="username"
        :placeholder="$t('username')"
        class="form-control"
        type="text"
        @input="checkInput('username')"
      />
      <div v-if="usernameError" class="invalid-feedback d-block">
        {{ usernameError }}
      </div>
    </div>
    <div class="field-wrapper text-center mt-4">
      <SubmitWithLoaderComponent
        :loading="loading"
        :text="$t('reinitialize')"
        @submit="reinitialize($event)"
      />
    </div>
  </form>
</template>

<script>
import { mapState } from "pinia";
import { loginManager } from "@/services/security/login-manager";
import { usePlatformStore } from "@/store/platform/platform";
import SubmitWithLoaderComponent from "@/components/utilities/SubmitWithLoaderComponent.vue";

export default {
  name: "ResetPasswordView",
  components: { SubmitWithLoaderComponent },
  data() {
    return {
      message: "",
      username: "",
      usernameError: "",
      loading: false,
    };
  },
  computed: {
    ...mapState(usePlatformStore, {
      design: (store) => store.design,
      name: (store) => store.name,
    }),
  },
  methods: {
    checkInput(input) {
      if (!this.$data[`${input}`]) {
        this.$data[`${input}Error`] = this.$t("requiredField");
      } else {
        this.$data[`${input}Error`] = "";
      }
    },

    reinitialize(e) {
      e.preventDefault();
      if (!this.usernameError) {
        this.loading = true;
        loginManager.resetPassword(this.username).then(() => {
          this.$router.push({ name: "Login" });
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
