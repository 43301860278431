import { userManager } from "@/services/security/user-manager";
import { pictureManager } from "@/services/utilities/picture-manager";
import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/main";
import * as Sentry from "@sentry/vue";
import { messengerMessageManager } from "@/services/messenger-message/messenger-message-manager";

export const useUserStore = defineStore({
  id: "User",
  state: () => {
    return {
      user: {},
      userPicture: "",
      userType: "",
      userProfile: null,
      userProfileName: "",
      permissions: {},
    };
  },

  actions: {
    setUserInfos(user) {
      this.user = user;
    },

    setUserPermissions(permissions) {
      this.permissions = permissions;
    },

    init() {
      this.user = {};
      this.userPicture = "";
      this.userType = "";
      this.userProfile = null;
      this.userProfileName = "";
      this.permissions = {};
    },

    setUserPicture(picture) {
      this.userPicture = pictureManager.setPicture(picture);
    },

    setUserProfile(userProfile) {
      this.userProfile = userProfile;
    },

    setUserProfileName(userProfileName) {
      this.userProfileName = userProfileName;
    },

    setUserNewMessage(userNewMessage) {
      this.user.userNewMessage = userNewMessage;
    },

    fetchUserInfos() {
      if (useMainStore().isInternet) {
        return userManager.loadUserInfos().then((user) => {
          if (user) {
            this.setUserInfos(user);
            this.setUserPicture(user.photo);
            if (
              process.env.VUE_APP_SENTRY_ENV === "prod" ||
              process.env.VUE_APP_SENTRY_ENV === "uat"
            ) {
              Sentry.setUser({ email: user.email, id: user.id });
            }
            messengerMessageManager.get();
          }
        });
      }
    },

    fetchPermissions(trainingCourse) {
      return userManager.loadPermissions(trainingCourse).then((permissions) => {
        if (permissions) {
          this.setUserPermissions(permissions);
        }
      });
    },

    postUserUpdate(payload) {
      return userManager
        .putUserInfos(payload.id, payload.datas)
        .then((success) => success);
    },
  },
});
