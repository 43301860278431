import { defineStore } from "pinia";

export const useErrorStore = defineStore({
  id: "Error",
  state: () => {
    return {
      status: false,
      code: null,
      message: null,
    };
  },

  actions: {
    setError(status) {
      if (!this.status) {
        this.status = status;
      }
    },

    setErrorCode(code) {
      this.code = code;
    },

    setMessage(message) {
      this.message = message;
    },

    init() {
      this.status = false;
      this.code = null;
      this.message = null;
    },
  },
});
