export default {
  choose_subject: "Choose a subject",
  show_lesson: "View lessons",
  course: "Lessons",
  lessonLink: "Link",
  activityDone: "Activities done",
  workToDo: "Work to be prepared",
  generalCourses: "General lessons",
  attachments: "Attachments",
  attachment: "Attachment",
  download: "Download",
  "Taught subject": "Taught subject",
};
