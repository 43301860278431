import { defineStore } from "pinia";
import { adminManager } from "@/services/admin/admin-manager";
import { skillManager } from "@/services/skill/skill-manager";
import { studeaManagerManager } from "@/services/studea-manager/studea-manager-manager";

export const useSkillStore = defineStore({
  id: "Skill",
  state: () => {
    return {
      skillConfig: [],
    };
  },
  actions: {
    fetchAdminSkillConfig() {
      return adminManager.getSkillConfig().then((response) => {
        this.skillConfig = response;
      });
    },
    fetchSkillConfig(trainingCourse, triad) {
      return skillManager
        .getSkillConfig(trainingCourse, triad)
        .then((response) => {
          this.skillConfig = response;
        });
    },

    fetchStudeaManagerSkillConfig(trainingCourse) {
      return studeaManagerManager
        .getStudeaManagerSkillConfig(trainingCourse)
        .then((response) => {
          this.skillConfig = response;
        });
    },
  },
});
