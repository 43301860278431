<template>
  <div>
    <div v-for="item in Object.entries(data)" :key="item[0]" class="item">
      <div class="item__label">
        {{ $t("dashboard.driverLicence.progress." + item[0]) }}
      </div>
      <div class="item__date">{{ item[1] ? formatDate(item[1]) : "--" }}</div>
    </div>
  </div>
</template>

<script>
import { dateFormatter } from "@/services/utilities/date-formatter";

export default {
  name: "DriverLicenceProgressComponent",
  components: {},
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    formatDate(date) {
      return dateFormatter.format(date, "short");
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  padding: 5px;

  &:nth-child(2n + 1) {
    background-color: $lighter-grey;
  }

  &__label {
    width: 200px;
    margin-right: 15px;
  }

  &__date {
    font-weight: bold;
    color: $blue;
  }
}
</style>
