export default {
  error: {
    error_occured: "Ha habido un error",
    reload_page: "Recargar la página",
    back: "Retroceder",
    message: {
      not_found: "La página que ha solicitado no se encuentra.",
      unavailable: "Servicio no disponible",
      not_authorized_title: "Acceso prohibido",
      not_authorized: "No tiene permiso para acceder a esta página",
      unauthenticated: "Debe autenticarse para acceder a esta página",
    },
    go_back_to_home: "Volver al principio",
  },
};
